
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import image from '../image/sideImage.jpg'
import flag from '../image/flag.png'
import { AuthContext } from '../Services/Authcontext';
import { MAX_RESUMES_LIMIT } from '../Services/commonurls';

const sidebar = () => {
    const { dispatch, CURRENT_RESUMES_COUNT } = useContext(AuthContext);
    const handleCreate = () => {
        dispatch({
            type: "ADD_BASIC_INFO",
            payload: ''
        });
        dispatch({
            type: 'ADD_RESUME_DATA_SKELETON',
            payload: null
        });
        dispatch({
            type: 'ADD_RESUMEID',
            payload: '',
        });
    }

    return (
        <>
            <div className="col-3 p-0 h-100 d-none d-lg-block">
                <div className="side-bar h-100 position-fixed w-25">
                    <div className='pt-5 px-4 text-center text-dark' style={{
                        backgroundImage: `url(${image})`, height: '100%', backgroundPosition: 'bottom',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}>
                        <div className="mt-5">
                            <h3 className='fw-bold px-2 mb-4'>Creating Resume, Simplified</h3>
                            <p className='small'>Create job worthy resume that help you get hired fast. Easy to use and done within minutes.</p>
                            {

                                (CURRENT_RESUMES_COUNT < MAX_RESUMES_LIMIT) ?
                                    <NavLink className="btn btn-lg btn-primary slide-btn mt-4 px-4 text-decoration-none" to='/rb-creation-option' onClick={() => handleCreate()}><img src={flag} width="20px" height="20px" alt="" className='float-start mt-1' />Create my resume </NavLink>
                                    :
                                    <button className="btn btn-lg btn-primary slide-btn mt-4 px-4 text-decoration-none w-100" data-bs-toggle="modal" data-bs-target="#maxAlertModal"><img src={flag} width="20px" height="20px" alt="" className='float-start mt-1' />Create my resume </button>
                            }
                            <p className='small text-primary mt-4'>Developed by hiring professionals</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default sidebar;