import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";
const ResumeTitle = ({ templateType, sections }) => {
  const { resumeDataSkeleton, personalInformation, basicInfo, resumeSkeleton, templateStatus } = useContext(AuthContext);
  const headerCard = () => {
    if (templateType === "ats_pro") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && basicInfo === '') || basicInfo === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeTitle">
          <div className="text-center mb-4">
            <h6 className="resume_name mb-0 fw-bold">
              <span className="mb-0 me-1" id="firstName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.first_name : personalInformation.first_name}</span>
              <span className="mb-0" id="lastName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.last_name : personalInformation.last_name}</span>
            </h6>
            <hr className="mt-1" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} />
            <span className="resume_common_heading" id="designation">
              {(resumeDataSkeleton && resumeDataSkeleton.basic_info && resumeDataSkeleton.basic_info.resume_title !== '') ? resumeDataSkeleton.basic_info.resume_title : basicInfo.resume_title}
            </span>
          </div>
          <br />
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && basicInfo === '') || basicInfo === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeTitle">
          <div className="row">
            <h2>
              <span className="mb-0 me-1" id="firstName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.first_name : personalInformation.first_name}</span>
              <span className="mb-0" id="lastName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.last_name : personalInformation.last_name}</span>
            </h2>
            <p className="fw-bold" id="designation">{basicInfo.resume_title}</p>
          </div>
        </li>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && basicInfo === '') || basicInfo === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeTitle">
          <div className="row">
            <h2>
              <span className="mb-0 me-1" id="firstName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.first_name : personalInformation.first_name}</span>
              <span className="mb-0" id="lastName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.last_name : personalInformation.last_name}</span>
            </h2>
            <p className="fw-bold" id="designation">{basicInfo.resume_title}</p>
          </div>
        </li>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && basicInfo === '') || basicInfo === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeTitle">
          <div className="row">
            <h2>
              <span className="mb-0 me-1" id="firstName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.first_name : personalInformation.first_name}</span>
              <span className="mb-0" id="lastName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.last_name : personalInformation.last_name}</span>
            </h2>
            <p className="fw-bold" id="designation">{basicInfo.resume_title}</p>
          </div>
        </li>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <>

        </>
      );
    }
  }
  return headerCard();
}

export default ResumeTitle;
