import React, { } from 'react';
import { NavLink } from 'react-router-dom';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'draft-js/dist/Draft.css'
import CustomOption from './CustomOption';
import RightSideResume from './RightSideResume';
import { AuthContext } from '../Services/Authcontext'
import { withRouter } from './WithRouter';
import NotificationCard from './NotificationCard';
import LeftHideButton from './LeftHideButton';
import MobileHeader from './MobileHeader';
import video from '../image/animation/job_summary.webm'

class JobSummary extends React.Component {
    static contextType = AuthContext
    constructor(props) {
        super(props)
        const html = '';
        const contentBlock = htmlToDraft(html);

        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            this.state = {
                editorState: editorState,
                isSubmit: false,
                suggestion: null,
                resumeTitle: null,
            };
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }


    hadleSetContent = (data) => {
        const html = (data) ? data : '';
        const contentBlock = htmlToDraft(html);

        if (contentBlock) {
            const editorState = this.state.editorState;

            let { contentBlocks, entityMap } = htmlToDraft(data);
            let contentState = Modifier.replaceWithFragment(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
            )
            const newEditorState = EditorState.push(editorState, contentState, 'insert-fragment');
            this.onEditorStateChange(newEditorState)
        }
    }


    onEditorStateChange = editorState => {
        this.setState({ editorState: editorState });
        document.getElementById('jobSummary').innerHTML = (this.state.editorState !== null) ? draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) : '';
    };
    handleSubmit(e) {
        e.preventDefault();
        const { dispatch } = this.context;
        dispatch({
            type: 'DATA_UPDATED',
            payload: true
        })
        let data = { profile_summary: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())), step_status: true };

        this.setState({ isSubmit: !this.state.isSubmit })
        dispatch({
            type: "ADD_JOB_SUMMARY",
            payload: data
        });
        dispatch({
            type: "ADD_RESUME_TITLE",
            payload: this.state.resumeTitle
        })
        for (var key in this.context.stepCompleteness) {
            if (this.context.stepCompleteness[key]['resume_step_id'] === Number(this.props.step)) {
                this.context.stepCompleteness[key]['step_status'] = true;
            }
        }
        this.props.setProgressBar(30)
        this.props.navigate(`/rb-step?step=${Number(this.props.step) + 1}`)
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.context.jobSummary) {
            this.hadleSetContent(this.context.jobSummary.profile_summary)
            document.getElementById('jobSummary').innerHTML = this.context.jobSummary.profile_summary;

        }
        if (this.context.resumeTitle) {
            this.setState({ resumeTitle: this.context.resumeTitle })
        }
    }
    render() {
        var step = Number(this.props.step)
        return (
            <>
                <MobileHeader setIsLoading={this.props.setIsLoading} Title={"Job Summary"} ProgressBar={this.props.progressBar} StepList={this.props.stepList} Step={Number(this.props.step)} />
                <div className="row ms-lg-0">
                    <div className="col-md-12 col-lg-5 bg-white p-lg-4 position-relative" id="id_left">
                        <LeftHideButton rightHide={this.props.rightHide} rightShow={this.props.rightShow} />
                        <div className="" id="id_left_side">
                            <NotificationCard
                                short_notes={
                                    <>
                                        <p className='mb-1 small'>Enter your current professional / job title in a way that industry generally calls it and write a short
                                            summary of your professional profile in about 6-8 lines.</p>
                                    </>
                                }
                                Notes={
                                    <>
                                        <p className='mb-1 small'>Enter your current professional / job title in a way that industry generally calls it and write a short
                                            summary of your professional profile in about 6-8 lines.</p>
                                        <p className='mb-1 small'>Mention total of how many years of work experience you have, one or two major achievements (e.g.
                                            Sales targets, awards, software delivered) and your two or three strong skills and/or competencies,
                                            related to job you intend to apply. You can also make use of pre-written phrases available.</p>
                                        <p className='mb-1 small'><span className='text-decoration-underline'>If you are a fresher</span>, focus on academic achievements including academic, skills learnt, personal
                                            projects and what you learnt or achieved during Internships. Also, worth mentioning what you aspire
                                            to achieve in your career. For title, you can say “Economics Graduate”, “Chemical Engineer” etc</p>
                                    </>
                                }
                                video={video}
                            />
                            <form onSubmit={this.handleSubmit} method="post" className="mt-4">
                                <div className="row m-2 m-md-0">
                                    <p className='text-muted small px-0'>PROFESSIONAL TITLE</p>
                                    <div className="mb-3 px-0">
                                        <input type="text" className="form-control" value={this.state.resumeTitle} onChange={(e) => {
                                            this.setState({ resumeTitle: e.target.value });
                                            document.getElementById("designation").innerHTML = e.target.value;

                                        }} name="resume_title" id="resume_title" placeholder="Resume Title" />
                                    </div>
                                    <p className='text-muted small px-0'>PROFESSIONAL SUMMARY</p>
                                    <Editor
                                        editorState={this.state.editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        spellCheck={true}
                                        stripPastedStyles={true}
                                        toolbar={{
                                            options: ['inline', 'fontSize', 'list', 'textAlign'],
                                            inline: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                        }}
                                        toolbarCustomButtons={[<CustomOption key={1} hadleSetContent={this.hadleSetContent} dataName="job_summary_content" type={`job_type=${this.context.basicInfo.job_type}`} />]}
                                        onEditorStateChange={this.onEditorStateChange}
                                    />
                                </div>
                                <div className="row m-2 m-md-0 mb-5 align-items-center">
                                    <div className="col p-0">
                                        <NavLink to={`/rb-step?step=${step - 1}`}>
                                            <button className="btn btn-outline-primary" onClick={() => { this.props.setProgressBar(10) }}>Back</button>
                                        </NavLink>
                                    </div>

                                    <div className="col p-0">
                                        <button className="btn btn-primary float-end" type='submit'>Next</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <RightSideResume />
                </div>
            </>
        );
    };
}

export default withRouter(JobSummary);