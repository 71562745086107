import React from 'react'

const TitleCard = ({ Title, Section, Description }) => {
    return (
        <>
            <div>
                <p className='fw-bold'>{Title}</p>
            </div>
        </>
    )
}

export default TitleCard