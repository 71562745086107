import React, { useContext } from "react";
import { Rating } from "react-simple-star-rating";
import { AuthContext } from "../Services/Authcontext";
const ResumeLanguage = ({ templateType, sections, suffle }) => {
  const { languages, resumeSkeleton, templateStatus } = useContext(AuthContext);

  const LanguageCard = () => {
    if (templateType === "ats_pro") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && languages === '') || languages === null || languages.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeLanguage">
          <h5 className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" style={{ backgroundColor: '#0000001a' }}>Language</h5>
          <div className="px-3 py-2">
            {
              languages && languages.map((language, index) => {
                return <p key={index} className="mb-1 fw-bold small">
                  {language.language}
                </p>

              })
            }
          </div>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "exec_classic") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && languages === '') || languages === null || languages.length <= 0) ? "d-none" : "resume_section list-group my-3"} draggable="true" id="ResumeLanguage">
          <h5 className="text-capitalize fw-bold mb-3 text-break exec_classic_border" style={{ color: resumeSkeleton.styles.theme_color }}>Language</h5>
          <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, width: '100px', padding: '1px' }} className="mt-0" />
          <div className="row">
            <div className="col-12">
              <ul className="" style={{ listStyleType: 'disc' }}>
                {
                  languages && languages.map((language, index) => {
                    return <li key={index}>{language.language}</li>
                  })
                }
              </ul>
            </div>
          </div>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && languages === '') || languages === null || languages.length <= 0) ? "d-none" : "resume_section list-group my-3"} draggable="true" id="ResumeLanguage">
          <h5 className="fw-bold text-capitalize text-break" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>Language</h5>
          <hr className="mt-0" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }} />

          <div className="row">
            <div className="col-3 fw-bold"></div>
            <div className="col-9">
              {
                languages && languages.map((language, index) => {
                  return <p key={index} className="mb-1">{language.language}</p>
                })
              }
            </div>
          </div>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && languages === '') || languages === null || languages.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeLanguage">
          <h5 className="fw-bold text-capitalize text-break">Language</h5>
          <div className="row mb-3">
            <div className="col-12">
              {/* {
                languages && languages.map((language, index) => {
                  return <p key={index} className="mb-1">{language.language}</p>
                })
              } */}
              {
                languages && languages.map((language, index) => {
                  return <span className="badge me-2 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{language.language}</span>
                })
              }
            </div>
          </div>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && languages === '') || languages === null || languages.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeLanguage">
          <div className="mb-2">
            <h5 className="fw-bold text-uppercase text-break">Language</h5>
            <div className="row mb-2">
              <div className="col-12">
                <ul className="px-3">
                  {
                    languages && languages.map((language, index) => {
                      return <li key={index}>{language.language}</li>
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
          <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && languages === '') || languages === null || languages.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeLanguage">
          <div className="ps-2 mb-3">
            <h5 className="text-uppercase d-flex align-items-center fw-bold mb-3 text-break"><i className="bi bi-translate icon_circle_dark" style={(resumeSkeleton.styles.theme_color === '#fff') ? { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#000' } : { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#fff' }}></i>languages</h5>
            <div className="row">
              <div className="col-12">
                {
                  languages && languages.map((language, index) => {
                    return (
                      <div key={index} className="mb-2 ps-1">
                        <p className="mb-0">{language.language}</p>
                        <Rating iconsCount={3} ratingValue={language.rating * 20} readonly={true} initialValue={language.rating * 20} size={20} />
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && languages === '') || languages === null || languages.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeLanguage">
          <div className="mb-3">
            <h5 className="fw-bol text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>Language</h5>
            <div className="row">
              <div className="col-12">
                <ul className="" style={{ listStyleType: 'disc' }}>
                  {
                    languages && languages.map((language, index) => {
                      return <li key={index}>{language.language}</li>
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
          <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && languages === '') || languages === null || languages.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeLanguage">
          <div className="mb-3">
            <h5 className="fw-bold text-dark text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>Language</h5>
            <div className="row">
              <div className="col-12">
                <ul className="" style={{ listStyleType: 'disc' }}>
                  {
                    languages && languages.map((language, index) => {
                      return <li key={index}>{language.language}</li>
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && languages === '') || languages === null || languages.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeLanguage">
          <div className="mb-3">
            <h5 className="fw-bold text-dark text-uppercase text-break ps-2">Language</h5>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, padding: '1px' }} className="mt-0" />
            <div className="row ps-3">
              <div className="col-12">
                {
                  languages && languages.map((language, index) => {
                    return <span className="badge me-1 mb-1 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color}} key={index}>{language.language}</span>
                  })
                }
              </div>
            </div>
          </div>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
  }

  return (
    <>
      {LanguageCard()}
    </>
  )
}
export default ResumeLanguage;
