import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";
const ResumeHobbies = ({ templateType, sections, suffle, format }) => {
  const { hobbies, resumeSkeleton, templateStatus, hobbiesTitle } = useContext(AuthContext);
  // const HobbiesListCard = ({ hobbiesData }) => {
  //   return (
  //     <div className="mb-2">
  //       <p className="mb-0 fw-bold small">{hobbiesData.title}</p>
  //     </div>
  //   )
  // }
  const hobbiesCard = () => {
    if (templateType === "ats_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && hobbies === '') || hobbies === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeHobbies">
            <h5 id="hobbie_title" className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" style={{ backgroundColor: '#0000001a' }}>{hobbiesTitle}</h5>
            <div className="px-3 py-2">
              {/* <ul style={{ listStyleType: 'disc' }}>
                {
                  hobbies && hobbies.map((hobbie, index) => {
                    return <li key={index}>{hobbie.title}</li>
                  })
                }
              </ul> */}
              {
                hobbies && hobbies.map((hobbie, index) => {
                  return <p key={index} className="mb-1">{hobbie.title}</p>
                })
              }
            </div>

            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && hobbies === '') || hobbies === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeHobbies">
            <h5 id="hobbie_title" className="text-capitalize fw-bold mb-3 text-break exec_classic_border" style={{ color: resumeSkeleton.styles.theme_color }}>{hobbiesTitle}</h5>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, width: '100px', padding: '1px' }} className="mt-0" />
            <ul style={{ listStyleType: 'disc' }}>
              {
                hobbies && hobbies.map((hobbie, index) => {
                  return <li key={index}>{hobbie.title}</li>

                })
              }
            </ul>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />

          </li>
        </>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && hobbies === '') || hobbies === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeHobbies">
            <h5 id="hobbie_title" className="fw-bold text-capitalize text-break" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{hobbiesTitle}</h5>
            <hr className="mt-0" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }} />
            <div className="row">
              <div className="col-3"></div>
              <div className="col-9">
                <div className="mb-3">
                  {
                    hobbies && hobbies.map((hobbie, index) => {
                      return <p key={index} className="mb-1">{hobbie.title}</p>
                    })
                  }
                  {/* <ul style={{ listStyleType: 'none' }}>
                    {
                      hobbies && hobbies.map((hobbie, index) => {
                        return <li key={index}>{hobbie.title}</li>
                      })
                    }
                  </ul> */}
                  {/* {
                    hobbies && hobbies.map((hobbie, index) => {
                      return <span className="badge me-2 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{hobbie.title}</span>
                    })
                  } */}
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />

          </li>
        </>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && hobbies === '') || hobbies === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeHobbies">
            <div className="mb-2">
              <h5 id="hobbie_title" className="text-uppercase fw-bold mb-3 text-break">{hobbiesTitle}</h5><div className="row ps-1">
                <div className="col-12">
                  <ul style={{ listStyleType: 'disc' }}>
                    {
                      hobbies && hobbies.map((hobbie, index) => {
                        return <li key={index}>{hobbie.title}</li>
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && hobbies === '') || hobbies === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeHobbies">
            <div className="mb-2">
              <h5 id="hobbie_title" className="text-capitalize fw-bold mb-3 text-break">{hobbiesTitle}</h5><div className="row ps-1">
                <div className="col-12">
                  {/* <ul style={{ listStyleType: 'disc' }}>
                    {
                      hobbies && hobbies.map((hobbie, index) => {
                        return <li key={index}>{hobbie.title}</li>
                      })
                    }
                  </ul> */}
                  {
                    hobbies && hobbies.map((hobbie, index) => {
                      return <span className="badge me-2 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{hobbie.title}</span>
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && hobbies === '') || hobbies === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeHobbies">
            <div className="ps-2 mb-3">
              <h5 id="hobbie_title" className="text-uppercase d-flex align-items-center fw-bold mb-3 text-break"><i className="bi bi-tv icon_circle_dark" style={(resumeSkeleton.styles.theme_color === '#fff') ? { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#000' } : { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#fff' }}></i>{hobbiesTitle}</h5>
              <div className="row ps-1">
                <div className="col-12">
                  <ul style={{ listStyleType: 'disc' }}>
                    {
                      hobbies && hobbies.map((hobbie, index) => {
                        return <li key={index}>{hobbie.title}</li>
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && hobbies === '') || hobbies === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeHobbies">
            <div className="mb-2">
              <h5 className="fw-bol text-uppercase mb-3 text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>{hobbiesTitle}</h5>
              <div className="row ps-1">
                <div className="col-12">
                  <ul style={{ listStyleType: 'disc' }}>
                    {
                      hobbies && hobbies.map((hobbie, index) => {
                        return <li key={index}>{hobbie.title}</li>
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && hobbies === '') || hobbies === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeHobbies">
            <div className="mb-2">
              <h5 id="hobbie_title" className="fw-bold text-dark text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>{hobbiesTitle}</h5>
              <div className="row ps-1">
                <div className="col-12">
                  <ul style={{ listStyleType: 'disc' }}>
                    {
                      hobbies && hobbies.map((hobbie, index) => {
                        return <li key={index}>{hobbie.title}</li>
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && hobbies === '') || hobbies === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeHobbies">
            <div className="mb-2">
              <h5 id="hobbie_title" className="fw-bold text-dark text-uppercase text-break ps-2">{hobbiesTitle}</h5>
              <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, padding: '1px' }} className="mt-0" />
              <div className="row ps-3">
                <div className="col-12">
                  {/* <ul style={{ listStyleType: 'disc' }}>
                    {
                      hobbies && hobbies.map((hobbie, index) => {
                        return <li key={index}>{hobbie.title}</li>
                      })
                    }
                  </ul> */}
                  {
                    hobbies && hobbies.map((hobbie, index) => {
                      return <span className="badge me-2 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{hobbie.title}</span>
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
  }

  return hobbiesCard();
}

export default ResumeHobbies;