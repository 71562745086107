export const MY_APP_TRACKER_API_LINK = 'myappl-tracker/application/create/'
export const USER_INFO_API_LINK = 'resume-builder/user-info/'
export const RESUME_DOWNLOAD_API_LINK = 'resume-mgr/myresume/download/'
export const RESUME_VIEW_API_LINK = 'resume-mgr/myresume/view/'
export const RESUME_LIST_API_LINK = 'resume-mgr/myresume/list/'
export const RESUME_CLONE_API_LINK = 'resume-mgr/myresume/clone/'
export const RESUME_DELETE_API_LINK = 'resume-mgr/myresume/delete/'
export const RESUME_TEMPLATE_SELECTION_API_LINK = 'resume-builder/template-selection/'
export const RESUME_DATA_CONTENT_API_LINK = 'resume-builder/data-content/'
export const RESUME_SAVE_API_LINK = 'resume-builder/save/'
export const RESUME_PDF_DELETE_API_LINK = 'resume-mgr/myresume/pdf-delete/'
export const IMAGE_UPLOAD_API_LINK = 'image-upload/'
export const RESUME_STEPS_API_LINK = 'resume-builder/step/'
export const SWITCH_TEMPLATE_API_LINK = 'resume-mgr/myresume/switch-template/'
export const RESUME_CREATE_API_LINK = 'resume-builder/resume/create/'
export const REFRESH_TOKEN_API_LINK = 'token/refresh/'
export const MAX_RESUMES_LIMIT = 10
export const SESSION_TIME = 30