import React from 'react'
import { Link } from 'react-router-dom'

const Error404 = () => {
    return (
        <>
            <div className="container h-100 d-flex justify-content-center">
                <div className="align-self-center text-center p-5">
                    <img src="/image/error_404.svg" alt='404' width={'100%'} />
                    <Link to={'/myresume'} className="btn btn-primary">Home</Link>
                </div>
            </div>
        </>
    )
}

export default Error404