import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../Services/Authcontext';
import axiosInstance from '../Services/axios';
import { RESUME_DATA_CONTENT_API_LINK, RESUME_TEMPLATE_SELECTION_API_LINK } from '../Services/commonurls';

const ChooseTemplateCategory = ({ setEmpty, setTemplates, setFilterTemplates }) => {
    const { dispatch, basicInfo, resumeDataSkeleton } = useContext(AuthContext);
    const [jobFamily, setJobFamily] = useState((resumeDataSkeleton && resumeDataSkeleton.basic_info && resumeDataSkeleton.basic_info.job_family !== '') ? resumeDataSkeleton.basic_info.job_family : (basicInfo.job_family) ? basicInfo.job_family : '');
    const [jobFamilyList, setJobFamilyList] = useState([]);
    const [jobGroup, setJobGroup] = useState((resumeDataSkeleton && resumeDataSkeleton.basic_info && resumeDataSkeleton.basic_info.job_group !== '') ? resumeDataSkeleton.basic_info.job_group : (basicInfo.job_group) ? basicInfo.job_group : '');
    const [jobGroupList, setJobGroupList] = useState([]);
    const [jobType, setJobType] = useState((resumeDataSkeleton && resumeDataSkeleton.basic_info && resumeDataSkeleton.basic_info.job_type !== '') ? resumeDataSkeleton.basic_info.job_type : (basicInfo.job_type) ? basicInfo.job_type : '');
    const [jobTypeList, setJobTypeList] = useState([]);
    const [Valid, setValid] = useState(false);
    const [isActive, setIsActive] = useState(true);

    const handleFetchData = async (setvalue, param) => {
        await axiosInstance.get(`${RESUME_DATA_CONTENT_API_LINK}?data_name=${param}`)
            .then((res) => {
                setvalue(res.data.data.results)
            });
    }

    const handleJobFamily = useCallback((data) => {
        let param = `job_group&job_family=${data}`;
        handleFetchData(setJobGroupList, param)
        setJobFamily(data)
    }, [])


    const handleJobGroup = useCallback((data) => {
        let param = `job_type&job_group=${data}`;
        handleFetchData(setJobTypeList, param)
        setJobGroup(data)
    },[])

    const handleJobType = (data) => {
        setJobType(data)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (jobFamily !== '' && jobGroup !== '' && jobType !== '') {
            let data = {
                job_family: jobFamily,
                job_group: jobGroup,
                job_type: jobType,
            }

            dispatch({
                type: "ADD_BASIC_INFO",
                payload: data
            })
            setValid(false)
            setEmpty(false)
            fetchTemplates();

        } else {
            setValid(!Valid)
        }
    }
    const fetchTemplates = async () => {
        await axiosInstance.get(`${RESUME_TEMPLATE_SELECTION_API_LINK}?job_family=${jobFamily}`)
            .then((response) => {
                setTemplates(response.data.data.resume_template_list);
                setFilterTemplates(response.data.data.resume_template_list);
            })
    }
    useEffect(() => {
        if (resumeDataSkeleton && resumeDataSkeleton.basic_info && resumeDataSkeleton.basic_info !== '') {
            setEmpty(false)
        } else {
            setEmpty(true)
        }
        if (isActive) {
            handleFetchData(setJobFamilyList, 'job_family');
            setIsActive(false);
        }
        if (jobFamily && jobFamily !== '') {
            handleJobFamily(jobFamily);
        }
        if (jobGroup && jobGroup !== '') {
            handleJobGroup(jobGroup);
        }
    }, [])
    return (
        <>
            <div className="d-flex justify-content-center align-items-center">
                <div className="col-lg-6 card border-0 rounded-4">
                    <div className='card-body p-4'>
                        <form action="" onSubmit={handleSubmit} method="post" className="">
                            {(Valid) ? <p className='text-danger'>Please fill all the fields.</p> : ''}

                            <div className="form-floating mb-3">
                                <select className="form-select" value={jobFamily} onChange={(e) => { handleJobFamily(e.target.value) }} name="job_family" id="job_family">
                                    <option value="">Select Job Family</option>

                                    {
                                        jobFamilyList && jobFamilyList.map((jobfamily, index) => {
                                            return <OptionCard key={index} value={jobfamily.code} selected={jobFamily} name={jobfamily.name} />
                                        })
                                    }
                                </select>
                                <label htmlFor="job_family">Job Family</label>
                            </div>
                            <div className="form-floating mb-3">
                                <select className="form-select" value={jobGroup} onChange={(e) => { handleJobGroup(e.target.value) }} name="job_group" id="job_group">
                                    <option value="">Select Job Group</option>

                                    {
                                        jobGroupList && jobGroupList.map((jobgroup, index) => {
                                            return <OptionCard key={index} value={jobgroup.code} selected={jobGroup} name={jobgroup.name} />
                                        })
                                    }
                                </select>
                                <label htmlFor="job_group">Job Group</label>
                            </div>
                            <div className="form-floating mb-3">
                                <select className="form-select" value={jobType} onChange={(e) => { handleJobType(e.target.value) }} name="type" id="type">
                                    <option value="">Select Job Type</option>

                                    {
                                        jobTypeList && jobTypeList.map((jobtype, index) => {
                                            return <OptionCard key={index} value={jobtype.code} selected={jobType} name={jobtype.name} />
                                        })
                                    }
                                </select>
                                <label htmlFor="type">Type</label>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Link to={'/rb-creation-option'} className="btn btn-outline-primary">Back</Link>
                                </div>
                                <div className="col text-end">
                                    <button className="btn btn-primary" type='submit'>Next</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChooseTemplateCategory

const OptionCard = ({ value, name, selected }) => {
    return <option value={value}>{name}</option>
}