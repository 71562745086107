import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";
const ResumePersonalDetails = ({ templateType, sections, format }) => {
  const { resumeDataSkeleton, personalInformation, resumeSkeleton, templateStatus } = useContext(AuthContext);
  const contactCard = () => {
    if (templateType === "ats_pro") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumePersonaldetails">
          <div id="resume_step2" className="">
            <h5 className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" style={{ backgroundColor: '#0000001a' }}>Contact</h5>
            <div className="px-3 py-2">
              <p className="mb-0 d-none">
                <span id="gender">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.gender !== '') ? resumeDataSkeleton.personalInformation.gender : personalInformation.gender}</span>
              </p>
              <p className="mb-2">
                <span className="fw-bold small capitalize d-block">Email</span>
                <span id="email" className="small text-break lh-1">
                  {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.email !== '') ? resumeDataSkeleton.personalInformation.email : personalInformation.email}
                </span>
              </p>
              <p className="mb-2">
                <span className="fw-bold small capitalize d-block">Phone</span>
                <span id="phone" className="small">
                  {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.phone_number !== '') ? resumeDataSkeleton.personalInformation.phone_number : personalInformation.phone_number}
                </span>
              </p>

              <p className="mb-2 d-none">
                <span className="fw-bold small capitalize d-block">Date of Birth</span>
                <span id="dob" className="small">{(personalInformation.dob) ? format(personalInformation.dob, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</span>
              </p>
              <p className="mb-2">
                <span className="fw-bold small capitalize d-block">Location</span>
                <span id="address" className="small d-none">
                  {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.address !== '') ? resumeDataSkeleton.personalInformation.address : personalInformation.address}
                </span>
                {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.address_line_2 !== '') ? ',' : ''}  <span id="address2" className="small d-none">
                  {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.address_line_2 !== '') ? resumeDataSkeleton.personalInformation.address_line_2 : personalInformation.address}
                </span>
                {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.city !== '') ? '' : ''} <span id="city">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.city !== '') ? resumeDataSkeleton.personalInformation.city : personalInformation.city}</span>{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.country !== '') ? '' : ', '}
                <span id="country">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.country !== '') ? resumeDataSkeleton.personalInformation.country : personalInformation.country}</span>
                {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.state !== '') ? ',' : ''} <span id="state" className="d-none">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.state !== '') ? resumeDataSkeleton.personalInformation.state : personalInformation.state}</span>
                {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.postalcode !== '') ? '' : ''} <span id="postal" className="d-none">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.postalcode !== '') ? resumeDataSkeleton.personalInformation.postalcode : personalInformation.postalcode}</span>
              </p>
            </div>
          </div>

          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>

      );
    }
    else if (templateType === "exec_classic") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumePersonaldetails">
          <h5 className="text-capitalize fw-bold mb-3 text-break exec_classic_border" style={{ color: resumeSkeleton.styles.theme_color }}>My Contact</h5>
          <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, width: '100px', padding: '1px' }} className="mt-0" />
          <div className="col-12">
            <p className="text-break m-2 ms-0 small">
              <span className="p-1 me-2"><i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-telephone-fill"></i></span> <span id="phone">{personalInformation.phone_number}</span>
            </p>
            <p className="text-break m-2 ms-0 small">
              <span className="p-1 me-2"><i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-envelope-fill"></i></span> <span id="email">{personalInformation.email}</span>
            </p>
            <p className="text-break m-2 ms-0 small">
              <span className="p-1 me-2"><i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-geo-alt-fill"></i> </span>
              <span className="d-none" id="address"> {personalInformation.address}{(personalInformation.address) ? ',' : ''} </span>
              <span className="d-none" id="address2"> {personalInformation.address}{(personalInformation.address_line_2 && personalInformation.address_line_2 !== '') ? ',' : ''} </span>
              <span className="" id="city"> {personalInformation.city}{(personalInformation.city) ? ',' : ''} </span>
              <span className="d-none" id="state"> {personalInformation.state}{(personalInformation.state) ? ',' : ''} </span>
              <span className="" id="country"> {personalInformation.country}{(personalInformation.country) ? '.' : ''} </span>
              <span className="d-none" id="postal"> {personalInformation.postalcode}{(personalInformation.postalcode) ? '.' : ''} </span>
            </p>
          </div>
          <p className="d-none" id="dob"></p>
          <p className="d-none" id="gender"></p>
          <p className="d-none" id=""></p>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} /><input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumePersonaldetails">
          <div className="col-12 pt-1 d-flex">
            <p className="mb-0 me-3">
              <span className="fw-bold me-1"> Email: </span> <span id="email">{personalInformation.email}</span></p>
            <p className="mb-0 me-3">
              <span className="fw-bold me-1"> Phone: </span> <span id="phone">{personalInformation.phone_number}</span> </p>
            <p className="mb-0 me-3">
              <span className="fw-bold me-1" id=""> Location: </span>
              <span className="me-1 d-none" id="address"> {personalInformation.address}{(personalInformation.address) ? ',' : ''}  </span>
              <span className="me-1 d-none" id="address2"> {personalInformation.address_line_2}{(personalInformation.address_line_2) ? ',' : ''}  </span>
              <span className="me-1" id="city"> {personalInformation.city}{(personalInformation.city) ? ', ' : ''} </span>
              <span className="me-1 d-none" id="state"> {personalInformation.state}{(personalInformation.state) ? ',' : ''} </span>
              <span className="me-1" id="country"> {personalInformation.country}{(personalInformation.country) ? '.' : ''} </span>
              <span className="me-1 d-none" id="postal"> {personalInformation.postalcode}{(personalInformation.postalcode) ? '.' : ''} </span>
            </p>
          </div>
          <p className="d-none" id="dob">{(personalInformation.dob) ? (personalInformation.dob) ? format(personalInformation.dob, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : '' : ""}</p>
          <p className="d-none" id="gender"></p>
          <p className="d-none" id=""></p>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <li className="resume_section list-group d-none" draggable="true" id="ResumePersonaldetails">
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} /><input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumePersonaldetails">

            <div className="col-12 pt-3">
              <table className="table">
                <thead style={{ borderStyle: 'none', borderColor: resumeSkeleton.styles.theme_color, borderWidth: '1px', borderTopStyle: 'solid' }}>
                  <tr className="" style={{ borderStyle: 'none', borderColor: resumeSkeleton.styles.theme_color, borderWidth: '1px', borderBottomStyle: 'solid' }}>
                    <th style={{ borderStyle: 'none', borderColor: resumeSkeleton.styles.theme_color, borderWidth: '1px', borderBottomStyle: 'solid' }}>Email</th>
                    <th style={{ borderStyle: 'none', borderColor: resumeSkeleton.styles.theme_color, borderWidth: '1px', borderBottomStyle: 'solid' }}>Phone</th>
                    <th style={{ borderStyle: 'none', borderColor: resumeSkeleton.styles.theme_color, borderWidth: '1px', borderBottomStyle: 'solid' }} className='text-end'>Location</th>
                  </tr>
                </thead>
                <tbody className="" style={{ borderStyle: 'none', borderColor: resumeSkeleton.styles.theme_color, borderWidth: '1px', borderBottomStyle: 'solid', borderTopStyle: 'solid' }}>
                  <tr>
                    <td><span id="email">{personalInformation.email}</span></td>
                    <td><span id="phone">{personalInformation.phone_number}</span></td>
                    <td className="text-end">
                      <span className="d-none" id="address"> {personalInformation.address}{(personalInformation.address) ? ',' : ''} </span>
                      <span className="d-none" id="address2"> {personalInformation.address_line_2}{(personalInformation.address_line_2) ? ',' : ''} </span>
                      <span className="" id="city"> {personalInformation.city}{(personalInformation.city) ? ',' : ''} </span>
                      <span className="d-none" id="state"> {personalInformation.state}{(personalInformation.state) ? ',' : ''} </span>
                      <span className="" id="country"> {personalInformation.country}{(personalInformation.country) ? '.' : ''} </span>
                      <span className="d-none" id="postal"> {personalInformation.postalcode}{(personalInformation.postalcode) ? '.' : ''} </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="d-none" id="dob">{(personalInformation.dob) ? format(personalInformation.dob, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</p>
            <p className="d-none" id="gender"></p>
            <p className="d-none" id=""></p>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumePersonaldetails">
            <div className="col-12">
              <p className="mb-0 text-break" id="phone"><span className="p-1 me-2"><i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-telephone-fill"></i></span>{personalInformation.phone_number}</p>
              <p className="mb-0 text-break" id="email"><span className="p-1 me-2"><i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-envelope-fill"></i></span>{personalInformation.email}</p>
              <p className="mb-0 text-break">
                <span className="p-1 me-2"><i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-geo-alt-fill"></i> </span>
                <span className="d-none" id="address"> {personalInformation.address}{(personalInformation.address) ? ',' : ''}  </span>
                <span className="d-none" id="address2"> {personalInformation.address_line_2}{(personalInformation.address_line_2) ? ',' : ''}  </span>
                <span className="" id="city"> {personalInformation.city}{(personalInformation.city) ? ',' : ''} </span>
                <span className="d-none" id="state"> {personalInformation.state}{(personalInformation.state) ? ',' : ''} </span>
                <span className="" id="country"> {personalInformation.country}{(personalInformation.country) ? '.' : ''} </span>
                <span className="d-none" id="postal"> {personalInformation.postalcode}{(personalInformation.postalcode) ? '.' : ''} </span>
              </p>
              <hr style={{ height: '10px', backgroundColor: resumeSkeleton.styles.theme_color, opacity: '1' }} />
            </div>
            <p className="d-none" id="dob">{(personalInformation.dob) ? format(personalInformation.dob, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</p>
            <p className="d-none" id="gender"></p>
            <p className="d-none" id=""></p>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group mb-"} draggable="true" id="ResumePersonaldetails">
            {/* <div className="col-12 pb-3">
              <div className="">
                <p className="mb-0 d-none">
                  <span id="gender">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.gender !== '') ? resumeDataSkeleton.personalInformation.gender : personalInformation.gender}</span>
                </p>
                <p className="mb-1">
                  <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-telephone-fill fs-6"></i>
                  <span id="phone" className="ps-2">
                    {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.phone_number !== '') ? resumeDataSkeleton.personalInformation.phone_number : personalInformation.phone_number}
                  </span>
                </p>
                <p className="mb-1">
                  <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-envelope-fill fs-6"></i>
                  <span id="email" className="ps-2">
                    {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.email !== '') ? resumeDataSkeleton.personalInformation.email : personalInformation.email}
                  </span>
                </p>
                <p className="mb-1 d-none">
                  <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-tv-fill fs-6"></i>
                  <span id="dob" className="ps-2">{(resumeDataSkeleton && resumeDataSkeleton.personlInformation && resumeDataSkeleton.personalInformation.dob !== '') ? resumeDataSkeleton.personalInformation.dob : personalInformation.dob}</span>
                </p>
                <p className="mb-1">
                  <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-house-door-fill fs-6"></i>
                  <span id="address" className="ps-2 d-none">
                    {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.address !== '') ? resumeDataSkeleton.personalInformation.address : ''}
                  </span>
                  <span id="address2" className="ps-2 d-none">
                    {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.address_line_2 !== '') ? ', ' + resumeDataSkeleton.personalInformation.address_line_2 : ''}
                  </span>
                  <span className="ps-2" id="city">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.city !== '') ? ', ' + resumeDataSkeleton.personalInformation.city : personalInformation.city}</span>
                </p>
              </div>
            </div>
            <p className="d-none" id="dob">{(personalInformation.dob) ? format(personalInformation.dob, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</p>
            <p className="d-none" id="gender"></p>
            <p className="d-none" id=""></p>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" /> */}
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumePersonaldetails">
            <div className="col-12 pb-3 ps-4">
              <div className="">
                <p className="mb-0 d-none">
                  <span id="gender">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.gender !== '') ? resumeDataSkeleton.personalInformation.gender : personalInformation.gender}</span>
                </p>
                <p className="mb-1">
                  <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-telephone-fill fs-6"></i>
                  <span id="phone" className="ps-2">
                    {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.phone_number !== '') ? resumeDataSkeleton.personalInformation.phone_number : personalInformation.phone_number}
                  </span>
                </p>
                <p className="mb-1">
                  <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-envelope-fill fs-6"></i>
                  <span id="email" className="ps-2">
                    {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.email !== '') ? resumeDataSkeleton.personalInformation.email : personalInformation.email}
                  </span>
                </p>
                <p className="mb-1 d-none">
                  <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-tv-fill fs-6"></i>
                  <span id="dob" className="ps-2">{(resumeDataSkeleton && resumeDataSkeleton.personlInformation && resumeDataSkeleton.personalInformation.dob !== '') ? resumeDataSkeleton.personalInformation.dob : personalInformation.dob}</span>
                </p>
                <p className="mb-1">
                  <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-house-door-fill fs-6 pe-2"></i>
                  <span id="address" className="ps-2 d-none">
                    {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.address !== '') ? resumeDataSkeleton.personalInformation.address : ''}
                  </span>
                  <span id="address2" className="ps-2 d-none">
                    {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.address_line_2 !== '') ? ', ' + resumeDataSkeleton.personalInformation.address_line_2 : ''}
                  </span>
                  <span id="postal" className="d-none">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.postalcode !== '') ? resumeDataSkeleton.personalInformation.postalcode : personalInformation.postalcode}</span>
                  <span id="city" className="">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.city !== '') ? ', ' + resumeDataSkeleton.personalInformation.city : personalInformation.city}</span>
                  <span className="d-none" id="state"> {personalInformation.state}{(personalInformation.state) ? ',' : ''} </span>
                  <span id="country" className="">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.country !== '') ? ', ' + resumeDataSkeleton.personalInformation.country : ', ' + personalInformation.country}</span>
                </p>
              </div>
            </div>
            <p className="d-none" id="dob">{(personalInformation.dob) ? format(personalInformation.dob, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</p>
            <p className="d-none" id="gender"></p>
            <p className="d-none" id=""></p>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group mb-"} draggable="true" id="ResumePersonaldetails">
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
  }
  return (
    <>{contactCard()}</>
  )
}
export default ResumePersonalDetails;
