import React, { useContext } from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../Services/Authcontext';
import axiosInstance from '../Services/axios';
import { RESUME_DOWNLOAD_API_LINK, RESUME_PDF_DELETE_API_LINK } from '../Services/commonurls';

const FixedIcons = ({ handleflip, flip, page }) => {
    const { dispatch, resumeDataSkeleton, isUpdated, resumeID } = useContext(AuthContext)
    const hexToRgbA = (hex, opacity) => {
        let c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = `0x${c.join('')}`;
            return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
        }
        throw new Error('Bad Hex');
    };
    const resumeColoreChange = (id) => {
        var localResumeSkeleton = JSON.parse(localStorage.getItem("ADD_RESUME_SKELETON_COPY"));
        localResumeSkeleton.styles.theme_color = id;
        localResumeSkeleton.styles.rbga_color = hexToRgbA(id, 1);
        localStorage.setItem("ADD_RESUME_SKELETON_COPY", JSON.stringify(localResumeSkeleton));
        dispatch({
            type: 'UPDATE_THEME_COLOR',
            payload: id
        })
        dispatch({
            type: 'UPDATE_THEME_RBGA_COLOR',
            payload: hexToRgbA(id, 1)
        })
    }
    const downloadResume = (resumeId) => {
        let data = { "resume_id": resumeId }
        // this.props.setIsLoading(true)
        axiosInstance.post(RESUME_DOWNLOAD_API_LINK, data).then((res) => {
            let url = { "resume_url": res.data.data.resume_url }
            var isIphone = /(iPhone)/i.test(navigator.userAgent);
            // eslint-disable-next-line
            var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
            if (isIphone && isSafari) {
                // var popup_window = window.open(res.data.data.resume_url, '_blank');
                // try {
                //     popup_window.focus();
                // } catch (e) {
                //     alert("Pop-up Blocker is enabled! Please add this site to your exception list.");
                // }
                let link = document.createElement('a');
                link.setAttribute('href', res.data.data.resume_url);
                link.click();
                link.remove();
            }
            else {
                let link = document.createElement('a');
                link.setAttribute('target', '_blank');
                link.setAttribute('rel', 'noopener noreferrer');
                link.setAttribute('href', res.data.data.resume_url);
                link.click();
                link.remove();
            }
            // this.props.setIsLoading(false)
            setTimeout(() => {
                axiosInstance.post(RESUME_PDF_DELETE_API_LINK, url).then((res) => {
                })
            }, 5000);
        });
    }

    const resumeDateFormatChange = (id) => {
        dispatch({
            type: 'UPDATE_THEME_DATE_FORMAT',
            payload: id
        })
        var localResumeSkeleton = JSON.parse(localStorage.getItem("ADD_RESUME_SKELETON_COPY"));
        localResumeSkeleton.styles.theme_date_format = id;
        localStorage.setItem("ADD_RESUME_SKELETON_COPY", JSON.stringify(localResumeSkeleton));
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <div>
                    <span
                        className="bg-white rounded-circle d-inline-block mb-2 text-secondary-yellow p-1" onClick={() => resumeColoreChange('#ff9800')} id="resume_yellow"
                        type="button">
                        <i className="bi bi-circle-half fs-4"></i>
                    </span>
                    <span
                        className="bg-white rounded-circle d-inline-block mb-2 text-secondary-violet p-1" onClick={() => resumeColoreChange('#7356BF')} id="resume_violet"
                        type="button">
                        <i className="bi bi-circle-half fs-4"></i>
                    </span>
                </div>
                <div>
                    <span
                        className="bg-white rounded-circle d-inline-block mb-2 text-secondary-darkgreen p-1" onClick={() => resumeColoreChange('#006787')}
                        id="resume_dark_green" type="button">
                        <i className="bi bi-circle-half fs-4"></i>
                    </span>
                    <span
                        className="bg-white rounded-circle d-inline-block mb-2 text-secondary-lightgreen p-1" onClick={() => resumeColoreChange('#01AB91')}
                        id="resume_light_green" type="button">
                        <i className="bi bi-circle-half fs-4"></i>
                    </span>
                </div>
                <div>
                    <span
                        className="bg-white rounded-circle d-inline-block mb-2 text-secondary-brown p-1" onClick={() => resumeColoreChange('#99644C')} id="resume_brown"
                        type="button">
                        <i className="bi bi-circle-half fs-4"></i>
                    </span>
                    <span
                        className="bg-white rounded-circle d-inline-block mb-2 text-secondary-blue p-1" onClick={() => resumeColoreChange('#0c539d')} id="resume_blue"
                        type="button">
                        <i className="bi bi-circle-half fs-4"></i>
                    </span>
                </div>
                <div>
                    <span
                        className="bg-white rounded-circle d-inline-block mb-2 text-dark p-1" onClick={() => resumeColoreChange('#000')} id="resume_dark"
                        type="button">
                        <i className="bi bi-circle-half fs-4"></i>
                    </span>
                    <span
                        className="bg-white rounded-circle d-inline-block mb-2 p-1 text-gray" onClick={() => resumeColoreChange('#fff')} id="resume_white"
                        type="button">
                        <i className="bi bi-circle-half fs-4"></i>
                    </span>
                </div>
            </Popover.Body>
        </Popover >
    );

    const DateFomart = (
        <Popover id="date-format">
            <Popover.Body>
                <div>
                    <span id='dd' className='cursor-pointer d-block mb-2' onClick={() => { resumeDateFormatChange("dd/mm/yyyy") }}>dd/mm/yyyy</span>
                    <span id='mm' className='cursor-pointer d-block mb-2' onClick={() => { resumeDateFormatChange("mm/dd/yyyy") }}>mm/dd/yyyy</span>
                    <span id='yyyy' className='cursor-pointer d-block' onClick={() => { resumeDateFormatChange("yyyy/mm/dd") }}>yyyy/mm/dd</span>
                </div>
            </Popover.Body>
        </Popover >
    );
    return (
        <>
            <div className="">
                <div className="position-absolute floating-icons">
                    <OverlayTrigger rootClose trigger="click" placement="left" overlay={popover}>
                        <span className={(page === 'preview') ? "d-none" : "bg-white rounded-circle d-inline-block mb-2 p-1"} title='Change Color'><img
                            src={process.env.PUBLIC_URL + "/image/icons/color_icon.png"} alt="" width="25" height="25" /></span>
                    </OverlayTrigger>
                    <OverlayTrigger rootClose trigger="click" placement="left" overlay={DateFomart}>
                        <span className="bg-white rounded-circle d-inline-block mb-2 p-1" title='Change Date Format' style={{ width: '35px', height: "35px" }}><i className='bi bi-calendar-check'></i></span>
                    </OverlayTrigger>
                    {/* <a href={(process.env.NODE_ENV === 'development') ? process.env.REACT_APP_WEBSITE_URL + `api/preview/resume/${resumeID}/?token=${localStorage.getItem('accessToken')}` : process.env.REACT_APP_PROD_API_URL + `preview/resume/${resumeID}/?token=${localStorage.getItem('accessToken')}`} title='Download' target={'_blank'} role={'button'} className="btn bg-white rounded-circle d-flex justify-content-center align-items-center fixed-icon mb-2" ><i className="bi bi-download fs-5"></i></a> */}
                    <button type='button' title='Download' target={'_blank'} className="btn bg-white rounded-circle d-flex justify-content-center align-items-center fixed-icon mb-2" onClick={() => { downloadResume(resumeID) }}><i className="bi bi-download fs-5"></i></button>

                    <span title='Flip' className="btn bg-white rounded-circle d-flex justify-content-center align-items-center fixed-icon mb-2" onClick={() => { handleflip((flip) ? false : true) }}><i className="bi bi-shuffle fs-5" ></i></span>
                    {
                        (resumeDataSkeleton && resumeDataSkeleton.resume_document_pdf !== '' && isUpdated === false) ? (<>
                            <Link to={'/rb-template-selection'} title='Change Template' role={"button"} className={(page === 'preview') ? 'd-none' : "bg-white rounded-circle d-flex justify-content-center align-items-center fixed-icon mb-2 text-dark"}><i className="bi bi-file-earmark fs-5 "></i></Link>
                        </>) : (<>
                            <div role={'button'} title='Change Template' data-bs-toggle="modal" data-bs-target="#templateChangeAlertModal" className={(page === 'preview') ? 'd-none' : "bg-white rounded-circle d-flex justify-content-center align-items-center fixed-icon mb-2"}><i className="bi bi-file-earmark fs-5 "></i></div>
                        </>)
                    }

                    <Link to={'/myresume'} title='Preview' className={(page !== 'preview') ? 'd-none' : "btn bg-white rounded-circle d-flex justify-content-center align-items-center fixed-icon mb-2"} ><i className="bi bi-skip-backward fs-5"></i></Link>


                </div>
            </div>
        </>
    );
}


export default FixedIcons;