import React from 'react';
import { NavLink } from 'react-router-dom';
import RightSideResume from './RightSideResume';
import { withRouter } from './WithRouter';
import { nanoid } from 'nanoid';
import MobileHeader from './MobileHeader';
import { AuthContext } from '../Services/Authcontext';
import LeftHideButton from './LeftHideButton';
import NotificationCard from './NotificationCard';
import video from '../image/animation/certificate.webm'


class Certification extends React.Component {
    static contextType = AuthContext

    constructor(props) {
        super(props)
        this.state = {
            showForm: false,
            isEdit: false,
            editId: '',
            NameOfCertificate: '',
            CertificateBy: '',
            CertificateDate: '',
            CertificateDescription: '',
            certificateList: [
                // {
                //     id: "default",
                //     NameOfCertificate: 'Certificate Name goes here',
                //     CertificateBy: 'Certified by',
                //     CertificateDate: 'Issued on',
                //     CertificateDescription: '',
                // },
            ]
        };
        this.handleNext = this.handleNext.bind(this);
        this.handleForm = this.handleForm.bind(this);
        this.handleCertificateDelete = this.handleCertificateDelete.bind(this);
        this.handleCertificateEdit = this.handleCertificateEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleForm() {
        this.setState({ showForm: !this.state.showForm })
    }

    handleCertificateEdit(id) {
        this.setState({ isEdit: !this.state.isEdit })
        this.setState({ editId: id })
        this.setState({ showForm: !this.state.showForm })
        const EditCertificate = this.state.certificateList.filter(certificate => certificate.id === id);
        EditCertificate.map((editData) => {
            this.setState({ id: editData.id });
            this.setState({ NameOfCertificate: editData.NameOfCertificate });
            this.setState({ CertificateBy: editData.CertificateBy });
            this.setState({ CertificateDate: editData.CertificateDate });
            this.setState({ CertificateDescription: editData.CertificateDescription });
            return '';
        });
    }

    handleCertificateDelete(id) {
        const { dispatch } = this.context;
        const deleteCertificate = this.state.certificateList.filter(certificate => certificate.id !== id);
        this.setState({ certificateList: deleteCertificate })
        setTimeout(() => {
            dispatch({
                type: 'ADD_CERTIFICATE',
                payload: this.state.certificateList
            })
        }, 1000)
    }

    handleSubmit(e, formType) {
        const { dispatch } = this.context;
        e.preventDefault();
        dispatch({
            type: 'DATA_UPDATED',
            payload: true
        })
        if (formType === 'add') {
            const addCertificate = {
                id: nanoid(),
                NameOfCertificate: this.state.NameOfCertificate,
                CertificateBy: this.state.CertificateBy,
                CertificateDate: this.state.CertificateDate,
                CertificateDescription: this.state.CertificateDescription,
            }
            this.setState({ certificateList: [...this.state.certificateList, addCertificate] })

        } else {
            const EditCertificate = this.state.certificateList.map(certificate => {
                if (certificate.id === this.state.editId) {
                    return {
                        ...certificate, NameOfCertificate: this.state.NameOfCertificate,
                        CertificateBy: this.state.CertificateBy,
                        CertificateDate: this.state.CertificateDate,
                        CertificateDescription: this.state.CertificateDescription,
                    };
                }
                return certificate;
            });
            this.setState({ certificateList: EditCertificate })
            this.setState({ isEdit: !this.state.isEdit })
            this.setState({ editId: '' })
        }
        this.setState({ showForm: !this.state.showForm })
        this.setState({ id: '' });
        this.setState({ NameOfCertificate: '' });
        this.setState({ CertificateBy: '' });
        this.setState({ CertificateDate: '' });
        this.setState({ CertificateDescription: '' });

        setTimeout(() => {
            dispatch({
                type: 'ADD_CERTIFICATE',
                payload: this.state.certificateList
            })
        }, 1000)
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.context.certificate) {
            this.setState({ certificateList: this.context.certificate })
        }
    }

    handleNext(e) {
        const { dispatch } = this.context;
        let data = this.state.certificateList.filter(certificate => certificate.id !== "default");
        dispatch({
            type: "ADD_CERTIFICATE",
            payload: data
        });
        for (var key in this.context.stepCompleteness) {
            if (this.context.stepCompleteness[key]['resume_step_id'] === Number(this.props.step)) {
                this.context.stepCompleteness[key]['step_status'] = true;
            }
        }
        // this.props.setProgressBar(60)
        this.props.navigate(`/rb-step?step=${Number(this.props.step) + 1}`);
    }

    render() {
        const step = Number(this.props.step)
        return (
            <>
                <MobileHeader setIsLoading={this.props.setIsLoading} Title={"Certification"} ProgressBar={this.props.progressBar} StepList={this.props.stepList} Step={Number(this.props.step)} />
                <div className="row ms-lg-0">
                    <div className="col-md-12 col-lg-5 bg-white p-lg-4 position-relative" id="id_left">
                        <LeftHideButton rightHide={this.props.rightHide} rightShow={this.props.rightShow}  />
                        <div className="" id="id_left_side">
                            <NotificationCard
                                short_notes={
                                    <>
                                        <p className='mb-1 small'>Let’s move on to certifications, provide any specific certification courses you have attended to
                                            enhance your knowledge in a field related to the job you are applying.</p>
                                    </>
                                }
                                Notes={
                                    <>
                                        <p className='mb-1 small'>Let’s move on to certifications, provide any specific certification courses you have attended to
                                            enhance your knowledge in a field related to the job you are applying.</p>
                                        <p className='mb-1 small'>Enter the certification name, provider name and date obtained. Optionally you can more detail in
                                            description section which may not be includes in all the templates.</p>
                                    </>
                                } video={video} />
                            <form onSubmit={(e) => { this.handleSubmit(e, (this.state.isEdit) ? 'edit' : 'add') }} method="post" className={(this.state.showForm) ? "mt-4" : "d-none"}>
                                <div className="form-floating mb-3">
                                    <input type="text" name="certificate_name" id="certificate_name" value={this.state.NameOfCertificate} onChange={(e) => { this.setState({ NameOfCertificate: e.target.value }) }}
                                        className="form-control" placeholder="Certificate Name 1" />
                                    <label htmlFor="certificate_name">Name of certification</label>
                                </div>
                                <div className="row mb-3">
                                    <div className="col pe-2">
                                        <div className="form-floating">
                                            <input type="text" name="certified_by" id="certified_by" value={this.state.CertificateBy} onChange={(e) => { this.setState({ CertificateBy: e.target.value }) }}
                                                className="form-control" placeholder="Certified by" />
                                            <label htmlFor="certified_by">Certified by</label>
                                        </div>
                                    </div>
                                    <div className="col ps-2">
                                        <div className="form-floating">
                                            <input type="month" name="certificate_date" id="certificate_date" value={this.state.CertificateDate} onChange={(e) => { this.setState({ CertificateDate: e.target.value }) }} className="form-control"
                                                placeholder="Date" />
                                            <label htmlFor="certificate_date">Date </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-floating mb-3">
                                    <textarea name="certificate_description" id="certificate_description" style={{ height: '100px' }} className="form-control"
                                        value={this.state.CertificateDescription} onChange={(e) => { this.setState({ CertificateDescription: e.target.value }) }}
                                        placeholder="Description"></textarea>
                                    <label htmlFor="certificate_description">Description </label>
                                </div>
                                <div className="row mb-3 mx-0">
                                    <div className="col p-0">
                                        <button className="btn btn-outline-primary mt-3" type="button" onClick={this.handleForm}>Cancel</button>
                                    </div>
                                    <div className="col p-0">
                                        <button type='submit' className="btn btn-primary mt-3 float-end" id="add_new_job_mobile">Add</button>
                                    </div>
                                </div>
                            </form>
                            <div className={(this.state.showForm) ? "d-none" : ''}>
                                {
                                    this.state.certificateList.map((certificate, index) => {
                                        return <CertificateCard key={index} handleCertificateDelete={this.handleCertificateDelete} handleCertificateEdit={this.handleCertificateEdit} Id={certificate.id} NameOfCertificate={certificate.NameOfCertificate} CertificateBy={certificate.CertificateBy} CertificateDate={certificate.CertificateDate} CertificateDescription={certificate.CertificateDescription} />
                                    })
                                }
                            </div>
                            <div id="job_view_mobile" className={(this.state.showForm) ? "d-none" : ''}>
                                <div className="d-grid">
                                    <button className="px-3 mt-3 add_job_btn btn" id="add_job_btn_mobile"
                                        onClick={this.handleForm}><i className="bi bi-plus"></i> Add Certificate</button>
                                </div>
                                <div className="row mx-0 mb-4">
                                    <div className="col p-0">
                                        <NavLink to={`/rb-step?step=${step - 1}`} className="btn bg-transparent text-primary btn-outline-primary mt-3" role={'button'}>Back</NavLink>
                                        <NavLink to={`/rb-step?step=${step + 1}`} className="btn bg-transparent text-primary btn-outline-primary mt-3 mx-2" role={'button'} onClick={this.handleNext}>Skip</NavLink>
                                    </div>
                                    <div className="col p-0">
                                        <button type='button' className="btn btn-primary mt-3 float-end" onClick={this.handleNext}>Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <RightSideResume />
                </div>
            </>
        );
    }

}

const CertificateCard = ({ Id, NameOfCertificate, CertificateBy, CertificateDate, CertificateDescription, handleCertificateDelete, handleCertificateEdit }) => {
    return (
        <>
            <div className="row pt-3 mx-0 ms-0 border mt-2">
                <div className="col-2 text-center">
                    <img src="/image/icons/Certified.svg" alt='icon' />
                </div>
                <div className="col-10 ps-lg-0">
                    <p className="fw-bold small mb-0">{NameOfCertificate}</p>
                    <p className="text-muted small mb-0">{CertificateBy}</p>
                    <p className="text-muted small mb-0">{(CertificateDate) ? CertificateDate : ''}</p>
                    <p className="text-muted small mb-0">{CertificateDescription}</p>
                    <div className="row mx-0 mb-3 text-end">
                        <div className='col-lg-10'></div>
                        <div className="col-1">
                            <span className="text-end text-decoration-underline text-danger" onClick={() => { handleCertificateDelete(Id) }}> <i className="bi bi-trash"></i> </span>
                        </div>
                        <div className="col-1">
                            <span className="text-end text-decoration-underline text-primary" onClick={() => { handleCertificateEdit(Id) }}> <i className="bi bi-pencil"></i> </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


// const addjobopen = () => {
//     var element1 = document.querySelector('#add_job');
//     element1.classList.remove("d-none");
//     element1.classList.add("d-block");
//     document.querySelector('#job_view').classList.add("d-none");
//     var element2 = document.querySelector('#job_view_list');
//     element2.classList.remove("d-block");
//     element2.classList.add("d-none");
// }
// const addnewjob = () => {
//     var element3 = document.querySelector('#add_job');
//     element3.classList.remove("d-block");
//     element3.classList.add("d-none");
//     var element4 = document.querySelector('#job_view_list');
//     element4.classList.remove("d-none");
//     element4.classList.add("d-block");
// }

// //for mobile
// function addjobopenmobile() {
//     var element5 = document.querySelector('#add_job_mobile');
//     element5.classList.remove("d-none");
//     element5.classList.add("d-block");
//     document.querySelector('#job_view_mobile').classList.remove("d-block");
//     document.querySelector('#job_view_mobile').classList.add("d-none");
//     var element6 = document.querySelector('#job_view_list_mobile');
//     element6.classList.remove("d-block");
//     element6.classList.add("d-none");
// }

// function addnewjobmobile() {
//     var element7 = document.querySelector('#add_job_mobile');
//     element7.classList.remove("d-block");
//     element7.classList.add("d-none");
//     var element8 = document.querySelector('#job_view_list_mobile');
//     element8.classList.remove("d-none");
//     element8.classList.add("d-block");
//     document.querySelector('#job_view_mobile').classList.add("d-block");
//     document.querySelector('#job_view_mobile').classList.remove("d-none");
// }
export default withRouter(Certification);