import React from "react";
import $ from "jquery";
import "jquery-ui";
import FixedIcons from "../Components/FixedIcons";
import ResumeTemplates from "./ResumeTemplates";
import "../css/resumeformat.css";
import html2pdf from 'html2pdf.js'
import { AuthContext } from "../Services/Authcontext";
import { flip } from "@popperjs/core";

require("jquery-ui");
require("jquery-ui/ui/widgets/sortable");
require("jquery-ui/ui/disable-selection");

class RightSideResume extends React.Component {
  static contextType = AuthContext
  constructor(props) {
    super(props);
    this.state = {
      suffle: false,
      flip: true
    };
  }

  generatePdf = () => {
    document.getElementById('profileImage').style.borderRadius = '50%';

    var element = document.getElementById('pdf');
    var opt = {
      margin: 0,
      filename: document.getElementById('designation').textContent + '.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
    };

    html2pdf().set(opt).from(element).save();
    setTimeout(() => {
      document.getElementById('profileImage').style.borderRadius = '';
    }, 1000)

  }

  handleflip = (fliped) => {
    const { dispatch } = this.context;
    dispatch({
      type: 'FLIPED_UPDATED',
      payload: fliped
    })
    this.setState({ flip: fliped });
    var localResumeSkeleton = JSON.parse(localStorage.getItem("ADD_RESUME_SKELETON_COPY"));
    localResumeSkeleton.styles.layout_flip_enabled = fliped;
    localStorage.setItem("ADD_RESUME_SKELETON_COPY", JSON.stringify(localResumeSkeleton));
    
    if (this.context.resumeSkeleton) {
      if (this.context.resumeSkeleton.styles.layout_flip_enabled) {
        document.getElementsByTagName('ul')[0].style.order = 2
      } else {
        document.getElementsByTagName('ul')[0].style.order = 0
      }
    }

  }

  handlesuffle = (suffled) => {
    this.setState({ suffle: suffled })
  }

  componentDidMount() {

    const { dispatch } = this.context;
    dispatch({
      type: 'ADD_RESUME_SKELETON',
      payload: JSON.parse(localStorage.getItem("ADD_RESUME_SKELETON_COPY"))
    })

    document.querySelectorAll('#pdf ul li').forEach((ele) => {
      ele.classList.remove('resume_section')
    });
    this.setState({ flip: (this.context.resumeSkeleton) ? this.context.resumeSkeleton.styles.layout_flip_enabled : flip })

    // var updatedResumeSkeleton1 = JSON.parse(localStorage.getItem("ADD_RESUME_SKELETON_COPY"));
    // console.log("updatedResumeSkeleton1.styles.layout_flip_enabled : ", updatedResumeSkeleton1.styles.layout_flip_enabled);
    
    // var localResumeSkeleton = JSON.parse(JSON.stringify(this.context.resumeSkeleton))

    $("#LEFT, #RIGHT").sortable({ connectWith: ".connectedSortable", forceHelperSize: true }).disableSelection();

    $("#LEFT, #RIGHT").on('sortstart', function (evt, ui) {
      ui.item.toggleClass("highlight");
      $(ui.item).data("old-ndex", ui.item.index());
    });
    $("#LEFT, #RIGHT").on('sortstop', function (evt, ui) {
      ui.item.toggleClass("highlight");
      // });
      // $("#LEFT, #RIGHT").on('sortupdate', function (evt, ui) {
      var id = ui.item.attr("id");
      var parent_id = $("#" + id).parent().attr("id");
      var your_helper = $(ui.sender).attr("id");
      // eslint-disable-next-line
      if (your_helper != undefined) {
        $("#" + your_helper + " li").each(function (index) {
          $(this).find("input[name*='section_order']").val(index);
        });
      }
      var parent_id_val = parent_id;
      $("#" + id + " input[name*='section_column']").val(parent_id_val);
      $("#" + parent_id + " li").each(function (index) {
        $(this).find("input[name*='section_order']").val(index);
      });
      // eslint-disable-next-line
      var resume_data = new Array();
      var r_id;
      var r_section;
      // eslint-disable-next-line
      var r_position;
      var r_section_id;
      var r_type;
      var left_sec = [];
      var right_sec = [];
      var default_sec = [];

      var updatedResumeSkeleton = JSON.parse(localStorage.getItem("ADD_RESUME_SKELETON_COPY"));
      // console.log("updatedResumeSkeleton.styles.layout_flip_enabled : ", updatedResumeSkeleton.styles.layout_flip_enabled);

      $("input[name*='section_column']").each(function (index) {
        var chk = $(this).val();
        // eslint-disable-next-line
        if (chk != undefined) {
          r_position = chk;
        }
        var com_position = $(this).closest('ul').attr("id");
        // console.log(com_position);
        if (updatedResumeSkeleton.styles.layout_flip_enabled) {
          // eslint-disable-next-line
          if (com_position == "RIGHT") {
            com_position = "LEFT"
          }
          // eslint-disable-next-line
          else if (com_position == "LEFT") {
            com_position = "RIGHT"
          }
        }
        resume_data[index] = new Array({
          'section_column': com_position
        });
      });

      $("input[name*='section_order']").each(function (index) {
        var chk = $(this).val();
        // eslint-disable-next-line
        if (chk != undefined) {
          r_id = chk;
        }
        resume_data[index].push({
          'section_order': r_id
        });
      });
      $("input[name*='section_name']").each(function (index) {
        var chk = $(this).val();
        // eslint-disable-next-line
        if (chk != undefined) {
          r_section = chk;
        }
        resume_data[index].push({
          'section_name': r_section
        });

      });
      $("input[name*='section_id']").each(function (index) {
        var chk = $(this).val();
        // eslint-disable-next-line
        if (chk != undefined) {
          r_section_id = chk;
        }
        resume_data[index].push({
          'section_id': r_section_id
        });

      });

      $("input[name*='template_type']").each(function (index) {
        var chk = $(this).val();
        // eslint-disable-next-line
        if (chk != undefined) {
          r_type = chk;
        }

        resume_data[index].push({
          'template_type': r_type
        });
      });
      for (var i = 0; i < resume_data.length; i++) {
        // eslint-disable-next-line
        if (resume_data[i][0].section_column == "LEFT" || resume_data[i][0].section_column == "left") {
          var left_obj = {
            section_column: resume_data[i][0].section_column,
            section_order: resume_data[i][1].section_order,
            section_name: resume_data[i][2].section_name,
            section_id: resume_data[i][3].section_id,
            template_type: resume_data[i][4].template_type,
          }
          left_sec.push(left_obj)
        }
        // eslint-disable-next-line
        else if (resume_data[i][0].section_column == "RIGHT" || resume_data[i][0].section_column == "right") {
          var right_obj = {
            section_column: resume_data[i][0].section_column,
            section_order: resume_data[i][1].section_order,
            section_name: resume_data[i][2].section_name,
            section_id: resume_data[i][3].section_id,
            template_type: resume_data[i][4].template_type,
          }
          right_sec.push(right_obj)

        }
        else {
          var default_obj = {
            section_column: resume_data[i][0].section_column,
            section_order: resume_data[i][1].section_order,
            section_name: resume_data[i][2].section_name,
            section_id: resume_data[i][3].section_id,
            template_type: resume_data[i][4].template_type,
          }
          default_sec.push(default_obj)
        }
      }

      updatedResumeSkeleton['sections']['left_sec'] = JSON.parse(JSON.stringify(left_sec));
      updatedResumeSkeleton['sections']['right_sec'] = JSON.parse(JSON.stringify(right_sec));
      // console.log(localResumeSkeleton);

      localStorage.setItem("ADD_RESUME_SKELETON_COPY", JSON.stringify(updatedResumeSkeleton));

      // dispatch({
      //   type: 'ADD_RESUME_SKELETON_SECTION_LEFT',
      //   payload: JSON.parse(JSON.stringify(left_sec))
      // })
      // dispatch({
      //   type: 'ADD_RESUME_SKELETON_SECTION_RIGHT',
      //   payload: JSON.parse(JSON.stringify(right_sec))
      // })

    });


    if (window.performance) {
      if (window.performance.navigation.type === "reload" || window.performance.navigation.type === 1) {
        if (this.context.resumeDataSkeleton === '' || this.context.resumeDataSkeleton === null) {
          window.location.href = '/myresume';
        };

      }
    }

  }
  render() {
    return (
      <>
        <div
          className="col-md-7 position-relative d-none d-lg-block bg-light-white" id="id_right">
          <div
            className="position-absolute expand_right_div">
            <div className="btn-group" role="group" aria-label="Basic example">
              <button
                type="button"
                className="btn btn-success text-white btn-ls"
                onClick={leftHide}
                id="id_left_expand_hide">
                <i className="bi bi-arrows-fullscreen"></i>
              </button>
              <button
                type="button"
                className="btn btn-success text-white d-none btn-ls"
                onClick={leftShow}
                id="id_left_expand_show">
                <i className="bi bi-fullscreen-exit"></i>
              </button>

            </div>

          </div>
          <div className="justify-content-center align-items-center pb-5">
            <div className="row">
              <div className="col-md-1 col-lg-1 col-xl-1 col-xxl-2 p-0"></div>
              <div className="col-md-9 col-lg-9 col-xl-10 col-xxl-8 p-0 align-self-start p-0 shadow bg-white mt-3 p-2 pdf_zoom text-break text-wrap" id="resume_container">
                <ResumeTemplates suffle={this.state.suffle} flip={this.state.flip} />
              </div>
              <div className="col-md-2 col-lg-2 col-xl-1 col-xxl-2 p-0 text-center">
                <FixedIcons pdf='' generatePdf={this.generatePdf} suffle={this.state.suffle} handlesuffle={this.handlesuffle} flip={this.state.flip} handleflip={this.handleflip} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}


const leftHide = () => {
  document.getElementById("id_left").classList.add("d-none");
  document.getElementById("id_right").classList.add("col-md-12");
  document.getElementById("resume_container").removeAttribute('style');
  document.getElementById("pdf").removeAttribute('style');
  document.getElementById("resume_container").style.width = '902px';
  document.getElementById("id_right").classList.remove("col-md-7");
  document.getElementById("pdf").classList.remove('pdf_zoom_level');
  document.getElementById("resume_container").classList.remove('pdf_zoom');
  document.getElementById("id_left_expand_hide").classList.add("d-none");
  document.getElementById("id_left_expand_show").classList.remove("d-none");
  document.querySelectorAll('#pdf ul li').forEach((ele) => {
    ele.classList.add('resume_section')
  });

};

const leftShow = () => {
  document.getElementById("id_left").classList.remove("d-none");
  document.getElementById("id_right").classList.remove("col-md-12");
  document.getElementById("resume_container").removeAttribute('style');
  document.querySelectorAll('#pdf ul li').forEach((ele) => {
    ele.classList.remove('resume_section')
  });
  document.getElementById("resume_container").classList.add('pdf_zoom');
  document.getElementById("pdf").classList.add('pdf_zoom_level');
  document.getElementById("id_right").classList.add("col-md-7");
  document.getElementById("id_left_expand_hide").classList.remove("d-none");
  document.getElementById("id_left_expand_show").classList.add("d-none");

};

export default RightSideResume;
