import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";
const ResumeTraining = ({ templateType, sections, suffle, format, flip }) => {
  const { training, resumeSkeleton, templateStatus, trainingTitle } = useContext(AuthContext);
  const TrainingListCard = ({ trainingsData }) => {
    return (
      <div className="mb-3">
        <p className="mb-0 fw-bold small">{trainingsData.title}</p>
        <p className="mb-0 small">{trainingsData.section}</p>
        <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}> <i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(trainingsData.startDate) ? format(trainingsData.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} - {(trainingsData.endDate) ? format(trainingsData.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
        <div className="small">{trainingsData.summary}</div>
      </div>
    )
  }
  const ResumeTrainingCard = () => {
    if (templateType === "ats_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && training === '') || training === null || training.length === 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeTraining">
            <h5 className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" id="traning_title" style={{ backgroundColor: '#0000001a' }}>{trainingTitle}</h5>
            <div className="px-3 py-2">
              {
                training && training.map((trainings, index) => {
                  return (
                    <div className="row mb-3" key={index}>
                      <div className="col-3">
                        <p className="mb-0 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(trainings.startDate) ? format(trainings.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} - {(trainings.endDate) ? format(trainings.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                      </div>
                      <div className="col-9">
                        <p className="mb-0 fw-bold small">{trainings.title}</p>
                        <p className="mb-0 small">{trainings.section}</p>
                        <div className="small">{trainings.summary}</div>
                      </div>
                    </div>
                  )
                })
              }
            </div>

            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && training === '') || training === null || training.length === 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeTraining">
            <h5 className="text-capitalize fw-bold mb-3 text-break exec_classic_border" id="traning_title" style={{ color: resumeSkeleton.styles.theme_color }}>{trainingTitle}</h5>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, width: '100px', padding: '1px' }} className="mt-0" />
            {
              training && training.map((trainings, index) => {
                return (
                  <div className="row" key={index}>
                    {/* <div className="col-3">
                    </div> */}
                    <div className="col-9">
                      <h6 className="fw-bold">{trainings.title}</h6>
                      <p className="mb-0 small">{trainings.section}</p>
                      <div className="small">{trainings.summary}</div>
                      <p className="mb-0 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(trainings.startDate) ? format(trainings.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} - {(trainings.endDate) ? format(trainings.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                    </div>
                  </div>
                )
              })
            }

            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && training === '') || training === null || training.length === 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeTraining">
            <h5 className="fw-bold text-capitalize text-break" id="traning_title" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{trainingTitle}</h5>
            <hr className="mt-0" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }} />
            <div className="row">
              <div className="col-3"></div>
              <div className="col-9">
                {
                  training && training.map((trainings, index) => {
                    return <TrainingListCard key={index} trainingsData={trainings} />
                  })
                }
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && training === '') || training === null || training.length === 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeTraining">
            <div className="ps-2 mb-3">
              <h5 id="traning_title" className="text-uppercase d-flex align-items-center fw-bold mb-3 text-break"><i className="bi bi-app-indicator icon_circle_dark" style={(resumeSkeleton.styles.theme_color === '#fff') ? { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#000' } : { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#fff' }}></i>{trainingTitle}</h5>
              <div className="row ps-1">
                <div className="col-12">
                  {
                    training && training.map((trainings, index) => {
                      return <TrainingListCard key={index} trainingsData={trainings} />
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && training === '') || training === null || training.length === 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeTraining">
            <div className="mb-2">
              <h5 id="traning_title" className="fw-bold text-uppercase text-break">{trainingTitle}</h5>
              <div className="row">
                <div className="col-12">
                  {
                    training && training.map((trainings, index) => {
                      return (
                        <div className="mb-3" key={index}>
                          <p className="mb-0 fw-bold small">{trainings.title}</p>
                          <p className="mb-0 small">{trainings.section}</p>
                          <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(trainings.startDate) ? format(trainings.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} - {(trainings.endDate) ? format(trainings.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                          <div className="small">{trainings.summary}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && training === '') || training === null || training.length === 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeTraining">
            <h5 id="traning_title" className="fw-bold text-capitalize text-break">{trainingTitle}</h5>
            <div className="mb-1">
              {
                training && training.map((trainings, index) => {
                  return (
                    <div className="mb-3" key={index}>
                      <p className="mb-0 fw-bold small">{trainings.title}</p>
                      <p className="mb-0 small">{trainings.section}</p>
                      <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(trainings.startDate) ? format(trainings.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} - {(trainings.endDate) ? format(trainings.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                      <div className="small">{trainings.summary}</div>
                    </div>
                  )
                })
              }
            </div>

            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && training === '') || training === null || training.length === 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeTraining">
            <h5 id="traning_title" className="fw-bol text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>{trainingTitle}</h5>
            <div className="mb-1">
              {
                training && training.map((trainings, index) => {
                  return <TrainingListCard key={index} trainingsData={trainings} />
                })
              }
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && training === '') || training === null || training.length === 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeTraining">
            <h5 id="traning_title" className="fw-bold text-dark text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>{trainingTitle}</h5>
            <div className="mb-1">
              {
                training && training.map((trainings, index) => {
                  return (
                    <div className="mb-3" key={index}>
                      <p className="mb-0 fw-bold small">{trainings.title}</p>
                      <p className="mb-0 small">{trainings.section}</p>
                      <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(trainings.startDate) ? format(trainings.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} - {(trainings.endDate) ? format(trainings.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                      <div className="small">{trainings.summary}</div>
                    </div>
                  )
                })
              }
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && training === '') || training === null || training.length === 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeTraining">
            <h5 id="traning_title" className="fw-bold text-dark text-uppercase text-break ps-2">{trainingTitle}</h5>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, padding: '1px' }} className="mt-0" />
            <div className="mb-1">
              {
                training && training.map((trainings, index) => {
                  return (
                    <div key={index} className="mb-3 ps-3">
                      <p className="mb-0 fw-bold small">{trainings.title}</p>
                      <p className="mb-0 small">{trainings.section}</p>
                      <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(trainings.startDate) ? format(trainings.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}  {(trainings.endDate) ? ' - ' + format(trainings.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                      <div className="small">{trainings.summary}</div>
                    </div>
                  )
                })
              }
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
  }

  return (<>{ResumeTrainingCard()}</>)
}

export default ResumeTraining;