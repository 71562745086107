import React, { Fragment, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';

/** Components **/
import Login from './Screens/Auth_Manager/Login';
import MyResume from './Components/MyResume';
import ResumebuilderCreateOption from './Screens/Resume_Builder/resumebuilder_create_option';
import ResumebuilderStep from './Screens/Resume_Builder/resumebuilder_step';
import PrivateRoute from './Router/privateroute';
import ResumeTitle from './Components/ResumeTitle';
import JobSummary from './Components/JobSummary';
import WorkExperience from './Components/WorkExperience';
import PersonalInformation from './Components/PersonalInformation';
import Education from './Components/Education';
import Certification from './Components/Certification';
import Language from './Components/Language';
import References from './Components/References';
import Competencies from './Components/Competencies';
import Skills from './Components/Skills';
import Others from './Components/Others';
import CreateResumeNavbar from './Components/createResumeNavbar';
import CreateResumeSidebar from './Components/createResumeSidebar';
import ResumebuilderTemplateSelection from './Screens/Resume_Builder/resumebuilder_template_selection';
import { AuthProvider } from './Services/Authcontext';
import Success from './Components/Success';
import Error404 from './Components/Error404';
import Error500 from './Components/Error500';
import Loading from './Components/Loading';
import Preview from './Components/Preview';
import Apply from './Components/Apply'
import SessionTimeout from './Services/SessionTimeout';


const CreateResumeLayout = ({ children, ...rest }) => {
  return (
    <>
      <CreateResumeNavbar />
      <CreateResumeSidebar />
      <main className="container-fluid mt-7">
        <div className="row">
          <div className="col">
            <Outlet />
          </div>
        </div>
      </main>
    </>
  )
}
const App = () => {
  useEffect(() => {
    if (!localStorage.getItem('refreshToken')) { window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_LOGOUT_URL : process.env.REACT_APP_PROD_LOGOUT_URL };
  }, [])
  return (
    <AuthProvider>
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route path="*" element={<Error404 />} />
            <Route path="/error505" element={<Error500 />} />
            <Route exact path='/' element={<PrivateRoute />} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/apply' element={<Apply />} />
            <Route exact path='/loading' element={<Loading />} />
            <Route exact path='/preview' element={<Preview />} />
            <Route exact path='/myresume' element={<MyResume />} />
            <Route exact path='/rb-creation-option' element={<ResumebuilderCreateOption />} />
            <Route exact path='/rb-template-selection' element={<ResumebuilderTemplateSelection />} />
            <Route exact path='/rb-step' element={<ResumebuilderStep />} />
            <Route exact path='/success' element={<Success />} />
            <Route exact path="/" element={<CreateResumeLayout />} >
              <Route exact path="/resumetitle" element={<ResumeTitle />} />
              <Route exact path="/jobsummary" element={<JobSummary />} />
              <Route exact path="/workexperience" element={<WorkExperience />} />
              <Route exact path="/personalinformation" element={<PersonalInformation />} />
              <Route exact path="/education" element={<Education />} />
              <Route exact path="/certification" element={<Certification />} />
              <Route exact path="/language" element={<Language />} />
              <Route exact path="/references" element={<References />} />
              <Route exact path="/competencies" element={<Competencies />} />
              <Route exact path="/skills" element={<Skills />} />
              <Route exact path="/others" element={<Others />} />
            </Route>
          </Routes>
        </Fragment>
        <SessionTimeout />
      </BrowserRouter>
    </AuthProvider>

  );
}

export default App; 