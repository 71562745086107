import React, { useState } from 'react'
// import video from '../image/hi.mp4'
const NotificationCard = ({ short_notes, Notes, video }) => {
    const [showAll, setShowAll] = useState(false);
    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);
    return (
        <>
            <div className="d-flex align-items-start my-2">
                <video autoPlay muted width="160">
                    <source src={video} type="video/mp4" />
                </video>
                <div className="card border-0 hi-popper-bg rounded-4 me-4" data-popper-placement="right">
                    <div className="bubble position-relative"></div>
                    <div className="card-body">
                        {(showAll) ? Notes : short_notes}
                        {(showAll) ? <button className='btn btn-link btn-sm p-0' onClick={showLess}>Read less</button> : <button className='btn btn-link btn-sm p-0' onClick={showMore}>Read more</button>}

                    </div>
                </div>
            </div>
        </>
    )
}

export default NotificationCard