import React, { } from 'react';
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap';
import { EditorState, Modifier } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axiosInstance from '../Services/axios';
import { AuthContext } from '../Services/Authcontext';
import { RESUME_DATA_CONTENT_API_LINK } from '../Services/commonurls';

class CustomOption extends React.Component {
    static contextType = AuthContext

    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            suggestion: null,
        };
    }


    closeModal = () => this.setState({ isOpen: false });
    static propTypes = {
        onChange: PropTypes.func,
        editorState: PropTypes.object,
    };

    handleFetchData = async (dataName, type) => {
        await axiosInstance.get(`${RESUME_DATA_CONTENT_API_LINK}?data_name=${dataName}&${type}`)
            .then((res) => {
                this.setState({ suggestion: res.data.data.results })
            });
    }
    openModal = () => {
        this.setState({ isOpen: true });
        this.handleFetchData(this.props.dataName, this.props.type);
    };
    addStar = () => {
        const { editorState, onChange } = this.props;
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            '',
            editorState.getCurrentInlineStyle(),
        );
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    };

    render() {
        return (
            <>
                <div onClick={() => { this.openModal() }} className=''>
                    <button type='button' id="pre-written" className='btn btn-outline-secondary btn-sm'>Add pre-written phrase </button>
                </div>
                <Modal show={this.state.isOpen} onHide={this.closeModal}>
                    <Modal.Body>

                        <div className="">
                            {
                                (this.state.suggestion) ? (
                                    (this.state.suggestion && this.state.suggestion.map((suggestion, index) => {
                                        return <p className="job_list" onClick={() => { this.props.hadleSetContent(suggestion.content); }}>{suggestion.content}</p>
                                    }))
                                ) : (
                                    <>
                                        <i className='bi bi-info-circle mb-2'></i>
                                        <p className='text-center fw-bold'>No Pre written phrase exists for your Job Type</p>
                                    </>
                                )
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default CustomOption;