import React from 'react';
import RightSideResume from './RightSideResume';
import TrainingCard from './TrainingCard';
import CocurricularActivitiesCard from './CocurricularActivitiesCard';
import HonoursandAwardsCard from './HonoursandAwardsCard';
import CompetitionsCard from './CompetitionsCard';
import VolunteeringCard from './VolunteeringCard';
import Hobbies from './Hobbies';
import AchievementsCard from './AchievementsCard';
import PersonalProjectsCard from './PersonalProjectsCard';
import NotificationCard from './NotificationCard';
import LeftHideButton from './LeftHideButton';
import MobileHeader from './MobileHeader';
import { Link } from 'react-router-dom';
import { AuthContext } from '../Services/Authcontext';
import ReferenceCard from './ReferenceCard';
import video from '../image/animation/others.webm'



class Others extends React.Component {
    static contextType = AuthContext
    constructor(props) {
        super(props)
        this.state = {
            trainingCard: false,
            honourCard: false,
            cocurricularCard: false,
            competitionCard: false,
            volunterCard: false,
            hobbieCard: false,
            achievementCard: false,
            personalCard: false,
            linksCard: false,
            referenceCard: false,
            done: false
        }

        this.handleCard = this.handleCard.bind(this);
    }

    handleCard() {
        this.setState({ card: !this.state.card })
    }

    handleDone = () => {
        document.getElementById('saveBtnMain').click();
    }


    handleDateValiadation = (date) => {
        var today = date;
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }

        return today = yyyy + '-' + mm + '-' + dd;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.context.competitions.length <= 0) {
            this.setState({ competitionCard: this.state.competitionCard })
        }
        else if (this.context.competitions && this.context.competitions !== null && this.context.competitions !== "") {
            this.setState({ competitionCard: !this.state.competitionCard })
        }
        if (this.context.training.length <= 0) {
            this.setState({ trainingCard: this.state.trainingCard })
        }
        else if (this.context.training && this.context.training !== null && this.context.training !== "") {
            this.setState({ trainingCard: !this.state.trainingCard })
        }
        if (this.context.honours.length <= 0) {
            this.setState({ honourCard: this.state.honourCard })
        }
        else if (this.context.honours && this.context.honours !== null && this.context.honours !== "") {
            this.setState({ honourCard: !this.state.honourCard })
        }
        if (this.context.cocurricular.length <= 0) {
            this.setState({ cocurricularCard: this.state.cocurricularCard })
        }
        else if (this.context.cocurricular && this.context.cocurricular !== null && this.context.cocurricular !== "") {
            this.setState({ cocurricularCard: !this.state.cocurricularCard })
        }
        if (this.context.volunteer.length <= 0) {
            this.setState({ volunterCard: this.state.volunterCard })
        }
        else if (this.context.volunteer && this.context.volunteer !== null && this.context.volunteer !== "") {
            this.setState({ volunterCard: !this.state.volunterCard })
        }
        if (this.context.hobbies.length <= 0) {
            this.setState({ hobbieCard: this.state.hobbieCard })
        }
        else if (this.context.hobbies && this.context.hobbies !== null && this.context.hobbies !== "") {
            this.setState({ hobbieCard: !this.state.hobbieCard })
        }
        if (this.context.achievements.length <= 0) {
            this.setState({ achievementCard: this.state.achievementCard })
        }
        else if (this.context.achievements && this.context.achievements !== null && this.context.achievements !== "") {
            this.setState({ achievementCard: !this.state.achievementCard })
        }
        if (this.context.personalProject.length <= 0) {
            this.setState({ personalCard: this.state.personalCard })
        }
        else if (this.context.personalProject && this.context.personalProject !== null && this.context.personalProject !== "") {
            this.setState({ personalCard: !this.state.personalCard })
        }
        if (this.context.links.length <= 0) {
            this.setState({ linksCard: this.state.linksCard })
        }
        else if (this.context.links && this.context.links !== null && this.context.links !== "") {
            this.setState({ linksCard: !this.state.linksCard })
        }
        if (this.context.references.length <= 0) {
            this.setState({ referenceCard: this.state.referenceCard })
        }
        else if (this.context.references && this.context.references !== null && this.context.references !== "") {
            this.setState({ referenceCard: !this.state.referenceCard })
        }
    }
    render() {
        return (
            <>
                <MobileHeader setIsLoading={this.props.setIsLoading} Title={"Others"} ProgressBar={this.props.progressBar} StepList={this.props.stepList} Step={Number(this.props.step)} />
                <div className="row">
                    <div className="col-md-12 col-lg-5 bg-white p-lg-4 position-relative" id="id_left">
                        <LeftHideButton rightHide={this.props.rightHide} rightShow={this.props.rightShow} />
                        <div className="" id="id_left_side">
                            <NotificationCard
                                short_notes={
                                    <>
                                        <p className='mb-1 small'>Hurray… you are almost there! You can add optional sections of your choice here that highlights
                                            your Achievements, Hobbies or Interests and Honours and Awards.</p>
                                    </>
                                }
                                Notes={
                                    <>
                                        <p className='mb-1 small'>Hurray… you are almost there! You can add optional sections of your choice here that highlights
                                            your Achievements, Hobbies or Interests and Honours and Awards.</p>
                                        <p className='mb-1 small'><span className='text-decoration-underline'>If you are a fresher</span>, would be good to add Training Programs you attended, Co-Curricular or Extra
                                            Curricular activities you participated, Competitions Won and Volunteering activities you are
                                            involved. Add details of Personal or additional projects successfully completed.</p>
                                        <p className='mb-1 small'>References are point of contacts that your potential employer can reach out to get to know more
                                            about you.</p>
                                    </>
                                } video={video} />
                            <div>
                                <button type="button" id="trainingCard" className={(this.state.trainingCard) ? "badge bg-secondary other_btn other_highlight" : "badge bg-secondary other_btn"}
                                    onClick={() => { this.setState({ trainingCard: !this.state.trainingCard }) }}><i className="bi bi-plus-circle"></i> Training Programs</button>
                                <button type="button" id="menu_honours" className={(this.state.honourCard) ? "badge bg-secondary other_btn other_highlight" : "badge bg-secondary other_btn"}
                                    onClick={() => { this.setState({ honourCard: !this.state.honourCard }) }}><i className="bi bi-plus-circle"></i> Honours and Awards</button>
                                <button type="button" id="menu_cocurricular" className={(this.state.cocurricularCard) ? "badge bg-secondary other_btn other_highlight" : "badge bg-secondary other_btn"}
                                    onClick={() => { this.setState({ cocurricularCard: !this.state.cocurricularCard }) }}><i className="bi bi-plus-circle"></i> Co-curricularactivities</button>
                                <button type="button" id="menu_competitions" className={(this.state.competitionCard) ? "badge bg-secondary other_btn other_highlight" : "badge bg-secondary other_btn"}
                                    onClick={() => { this.setState({ competitionCard: !this.state.competitionCard }) }}><i className="bi bi-plus-circle"></i> Competitions</button>
                                <button type="button" id="menu_volunteering" className={(this.state.volunterCard) ? "badge bg-secondary other_btn other_highlight" : "badge bg-secondary other_btn"}
                                    onClick={() => { this.setState({ volunterCard: !this.state.volunterCard }) }}><i className="bi bi-plus-circle"></i> Volunteering</button>
                                <button type="button" id="menu_hobbies" className={(this.state.hobbieCard) ? "badge bg-secondary other_btn other_highlight" : "badge bg-secondary other_btn"}
                                    onClick={() => { this.setState({ hobbieCard: !this.state.hobbieCard }) }}><i className="bi bi-plus-circle"></i> Hobbies and
                                    Interests</button>
                                <button type="button" id="menu_achievements" className={(this.state.achievementCard) ? "badge bg-secondary other_btn other_highlight" : "badge bg-secondary other_btn"}
                                    onClick={() => { this.setState({ achievementCard: !this.state.achievementCard }) }}><i className="bi bi-plus-circle"></i> Achievements</button>
                                <button type="button" id="menu_personal" className={(this.state.personalCard) ? "badge bg-secondary other_btn other_highlight" : "badge bg-secondary other_btn"}
                                    onClick={() => { this.setState({ personalCard: !this.state.personalCard }) }}><i className="bi bi-plus-circle"></i> Personal Project</button>

                                <button type="button" id="menu_reference" className={(this.state.referenceCard) ? "badge bg-secondary other_btn other_highlight" : "badge bg-secondary other_btn"}
                                    onClick={() => { this.setState({ referenceCard: !this.state.referenceCard }) }}><i className="bi bi-plus-circle"></i> Reference</button>

                            </div>
                            <TrainingCard Display={this.state.trainingCard} handleDateValiadation={this.handleDateValiadation} />
                            <HonoursandAwardsCard Display={this.state.honourCard} handleDateValiadation={this.handleDateValiadation} />
                            <CocurricularActivitiesCard Display={this.state.cocurricularCard} handleDateValiadation={this.handleDateValiadation} />
                            <CompetitionsCard Display={this.state.competitionCard} handleDateValiadation={this.handleDateValiadation} />
                            <VolunteeringCard Display={this.state.volunterCard} handleDateValiadation={this.handleDateValiadation} />
                            <Hobbies Display={this.state.hobbieCard} handleDateValiadation={this.handleDateValiadation} />
                            <AchievementsCard Display={this.state.achievementCard} handleDateValiadation={this.handleDateValiadation} />
                            <PersonalProjectsCard Display={this.state.personalCard} handleDateValiadation={this.handleDateValiadation} />
                            <ReferenceCard Display={this.state.referenceCard} handleDateValiadation={this.handleDateValiadation} />
                            <div className="row mb-4" id="">
                                <div className="col ms-2">
                                    <Link to={`/rb-step?step=${this.props.step - 1}`}>
                                        <button className="btn btn-outline-primary mt-3">Back</button>
                                    </Link>
                                </div>

                                <div className="col">
                                    <button className="btn btn-primary mt-3 float-end" onClick={this.handleDone} type='button' >Done</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <RightSideResume />
                </div>
            </>
        );
    };
}

export default Others;