import React from 'react';
import { NavLink } from 'react-router-dom';
import RightSideResume from './RightSideResume';
import { withRouter } from './WithRouter';
import { nanoid } from 'nanoid';
import { AuthContext } from '../Services/Authcontext';
import NotificationCard from './NotificationCard';
import LeftHideButton from './LeftHideButton';
import MobileHeader from './MobileHeader';
import axiosInstance from '../Services/axios';
import video from '../image/animation/education.webm'
import { RESUME_DATA_CONTENT_API_LINK } from '../Services/commonurls';


class Education extends React.Component {
    static contextType = AuthContext

    constructor(props) {
        super(props)
        this.state = {
            showForm: false,
            isEdit: false,
            editId: '',
            SchoolName: '',
            SchoolCity: '',
            SchoolCountry: '',
            Degree: '',
            DegreeList: '',
            University: '',
            UniversityList: '',
            Field: '',
            StartDate: '',
            EndDate: '',
            IsCurrent: false,
            Summary: '',
            schoolList: [
                // {
                //     id: "default",
                //     SchoolName: 'Degree goes here',
                //     SchoolLocation: 'location',
                //     Degree: 'BBA',
                //     Field: 'College/school',
                //     StartDate: '2014-10-1',
                //     EndDate: '2019-10-1',
                //     IsCurrent: false,
                // },
            ]
        };
        this.handleNext = this.handleNext.bind(this);
        this.handleForm = this.handleForm.bind(this);
        this.handleSchoolDelete = this.handleSchoolDelete.bind(this);
        this.handleSchoolEdit = this.handleSchoolEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleForm() {
        this.setState({ showForm: !this.state.showForm })
    }

    handleFetchDegree = async () => {
        await axiosInstance.get(`${RESUME_DATA_CONTENT_API_LINK}?data_name=education_type`)
            .then((res) => {
                this.setState({ DegreeList: res.data.data.results })
            });
    }

    handleFetchUniversity = async () => {
        await axiosInstance.get(`${RESUME_DATA_CONTENT_API_LINK}?data_name=education_institution&search_query=`)
            .then((res) => {
                this.setState({ UniversityList: res.data.data.results })
            });
    }

    handleSchoolEdit(id) {
        this.setState({ isEdit: !this.state.isEdit })
        this.setState({ editId: id })
        this.setState({ showForm: !this.state.showForm })
        const EditJob = this.state.schoolList.filter(school => school.id === id);
        // eslint-disable-next-line
        EditJob.map((editData) => {
            this.setState({ id: editData.id });
            this.setState({ SchoolName: editData.SchoolName });
            this.setState({ SchoolCity: editData.SchoolCity });
            this.setState({ SchoolCountry: editData.SchoolCountry });
            this.setState({ Degree: editData.Degree });
            this.setState({ Field: editData.Field });
            this.setState({ StartDate: editData.StartDate });
            this.setState({ EndDate: editData.EndDate });
            this.setState({ IsCurrent: editData.IsCurrent });
            this.setState({ Summary: editData.Summary });
            this.setState({ University: editData.University });
        });
    }

    handleSchoolDelete(id) {
        const { dispatch } = this.context;
        const deleteJob = this.state.schoolList.filter(school => school.id !== id);
        this.setState({ schoolList: deleteJob })
        setTimeout(() => {
            dispatch({
                type: 'ADD_EDUCATION',
                payload: this.state.schoolList
            })
        }, 1000)
    }

    handleSubmit(e, formType) {
        e.preventDefault();
        const { dispatch } = this.context;
        dispatch({
            type: 'DATA_UPDATED',
            payload: true
        })
        if (formType === 'add') {
            const addJob = {
                id: nanoid(),
                SchoolName: this.state.SchoolName,
                SchoolCity: this.state.SchoolCity,
                SchoolCountry: this.state.SchoolCountry,
                Degree: this.state.Degree,
                Field: this.state.Field,
                StartDate: this.state.StartDate,
                EndDate: this.state.EndDate,
                IsCurrent: this.state.IsCurrent,
                Summary: this.state.Summary,
                University: this.state.University,
            }
            this.setState({ schoolList: [...this.state.schoolList, addJob] })

        } else {
            const EditJob = this.state.schoolList.map(school => {
                if (school.id === this.state.editId) {
                    return {
                        ...school, SchoolName: this.state.SchoolName,
                        SchoolCity: this.state.SchoolCity,
                        SchoolCountry: this.state.SchoolCountry,
                        Degree: this.state.Degree,
                        Field: this.state.Field,
                        StartDate: this.state.StartDate,
                        EndDate: this.state.EndDate,
                        IsCurrent: this.state.IsCurrent,
                        Summary: this.state.Summary,
                        University: this.state.University,
                    };
                }
                return school;
            });
            this.setState({ schoolList: EditJob })
            this.setState({ isEdit: !this.state.isEdit })

        }

        this.setState({ showForm: !this.state.showForm })
        this.setState({ editId: '' })
        this.setState({ id: '' });
        this.setState({ SchoolName: '' });
        this.setState({ SchoolCity: '' });
        this.setState({ SchoolCountry: '' });
        this.setState({ Degree: '' });
        this.setState({ Field: '' });
        this.setState({ StartDate: '' });
        this.setState({ EndDate: '' });
        this.setState({ IsCurrent: '' });
        this.setState({ Summary: '' });
        this.setState({ University: '' });

        setTimeout(() => {
            dispatch({
                type: 'ADD_EDUCATION',
                payload: this.state.schoolList
            })
        }, 1000)
    };

    handleDateValiadation = (date) => {
        var today = date;
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }

        return today = yyyy + '-' + mm;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.handleFetchDegree();
        this.handleFetchUniversity();
        if (this.context.education) {
            this.setState({ schoolList: this.context.education })
        }
        document.getElementById("edustartdate").setAttribute("max", this.handleDateValiadation(new Date()));
    }

    handleNext() {
        const { dispatch } = this.context;
        let data = this.state.schoolList.filter(school => school.id !== "default");
        dispatch({
            type: "ADD_EDUCATION",
            payload: data
        });
        for (var key in this.context.stepCompleteness) {
            if (this.context.stepCompleteness[key]['resume_step_id'] === Number(this.props.step)) {
                this.context.stepCompleteness[key]['step_status'] = true;
            }
        }
        this.props.navigate(`/rb-step?step=${Number(this.props.step) + 1}`)
    }
    render() {
        var step = Number(this.props.step)
        return (
            <>
                <MobileHeader setIsLoading={this.props.setIsLoading} Title={"Education"} ProgressBar={this.props.progressBar} StepList={this.props.stepList} Step={Number(this.props.step)} />
                <div className="row">
                    <div className="col-md-12 col-lg-5 bg-white p-lg-4 position-relative" id="id_left">
                        <LeftHideButton rightHide={this.props.rightHide} rightShow={this.props.rightShow} />
                        <div className="" id="id_left_side">
                            <NotificationCard short_notes={
                                <>
                                    <p className='mb-1 small'>You are doing well! Let’s now capture your education qualification details starting with more recent
                                        one first. For graduates, you can enter university or college name or both</p>
                                </>
                            } Notes={
                                <>
                                    <p className='mb-1 small'>You are doing well! Let’s now capture your education qualification details starting with more recent
                                        one first. For graduates, you can enter university or college name or both and Field of study. Don’t
                                        forget to add start and end dates for each qualification.</p>
                                    <p className='mb-1 small'><span className='text-decoration-underline'>If you are a fresher</span>, it would be good to provide the scores you got during graduation. If you are on
                                        high school do provide details of O level / 10 th Grade in addition to and 12 th Grade / A level / IB
                                        scores.</p>

                                </>
                            } video={video}
                            />

                            <form onSubmit={(e) => { this.handleSubmit(e, (this.state.isEdit) ? 'edit' : 'add') }} method="post" className={(this.state.showForm) ? "mt-4" : "d-none"} id="educationForm">
                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="form-floating">
                                            <input type="text" list='UniversityList' name="schoolname" id="schoolname" className="form-control resume_edu_input" value={this.state.SchoolName} onChange={(e) => { this.setState({ SchoolName: e.target.value }) }}
                                                placeholder="School Name" />
                                            <label htmlFor="school_name">School Name</label>
                                        </div>
                                    </div>
                                    <datalist id="UniversityList">
                                        {
                                            this.state.UniversityList && this.state.UniversityList.map((degree, index) => {
                                                return <option value={degree} key={index}>{degree}</option>
                                            })
                                        }
                                    </datalist>

                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" name="eduuniversity" id="eduuniversity" className="form-control resume_edu_input" value={this.state.University} onChange={(e) => { this.setState({ University: e.target.value }) }}
                                        placeholder="University" />
                                    <label htmlFor="edu_field">University</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <select className="form-select" value={this.state.Degree} onChange={(e) => { this.setState({ Degree: e.target.value }) }} name="edudegree" id="edudegree">
                                        <option value="">Select Education</option>
                                        {
                                            this.state.DegreeList && this.state.DegreeList.map((degree, index) => {
                                                return <OptionCard key={index} degree={degree} />
                                            })
                                        }
                                    </select>
                                    <label htmlFor="edu_degree">Type Of Education</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" name="edufield" id="edufield" className="form-control resume_edu_input" value={this.state.Field} onChange={(e) => { this.setState({ Field: e.target.value }) }}
                                        placeholder="Field of Study" />
                                    <label htmlFor="edu_field">Field of Study</label>
                                </div>
                                <div className="row mb-3">
                                    <div className="col pe-2">
                                        <div className="form-floating">
                                            {/* <select name="edu_city" id="edu_city" value={this.state.SchoolCity} onChange={(e) => {
                                                this.setState({ SchoolCity: e.target.value });
                                                this.setState({ SchoolCountry: e.target.options[e.target.selectedIndex].dataset.country })
                                            }} className='form-select'>
                                                {this.context.cityList && this.context.cityList.map((city, i) => {
                                                    return <option key={i}
                                                        defaultValue={(city.name.toLowerCase().charAt(0).toUpperCase() + city.name.toLowerCase().slice(1))===this.state.SchoolCity?true:false}
                                                        value={city.name.toLowerCase().charAt(0).toUpperCase() + city.name.toLowerCase().slice(1)}
                                                        data-country={city.country__name.toLowerCase().charAt(0).toUpperCase() + city.country__name.toLowerCase().slice(1)}>
                                                        {city.name.toLowerCase().charAt(0).toUpperCase() + city.name.toLowerCase().slice(1)}
                                                    </option>
                                                })}
                                            </select> */}
                                            <input
                                                type="text"
                                                name="edu_city"
                                                id="edu_city"
                                                className="form-control resume_edu_input"
                                                placeholder="City"
                                                value={this.state.SchoolCity}
                                                onChange={(e) => { this.setState({ SchoolCity: e.target.value }); }}
                                            />
                                            <label htmlFor="City">City</label>
                                        </div>
                                    </div>
                                    <div className="col ps-2">
                                        <div className="form-floating">
                                            <select name="edu_state" id="edu_state" value={this.state.SchoolCountry} onChange={(e) => {
                                                this.setState({ SchoolCountry: e.target.value });
                                            }} className='form-select'>
                                                {this.context.countryList && this.context.countryList.map((country, i) => {
                                                    return <option key={i}
                                                        value={country.toLowerCase().charAt(0).toUpperCase() + country.toLowerCase().slice(1)}
                                                        defaultValue={(country.toLowerCase().charAt(0).toUpperCase() + country.toLowerCase().slice(1) === this.state.SchoolCountry) ? true : false}
                                                    >{country.toLowerCase().charAt(0).toUpperCase() + country.toLowerCase().slice(1)}
                                                    </option>
                                                })}
                                            </select>
                                            {/* <input
                                                type="text"
                                                name="edu_state"
                                                id="edu_state"
                                                className="form-control resume_edu_input"
                                                placeholder="Country"
                                                value={this.state.SchoolCountry}
                                                onChange={(e) => { this.setState({ SchoolCountry: e.target.value }) }}
                                            /> */}
                                            <label htmlFor="state">Country</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col pe-2">
                                        <div className="form-floating">
                                            <input type="month" name="edustartdate" id="edustartdate" className="form-control resume_edu_input resume_edu_input_date" value={this.state.StartDate} onChange={(e) => { this.setState({ StartDate: e.target.value }); document.getElementById('eduenddate').setAttribute("min", this.handleDateValiadation(new Date(e.target.value))) }}
                                                placeholder="Start Date" />
                                            <label htmlFor="edu_start_date">Start Date</label>
                                        </div>
                                    </div>
                                    <div className="col ps-2">
                                        <div className="form-floating">
                                            <input type="month" name="eduenddate" id="eduenddate" className="form-control resume_edu_input resume_edu_input_date" value={this.state.EndDate} onChange={(e) => { this.setState({ EndDate: e.target.value }) }}
                                                placeholder="End Date" />
                                            <label htmlFor="edu_end_date">End Date </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3 d-none">
                                    <div className="col">
                                        <div className="form-floating">
                                            <textarea name="description" id="description" style={{ height: '100px' }} className="form-control" value={this.state.Summary} onChange={(e) => { this.setState({ Summary: e.target.value }) }}
                                                placeholder="Description" />
                                            <label htmlFor="school_name">Description</label>
                                        </div>
                                    </div>
                                </div>
                                <input type="hidden" name="edu_id" id="edu_id" />
                                <div className="form-check mt-3">
                                    <input className="form-check-input" type="checkbox" name="educurrent" id="educurrent" checked={this.state.IsCurrent} onChange={(e) => { this.setState({ IsCurrent: e.target.checked }) }} />
                                    <label className="form-check-label" htmlFor="edu_current">
                                        Currently Pursuing
                                    </label>
                                </div>
                                <div className="row mb-3 mx-0">
                                    <div className="col p-0">
                                        <button type='button' className="btn btn-outline-primary mt-3" onClick={this.handleForm}>Cancel</button>
                                    </div>
                                    <div className="col p-0">
                                        <button type='submit' className="btn btn-primary mt-3 float-end" id="add_new_job">{(this.state.isEdit) ? 'Add' : 'Add'} </button>
                                    </div>
                                </div>
                            </form>
                            <div className={(this.state.showForm) ? "d-none" : ''}>
                                {
                                    this.state.schoolList.map((school, index) => {
                                        return <EducationCard key={index} handleSchoolDelete={this.handleSchoolDelete} handleSchoolEdit={this.handleSchoolEdit} Id={school.id} SchoolName={school.SchoolName} SchoolCity={school.SchoolCity} SchoolCountry={school.SchoolCountry} University={school.University} Degree={school.Degree} Field={school.Field} StartDate={school.StartDate} EndDate={school.EndDate} IsCurrent={school.IsCurrent} Summary={school.Summary} />
                                    })
                                }
                            </div>
                            <div id="job_view" className={(this.state.showForm) ? "d-none" : ''}>
                                <div className="d-grid">
                                    <button className="btn mt-3 add_job_btn px-3" id="add_job_btn" onClick={this.handleForm}><i className="bi bi-plus"></i> add degree</button>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <NavLink to={`/rb-step?step=${step - 1}`}>
                                            <button className="btn btn-outline-primary mt-3">Back</button>
                                        </NavLink>
                                    </div>
                                    <div className="col">
                                        <button type='button' className="btn btn-primary mt-3 float-end" onClick={this.handleNext}>Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <RightSideResume />
                </div>
            </>
        );
    };
}

const OptionCard = ({ degree }) => {
    return <option value={degree.short_name}>{degree.name}</option>
};

const EducationCard = ({ Id, SchoolName, SchoolCity, SchoolCountry, University, Degree, Field, StartDate, EndDate, IsCurrent, Summary, handleSchoolDelete, handleSchoolEdit }) => {
    return (
        <>
            <div className="row pt-3 mx-0 ms-0 border mt-2">
                <div className="col-2 text-center">
                    <img src={process.env.PUBLIC_URL + "/image/icons/job.png"} alt='icon' />
                </div>
                <div className="col-10 ps-lg-0">
                    <p className="fw-bold small mb-0">{SchoolName} {(Degree) ? '(' + Degree + ')' : ''}</p>
                    <p className="fw-bold small mb-0">{University}</p>
                    <p className="text-muted small mb-0">{Field} {(SchoolCity) ? '|' : ''} {SchoolCity}{(SchoolCountry) ? ',' + SchoolCountry : ''}</p>
                    <p className="text-muted small mb-0">{(StartDate) ? new Date(StartDate).toString().split(' ')[1] + " " + new Date(StartDate).toString().split(' ')[3] : ''} {(IsCurrent) ? ' - Pursuing' : (EndDate) ? " - " + new Date(EndDate).toString().split(' ')[1] + " " + new Date(EndDate).toString().split(' ')[3] : ''}</p>
                    <p className='mb-1 small'>{Summary}</p>
                    <div className="row mb-3">
                        <div className="col">
                            {(IsCurrent) ? (<span className="small alert-success rounded-pill py-1 px-2">Currently Pursuing</span>) : ''}
                        </div>
                        <div className="col-auto">
                            <span className="text-end text-decoration-underline text-danger" onClick={() => { handleSchoolDelete(Id) }}> <i className="bi bi-trash"></i>
                            </span>
                        </div>
                        <div className="col-auto">
                            <span className="text-end text-decoration-underline text-primary" onClick={() => { handleSchoolEdit(Id) }}><i className="bi bi-pencil"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default withRouter(Education);