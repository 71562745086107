import React from 'react'

const LeftHideButton = ({ rightHide, rightShow }) => {
    return (
        <>
            <div className="position-absolute expand_left_div">
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-primary btn-sh d-none d-lg-block" onClick={rightHide} id="id_right_expand_hide">
                        <i className='bi bi-arrows-fullscreen'></i>
                    </button>
                    <button type="button" className="btn btn-primary d-none btn-sh" onClick={rightShow} id="id_right_expand_show">
                        <i className='bi bi-fullscreen-exit'></i>
                    </button>
                </div>
            </div>
        </>
    )
}

export default LeftHideButton