// import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Loading from "../Components/Loading";
import { AuthContext } from "../Services/Authcontext";

const PrivateRoute = () => {
    const { dispatch } = useContext(AuthContext);
    // eslint-disable-next-line
    const [isAuth, setIsAuth] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.search) {
            const refresh_token = window.location.search.split('&')[0].split('refresh_token=')[1];
            const access_token = window.location.search.split('&')[1].split('access_token=')[1];
            localStorage.setItem("refreshToken", refresh_token)
            localStorage.setItem("accessToken", access_token)
            dispatch({
                type: 'ADD_FRESH_TOKEN',
                payload: refresh_token
            });
            dispatch({
                type: 'ADD_ACCESS_TOKEN',
                payload: access_token
            });
            setIsAuth(true);
            var reloadCount = sessionStorage.getItem('reloadCount');
            if (!reloadCount) {
                sessionStorage.setItem('reloadCount', reloadCount + 1);
                window.location.reload();
            } else {
                sessionStorage.removeItem('reloadCount');
                navigate('/myresume');
            }
        } else {
            window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_LOGOUT_URL : process.env.REACT_APP_PROD_LOGOUT_URL;
        }
    }, [])

    return <Loading />;
}

export default PrivateRoute;