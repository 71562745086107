import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from '../Services/Authcontext';
import axiosInstance from '../Services/axios';
import { USER_INFO_API_LINK } from '../Services/commonurls';

const Navbar = ({ status }) => {
    const { dispatch } = useContext(AuthContext);
    const [userDetails, setuserDetails] = useState('');
    const fetchUserDetails = async () => {
        await axiosInstance.get(USER_INFO_API_LINK).then((res) => {
            setuserDetails(res.data.data);
            dispatch({
                type: 'ADD_USER_DETAILS',
                payload: res.data.data,
            });
            // eslint-disable-next-line
            String.prototype.toCapitalize = function () {
                return this.toLowerCase().replace(/^.|\s\S/g, function (a) { return a.toUpperCase(); });
            }
            if (res.data.data) {
                let data = {
                    is_terms_verified: true,
                    address_line_1: res.data.data.user_social_profile.address,
                    address_line_2: res.data.data.user_social_profile.address_2,
                    city: res.data.data.user_social_profile.living_city,
                    state: res.data.data.user_social_profile.living_state,
                    country: (res.data.data.user_social_profile.living_country) ? res.data.data.user_social_profile.living_country.toCapitalize() : '',
                    dob: res.data.data.user_social_profile.dob,
                    email: res.data.data.email,
                    // first_name: (res.data.data.user_kyc_profile.first_name !== null && res.data.data.user_kyc_profile.first_name !== '') ? res.data.data.user_kyc_profile.first_name : res.data.data.first_name,
                    first_name: res.data.data.first_name,
                    gender: res.data.data.user_social_profile.gender,
                    // last_name: (res.data.data.user_kyc_profile.last_name !== null && res.data.data.user_kyc_profile.last_name !== '') ? res.data.data.user_kyc_profile.last_name : res.data.data.last_name,
                    last_name: res.data.data.last_name,
                    phone_number: res.data.data.user_kyc_profile.phone_number,
                    postalcode: res.data.data.user_social_profile.postalcode,
                    profile_picture: res.data.data.user_kyc_profile.avatar,
                    show_profile_picture: res.data.data.user_kyc_profile.show_profile_picture,
                    // profile: ImageName,
                }
                dispatch({
                    type: "ADD_PERSONAL_INFORMATION",
                    payload: data
                })
            }
        });
    }
    const handleRedirect = (page) => {
        switch (page) {
            case 'logout':
                window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_LOGOUT_URL : process.env.REACT_APP_PROD_LOGOUT_URL;
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('accessToken')
                break;
            case 'my-profile':
                window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_WEBSITE_URL + `account/${page}` : process.env.REACT_APP_PROD_CESS_URL + `account/${page}`;
                break;
            case 'overview':
                window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_WEBSITE_URL + `account/${page}` : process.env.REACT_APP_PROD_CESS_URL + `account/${page}`;
                break;
            default:
                window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_WEBSITE_URL + `${page}` : process.env.REACT_APP_PROD_WEBSITE_URL + `${page}`;
                break;
        }
    }
    const handleCanvas = () => {
        document.getElementById('closeBtnCanvas').click();
    }
    useEffect(() => {
        fetchUserDetails();
    }, [])
    return (
        <>
            <div className={(status) ? "shadow-sm fixed-top" : ""}>
                <nav className="navbar navbar-expand-lg navbar-light bg-white p-0">
                    <div className={(status) ? "container-fluid px-0 px-lg-4 py-2 justify-content-between" : "container-fluid py-2 py-md-0 pe-lg-0 justify-content-between"} >
                        <button className="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions"
                            aria-controls="offcanvasWithBothOptions">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <NavLink className="navbar-brand p-0" to='#' onClick={() => { handleRedirect('') }}>
                            <img src={process.env.PUBLIC_URL + '/image/logo_meritport.svg'} width="170px" alt="" />
                        </NavLink>
                        <span></span>
                        {
                            (status) ? (<>
                                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                    <ul className="navbar-nav ms-auto align-items-center d-none d-lg-flex">
                                        <li className="nav-item dropdown d-none d-lg-block">
                                            <NavLink className="nav-link dropdown-toggle p-0" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={(userDetails && userDetails.user_kyc_profile && userDetails.user_kyc_profile.avatar !== '' && userDetails.user_kyc_profile.avatar !== null) ? userDetails.user_kyc_profile.avatar : process.env.PUBLIC_URL + "/image/default_user.png"} alt="mdo" width="40" height="40" className="rounded-circle p-1 border" />
                                            </NavLink>
                                            <ul className="dropdown-menu text-small p-0 border">
                                                <li className="border-bottom"><Link className="dropdown-item small d-flex align-items-center" to="/myresume" ><i className="bi bi-layout-text-sidebar-reverse me-2 fs-5"></i>My Resume </Link></li>
                                                <li className="border-bottom"><Link className="dropdown-item small d-flex align-items-center" to="#" onClick={() => { handleRedirect('my-profile') }}><i className="bi bi-gear me-2 fs-5"></i>My Account </Link></li>
                                                <li className="border-bottom"><Link className="dropdown-item small d-flex align-items-center" to="#" onClick={() => { handleRedirect('contact') }}><i className="bi bi-person-rolodex me-2 fs-5"></i>Contact Us </Link></li>
                                                <li className="border-bottom"><Link className="dropdown-item small d-flex align-items-center" to="#" onClick={() => { handleRedirect('help') }}><i className="bi bi-info-circle me-2 fs-5"></i>Help/FAQs </Link></li>
                                                <li className="border-bottom"><Link className="dropdown-item small d-flex align-items-center" to="#" onClick={() => { handleRedirect('logout') }}><i className="bi bi-door-closed me-2 fs-5"></i>Sign out</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                            </>) : (<>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <ul className="navbar-nav ms-auto">
                                        <li className="nav-item">
                                            <NavLink className="nav-link px-5" to="/login">Login</NavLink>
                                        </li>
                                        <li className="nav-item pe-3">
                                            <NavLink className="btn bg-primary-light px-5" to="/signup">Signup</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </>)
                        }

                    </div>
                </nav>
            </div>

            <div className="offcanvas offcanvas-start bg_sidebar_mob text-white" tabIndex="-1" id="offcanvasWithBothOptions"
                aria-labelledby="offcanvasWithBothOptionsLabel ">
                <div className="offcanvas-header bg-transparent align-items-center px-4 pb-0">
                    <div className="row align-items-center w-100">
                        <div className="col-3">
                            <div className="position-relative d-flex justify-content-center align-items-center flexDirection">
                                <img src={(userDetails && userDetails.user_kyc_profile && userDetails.user_kyc_profile.avatar !== '' && userDetails.user_kyc_profile.avatar !== null) ? userDetails.user_kyc_profile.avatar : process.env.PUBLIC_URL + "/image/default_user.png"} className="rounded-circle p-1 border" width="70" height='70' alt='profile' />
                            </div>
                        </div>
                        <div className="col-8">
                            <h4 className="mt-4 mb-1">{userDetails && (userDetails.first_name && userDetails.last_name) ? userDetails.first_name + ' ' + userDetails.last_name : userDetails.username}</h4>
                            <p>
                                {(userDetails && userDetails.email !== '') ? userDetails.email : ''}
                            </p>
                        </div>
                        <div data-bs-dismiss="offcanvas" aria-label="Close" id='closeBtnCanvas' className="col-1 text-end">
                            <i className="bi bi-x-lg"></i>
                        </div>

                    </div>
                </div>
                <div className="offcanvas-body pe-0 ps-3">
                    <div className="card border-0 bg-transparent">
                        <div className='card-body p-0'>
                            <div className='mt-3'>
                                <NavLink to='/myresume' onClick={handleCanvas} className='text-white text-decoration-none'><i className="bi bi-layout-text-sidebar-reverse me-2 fs-5"></i>My Resumes</NavLink>
                                <hr />
                                <NavLink to='#' onClick={() => { handleRedirect('my-profile') }} className='text-white text-decoration-none'><i className="bi bi-gear me-2 fs-5"></i>My Account</NavLink>
                                <hr />
                                <NavLink to='/apply' onClick={handleCanvas} className='text-white text-decoration-none'><i className="bi bi-envelope me-2 fs-5"></i>Apply</NavLink>
                                <hr />

                                <NavLink to='#' onClick={() => { handleRedirect('contact') }} className='text-white text-decoration-none'><i className="bi bi-person-rolodex me-2 fs-5"></i>Contact Us</NavLink>
                                <hr />

                                <NavLink to='#' onClick={() => { handleRedirect('help') }} className='text-white text-decoration-none'><i className="bi bi-info-circle me-2 fs-5"></i>Help Center</NavLink>
                                <hr />
                                <NavLink to='#' onClick={() => { handleRedirect('logout') }} className='text-white text-decoration-none'><i className="bi bi-door-closed me-2 fs-5"></i>Sign out</NavLink>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>

    );
};

export default Navbar;