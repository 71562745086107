import React from 'react';
import { NavLink } from 'react-router-dom';
import RightSideResume from './RightSideResume';
import { withRouter } from './WithRouter';
import { AuthContext } from '../Services/Authcontext';
import NotificationCard from './NotificationCard';
import LeftHideButton from './LeftHideButton';
import MobileHeader from './MobileHeader';
import video from '../image/animation/others.webm'


class Links extends React.Component {
    static contextType = AuthContext
    constructor(props) {
        super(props)
        this.state = {
            resume_links: '',
            isValid: false,
            isEdit: false,
            showForm: false,
            linksList: [],
            techSkillSuggestions: null,
        }
        this.handleForm = this.handleForm.bind(this);
        this.handleTechChange = this.handleTechChange.bind(this);
        this.handleTechRating = this.handleTechRating.bind(this);
        this.addTechFormFields = this.addTechFormFields.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    handleNext(e) {
        for (var key in this.context.stepCompleteness) {
            if (this.context.stepCompleteness[key]['resume_step_id'] === Number(this.props.step)) {
                this.context.stepCompleteness[key]['step_status'] = true;
            }
        }
        this.props.navigate(`/rb-step?step=${Number(this.props.step) + 1}`)
    }
    handleTechChange(i, e) {
        let linksList = this.state.linksList;
        linksList[i][e.target.name] = e.target.value;
        this.setState({ linksList });
    }

    handleTechRating(i, rate) {
        // eslint-disable-next-line
        this.state.linksList[i]["rating"] = parseInt(rate) / 20;
    }

    addTechFormFields(name) {
        this.setState(({
            linksList: [...this.state.linksList, { linkName: "", link: name }]
        }))
    }

    removeTechFormFields(i) {
        let linksList = this.state.linksList;
        linksList.splice(i, 1);
        this.setState({ linksList });
    }

    handleForm() {
        this.setState({ showForm: !this.state.showForm })
    }

    handleSubmit(e, formType) {
        e.preventDefault();
        const { dispatch } = this.context;
        dispatch({
            type: 'DATA_UPDATED',
            payload: true
        })
        if (formType === 'add') {
        } else {
            this.setState({ isEdit: !this.state.isEdit })
            this.setState({ editId: '' })
        }
        this.setState({ showForm: !this.state.showForm })
        setTimeout(() => {
            dispatch({
                type: 'ADD_LINKS',
                // eslint-disable-next-line
                payload: this.state.linksList.filter((linkList) => { if (linkList.link !== '' && linkList.link !== 'undefined' && linkList.link !== undefined) { return linkList } })
            })
        }, 1000)
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.context.links && this.context.links !== '' && this.context.links !== null) {
            this.setState({ linksList: this.context.links })
        }
    }

    render() {
        const step = Number(this.props.step)
        return (
            <>
                <MobileHeader setIsLoading={this.props.setIsLoading} Title={"Links"} ProgressBar={this.props.progressBar} StepList={this.props.stepList} Step={Number(this.props.step)} />
                <div className="row ms-lg-0">
                    <div className="col-md-12 col-lg-5 bg-white p-lg-4 position-relative" id="id_left">
                        <LeftHideButton rightHide={this.props.rightHide} rightShow={this.props.rightShow} />
                        <div className="" id="id_left_side">
                            <NotificationCard
                                short_notes={
                                    <>
                                        <p className='mb-1 small'>Including links (URLs or hyperlinks) on a resume gives hiring managers insights into your additional
                                            credentials and expertise.</p>
                                    </>
                                }
                                Notes={
                                    <>
                                        <p className='mb-1 small'>Including links (URLs or hyperlinks) on a resume gives hiring managers insights into your additional
                                            credentials and expertise.</p>
                                        <p className='mb-1 small'>For example, people generally provide professional links such as Linked In and software engineers
                                            can add link for their coding profiles (Github), writers can include their blog links and academics can
                                            share the links for their published research articles.</p>
                                    </>
                                } video={video} />

                            <form onSubmit={(e) => { this.handleSubmit(e, (this.state.isEdit) ? 'edit' : 'add') }} method="post" className={(this.state.showForm) ? "mt-4" : 'd-none'}>
                                {(this.state.isValid) ? <p className='text-danger'>Please fill the field.</p> : ''}
                                <div className="row mx-lg-0 p-0 m-0 pt-3">
                                    <div className="p-0 m-0" id="new_add_links">
                                        {this.state.linksList.map((element, index) => (
                                            <div className="mt-3 row p-0 m-0 mx-lg-0 add_links" key={index}>
                                                <div className="col-5 ps-0 pe-1">
                                                    <input type="text" name="linkName" id={`linkName_${element.linkName}`} value={element.linkName} onChange={(e) => { this.handleTechChange(index, e) }} className="form-control"
                                                        placeholder="add link name" />
                                                </div>
                                                <div className="col-5 px-0">
                                                    <input type="text" name="link" id={`link_${element.link}`} value={element.link} onChange={(e) => { this.handleTechChange(index, e) }} className="form-control"
                                                        placeholder="add link" />
                                                </div>
                                                <button type="button" className="col-2 del_link btn" onClick={() => this.removeTechFormFields(index)}><i className="bi bi-trash"></i></button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="d-grid mb-3 add_links">
                                    <button className=" btn mt-3 add_job_btn add_links" type='button' id="add_links"
                                        onClick={() => this.addTechFormFields()}><i className="bi bi-plus"></i> add link</button>
                                </div>
                                <div className="row mb-4">
                                    <div className="col ms-2">
                                        <button className="btn btn-outline-primary mt-3" type="button" onClick={this.handleForm}>Cancel</button>
                                    </div>

                                    <div className="col">
                                        <button className="btn btn-primary mt-3 float-end" id="links_done_btn" type="submit">{(this.state.isEdit) ? 'Add' : 'Add'}</button>
                                    </div>
                                </div>
                            </form>
                            <div className={(this.state.showForm) ? "d-none" : ''}>
                                {
                                    this.state.linksList && this.state.linksList.map((link, index) => {
                                        return <LinkListCard key={index} handleDelete={this.handlePersonalDelete} handleEdit={this.handlePersonalEdit} link={link} />
                                    })
                                }
                            </div>
                            <div id="links" className={(this.state.showForm) ? "d-none" : ''}>
                                <div className="row mb-4 mt-3">
                                    <div className="col">
                                    </div>
                                    <div className="col text-end">
                                        <button type="button" className="btn add_btn" id="links_add_btn" onClick={this.handleForm}><i
                                            className="bi bi-plus"></i> add</button>

                                    </div>
                                </div>
                            </div>

                            <div id="job_view" className={(this.state.showForm) ? "d-none" : ''}>
                                <div className="row mb-4">
                                    <div className="col">
                                        <NavLink to={`/rb-step?step=${step - 1}`}>
                                            <button className="btn btn-outline-primary mt-3">Back</button>
                                        </NavLink>
                                        <NavLink to={`/rb-step?step=${step + 1}`} onClick={this.handleNext}>
                                            <button className="btn btn-outline-primary mt-3 mx-2">Skip</button>
                                        </NavLink>
                                    </div>
                                    <div className="col">
                                        <button className="btn btn-primary mt-3 float-end" type='button' onClick={this.handleNext}>Next</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <RightSideResume />
                </div>
            </>
        );
    };
}

const LinkListCard = ({ link }) => {
    return (
        <>
            <div className='card my-3'>
                <div className='card-body'>
                    <p className="mb-0">{link.linkName}</p>
                    <p className="text-muted small mb-0">{link.link}</p>
                </div>
            </div>
        </>
    )
}

export default withRouter(Links);