import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";
const ResumeEducation = ({ templateType, sections, suffle }) => {
  const { resumeSkeleton, education, templateStatus } = useContext(AuthContext);

  const EducationCard = () => {
    if (templateType === "ats_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && education === '') || education === null || education.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeEducation">
            <h5 className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" style={{ backgroundColor: '#0000001a' }}>Education</h5>
            <div className="px-3 py-2">
              {
                education && education.map((education, index) => {
                  return (
                    <div className="row mb-2" key={index}>
                      <div className="col-3 small">
                        <i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>
                          {(education.StartDate) ? new Date(education.StartDate).toString().split(' ')[1] + " " + new Date(education.StartDate).toString().split(' ')[3] : ''} {(education.IsCurrent) ? ' - Pursuing' : (education.EndDate) ? ' - ' + new Date(education.EndDate).toString().split(' ')[1] + " " + new Date(education.EndDate).toString().split(' ')[3] : ''}
                        </i>
                      </div>
                      <div className="col-9">
                        <p className="mb-0 fw-bold">{education.Degree} {(education.Field) ? '(' + education.Field + ')' : ''}</p>
                        <i className="mb-1 small">{education.SchoolName}</i>
                        <i className="mb-1 small">{(education.University) ? ', ' + education.University : ''}</i>
                        <p className="mb-0 small">{education.Summary}</p>
                      </div>
                    </div>
                  )
                })
              }
              <input type="hidden" name="section_order" value={sections.section_order} />
              <input type="hidden" name="section_name" value={sections.section_name} />
              <input type="hidden" name="section_id" value={sections.section_id} />
              <input type="hidden" name="section_column" value={sections.section_column} />
              <input type="hidden" name="template_type" value={templateType} />
            </div>
          </li>
        </>
      );
    }
    else if (templateType === "exec_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && education === '') || education === null || education.length <= 0) ? "d-none" : "resume_section list-group my-3"} draggable="true" id="ResumeEducation">
            <h5 className="text-capitalize fw-bold mb-3 text-break exec_classic_border" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>Education</h5>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, width: '100px', padding: '1px' }} className="mt-0" />
            <ul style={{ listStyleType: 'none' }} className='ps-0'>
              {
                education && education.map((education, index) => {
                  return (
                    <li key={index} className='mb-2'>
                      <p className="fw-bold mb-0">{education.Degree} {(education.Field) ? '(' + education.Field + ')' : ''}</p>
                      <p className="small mb-1">{education.SchoolName}</p>
                      <p className="small mb-1">{education.University}</p>
                      <p className="small mb-0">
                        <i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>
                          {(education.IsCurrent) ? 'Pursuing' : `Completed in ${education.EndDate}`}
                        </i>
                      </p>
                    </li>
                  )
                })
              }
            </ul>

            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>

        </>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && education === '') || education === null || education.length <= 0) ? "d-none" : "resume_section list-group my-3"} draggable="true" id="ResumeEducation">
            <h5 className="fw-bold text-capitalize text-break" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>Education</h5>
            <hr className="mt-0" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }} />
            {
              education && education.map((education, index) => {
                return (
                  <div className="row mb-2" key={index}>
                    <div className="col-3 small">
                      <i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>
                        {(education.StartDate) ? new Date(education.StartDate).toString().split(' ')[1] + " " + new Date(education.StartDate).toString().split(' ')[3] : ''} {(education.IsCurrent) ? ' - Pursuing' : (education.EndDate) ? ' - ' + new Date(education.EndDate).toString().split(' ')[1] + " " + new Date(education.EndDate).toString().split(' ')[3] : ''}
                      </i>
                    </div>
                    <div className="col-9">
                      <p className="fw-bold mb-0">{education.Degree} {(education.Field) ? '(' + education.Field + ')' : ''}</p>
                      <p className="small mb-0">{education.University}</p>
                      <p className="small mb-0">{education.SchoolName}</p>
                    </div>
                  </div>
                )
              })
            }

            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>

        </>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && education === '') || education === null || education.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeEducation">
            <div className="mb-3">
              <h5 className="fw-bold text-capitalize text-break">Education</h5>
              <ul style={{ listStyleType: 'disc' }}>
                {
                  education && education.map((education, index) => {
                    // eslint-disable-next-line
                    return <li key={index}>{(education.Degree && education.Degree !== '') ? education.Degree : ''} {(education.Field) ? '(' + education.Field + '), ' : ''}{(education.SchoolName && education.SchoolName !== '') ? education.SchoolName + ', ' : ''}{(education.University && education.University !== '') ? education.University + ', ' : ''}{(education.SchoolLocation && education.SchoolLocation !== '') ? education.SchoolLocation + ', ' : ''} <i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(education.StartDate) ? new Date(education.StartDate).toString().split(' ')[1] + " " + new Date(education.StartDate).toString().split(' ')[3] : ''} {(education.IsCurrent) ? ' - Pursuing' : (education.EndDate) ? ' - ' + new Date(education.EndDate).toString().split(' ')[1] + " " + new Date(education.EndDate).toString().split(' ')[3] : ''}</i></li>
                  })
                }
              </ul>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>

        </>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && education === '') || education === null || education.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeEducation">
            <div className="mb-2">
              <h5 className="fw-bold text-uppercase mb-3">Education</h5>
              {
                education && education.map((education, index) => {

                  return (
                    <div className="mb-2 px-1" key={index}>
                      <h6 className="fw-bold mb-0">{education.Degree} {(education.Field) ? '(' + education.Field + ')' : ''}</h6>
                      <p className="mb-0 small">{education.SchoolName}</p>
                      <p className=" mb-0 small">{education.University}</p>
                      <i className="small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(education.StartDate) ? new Date(education.StartDate).toString().split(' ')[1] + " " + new Date(education.StartDate).toString().split(' ')[3] : ''} {(education.IsCurrent) ? ' - Pursuing' : (education.EndDate) ? ' - ' + new Date(education.EndDate).toString().split(' ')[1] + " " + new Date(education.EndDate).toString().split(' ')[3] : ''} </i>
                      <p className="small text-muted small">{education.SchoolLocation}</p>
                    </div>
                  )
                })
              }
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>

        </>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && education === '') || education === null || education.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeEducation">
            <div className="ps-2 mb-3">
              <h5 className="text-uppercase d-flex align-items-center fw-bold mb-3 text-break"><i className="bi bi-bank icon_circle_dark" style={(resumeSkeleton.styles.theme_color === '#fff') ? { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#000' } : { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#fff' }}></i>Education</h5>
              {
                education && education.map((education, index) => {

                  return (
                    <div className="mb-2 px-1" key={index}>
                      <h6 className="fw-bold mb-0">{education.Degree} {(education.Field) ? '(' + education.Field + ')' : ''}</h6>
                      <p className="mb-0">{education.SchoolName}</p>
                      <p className="mb-0">{education.University}</p>
                      <i className="small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(education.StartDate) ? new Date(education.StartDate).toString().split(' ')[1] + " " + new Date(education.StartDate).toString().split(' ')[3] : ''} {(education.IsCurrent) ? ' - Pursuing' : (education.EndDate) ? ' - ' + new Date(education.EndDate).toString().split(' ')[1] + " " + new Date(education.EndDate).toString().split(' ')[3] : ''} <span className="float-end">{education.SchoolLocation}</span></i>
                    </div>
                  )
                })
              }
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>

        </>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && education === '') || education === null || education.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeEducation">
            <div className="mb-3">
              <h5 className="fw-bol text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>Education</h5>
              {
                education && education.map((education, index) => {
                  return (
                    <div className="mb-3" key={index}>
                      <h6 className="fw-bold mb-2 text-uppercase small">{education.Degree} {(education.Field) ? '(' + education.Field + ')' : ''}</h6>
                      <p className="mb-0 small">{education.SchoolName}</p>
                      <p className="mb-0 small">{education.University}</p>
                      <p className="mb-0 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(education.StartDate) ? new Date(education.StartDate).toString().split(' ')[1] + " " + new Date(education.StartDate).toString().split(' ')[3] : ''} {(education.IsCurrent) ? ' - Pursuing' : (education.EndDate) ? ' - ' + new Date(education.EndDate).toString().split(' ')[1] + " " + new Date(education.EndDate).toString().split(' ')[3] : ''} </i></p>
                    </div>
                  )
                })
              }
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>

        </>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && education === '') || education === null || education.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeEducation">
            <div className="mb-3">
              <h5 className="fw-bold text-dark text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>Education</h5>
              {
                education && education.map((education, index) => {
                  return (
                    <div className="mb-3" key={index}>
                      <h6 className="fw-bold mb-0 text-uppercase small text-dark">{education.Degree} {(education.Field) ? '(' + education.Field + ')' : ''}</h6>
                      <p className="mb-0 small">{education.SchoolName}</p>
                      <p className="mb-0 small">{education.University}</p>
                      <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(education.StartDate) ? new Date(education.StartDate).toString().split(' ')[1] + " " + new Date(education.StartDate).toString().split(' ')[3] : ''} {(education.IsCurrent) ? ' - Pursuing' : (education.EndDate) ? ' - ' + new Date(education.EndDate).toString().split(' ')[1] + " " + new Date(education.EndDate).toString().split(' ')[3] : ''} </i></p>
                    </div>
                  )
                })
              }
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>

        </>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && education === '') || education === null || education.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeEducation">
            <div className="mb-3">
              <h5 className="fw-bold text-dark text-uppercase text-break ps-2">Education</h5>
              <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, padding: '1px' }} className="mt-0" />
              <div className="ps-3">
                {
                  education && education.map((education, index) => {
                    return (
                      <div className="mb-3" key={index}>
                        <h6 className="fw-bold mb-0 text-uppercase small">{education.Degree} {(education.Field) ? '(' + education.Field + ')' : ''}</h6>
                        <p className="mb-0 small">{education.SchoolName}</p>
                        <p className="mb-0 small">{education.University}</p>
                        <i className="small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(education.StartDate) ? new Date(education.StartDate).toString().split(' ')[1] + " " + new Date(education.StartDate).toString().split(' ')[3] : ''} {(education.IsCurrent) ? ' - Pursuing' : (education.EndDate) ? ' - ' + new Date(education.EndDate).toString().split(' ')[1] + " " + new Date(education.EndDate).toString().split(' ')[3] : ''} </i>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>

        </>
      );
    }
  }

  return (
    <>{EducationCard()}</>
  )
}

export default ResumeEducation;

