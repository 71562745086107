import axios from "axios";
import { REFRESH_TOKEN_API_LINK } from "./commonurls";

const baseURL = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_API_URL : process.env.REACT_APP_PROD_API_URL;
const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 25000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': localStorage.getItem('accessToken')
            ? `Bearer ${localStorage.getItem('accessToken')}` : null
    },
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },

    async function (error) {
        if (error.code === "ERR_NETWORK") {
            window.location.href = '/error505'
            return Promise.reject(error);
        }
        const originalRequest = error.config;
        if (typeof error.response === 'undefined') {
            window.location.href = '/error505'
            return Promise.reject(error);
        }
        if (error.response.status === 500) {
            window.location.href = '/error505'
            return Promise.reject(error);
        }
        if (error.response.status === 401) {
            window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_LOGOUT_URL : process.env.REACT_APP_PROD_LOGOUT_URL;;
            return Promise.reject(error);
        }
        if (error.response.status === error.response.statusText === "Unauthorized") {
            window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_LOGOUT_URL : process.env.REACT_APP_PROD_LOGOUT_URL;;
            return Promise.reject(error);
        }
        if (error.response.status === 401 && error.response.statusText === "Unauthorized") {
            window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_LOGOUT_URL : process.env.REACT_APP_PROD_LOGOUT_URL;;
            return Promise.reject(error);

        }
        if (
            error.response.data.code === 'token_not_valid' &&
            error.response.status === 401 &&
            error.response.statusText === 'Unauthorized'
        ) {
            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken && refreshToken !== 'undefined') {
                const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
                const now = Math.ceil(Date.now() / 1000);

                if (tokenParts.exp > now) {
                    return axiosInstance.post(REFRESH_TOKEN_API_LINK, { refresh: refreshToken })
                        .then((response) => {
                            localStorage.setItem('accessToken', response.data.access);

                            axiosInstance.defaults.headers['Authorization'] = `Bearer ${response.data.refresh}`;
                            originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`;

                            return axiosInstance(originalRequest);
                        })
                        .catch((err) => { console.log(err) });
                } else {
                    console.log('refresh token is expired', tokenParts.exp, now);
                    window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_LOGOUT_URL : process.env.REACT_APP_PROD_LOGOUT_URL;
                }
            } else {
                console.log('refresh token not available');
                window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_LOGOUT_URL : process.env.REACT_APP_PROD_LOGOUT_URL;
            }
        }
        return Promise.reject(error);

    }
);

export default axiosInstance;
