import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RightSideResume from './RightSideResume';
import { AuthContext } from '../Services/Authcontext';
import MobileHeader from './MobileHeader';
import axiosInstance from '../Services/axios';
import LeftHideButton from './LeftHideButton';
import NotificationCard from './NotificationCard';
import { RESUME_DATA_CONTENT_API_LINK } from '../Services/commonurls';

const ResumeTitle = ({ step, setStep, progressBar, setIsLoading, rightHide, rightShow, stepList }) => {
    const { dispatch, basicInfo, resumeDataSkeleton, stepCompleteness } = useContext(AuthContext);
    const navigate = useNavigate();
    const [resumeTitle, setResumeTitle] = useState((resumeDataSkeleton && resumeDataSkeleton.basic_info && resumeDataSkeleton.basic_info.resume_title !== '') ? resumeDataSkeleton.basic_info.resume_title : (basicInfo.resume_title) ? basicInfo.resume_title : '');
    const [jobFamily, setJobFamily] = useState((resumeDataSkeleton && resumeDataSkeleton.basic_info && resumeDataSkeleton.basic_info.job_family !== '') ? resumeDataSkeleton.basic_info.job_family : (basicInfo.job_family) ? basicInfo.job_family : '');
    const [jobFamilyList, setJobFamilyList] = useState([]);
    const [jobGroup, setJobGroup] = useState((resumeDataSkeleton && resumeDataSkeleton.basic_info && resumeDataSkeleton.basic_info.job_group !== '') ? resumeDataSkeleton.basic_info.job_group : (basicInfo.job_group) ? basicInfo.job_group : '');
    const [jobGroupList, setJobGroupList] = useState([]);
    const [jobType, setJobType] = useState((resumeDataSkeleton && resumeDataSkeleton.basic_info && resumeDataSkeleton.basic_info.job_type !== '') ? resumeDataSkeleton.basic_info.job_type : (basicInfo.job_type) ? basicInfo.job_type : '');
    const [jobTypeList, setJobTypeList] = useState([]);
    const [Valid, setValid] = useState(false);
    // eslint-disable-next-line
    const [prefferedDateFormat, setPrefferedDateFormat] = useState((resumeDataSkeleton && resumeDataSkeleton.basic_info && resumeDataSkeleton.basic_info.preferred_data_format !== '' && resumeDataSkeleton.basic_info.preferred_data_format !== null) ? resumeDataSkeleton.basic_info.preferred_data_format : (basicInfo.preferred_data_format) ? basicInfo.preferred_data_format : '');

    const handleFetchData = async (setvalue, param) => {
        await axiosInstance.get(`${RESUME_DATA_CONTENT_API_LINK}?data_name=${param}`)
            .then((res) => {
                setvalue(res.data.data.results)
            });
    }

    const handleJobFamily = (data) => {
        let param = `job_group&job_family=${data}`;
        handleFetchData(setJobGroupList, param)
        setJobFamily(data)
    }


    const handleJobGroup = (data) => {
        let param = `job_type&job_group=${data}`;
        handleFetchData(setJobTypeList, param)
        setJobGroup(data)
    }

    const handleJobType = (data) => {
        setJobType(data)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (resumeTitle !== '' && jobFamily !== '' && jobGroup !== '' && jobType !== '') {

            let data = {
                resume_title: resumeTitle,
                job_family: jobFamily,
                job_group: jobGroup,
                job_type: jobType,
                perfered_date_format: prefferedDateFormat,
            }

            dispatch({
                type: "ADD_RESUME_TITLE",
                payload: data
            })

            for (var key in stepCompleteness) {
                if (stepCompleteness[key]['resume_step_id'] === Number(step)) {
                    stepCompleteness[key]['step_status'] = true;
                }
            }

            navigate(`/rb-step?step=${Number(step) + 1}`);
            setStep(Number(step) + 1)
            setValid(false)
        } else {
            setValid(!Valid)
        }
    }

    useEffect(() => {
        handleFetchData(setJobFamilyList, 'job_family');

        if (jobFamily && jobFamily !== '') {
            handleJobFamily(jobFamily);
        }
        if (jobGroup && jobGroup !== '') {
            handleJobGroup(jobGroup);
        }
    }, [jobGroup, jobFamily])


    return (
        <>
            <MobileHeader setIsLoading={setIsLoading} Title={"Resume Title"} ProgressBar={progressBar} StepList={stepList} Step={Number(step)} />
            <div className="row ms-lg-0">
                <div className="col-md-12 col-lg-5 bg-white p-lg-4 position-relative" id="id_left">
                    <LeftHideButton rightHide={rightHide} rightShow={rightShow} />
                    <div className="" id="id_left_side">
                        <NotificationCard Notes={
                            <>
                                <p className='small mb-1'><b>Hi I’m Lee!</b> </p>
                                <p className='small mb-0'>I will guide you to build your resume. Now give your resume a name and your desired job post</p>
                            </>
                        } />
                        <form action="" onSubmit={handleSubmit} method="post" className="mt-4">
                            {(Valid) ? <p className='text-danger'>Please fill all the fields.</p> : ''}

                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" value={resumeTitle} onChange={(e) => {
                                    setResumeTitle(e.target.value);
                                    document.getElementById("designation").innerHTML = e.target.value;

                                }} name="resume_title" id="resume_title" placeholder="Resume Titlem" />
                                <label htmlFor="resume_title">Resume Title</label>
                            </div>
                            <div className="form-floating mb-3">
                                <select className="form-select" value={jobFamily} onChange={(e) => { handleJobFamily(e.target.value) }} name="job_family" id="job_family">
                                    <option value="">Select Job Family</option>

                                    {
                                        jobFamilyList && jobFamilyList.map((jobfamily, index) => {
                                            return <OptionCard key={index} value={jobfamily.code} selected={jobFamily} name={jobfamily.name} />
                                        })
                                    }
                                </select>
                                <label htmlFor="job_family">Job Family</label>
                            </div>
                            <div className="form-floating mb-3">
                                <select className="form-select" value={jobGroup} onChange={(e) => { handleJobGroup(e.target.value) }} name="job_group" id="job_group">
                                    <option value="">Select Job Group</option>

                                    {
                                        jobGroupList && jobGroupList.map((jobgroup, index) => {
                                            return <OptionCard key={index} value={jobgroup.code} selected={jobGroup} name={jobgroup.name} />
                                        })
                                    }
                                </select>
                                <label htmlFor="job_group">Job Group</label>
                            </div>
                            <div className="form-floating mb-3">
                                <select className="form-select" value={jobType} onChange={(e) => { handleJobType(e.target.value) }} name="type" id="type">
                                    <option value="">Select Job Type</option>

                                    {
                                        jobTypeList && jobTypeList.map((jobtype, index) => {
                                            return <OptionCard key={index} value={jobtype.code} selected={jobType} name={jobtype.name} />
                                        })
                                    }
                                </select>
                                <label htmlFor="type">Type</label>
                            </div>
                            <div className="text-end" >
                                <button className="btn btn-primary" type='submit'>Next</button>
                            </div>
                        </form>
                    </div>
                </div>
                <RightSideResume />
            </div>
        </>
    );
}

export default ResumeTitle;


const OptionCard = ({ value, name, selected }) => {
    return <option value={value}>{name}</option>

}