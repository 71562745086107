
import React, { useContext, useEffect, useState } from 'react';
import axiosInstance from '../Services/axios';
import Navbar from './Navbar';
import Sidebar from './sidebar';
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'draft-js/dist/Draft.css'
import draftToHtml from 'draftjs-to-html';
import { Link, useNavigate } from 'react-router-dom';
import Loading from './Loading';
import { AuthContext } from '../Services/Authcontext';
import { MY_APP_TRACKER_API_LINK, USER_INFO_API_LINK } from '../Services/commonurls';

const Apply = () => {
    const { resumeID } = useContext(AuthContext);
    const [salutation, setsalutation] = useState('');
    const [recruitername, setrecruitername] = useState('');
    const [companyname, setcompanyname] = useState('');
    const [jobpost, setjobpost] = useState('');
    const [emailreceiver, setemailreceiver] = useState('');
    const [subject, setsubject] = useState('');
    const [userDetails, setuserDetails] = useState('');
    // const [attachedresume, setattachedresume] = useState('');
    const [Valid, setValid] = useState(false);
    const [editorState, setEditorState] = useState('')
    const [ccemail, setccEmail] = useState('');
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const fetchUserDetails = async () => {
        await axiosInstance.get(USER_INFO_API_LINK).then((res) => {
            setuserDetails(res.data.data);
            setccEmail(res.data.data.email);
        });
    }

    // const handleFile = (e) => {
    //     setattachedresume(e.target.files[0]);
    // }

    const onEditorStateChange = editorState => {
        setEditorState(editorState);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            salutation !== '' &&
            recruitername !== '' &&
            companyname !== '' &&
            jobpost !== '' &&
            emailreceiver !== '' &&
            subject !== ''
        ) {
            let data = {
                salutation: salutation,
                recruitername: recruitername,
                companyname: companyname,
                jobpost: jobpost,
                emailreceiver: emailreceiver,
                cc_email: ccemail,
                message: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                subject: subject,
                attachedresume: resumeID,
            };
            setLoading(true);
            axiosInstance.defaults.headers['Content-Type'] = 'multipart/form-data';
            axiosInstance.post(MY_APP_TRACKER_API_LINK, data).then((res) => {
                setLoading(true);
                if (res.data.status_code === 200) {
                    navigate('/success', { state: 'apply' });
                    setLoading(false);
                };
            });
            axiosInstance.defaults.headers['Content-Type'] = 'application/json';
            setValid(false)
        } else {
            setValid(true)
            window.scrollTo(0, 0)
        }
    }

    useEffect(() => {

        fetchUserDetails();
        const html = `<p>Dear {Recruiter Name Here},</p>
        <p>I have attached my resume and a cover letter for the  job to apply {position} at  {company} . The role and responsibilities seem exciting and matching to my interest areas and career goals.</p>
        <p>I am looking forward to meeting you in person to share my insights and ideas on making  company development quicker and more effective.</p>
        <p>Sincerely,
Your Name
Contact Number</p>`;
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState((contentState) ? EditorState.createWithContent(contentState) : '');
        if (!resumeID) {
            navigate('/myresume');
        }
    }, []);

    return (
        <>
            {
                (loading) ? <Loading /> : (<>
                    <Navbar status={true} />
                    <div className="main">
                        <div className="container-fluid mt-6 p-0">
                            <div className="row mx-0">
                                <Sidebar />
                                <div className="col-lg-9 bg-ab px-0">
                                    <h5 className="my-5 text-center text-dark"><span className="fw-bold">Send your resume</span></h5>
                                    <div className="container d-flex justify-content-center px-lg-2 px-0">
                                        <div className="col-12 col-md-8 align-self-center pb-5">
                                            <div className="card rounded-3 border-0 p-4 mb-4">
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center mb-3'>
                                                        <img src={(userDetails && userDetails.user_kyc_profile && userDetails.user_kyc_profile.avatar !== '' && userDetails.user_kyc_profile.avatar !== null) ? userDetails.user_kyc_profile.avatar : process.env.PUBLIC_URL + "/image/default_user.png"} width="100" height="100"
                                                            className="border rounded card-top-img p-1 me-3" alt="profile"></img>
                                                        <div>
                                                            <p><span className='text-dark'>hi!</span> we help you to apply your resume in more professional way</p>
                                                            <p className='text-dark'>Send your application to your recruiter using our tool.</p>
                                                        </div>
                                                    </div>
                                                    <form method='POST' onSubmit={handleSubmit} autoComplete='off'>
                                                        {(Valid) ? <p className='text-danger mb-1'>Please fill all the fields.</p> : ''}
                                                        <div className="row g-4 mb-3">
                                                            <div className="col-md">
                                                                <div className="form-floating">
                                                                    <select className="form-select" value={salutation} name='salutation' id="salutation" onChange={e => { setsalutation(e.target.value); }}>
                                                                        <option value>Select Salutation</option>
                                                                        <option value={'Mr'}>Mr</option>
                                                                        <option value={'Ms'}>Ms</option>
                                                                        <option value={'Mrs'}>Mrs</option>
                                                                    </select>
                                                                    <label htmlFor="salutation">Salutation</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" value={recruitername} name='recruitername' id="recruitername" onChange={e => {
                                                                        setrecruitername(e.target.value)
                                                                        const html = `<p>Dear ${salutation} ${e.target.value},</p>
                                                            <p>I have attached my resume and a cover letter for the  job to apply {position} at  {company} . The role and responsibilities seem exciting and matching to my interest areas and career goals.</p>
                                                            <p>I am looking forward to meeting you in person to share my insights and ideas on making  company development quicker and more effective.</p>
                                                            <p>Sincerely,
${userDetails.first_name} ${userDetails.last_name}
${userDetails.user_kyc_profile.phone_number}</p>`;
                                                                        const contentBlock = htmlToDraft(html);
                                                                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                                                                        setEditorState((contentState) ? EditorState.createWithContent(contentState) : '');
                                                                    }} placeholder="Recruiter Name" />
                                                                    <label htmlFor="recruitername">Recruiter Name</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row g-4 mb-3">
                                                            <div className="col-md">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" name='companyname' id="companyname" value={companyname} onChange={e => {
                                                                        setcompanyname(e.target.value);
                                                                        const html = `<p>Dear ${salutation} ${recruitername},</p>
                                                                    <p>I have attached my resume and a cover letter for the  job to apply position at ${e.target.value} . The role and responsibilities seem exciting and matching to my interest areas and career goals.</p>
                                                                    <p>I am looking forward to meeting you in person to share my insights and ideas on making  company development quicker and more effective.</p>
                                                                    <p>Sincerely,
${userDetails.first_name} ${userDetails.last_name}
${userDetails.user_kyc_profile.phone_number}</p>`;
                                                                        const contentBlock = htmlToDraft(html);
                                                                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                                                                        setEditorState((contentState) ? EditorState.createWithContent(contentState) : '');

                                                                    }} placeholder="Company Name" />
                                                                    <label htmlFor="companyname">Company Name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" name='jobpost' value={jobpost} id="jobpost" onChange={e => {
                                                                        setjobpost(e.target.value)
                                                                        const html = `<p>Dear ${salutation} ${recruitername},</p>
                                                                    <p>I have attached my resume and a cover letter for the  job to apply ${e.target.value} at ${companyname} . The role and responsibilities seem exciting and matching to my interest areas and career goals.</p>
                                                                    <p>I am looking forward to meeting you in person to share my insights and ideas on making  company development quicker and more effective.</p>
                                                                    <p>Sincerely,
${userDetails.first_name} ${userDetails.last_name}
${userDetails.user_kyc_profile.phone_number}</p>`;
                                                                        const contentBlock = htmlToDraft(html);
                                                                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                                                                        setEditorState((contentState) ? EditorState.createWithContent(contentState) : '');

                                                                    }} placeholder="Job Post" />
                                                                    <label htmlFor="jobpost">Job Post</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-floating mb-3">
                                                            <input type="email" className="form-control" name='emailreceiver' id="emailreceiver" onChange={e => setemailreceiver(e.target.value)} placeholder="Email receiver" />
                                                            <label htmlFor="emailreceiver">To</label>
                                                        </div>
                                                        <div className="form-floating mb-3">
                                                            <input type="email" className="form-control" disabled name='ccemail' id="ccemail" value={ccemail} placeholder="Email receiver" />
                                                            <label htmlFor="ccemail">CC</label>
                                                        </div>


                                                        <div className="form-floating mb-3">
                                                            <input type="text" className="form-control" name='subject' id="subject" onChange={e => setsubject(e.target.value)} placeholder="Subject" />
                                                            <label htmlFor="subject">Subject</label>
                                                        </div>
                                                        <div className="">
                                                            <Editor
                                                                editorState={editorState}
                                                                toolbarClassName="toolbarClassName"
                                                                wrapperClassName="demo-wrapper"
                                                                editorClassName="demo-editor"
                                                                spellCheck={true}
                                                                stripPastedStyles={true}
                                                                toolbar={{
                                                                    options: ['inline', 'fontSize', 'list', 'textAlign'],
                                                                    inline: { inDropdown: true },
                                                                    list: { inDropdown: true },
                                                                    textAlign: { inDropdown: true },
                                                                }}
                                                                onEditorStateChange={onEditorStateChange}
                                                            />
                                                        </div>
                                                        <span className="mb-3 w-100 d-block alert alert-success p-2"><i className="bi bi-paperclip"></i> Resume Attached {resumeID}</span>

                                                        <div className='d-flex justify-content-between'>
                                                            <Link to={'/myresume'} className='btn btn-outline-primary'>Cancel</Link>
                                                            <button type="submit" className='btn btn-primary'>Send</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="p-0 h-100 d-lg-none d-block">
                                    <div className="">
                                        <Link to="/rb-creation-option">
                                            <img src={process.env.PUBLIC_URL + "/image/sidebar_img.png"} width="100%" height="100%" alt="" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>)
            }
        </>

    );
};


export default Apply;

