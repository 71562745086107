import { Markup } from "interweave";
import React, { useContext, useState } from "react";
import { AuthContext } from "../Services/Authcontext";

const ResumeProfilePhoto = ({ templateType, sections, suffle }) => {
  const { resumeDataSkeleton, templateStatus, personalInformation, jobSummary, resumeSkeleton, resumeTitle } = useContext(AuthContext);
 // eslint-disable-next-line
  const [Image, setImage] = useState((personalInformation && personalInformation.profile_picture !== '' && personalInformation.profile_picture !== null) ? personalInformation.profile_picture : '');

  const resumeProfileCard = () => {
    if (templateType === "ats_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group my-3"} draggable="true" id="ResumeProfilePhoto">
            <div className={(personalInformation && personalInformation.show_profile_picture) ? "text-center mb-2" : 'd-none'}>
              <img
                src={(Image) ? Image : (process.env.NODE_ENV === 'development') ? "http://localhost:3000/image/default_user.png" : process.env.REACT_APP_PROD_RESUME_WEBSITE_URL + "image/default_user.png"}
                draggable="false"
                className="rounded-circle" style={{ borderRadius: "50%" }}
                width="100px" height="100px" id="profileImage" alt="profile_image" />
            </div>
            <div className="text-center px-3">
              <h6 className="resume_name mb-0 fw-bold text-break">
                <span className="mb-0 me-1" id="firstName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.first_name : personalInformation.first_name}</span>
                <span className="mb-0" id="lastName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.last_name : personalInformation.last_name}</span>
              </h6>
              <span className="text-capitalize" id="designation">
                {resumeTitle}
              </span>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeProfilePhoto">

            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeProfilePhoto">
            <div className="row">
              <h2 className="fw-bold mb-1 text-break" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>
                <span className="mb-0 me-1" id="firstName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.first_name : personalInformation.first_name}</span>
                <span className="mb-0" id="lastName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.last_name : personalInformation.last_name}</span>
              </h2>
              <p className="" id="designation">{resumeTitle}</p>
            </div>
            <p className="d-none" id="profileImage"></p>
            <p className="d-none" id="profileImage"></p>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeProfilePhoto">
            <div className="row">
              <div className={(personalInformation && personalInformation.show_profile_picture) ? "col-9" : 'col-12'}>
                <h2 className="text-break">
                  <span className="mb-0 me-1" id="firstName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.first_name : personalInformation.first_name}</span>
                  <span className="mb-0" id="lastName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.last_name : personalInformation.last_name}</span>
                </h2>
                <p className="fw-bold" id="designation" style={{ color: resumeSkeleton.styles.theme_color }}>{resumeTitle}</p>
                <div id="jobSummary">
                  <Markup content={jobSummary.profile_summary} />
                </div>
              </div>
              <div className={(personalInformation && personalInformation.show_profile_picture) ? "col-3 text-center" : 'd-none'}>
                <img
                  src={(Image) ? Image : (process.env.NODE_ENV === 'development') ? "http://localhost:3000/image/default_user.png" : process.env.REACT_APP_PROD_RESUME_WEBSITE_URL + "image/default_user.png"}
                  className={(personalInformation && personalInformation.show_profile_picture) ? "rounded-circle p-1" : "d-none"} width="120px" height="120px" id="profileImage" alt="profile_image" />
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeProfilePhoto">

            <div className="mb-3">
              <div className="d-flex">
                <svg className="flex-shrink-0 me-3" width="16" height="40">
                  <rect width="100%" height="100%" style={{ fill: resumeSkeleton.styles.theme_color }} /></svg>
                <h2 className="mb-0 fw-bold text-break">
                  <span className="mb-0 me-1" id="firstName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.first_name : personalInformation.first_name}</span>
                  <span className="mb-0" id="lastName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.last_name : personalInformation.last_name}</span>
                  <p className="small fw-normal mt-1" id="designation">{resumeTitle}</p>
                </h2>
              </div>
              <img
                src={(Image) ? Image : (process.env.NODE_ENV === 'development') ? "http://localhost:3000/image/default_user.png" : process.env.REACT_APP_PROD_RESUME_WEBSITE_URL + "image/default_user.png"} width="100px" height="100px"
                className={(personalInformation && personalInformation.show_profile_picture) ? "rounded-circle p-1 border d-none" : "d-none"} id="profileImage" alt="profile_image" />
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group d-none"} draggable="true" id="ResumeProfilePhoto">
            
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeProfilePhoto">
            
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalInformation === '') || personalInformation === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeProfilePhoto">
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
  }

  return (<>{resumeProfileCard()}</>)
}

export default ResumeProfilePhoto;
