import React from "react";
import { NavLink } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState, convertFromHTML, Modifier } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CustomOption from "./CustomOption";
import RightSideResume from "./RightSideResume";
import { nanoid } from 'nanoid';
import { AuthContext } from "../Services/Authcontext";
import { withRouter } from "./WithRouter";
import NotificationCard from "./NotificationCard";
import LeftHideButton from "./LeftHideButton";
import ExperienceCard from "./ExperienceCard";
import MobileHeader from "./MobileHeader";
import axiosInstance from "../Services/axios";
import video from '../image/animation/work_expereience.webm'
import { RESUME_DATA_CONTENT_API_LINK } from "../Services/commonurls";


class WorkExperience extends React.Component {
  static contextType = AuthContext
  constructor(props) {
    const html = '';

    super(props);
    const contentBlock = htmlToDraft(html);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState: editorState,
        showForm: false,
        isEdit: false,
        editId: '',
        JobTitle: '',
        Empoloyer: '',
        Client: '',
        City: '',
        State: '',
        StartDate: '',
        EndDate: '',
        Summary: '',
        IsCurrent: '',
        CompanyList: '',
        experienceList: [
          // {
          //   id: "default",
          //   JobTitle: 'Project Manager (3 Projects)',
          //   Empoloyer: 'ABC IT Solutions',
          //   City: 'Chennai',
          //   State: 'TN',
          //   StartDate: '2014',
          //   EndDate: '2019',
          //   Summary: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate similique optio tenetur nemo? Excepturi.',
          //   IsCurrent: true,
          // },
        ]
      };
    }
    this.handleForm = this.handleForm.bind(this);
    this.handleJobDelete = this.handleJobDelete.bind(this);
    this.handleJobEdit = this.handleJobEdit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }
  onEditorStateChange = (editorState) => {
    this.setState({ editorState: editorState });
    this.setState({ Summary: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) });
  };

  handleForm() {
    this.setState({ showForm: !this.state.showForm })
  }

  handleJobEdit(id) {
    this.setState({ isEdit: !this.state.isEdit })
    this.setState({ editId: id })
    this.setState({ showForm: !this.state.showForm })
    // const date = new Date();
    const EditJob = this.state.experienceList.filter(experience => experience.id === id);
    EditJob.map((editData) => {
      this.setState({ id: editData.id });
      this.setState({ JobTitle: editData.JobTitle });
      this.setState({ Empoloyer: editData.Empoloyer });
      this.setState({ Client: editData.Client });
      this.setState({ City: editData.City });
      this.setState({ State: editData.State });
      this.setState({ StartDate: editData.StartDate });
      this.setState({ EndDate: editData.EndDate });
      this.setState({ Summary: editData.Summary });
      const blocksFromHTML = convertFromHTML(editData.Summary);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      this.setState({ editorState: EditorState.createWithContent(state) });
      this.setState({ IsCurrent: editData.IsCurrent });
      return '';
    });
  }

  handleJobDelete(id) {
    const { dispatch } = this.context;
    const deleteJob = this.state.experienceList.filter(experience => experience.id !== id);
    this.setState({ experienceList: deleteJob })
    setTimeout(() => {
      dispatch({
        type: 'ADD_WORK_EXPERIENCE',
        payload: this.state.experienceList
      })
    }, 1000)
  }

  handleSubmit(e, formType) {
    e.preventDefault();
    const { dispatch } = this.context;
    dispatch({
      type: 'DATA_UPDATED',
      payload: true
    })
    if (formType === 'add') {
      const addJob = {
        id: nanoid(),
        Empoloyer: this.state.Empoloyer,
        Client: this.state.Client,
        JobTitle: this.state.JobTitle,
        City: this.state.City,
        State: this.state.State,
        StartDate: this.state.StartDate,
        EndDate: this.state.EndDate,
        Summary: this.state.Summary,
        IsCurrent: this.state.IsCurrent
      }
      this.setState({ experienceList: [...this.state.experienceList, addJob] })

    } else {

      const EditJob = this.state.experienceList.map(experience => {
        if (experience.id === this.state.editId) {
          return {
            ...experience, Empoloyer: this.state.Empoloyer,
            Client: this.state.Client,
            JobTitle: this.state.JobTitle,
            City: this.state.City,
            State: this.state.State,
            StartDate: this.state.StartDate,
            EndDate: this.state.EndDate,
            Summary: this.state.Summary,
            IsCurrent: this.state.IsCurrent
          };
        }
        return experience;
      });
      this.setState({ experienceList: EditJob })
      this.setState({ isEdit: !this.state.isEdit })
    }
    this.setState({ showForm: !this.state.showForm })
    this.setState({ Empoloyer: '' })
    this.setState({ Client: '' })
    this.setState({ JobTitle: '' })
    this.setState({ City: '' })
    this.setState({ State: '' })
    this.setState({ StartDate: '' })
    this.setState({ EndDate: '' })
    this.setState({ Summary: '' })
    this.setState({ IsCurrent: false })
    this.setState({ editorState: EditorState.createEmpty() })

    setTimeout(() => {
      dispatch({
        type: 'ADD_WORK_EXPERIENCE',
        payload: this.state.experienceList
      })
    }, 1000)

  };


  hadleSetContent = (data) => {
    const html = data;
    const contentBlock = htmlToDraft(html);

    if (contentBlock) {
      const editorState = this.state.editorState;

      let { contentBlocks, entityMap } = htmlToDraft(data);
      let contentState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
      )
      const newEditorState = EditorState.push(editorState, contentState, 'insert-fragment');
      this.onEditorStateChange(newEditorState)
    }
  }

  handleNext(e) {
    const { dispatch } = this.context;
    let data = this.state.experienceList.filter(certificate => certificate.id !== "default");
    dispatch({
      type: "ADD_WORK_EXPERIENCE",
      payload: data
    });
    for (var key in this.context.stepCompleteness) {
      if (this.context.stepCompleteness[key]['resume_step_id'] === Number(this.props.step)) {
        this.context.stepCompleteness[key]['step_status'] = true;
      }
    }
    this.props.navigate(`/rb-step?step=${Number(this.props.step) + 1}`)
  }

  handleFetchCompany = async () => {
    await axiosInstance.get(`${RESUME_DATA_CONTENT_API_LINK}?data_name=company&search_query=`)
      .then((res) => {
        this.setState({ CompanyList: res.data.data.results })
      });
  }

  handleDateValiadation = (date) => {
    var today = date;
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }

    return today = yyyy + '-' + mm;
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.handleFetchCompany();
    if (this.context.workExperience) {
      this.setState({ experienceList: this.context.workExperience })
    }
    document.getElementById("exp_start_date").setAttribute("max", this.handleDateValiadation(new Date()));
    document.getElementById("exp_end_date").setAttribute("readonly", true);
  }
  render() {
    var step = Number(this.props.step);

    const { editorState } = this.state;
    return (
      <>
        <MobileHeader setIsLoading={this.props.setIsLoading} Title={"Work Experience"} ProgressBar={this.props.progressBar} StepList={this.props.stepList} Step={Number(this.props.step)} />
        <div className="row ms-lg-0">
          <div
            className="col-md-12 col-lg-5 bg-white p-lg-4 position-relative" id="id_left">
            <LeftHideButton rightHide={this.props.rightHide} rightShow={this.props.rightShow} />
            <div className="" id="id_left_side">
              <NotificationCard
                short_notes={
                  <>
                    <p className="mb-1 small">Tell us about all your work experiences one by one starting with the current job. Put the most recent
                      experience / work at the top and present in descending order of date.</p>
                  </>
                }
                Notes={<>
                  <p className="mb-1 small">Tell us about all your work experiences one by one starting with the current job. Put the most recent
                    experience / work at the top and present in descending order of date.</p>
                  <p className="mb-1 small">Focus on providing “evidences” of what you achieved than “claims”. Hiring Managers only look for
                    relevant facts and evidence to confirm that you have the right skills for the role. Your claims like
                    “team player”, “hard working go getter” do not have much weight.</p>
                  <p className="mb-1 small">Ensure you entered the employer’s name, start and end dates of each role and optionally Client
                    Name and location details.</p>
                  <p className="mb-1 small"><span className='text-decoration-underline'>If you are a fresher</span>, you can use this section to share your internship or part time work experience.
                    You can call out in the detail section if this is internship or part time job.</p>
                </>} video={video} />
              <form onSubmit={(e) => { this.handleSubmit(e, (this.state.isEdit) ? 'edit' : 'add') }} method="post" className={(this.state.showForm) ? "mt-4" : "d-none"} id="experience">
                <div className="row mb-3">
                  <div className="col">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="exp_job_title"
                        id="exp_job_title"
                        className="form-control resume_exp_input"
                        placeholder="Job Title"
                        value={this.state.JobTitle}
                        onChange={(e) => { this.setState({ JobTitle: e.target.value }) }}
                      />
                      <label htmlFor="exp_job_title">Job Title</label>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="exp_employer"
                        id="exp_employer"
                        list='CompanyList'
                        className="form-control resume_exp_input"
                        placeholder="Employer"
                        value={this.state.Empoloyer}
                        onChange={(e) => { this.setState({ Empoloyer: e.target.value }) }}
                      />

                      <label htmlFor="exp_employer">Employer</label>
                    </div>
                  </div>
                  <datalist id="CompanyList">
                    {
                      this.state.CompanyList && this.state.CompanyList.map((company, index) => {
                        return <option value={company} key={index}>{company}</option>
                      })
                    }
                  </datalist>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="exp_client"
                        id="exp_client"
                        className="form-control resume_exp_input"
                        placeholder="Client Name"
                        value={this.state.Client}
                        onChange={(e) => { this.setState({ Client: e.target.value }) }}
                      />
                      <label htmlFor="exp_client">Client Name</label>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col pe-2">
                    <div className="form-floating">
                      {/* <select name="exp_city" id="exp_city" value={this.state.City} onChange={(e) => {
                        this.setState({ City: e.target.value });
                        this.setState({ State: e.target.options[e.target.selectedIndex].dataset.country })
                      }} className='form-select'>
                        {this.context.cityList && this.context.cityList.map((city, i) => {
                          return <option key={i}
                            defaultValue={(city.name.toLowerCase().charAt(0).toUpperCase() + city.name.toLowerCase().slice(1)) === this.state.City ? true : false}
                            value={city.name.toLowerCase().charAt(0).toUpperCase() + city.name.toLowerCase().slice(1)}
                            data-country={city.country__name.toLowerCase().charAt(0).toUpperCase() + city.country__name.toLowerCase().slice(1)}>
                            {city.name.toLowerCase().charAt(0).toUpperCase() + city.name.toLowerCase().slice(1)}
                          </option>
                        })}
                      </select> */}
                      <input
                        type="text"
                        name="exp_city"
                        id="exp_city"
                        className="form-control resume_exp_input"
                        placeholder="City"
                        value={this.state.City}
                        onChange={(e) => { this.setState({ City: e.target.value }); }}
                      />
                      <label htmlFor="City">City</label>
                    </div>
                  </div>
                  <div className="col ps-2">
                    <div className="form-floating">
                      <select name="exp_state" id="exp_state" value={this.state.State} onChange={(e) => {
                        this.setState({ State: e.target.value });
                      }} className='form-select'>
                        {this.context.countryList && this.context.countryList.map((country, i) => {
                          return <option key={i}
                            value={country.toLowerCase().charAt(0).toUpperCase() + country.toLowerCase().slice(1)}
                            defaultValue={(country.toLowerCase().charAt(0).toUpperCase() + country.toLowerCase().slice(1) === this.state.State) ? true : false}
                          >{country.toLowerCase().charAt(0).toUpperCase() + country.toLowerCase().slice(1)}
                          </option>
                        })}
                      </select>
                      {/* <input
                        type="text"
                        name="exp_state"
                        id="exp_state"
                        className="form-control resume_exp_input"
                        placeholder="Country"
                        value={this.state.State}
                        onChange={(e) => { this.setState({ State: e.target.value }) }}
                      /> */}
                      <label htmlFor="state">Country</label>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col pe-2">
                    <div className="form-floating">
                      <input
                        type="month"
                        name="exp_start_date"
                        id="exp_start_date"
                        className="form-control resume_exp_input_date"
                        placeholder="Start Date"
                        value={this.state.StartDate}
                        onChange={(e) => {
                          this.setState({ StartDate: e.target.value });
                          document.getElementById('exp_end_date').setAttribute("min", this.handleDateValiadation(new Date(e.target.value)));
                          document.getElementById("exp_end_date").removeAttribute("readonly");
                        }}
                      />
                      <label htmlFor="exp_start_date">Start Date</label>
                    </div>
                  </div>
                  <div className="col ps-2">
                    <div className="form-floating">
                      <input
                        type="month"
                        name="exp_end_date"
                        id="exp_end_date"
                        className="form-control resume_exp_input_date"
                        placeholder="End Date"
                        value={this.state.EndDate}
                        onChange={(e) => { this.setState({ EndDate: e.target.value }) }}
                      />
                      <label htmlFor="exp_end_date">End Date</label>
                    </div>
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input className="form-check-input" type="checkbox" name="exp_current" checked={this.state.IsCurrent} onChange={(e) => { this.setState({ IsCurrent: e.target.checked }); if (e.target.checked) { this.setState({ EndDate: '' }) } }} defaultValue="" id="exp_current" />
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    I currently work here
                  </label>
                </div>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  spellCheck={true}
                  stripPastedStyles={true}
                  toolbar={{
                    options: ['inline', 'fontSize', 'list', 'textAlign'],
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                  }}
                  toolbarCustomButtons={[<CustomOption hadleSetContent={this.hadleSetContent} dataName="work_experience_content" type={`job_type=${this.context.basicInfo.job_type}`} />]}
                  onEditorStateChange={this.onEditorStateChange}
                />
                <textarea
                  name="exp_pro"
                  className="form-control resume_exp_text d-none"
                  id="exp_proj"
                />
                <div className="row mb-3">
                  <div className="col">
                    <button className="btn btn-outline-primary mt-3" type="button" onClick={this.handleForm}>Cancel</button>
                  </div>
                  <div className="col">
                    <button className="btn btn-primary mt-3 float-end" id="add_new_job" type="submit">{(this.state.isEdit) ? 'Add' : 'Add'}</button>
                  </div>
                </div>
              </form>
              <div className={(this.state.showForm) ? "d-none" : ''}>
                {
                  this.state.experienceList.map((experiences, index) => {
                    return <ExperienceCard key={index} handleJobDelete={this.handleJobDelete} handleJobEdit={this.handleJobEdit} Id={experiences.id} JobTitle={experiences.JobTitle} Empoloyer={experiences.Empoloyer} Client={experiences.Client} City={experiences.City} State={experiences.State} StartDate={experiences.StartDate} EndDate={experiences.EndDate} Summary={experiences.Summary} IsCurrent={experiences.IsCurrent} />
                  })
                }
              </div>
              <div id="job_view" className={(this.state.showForm) ? "d-none" : ''}>
                <div className="d-grid">
                  <button type="button" className="add_job_btn btn mt-3" id="add_job_btn" onClick={this.handleForm}> <i className="bi bi-plus"></i> add job</button>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <NavLink to={`/rb-step?step=${step - 1}`}>
                      <button className="btn btn-outline-primary mt-3">
                        Back
                      </button>
                    </NavLink>
                    <NavLink to={`/rb-step?step=${step + 1}`}>
                      <button className="btn btn-outline-primary mt-3 mx-2" onClick={this.handleNext}>
                        Skip
                      </button>
                    </NavLink>
                  </div>

                  <div className="col">
                    <button type="button" className="btn btn-primary mt-3 float-end" onClick={this.handleNext}>
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RightSideResume />
        </div>
      </>
    );
  }
}



export default withRouter(WorkExperience);
