import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";
const ResumeCompetencies = ({ templateType, sections, suffle }) => {
  const { competencies, resumeSkeleton, templateStatus, competencyEnable } = useContext(AuthContext);

  const CompetenciesListCard = ({ competentice }) => {
    const skillRate = competentice.rating * 20 + '%'
    return (
      <div id="" className="my-2">
        <p className="small fw-bold mb-1">{competentice.skill}</p>
        <div className={(competencyEnable) ? "progress" : "d-none"} style={{ height: '8px', backgroundColor: '#0000001a' }}>
          <div className="progress-bar" role="progressbar" style={{ width: skillRate, backgroundColor: '#e9ecef' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    )
  }

  const competenciesCard = () => {
    if (templateType === "ats_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competencies === '') || competencies === null || ((competencies.OtherList && competencies.behavioralList && competencies.techList) ? competencies.OtherList.length <= 0 && competencies.behavioralList.length <= 0 && competencies.techList.length <= 0 : null)) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetencies">
            <h5 className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" style={{ backgroundColor: '#0000001a' }}>Competencies</h5>
            <div className="px-3 py-2">
              {
                competencies && competencies.behavioralList.map((competentice, index) => {
                  return <CompetenciesListCard competentice={competentice} key={index} />
                })
              }

              {
                competencies && competencies.techList.map((competentice, index) => {
                  return <CompetenciesListCard competentice={competentice} key={index} />
                })
              }

              {
                competencies && competencies.OtherList.map((competentice, index) => {
                  return <CompetenciesListCard competentice={competentice} key={index} />
                })
              }
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competencies === '') || competencies === null || ((competencies.OtherList && competencies.behavioralList && competencies.techList) ? competencies.OtherList.length <= 0 && competencies.behavioralList.length <= 0 && competencies.techList.length <= 0 : null)) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetencies">
            <h5 className="text-capitalize fw-bold mb-3 text-break" style={{ color: resumeSkeleton.styles.theme_color }}>Hard Skill</h5>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, width: '100px', padding: '1px' }} className="mt-0" />
            <div className="row">
              <div className="col-12">
                <ul className="" style={{ listStyleType: 'disc' }}>
                  {
                    competencies && competencies.behavioralList.map((competentice, index) => {
                      return <li key={index} className="">{competentice.skill}</li>
                    })
                  }
                </ul>

                <ul className="" style={{ listStyleType: 'disc' }}>
                  {
                    competencies && competencies.techList.map((competentice, index) => {
                      return <li key={index} className="">{competentice.skill}</li>
                    })
                  }
                </ul>

                <ul className="" style={{ listStyleType: 'disc' }}>
                  {
                    competencies && competencies.OtherList.map((competentice, index) => {
                      return <li key={index} className="">{competentice.skill}</li>
                    })
                  }
                </ul>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competencies === '') || competencies === null || ((competencies.OtherList && competencies.behavioralList && competencies.techList) ? competencies.OtherList.length <= 0 && competencies.behavioralList.length <= 0 && competencies.techList.length <= 0 : null)) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetencies">
            <h5 className="fw-bold text-capitalize text-break" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>Competencies</h5>
            <hr className="mt-0" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }} />

            <div className="row">
              <div className="col-3"></div>
              <div className="col-9">
                {
                  competencies && competencies.behavioralList.map((competentice, index) => {
                    return <p key={index} className="mb-1">{competentice.skill}</p>
                  })
                }

                {
                  competencies && competencies.techList.map((competentice, index) => {
                    return <p key={index} className="mb-1">{competentice.skill}</p>
                  })
                }

                {
                  competencies && competencies.OtherList.map((competentice, index) => {
                    return <p key={index} className="mb-1">{competentice.skill}</p>
                  })
                }
                <input type="hidden" name="section_order" value={sections.section_order} />
                <input type="hidden" name="section_name" value={sections.section_name} />
                <input type="hidden" name="section_id" value={sections.section_id} />
                <input type="hidden" name="section_column" value={sections.section_column} />
                <input type="hidden" name="template_type" value={templateType} />
              </div>
            </div>
          </li>
        </>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competencies === '') || competencies === null || ((competencies.OtherList && competencies.behavioralList && competencies.techList) ? competencies.OtherList.length <= 0 && competencies.behavioralList.length <= 0 && competencies.techList.length <= 0 : null)) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetencies">
            <div className="mb-3">
              <h5 className="fw-bold text-capitalize text-break">Competencies</h5>
              <div className="row">
                <div className="col-12">
                  {
                    competencies && competencies.behavioralList.map((competentice, index) => {
                      return <span className="badge me-2 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{competentice.skill}</span>
                    })
                  }

                  {
                    competencies && competencies.techList.map((competentice, index) => {
                      return <span className="badge me-2 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{competentice.skill}</span>
                    })
                  }

                  {
                    competencies && competencies.OtherList.map((competentice, index) => {
                      return <span className="badge me-2 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{competentice.skill}</span>
                    })
                  }
                  <input type="hidden" name="section_order" value={sections.section_order} />
                  <input type="hidden" name="section_name" value={sections.section_name} />
                  <input type="hidden" name="section_id" value={sections.section_id} />
                  <input type="hidden" name="section_column" value={sections.section_column} />
                  <input type="hidden" name="template_type" value={templateType} />
                </div>
              </div>
            </div>
          </li>
        </>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competencies === '') || competencies === null || ((competencies.OtherList && competencies.behavioralList && competencies.techList) ? competencies.OtherList.length <= 0 && competencies.behavioralList.length <= 0 && competencies.techList.length <= 0 : null)) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetencies">
            <div className="ps-2 mb-3">
              <h5 className="text-uppercase d-flex align-items-center fw-bold mb-3 text-break"><i className="bi bi-battery-charging icon_circle_dark" style={(resumeSkeleton.styles.theme_color === '#fff') ? { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#000' } : { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#fff' }}></i>Competencies</h5>
              <div className="row ps-1">
                <div className="col-12">
                  {
                    competencies && competencies.behavioralList.map((competentice, index) => {
                      return <span className="badge bg-secondary me-1 mb-1 text-break text-wrap" key={index}>{competentice.skill}</span>
                    })
                  }
                  {
                    competencies && competencies.techList.map((competentice, index) => {
                      return <span className="badge bg-secondary me-1 mb-1 text-break text-wrap" key={index}>{competentice.skill}</span>
                    })
                  }
                  {
                    competencies && competencies.OtherList.map((competentice, index) => {
                      return <span className="badge bg-secondary me-1 mb-1 text-break text-wrap" key={index}>{competentice.skill}</span>
                    })
                  }
                </div>
                <input type="hidden" name="section_order" value={sections.section_order} />
                <input type="hidden" name="section_name" value={sections.section_name} />
                <input type="hidden" name="section_id" value={sections.section_id} />
                <input type="hidden" name="section_column" value={sections.section_column} />
                <input type="hidden" name="template_type" value={templateType} />
              </div>
            </div>
          </li>
        </>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competencies === '') || competencies === null || ((competencies.OtherList && competencies.behavioralList && competencies.techList) ? competencies.OtherList.length <= 0 && competencies.behavioralList.length <= 0 && competencies.techList.length <= 0 : null)) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetencies">
            <div className="mb-2">
              <h5 className="fw-bold text-uppercase text-break">Competencies</h5>
              <div className="row">
                <div className="col-12">
                  <ul className="px-3">
                    {
                      competencies && competencies.behavioralList.map((competentice, index) => {
                        return <li key={index} className="">{competentice.skill}</li>
                      })
                    }
                  </ul>

                  <ul className="px-3">
                    {
                      competencies && competencies.techList.map((competentice, index) => {
                        return <li key={index} className="">{competentice.skill}</li>
                      })
                    }
                  </ul>

                  <ul className="px-3">
                    {
                      competencies && competencies.OtherList.map((competentice, index) => {
                        return <li key={index} className="">{competentice.skill}</li>
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competencies === '') || competencies === null || ((competencies.OtherList && competencies.behavioralList && competencies.techList) ? competencies.OtherList.length <= 0 && competencies.behavioralList.length <= 0 && competencies.techList.length <= 0 : null)) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetencies">
            <div className="mb-3">
              <h5 className="fw-bol text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>Competencies</h5>
              <div className="row">
                <div className="col-12">
                  <ul style={{ listStyleType: 'disc' }}>
                    {
                      competencies && competencies.behavioralList.map((competentice, index) => {
                        return <li key={index} className="">{competentice.skill}</li>
                      })
                    }
                  </ul>

                  <ul style={{ listStyleType: 'disc' }}>
                    {
                      competencies && competencies.techList.map((competentice, index) => {
                        return <li key={index} className="">{competentice.skill}</li>
                      })
                    }
                  </ul>

                  <ul style={{ listStyleType: 'disc' }}>
                    {
                      competencies && competencies.OtherList.map((competentice, index) => {
                        return <li key={index} className="">{competentice.skill}</li>
                      })
                    }
                  </ul>
                  <input type="hidden" name="section_order" value={sections.section_order} />
                  <input type="hidden" name="section_name" value={sections.section_name} />
                  <input type="hidden" name="section_id" value={sections.section_id} />
                  <input type="hidden" name="section_column" value={sections.section_column} />
                  <input type="hidden" name="template_type" value={templateType} />
                </div>
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
          </li>
        </>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competencies === '') || competencies === null || ((competencies.OtherList && competencies.behavioralList && competencies.techList) ? competencies.OtherList.length <= 0 && competencies.behavioralList.length <= 0 && competencies.techList.length <= 0 : null)) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetencies">
            <div className="mb-3">
              <h5 className="fw-bold text-dark text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>Competencies</h5>
              <div className="row">
                <div className="col-12">
                  <ul style={{ listStyleType: 'disc' }}>
                    {
                      competencies && competencies.behavioralList.map((competentice, index) => {
                        return <li key={index} className="">{competentice.skill}</li>
                      })
                    }
                  </ul>

                  <ul style={{ listStyleType: 'disc' }}>
                    {
                      competencies && competencies.techList.map((competentice, index) => {
                        return <li key={index} className="">{competentice.skill}</li>
                      })
                    }
                  </ul>

                  <ul style={{ listStyleType: 'disc' }}>
                    {
                      competencies && competencies.OtherList.map((competentice, index) => {
                        return <li key={index} className="">{competentice.skill}</li>
                      })
                    }
                  </ul>
                  <input type="hidden" name="section_order" value={sections.section_order} />
                  <input type="hidden" name="section_name" value={sections.section_name} />
                  <input type="hidden" name="section_id" value={sections.section_id} />
                  <input type="hidden" name="section_column" value={sections.section_column} />
                  <input type="hidden" name="template_type" value={templateType} />
                </div>
              </div>
            </div>
          </li>
        </>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competencies === '') || competencies === null || ((competencies.OtherList && competencies.behavioralList && competencies.techList) ? competencies.OtherList.length <= 0 && competencies.behavioralList.length <= 0 && competencies.techList.length <= 0 : null)) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetencies">
            <div className="mb-3">
              <h5 className="fw-bold text-dark text-uppercase text-break ps-2">Competencies</h5>
              <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, padding: '1px' }} className="mt-0" />
              <div className="row ps-3">
                <div className="col-12">
                  {
                    competencies && competencies.behavioralList.map((competentice, index) => {
                      return <span className="badge me-1 mb-1 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{competentice.skill}</span>
                    })
                  }
                  {
                    competencies && competencies.techList.map((competentice, index) => {
                      return <span className="badge me-1 mb-1 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{competentice.skill}</span>
                    })
                  }
                  {
                    competencies && competencies.OtherList.map((competentice, index) => {
                      return <span className="badge me-1 mb-1 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{competentice.skill}</span>
                    })
                  }
                  <input type="hidden" name="section_order" value={sections.section_order} />
                  <input type="hidden" name="section_name" value={sections.section_name} />
                  <input type="hidden" name="section_id" value={sections.section_id} />
                  <input type="hidden" name="section_column" value={sections.section_column} />
                  <input type="hidden" name="template_type" value={templateType} />
                </div>
              </div>
            </div>
          </li>
        </>
      );
    }
  }

  return (<>{competenciesCard()}</>)
}



export default ResumeCompetencies;
