import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import RightSideResume from './RightSideResume';
import { AuthContext } from '../Services/Authcontext';
import NotificationCard from './NotificationCard';
import LeftHideButton from './LeftHideButton';
import axiosInstance from '../Services/axios';
import MobileHeader from './MobileHeader';
import validator from "validator";
import video from '../image/animation/hello.webm'
import { IMAGE_UPLOAD_API_LINK } from '../Services/commonurls';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const PersonalInformation = ({ step, setStep, setProgressBar, setIsLoading, rightHide, rightShow, progressBar, leftHide, leftShow, stepList }) => {
    const { dispatch, personalInformation, userDetails, resumeDataSkeleton, resumeID, stepCompleteness, countryList } = useContext(AuthContext)
    const navigate = useNavigate();
    const [FirstName, setFirstName] = useState((personalInformation.first_name) ? personalInformation.first_name : '');
    const [LastName, setLastName] = useState((personalInformation.last_name) ? personalInformation.last_name : '');
    const [PostalCode, setPostalCode] = useState((personalInformation.postalcode) ? personalInformation.postalcode : '');
    const [Address, setAddress] = useState((personalInformation.address_line_1) ? personalInformation.address_line_1 : '');
    const [Address2, setAddress2] = useState((personalInformation.address_line_2) ? personalInformation.address_line_2 : '');
    const [City, setCity] = useState((personalInformation.city) ? personalInformation.city : '');
    const [Country, setCountry] = useState((personalInformation.country) ? personalInformation.country : '');
    const [State, setState] = useState((personalInformation.state) ? personalInformation.state : '');
    const [Gender, setGender] = useState((personalInformation.gender) ? personalInformation.gender : '');
    const [Dob, setDob] = useState('');
    const [Phone, setPhone] = useState((personalInformation.phone_number) ? personalInformation.phone_number : '');
    const [Email, setEmail] = useState((personalInformation.email) ? personalInformation.email : '');
    const [Image, setImage] = useState((personalInformation.profile_picture) ? personalInformation.profile_picture : '');
    const [IsChecked, setIsChecked] = useState(true);
    const [IsProfilePicShow, setIsProfilePicShow] = useState((personalInformation.show_profile_picture) ? personalInformation.show_profile_picture : false);
    const [Valid, setValid] = useState(false);
    const [IsOverSize, setIsOverSize] = useState(false);
    const [IsNotAccept, setIsNotAccept] = useState(false);
    const [message, setMessage] = useState("");


    const handleImage = (e) => {
        if (e.target.files[0] !== "undefined" || e.target.files[0] !== undefined) {
            const data = {
                'profile_picture': e.target.files[0],
                'resume_id': resumeID,
            }
            const fileSize = e.target.files[0].size / 1024 / 1024; // in MiB
            var filetype = e.target.files.item(0).name.split('.')[1]
            if (!['png', 'jpeg', 'jpg'].includes(filetype)) {
                setIsNotAccept(true)
            } else {
                if (fileSize > 1) {
                    setIsOverSize(true)
                } else {
                    axiosInstance.defaults.headers['Content-Type'] = 'multipart/form-data';
                    axiosInstance.post(IMAGE_UPLOAD_API_LINK, data).then((res) => {
                        setImage(res.data.data.profile_picture_url)
                        document.getElementById('fileImage').classList.add('w-100')
                        document.getElementById('profileImage').setAttribute('src', res.data.data.profile_picture_url);

                        dispatch({
                            type: 'ADD_PROFILE_PICTURE',
                            payload: { 'picture': res.data.data.profile_picture_url }
                        })
                    })
                    axiosInstance.defaults.headers['Content-Type'] = 'application/json';
                    setIsNotAccept(false)
                    setIsOverSize(false)
                }
            }
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch({
            type: 'DATA_UPDATED',
            payload: true
        })
        if (
            FirstName !== '' &&
            // LastName !== '' &&
            PostalCode !== '' &&
            Address !== '' &&
            City !== '' &&
            Country !== '' &&
            Gender !== '' &&
            Dob !== '' &&
            Phone !== '' &&
            Email !== '' &&
            IsChecked !== 'false' && IsChecked !== '') {

            let data = {
                is_terms_verified: IsChecked,
                address_line_1: Address,
                address_line_2: Address2,
                city: City,
                country: Country,
                dob: Dob,
                email: Email,
                first_name: FirstName,
                gender: Gender,
                last_name: LastName,
                phone_number: Phone,
                postalcode: PostalCode,
                state: State,
                profile_picture: Image,
                show_profile_picture: IsProfilePicShow
            }
            dispatch({
                type: "ADD_PERSONAL_INFORMATION",
                payload: data
            })
            for (var key in stepCompleteness) {
                if (stepCompleteness[key]['resume_step_id'] === Number(step)) {
                    stepCompleteness[key]['step_status'] = true;
                }
            }
            navigate(`/rb-step?step=${Number(step) + 1}`)
            setStep(Number(step) + 1)
            setValid(false)
        } else {
            window.scrollTo(0, 0);
            setValid(!Valid)
        }
    }
    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    const handleRedirect = (page) => {
        switch (page) {
            default:
                window.open((process.env.NODE_ENV === 'development') ? process.env.REACT_APP_WEBSITE_URL + `${page}` : process.env.REACT_APP_PROD_WEBSITE_URL + `${page}`, '_blank');
                break;
        }
    }



    useEffect(() => {
        document.getElementsByClassName('image-upload')[0].style.cursor = "pointer";
        document.getElementsByClassName('image-upload')[0].addEventListener('click', () => {
            document.getElementById('file-input').click();
        })
        setDob(formatDate((resumeDataSkeleton && resumeDataSkeleton.personal_info && resumeDataSkeleton.personal_info.dob !== '') ? resumeDataSkeleton.personal_info.dob : (personalInformation.dob) ? personalInformation.dob : ''))
    }, [])

    return (

        <>
            <MobileHeader setIsLoading={setIsLoading} Title={"Personal Information"} ProgressBar={progressBar} StepList={stepList} Step={Number(step)} />
            <div className="row ms-lg-0">
                <div className="col-md-12 col-lg-5 bg-white p-lg-4 position-relative" id="id_left">
                    <LeftHideButton rightHide={rightHide} rightShow={rightShow} />
                    <div className="" id="id_left_side">
                        <NotificationCard
                            short_notes={
                                <>
                                    <p className='mb-1 small'>It’s important to let your employers know your email, phone and location details.</p>
                                    <p className='mb-1 small'>If you want to change your personal details, you can do so via My Account &gt;&gt; My Profile Section.</p>
                                </>
                            }
                            Notes={<>
                                <p className='mb-1 small'>It’s important to let your employers know your email, phone and location details.</p>
                                <p className='mb-1 small'>If you want to change your personal details, you can do so via My Account &gt;&gt; My Profile Section.</p>
                                <p className='mb-1 small'>It’s recommended that you keep the resume to one page or two at the max</p>
                            </>}
                            video={video}
                        />

                        <form onSubmit={handleSubmit} method="post" className="mt-4">
                            {(Valid) ? <p className='text-danger'>Please fill all the fields.</p> : ''}
                            <div className="pb-3">
                                <div className="image-upload d-flex">
                                    <div className="imageupload d-inline-block">
                                        <label htmlFor="file-input" className='w-100'>
                                            <img src={(Image) ? Image : '/image/profile_upload.png'} id="fileImage" className={(Image) ? 'w-100' : ''} alt='fileImage' />
                                        </label>
                                    </div>
                                    <input id="file-input" type="file" accept=".png, .jpg, .jpeg" onChange={(e) => { handleImage(e) }} />
                                    <div className='d-flex flex-column'>
                                        <span className='ms-2 small'>jpg or png file with max size 2MB</span>
                                        <span className='ms-2 text-primary'>Upload Profile Photo</span>
                                    </div>
                                </div>

                                <p className='mt-2 text-danger'>{(IsOverSize) ? "File size exceeds 1 mb" : ""}</p>
                                <p className='mt-2 text-danger'>{(IsNotAccept) ? "This file is not accepted" : ""}</p>
                            </div>
                            <div className='mb-3'>
                                <input className="form-check-input" type="checkbox" checked={IsProfilePicShow} onChange={(e) => { setIsProfilePicShow(e.target.checked); }} id="IsProfilePicShow" />
                                <label className="form-check-label small ms-2">Show Profile Picture</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" disabled name="resume_first_name" value={FirstName} onChange={(e) => {
                                    setFirstName(e.target.value);
                                    document.getElementById('firstName').innerHTML = e.target.value;
                                }} id="resume_first_name" className="form-control"
                                    placeholder="First name" />
                                <label htmlFor="first_name">First name</label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="text" disabled name="resume_last_name" id="resume_last_name" value={LastName} onChange={(e) => {
                                    setLastName(e.target.value)
                                    document.getElementById('lastName').innerHTML = e.target.value;
                                }} className="form-control"
                                    placeholder="Last Name" />
                                <label htmlFor="last_name">Last Name</label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="text" disabled name="resume_email" id="resume_email" value={Email} onChange={(e) => {
                                    setEmail(e.target.value)
                                    document.getElementById('email').innerHTML = e.target.value;
                                    if (!validator.isEmail(e.target.value)) {
                                        document.getElementById('mailErrorMsg').classList.remove('d-none')
                                        setMessage("Please, enter valid Email!");
                                    } else {
                                        document.getElementById('mailErrorMsg').classList.add('d-none')
                                        setMessage('');
                                    }
                                }} className="form-control" placeholder="Email"
                                />
                                <label htmlFor="email">Email</label>
                                <span className='text-danger ms-1 small d-none' id="mailErrorMsg">{message}</span>
                            </div>

                            <div className="form-floating mb-3">
                                <PhoneInput
                                    placeholder="Phone"
                                    className='form-control'
                                    international
                                    defaultCountry="IN"
                                    countryCallingCodeEditable={false}
                                    name="resume_phone" maxLength={16} id="resume_phone" value={Phone}
                                    onChange={(event) => {
                                        setPhone(event);
                                        document.getElementById('phone').innerHTML = event;
                                    }}
                                    onKeyPress={(evt) => {
                                        var theEvent = evt || window.event;
                                        var key;
                                        if (theEvent.type === 'paste') {
                                            key = window.event.clipboardData.getData('text/plain');
                                        } else {
                                            key = theEvent.keyCode || theEvent.which;
                                            key = String.fromCharCode(key);
                                        }
                                        var regex = /[0-9]|\./;
                                        if (!regex.test(key)) {
                                            theEvent.returnValue = false;
                                            if (theEvent.preventDefault) theEvent.preventDefault();
                                        }
                                    }}
                                    disabled={(userDetails && userDetails.user_kyc_profile.is_profile_completed === true) ? true : false}
                                />
                                {/* <input type="text" disabled={(userDetails && userDetails.user_kyc_profile.is_profile_completed === true) ? true : false} name="resume_phone" maxLength={10} id="resume_phone" value={Phone}
                                    onChange={(e) => {
                                        setPhone(e.target.value);
                                        document.getElementById('phone').innerHTML = e.target.value;
                                    }}
                                    onKeyPress={(evt) => {
                                        var theEvent = evt || window.event;
                                        var key;
                                        if (theEvent.type === 'paste') {
                                            key = window.event.clipboardData.getData('text/plain');
                                        } else {
                                            key = theEvent.keyCode || theEvent.which;
                                            key = String.fromCharCode(key);
                                        }
                                        var regex = /[0-9]|\./;
                                        if (!regex.test(key)) {
                                            theEvent.returnValue = false;
                                            if (theEvent.preventDefault) theEvent.preventDefault();
                                        }
                                    }}
                                    className="form-control" placeholder="Phone" /> */}
                                <label htmlFor="phone">Phone</label>
                            </div>
                            <div className="row mb-3">
                                <div className="col pe-2">
                                    <div className="form-floating">
                                        <input type="date" disabled={(userDetails && userDetails.user_kyc_profile.is_profile_completed === true) ? true : false} name="resume_dob" id="resume_dob" value={Dob} onChange={(e) => {
                                            setDob(e.target.value);
                                            document.getElementById('dob').innerHTML = e.target.value;

                                        }} className="form-control" placeholder="DOB" />
                                        <label htmlFor="dob">DOB</label>
                                    </div>
                                </div>
                                <div className="col ps-2">
                                    <div className="form-floating">
                                        <select className="form-select" disabled={(userDetails && userDetails.user_kyc_profile.is_profile_completed === true) ? true : false} name="resume_gender" value={Gender} onChange={(e) => {
                                            setGender(e.target.value);
                                            document.getElementById('gender').innerHTML = e.target.value;
                                        }} id="resume_gender">
                                            <option defaultValue>Select Gender</option>
                                            <option value="MALE">Male</option>
                                            <option value="FEMALE">Female</option>
                                            <option value="OTHERS">Others</option>
                                        </select>
                                        <label htmlFor="type">Gender</label>
                                    </div>
                                </div>
                            </div>


                            <div className="form-floating mb-3">
                                <input type="text" disabled={(userDetails && userDetails.user_kyc_profile.is_profile_completed === true) ? true : false} name="resume_address" value={Address} onChange={(e) => {
                                    setAddress(e.target.value);
                                    document.getElementById('address').innerHTML = e.target.value;
                                }} id="resume_address" className="form-control" placeholder="Address Line 1" />
                                <label htmlFor="phone">Address Line 1</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" disabled={(userDetails && userDetails.user_kyc_profile.is_profile_completed === true) ? true : false} name="resume_address2" value={Address2} onChange={(e) => {
                                    setAddress2(e.target.value);
                                    document.getElementById('address2').innerHTML = e.target.value;
                                }} id="resume_address_2" className="form-control" placeholder="Address Line 2" />
                                <label htmlFor="phone">Address Line 2</label>
                            </div>
                            <div className="row mb-3">
                                <div className="col pe-2">
                                    <div className="form-floating">
                                        <input type="text" disabled={(userDetails && userDetails.user_kyc_profile.is_profile_completed === true) ? true : false} name="resume_city" value={City} onChange={(e) => {
                                            setCity(e.target.value);
                                            document.getElementById('city').innerHTML = e.target.value;
                                        }} id="resume_city" className="form-control" placeholder="City" />
                                        <label htmlFor="City">City</label>
                                    </div>
                                </div>
                                <div className="col ps-2">
                                    <div className="form-floating">
                                        <input type="text" disabled={(userDetails && userDetails.user_kyc_profile.is_profile_completed === true) ? true : false} name="resume_state" value={State} onChange={(e) => {
                                            setState(e.target.value);
                                            document.getElementById('state').innerHTML = e.target.value;
                                        }} id="resume_state" className="form-control" placeholder="State" />
                                        <label htmlFor="Postal Code">State</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col pe-2">
                                    <div className="form-floating">
                                        {/* <input type="text" disabled={(userDetails && userDetails.user_kyc_profile.is_profile_completed === true) ? true : false} name="resume_country" value={Country} onChange={(e) => {
                                            setCountry(e.target.value);
                                            document.getElementById('country').innerHTML = e.target.value;
                                        }} id="resume_country" className="form-control" placeholder="Country" /> */}
                                        <select name="resume_country" id="resume_country" disabled={(userDetails && userDetails.user_kyc_profile.is_profile_completed === true) ? true : false} value={Country} onChange={(e) => {
                                            setCountry(e.target.value);
                                        }} className='form-select'>
                                            {countryList && countryList.map((country, i) => {
                                                return <option key={i}
                                                    value={country.toLowerCase().charAt(0).toUpperCase() + country.toLowerCase().slice(1)}
                                                    defaultValue={(country.toLowerCase().charAt(0).toUpperCase() + country.toLowerCase().slice(1) === Country) ? true : false}
                                                >{country.toLowerCase().charAt(0).toUpperCase() + country.toLowerCase().slice(1)}
                                                </option>
                                            })}
                                        </select>
                                        <label htmlFor="Country">Country</label>
                                    </div>
                                </div>
                                <div className="col ps-2">
                                    <div className="form-floating">
                                        <input type="text" disabled={(userDetails && userDetails.user_kyc_profile.is_profile_completed === true) ? true : false} name="resume_postal" value={PostalCode} onChange={(e) => {
                                            setPostalCode(e.target.value);
                                            document.getElementById('postal').innerHTML = e.target.value;
                                        }} id="resume_postal" className="form-control" placeholder="Postal Code" />
                                        <label htmlFor="Postal Code">Postal Code</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" disabled type="checkbox" checked={IsChecked} onChange={(e) => { setIsChecked(e.target.checked) }} id="checkDefault" />
                                <label className="form-check-label small">
                                    By continuing, you agree to the <Link to="#" onClick={() => { handleRedirect('termsofuse') }} className="text-primary text-decoration-none fw-bold">Terms & Conditions</Link> and
                                    <Link to="#" onClick={() => { handleRedirect('privacy') }} className="text-primary text-decoration-none d-block fw-bold">Privacy Policy.</Link>
                                </label>
                            </div>
                            <div className="row mb-3 mx-0">
                                <div className="col p-0">
                                    <NavLink to={`/myresume`}>
                                        <button className="btn btn-outline-primary mt-3" onClick={() => { setProgressBar(0) }}>Back</button>
                                    </NavLink>
                                </div>

                                <div className="col p-0">
                                    <button type='submit' className="btn btn-primary mt-3 float-end" disabled={false}>Next</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <RightSideResume />
            </div>
        </>

    );

}


export default PersonalInformation;