import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";
const ResumeCoactivities = ({ templateType, sections, suffle, format }) => {
  const { cocurricular, resumeSkeleton, templateStatus, cocurricularTitle } = useContext(AuthContext);
  const CoactivitiesListCard = ({ cocurricularData }) => {
    return (
      <div className="mb-2">
        <p className="mb-0 fw-bold small">{cocurricularData.title}</p>
        <p className="mb-0 small">{cocurricularData.section}</p>
        <p className="mb-0 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(cocurricularData.startDate) ? format(cocurricularData.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(cocurricularData.endDate) ? ' - ' + format(cocurricularData.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
        <div className="small">{cocurricularData.summary}</div>
      </div>
    )
  }
  const coactivitiesCard = () => {
    if (templateType === "ats_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && cocurricular === '') || cocurricular === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCoactivities">
            <h5 id="cocurricular_title" className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" style={{ backgroundColor: '#0000001a' }}>{cocurricularTitle}</h5>
            <div className="px-3 py-2">
              {
                cocurricular && cocurricular.map((cocurriculars, index) => {
                  return (
                    <div className="row mb-2" key={index}>
                      <div className="col-3">
                        <p className="mb-0 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(cocurriculars.startDate) ? format(cocurriculars.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(cocurriculars.endDate) ? ' - ' + format(cocurriculars.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                      </div>
                      <div className="col-9">
                        <p className="mb-0 fw-bold small">{cocurriculars.title}</p>
                        <p className="mb-0 small">{cocurriculars.section}</p>
                        <div className="small">{cocurriculars.summary}</div>
                      </div>
                    </div>
                  )
                })
              }
            </div>

            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && cocurricular === '') || cocurricular === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCoactivities">
            <h5 id="cocurricular_title" className="text-capitalize fw-bold mb-3 text-break exec_classic_border" style={{ color: resumeSkeleton.styles.theme_color }}>{cocurricularTitle}</h5>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, width: '100px', padding: '1px' }} className="mt-0" />
            {
              cocurricular && cocurricular.map((cocurriculars, index) => {
                return (
                  <div className="row" key={index}>
                    {/* <div className="col-3">
                    </div> */}
                    <div className="col-12">
                      <h6 className="fw-bold">{cocurriculars.title}</h6>
                      <p className="mb-0 small">{cocurriculars.section}</p>
                      <div className="small">{cocurriculars.summary}</div>
                      <p className="mb-0 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(cocurriculars.startDate) ? format(cocurriculars.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}  {(cocurriculars.endDate) ? ' - ' + format(cocurriculars.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                    </div>
                  </div>
                )
              })
            }
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && cocurricular === '') || cocurricular === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCoactivities">
            <h5 id="cocurricular_title" className="fw-bold text-capitalize text-break" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{cocurricularTitle}</h5>
            <hr className="mt-0" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }} />
            <div className="row">
              <div className="col-3"></div>
              <div className="col-9">
                {
                  cocurricular && cocurricular.map((cocurriculars, index) => {
                    return <CoactivitiesListCard key={index} cocurricularData={cocurriculars} />
                  })
                }
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && cocurricular === '') || cocurricular === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCoactivities">
            <div className="ps-2 mb-3">
              <h5 id="cocurricular_title" className="text-uppercase d-flex align-items-center fw-bold mb-3 text-break"><i className="bi bi-activity icon_circle_dark" style={(resumeSkeleton.styles.theme_color === '#fff') ? { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#000' } : { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#fff' }}></i>{cocurricularTitle}</h5>
              <div className="row ps-1">
                <div className="col-12">
                  {
                    cocurricular && cocurricular.map((cocurriculars, index) => {
                      return <CoactivitiesListCard key={index} cocurricularData={cocurriculars} />
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && cocurricular === '') || cocurricular === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCoactivities">
            <div className="mb-2">
              <h5 id="cocurricular_title" className="fw-bold text-uppercase text-break">{cocurricularTitle}</h5>
              <div className="row">
                <div className="col-12">
                  {
                    cocurricular && cocurricular.map((cocurriculars, index) => {
                      return (
                        <div className="mb-2" key={index}>
                          <p className="mb-0 fw-bold small">{cocurriculars.title}</p>
                          <p className="mb-0 small">{cocurriculars.section}</p>
                          <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(cocurriculars.startDate) ? format(cocurriculars.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(cocurriculars.endDate) ? ' - ' + format(cocurriculars.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                          <div className="small">{cocurriculars.summary}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && cocurricular === '') || cocurricular === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCoactivities">
            <div className="mb-3">
              <h5 id="cocurricular_title" className="fw-bold text-capitalize text-break">{cocurricularTitle}</h5>
              <div className="row">
                <div className="col-12">
                  {
                    cocurricular && cocurricular.map((cocurriculars, index) => {
                      return (
                        <div className="mb-2" key={index}>
                          <p className="mb-0 fw-bold small">{cocurriculars.title}</p>
                          <p className="mb-0 small">{cocurriculars.section}</p>
                          <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(cocurriculars.startDate) ? format(cocurriculars.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(cocurriculars.endDate) ? ' - ' + format(cocurriculars.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                          <div className="small">{cocurriculars.summary}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && cocurricular === '') || cocurricular === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCoactivities">
            <div className="mb-1">
              <h5 id="cocurricular_title" className="fw-bol text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>{cocurricularTitle}</h5>
              <div className="row">
                <div className="col-12">
                  {
                    cocurricular && cocurricular.map((cocurriculars, index) => {
                      return <CoactivitiesListCard key={index} cocurricularData={cocurriculars} />
                    })
                  }
                </div>
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && cocurricular === '') || cocurricular === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCoactivities">
            <div className="mb-1">
              <h5 id="cocurricular_title" className="fw-bold text-dark text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>{cocurricularTitle}</h5>
              <div className="row">
                <div className="col-12">
                  {
                    cocurricular && cocurricular.map((cocurriculars, index) => {
                      return (
                        <div className="mb-2" key={index}>
                          <p className="mb-0 fw-bold small">{cocurriculars.title}</p>
                          <p className="mb-0 small">{cocurriculars.section}</p>
                          <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(cocurriculars.startDate) ? format(cocurriculars.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(cocurriculars.endDate) ? ' - ' + format(cocurriculars.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                          <div className="small">{cocurriculars.summary}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && cocurricular === '') || cocurricular === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCoactivities">
            <div className="mb-1">
              <h5 id="cocurricular_title" className="fw-bold text-dark text-uppercase text-break ps-2">{cocurricularTitle}</h5>
              <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, padding: '1px' }} className="mt-0" />
              <div className="row ps-3">
                <div className="col-12">
                  {
                    cocurricular && cocurricular.map((cocurriculars, index) => {
                      return (
                        <div className="mb-2" key={index}>
                          <p className="mb-0 fw-bold small">{cocurriculars.title}</p>
                          <p className="mb-0 small">{cocurriculars.section}</p>
                          <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(cocurriculars.startDate) ? format(cocurriculars.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(cocurriculars.endDate) ? ' - ' + format(cocurriculars.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                          <div className="small">{cocurriculars.summary}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
  }
  return coactivitiesCard();
}

export default ResumeCoactivities;