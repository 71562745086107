import React, { createContext, useReducer } from "react"

const AppReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_FRESH_TOKEN':
            return { ...state, refreshToken: action.payload };
        case 'ADD_ACCESS_TOKEN':
            return { ...state, isAuth: true, accessToken: localStorage.getItem('accessToken') };
        case 'ADD_TEMPLATE_LIST':
            return { ...state, templateList: action.payload };
        case 'ADD_RESUME_TEMPLATE':
            return { ...state, resumeTemplate: action.payload };
        case 'ADD_BASIC_INFO':
            return { ...state, basicInfo: action.payload };
        case 'ADD_RESUME_TITLE':
            return { ...state, resumeTitle: action.payload };
        case 'ADD_JOB_SUMMARY':
            return { ...state, jobSummary: action.payload };
        case 'ADD_PERSONAL_INFORMATION':
            return { ...state, personalInformation: action.payload };
        case 'ADD_WORK_EXPERIENCE':
            return { ...state, workExperience: action.payload };
        case 'ADD_EDUCATION':
            return { ...state, education: action.payload };
        case 'ADD_CERTIFICATE':
            return { ...state, certificate: action.payload };
        case 'ADD_SKILLS':
            return { ...state, skills: action.payload };
        case 'ADD_COMPETENCIES':
            return { ...state, competencies: action.payload };
        case 'ADD_REFERENCES':
            return { ...state, references: action.payload };
        case 'ADD_LANGUAGE':
            return { ...state, languages: action.payload };
        case 'ADD_OTHERS':
            return { ...state, others: action.payload };
        case 'ADD_RESUME_DATA_SKELETON':
            return { ...state, resumeDataSkeleton: action.payload };
        case 'ADD_RESUME_SKELETON':
            return { ...state, resumeSkeleton: action.payload };
        case 'ADD_RESUME_DATA':
            return { ...state, resumeData: action.payload };
        case 'ADD_RESUME_SKELETON_SECTION':
            return { ...state, section: action.payload };
        case 'ADD_RESUME_SKELETON_SECTION_LEFT':
            return { ...state, section: { ...state.section, left_sec: action.payload } };
        case 'ADD_RESUME_SKELETON_SECTION_RIGHT':
            return { ...state, section: { ...state.section, right_sec: action.payload } };
        case 'ADD_USER_DETAILS':
            return { ...state, userDetails: action.payload };
        case 'ADD_RESUMELIST':
            return { ...state, resumeList: action.payload };
        case 'ADD_RESUMEID':
            return { ...state, resumeID: action.payload };
        case 'ADD_STEP_COMPLETENESS':
            return { ...state, stepCompleteness: action.payload };
        case 'ADD_PROFILE_PICTURE':
            return { ...state, profile_picture: action.payload };
        case 'UPDATE_THEME_COLOR':
            return { ...state, resumeSkeleton: { ...state.resumeSkeleton, styles: { ...state.resumeSkeleton.styles, theme_color: action.payload } } };
        case 'UPDATE_THEME_RBGA_COLOR':
            return { ...state, resumeSkeleton: { ...state.resumeSkeleton, styles: { ...state.resumeSkeleton.styles, rbga_color: action.payload } } };
        case 'UPDATE_THEME_DATE_FORMAT':
            return { ...state, resumeSkeleton: { ...state.resumeSkeleton, styles: { ...state.resumeSkeleton.styles, theme_date_format: action.payload } } };
        case 'ADD_VOLUNTEER':
            return { ...state, volunteer: action.payload };
        case 'ADD_HONOURS':
            return { ...state, honours: action.payload };
        case 'ADD_ACHIEVEMENT':
            return { ...state, achievements: action.payload };
        case 'ADD_COCURRICULAR':
            return { ...state, cocurricular: action.payload };
        case 'ADD_TRAINING':
            return { ...state, training: action.payload };
        case 'ADD_COMPETITION':
            return { ...state, competitions: action.payload };
        case 'ADD_PERSONALPROJECT':
            return { ...state, personalProject: action.payload };
        case 'ADD_HOBBIE':
            return { ...state, hobbies: action.payload };
        case 'ADD_LINKS':
            return { ...state, links: action.payload };
        case 'ADD_TEMPLATE_STATUS':
            return { ...state, templateStatus: action.payload };
        case 'DATA_UPDATED':
            return { ...state, isUpdated: action.payload };
        case 'SKILL_ENABLED':
            return { ...state, skillEnable: action.payload };
        case 'COMPETENCIES_ENABLED':
            return { ...state, competencyEnable: action.payload };
        case 'FLIPED_UPDATED':
            return { ...state, resumeSkeleton: { ...state.resumeSkeleton, styles: { ...state.resumeSkeleton.styles, layout_flip_enabled: action.payload } } };
        case 'TRAINING_HEADER':
            return { ...state, trainingTitle: action.payload };
        case 'HONOURS_HEADER':
            return { ...state, honoursTitle: action.payload };
        case 'VOLUNTEER_HEADER':
            return { ...state, volunteerTitle: action.payload };
        case 'ACHIEVEMENT_HEADER':
            return { ...state, achievementsTitle: action.payload };
        case 'HOBBIE_HEADER':
            return { ...state, hobbiesTitle: action.payload };
        case 'PERSONALPROJECT_HEADER':
            return { ...state, personalProjectTitle: action.payload };
        case 'COCURRICULAR_HEADER':
            return { ...state, cocurricularTitle: action.payload };
        case 'COMPETITION_HEADER':
            return { ...state, competitionsTitle: action.payload };
        case 'CITYLIST':
            return { ...state, cityList: action.payload };
        case 'COUNTRYLIST':
            return { ...state, countryList: action.payload };
        case 'RESUMECOUNT':
            return { ...state, resumeCount: action.payload };
        default:
            return state;
    }
}

const initialState = {
    templateList: null,
    resumeTemplate: null,
    selectedResumeTemplate: null,
    isAuth: false,
    isUpdated: false,
    refreshToken: null,
    accessToken: null,
    basicInfo: '',
    links: '',
    jobSummary: '',
    personalInformation: '',
    education: '',
    workExperience: '',
    certificate: '',
    skills: '',
    competencies: '',
    references: '',
    languages: '',
    volunteer: '',
    honours: '',
    achievements: '',
    cocurricular: '',
    training: '',
    competitions: '',
    personalProject: '',
    hobbies: '',
    others: '',
    resumeDataSkeleton: null,
    resumeSkeleton: null,
    resumeData: '',
    userDetails: null,
    resumeList: null,
    resumeID: '',
    section: null,
    stepCompleteness: null,
    profile_picture: '',
    templateStatus: '',
    resumeTitle: '',
    skillEnable: false,
    competencyEnable: false,
    honoursTitle: '',
    volunteerTitle: '',
    achievementsTitle: '',
    hobbiesTitle: '',
    personalProjectTitle: '',
    cocurricularTitle: '',
    competitionsTitle: '',
    trainingTitle: '',
    linksTitle: '',
    cityList: '',
    countryList: '',
    resumeCount:0,
}

export const AuthContext = createContext();

export const AuthProvider = (props) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);
    return (
        <AuthContext.Provider
            value={{
                resumeTemplate: state.resumeTemplate,
                templateList: state.templateList,
                isAuth: state.isAuth,
                isUpdated: state.isUpdated,
                refreshToken: state.refreshToken,
                accessToken: state.accessToken,
                selectedResumeTemplate: state.selectedResumeTemplate,
                resumeData: state.resumeData,
                basicInfo: state.basicInfo,
                jobSummary: state.jobSummary,
                personalInformation: state.personalInformation,
                education: state.education,
                workExperience: state.workExperience,
                certificate: state.certificate,
                skills: state.skills,
                competencies: state.competencies,
                references: state.references,
                languages: state.languages,
                others: state.others,
                resumeDataSkeleton: state.resumeDataSkeleton,
                resumeSkeleton: state.resumeSkeleton,
                userDetails: state.userDetails,
                resumeList: state.resumeList,
                resumeID: state.resumeID,
                section: state.section,
                stepCompleteness: state.stepCompleteness,
                profile_picture: state.profile_picture,
                volunteer: state.volunteer,
                honours: state.honours,
                achievements: state.achievements,
                cocurricular: state.cocurricular,
                training: state.training,
                competitions: state.competitions,
                personalProject: state.personalProject,
                hobbies: state.hobbies,
                templateStatus: state.templateStatus,
                resumeTitle: state.resumeTitle,
                links: state.links,
                skillEnable: state.skillEnable,
                competencyEnable: state.competencyEnable,
                honoursTitle: state.honoursTitle,
                volunteerTitle: state.volunteerTitle,
                achievementsTitle: state.achievementsTitle,
                hobbiesTitle: state.hobbiesTitle,
                personalProjectTitle: state.personalProjectTitle,
                cocurricularTitle: state.cocurricularTitle,
                competitionsTitle: state.competitionsTitle,
                trainingTitle: state.trainingTitle,
                linksTitle: state.linksTitle,
                cityList: state.cityList,
                countryList: state.countryList,
                CURRENT_RESUMES_COUNT : state.resumeCount,
                dispatch,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}