import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";

const ResumeLinks = ({ templateType, sections, suffle }) => {
  const { resumeSkeleton, links, templateStatus } = useContext(AuthContext);

  const LinksListCard = ({ linksData }) => {
    return (
      <div className="mb-2">
        <p className="mb-0 fw-bold small">{linksData.linkName}</p>
        <p className="mb-0 small">{linksData.link}</p>
      </div>
    )
  }

  const resumeLinksCard = () => {
    if (templateType === "ats_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && links === '') || links === null || links.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeLinks">
            <h5 className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" style={{ backgroundColor: '#0000001a' }}>Links</h5>
            <div className="px-3 py-2">
              {
                links && links.map((link, index) => {
                  return <LinksListCard key={index} linksData={link} />
                })
              }
            </div>

            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && links === '') || links === null || links.length <= 0) ? "d-none" : "resume_section list-group my-3"} draggable="true" id="ResumeLinks">
            <h5 className="text-capitalize fw-bold mb-3 text-break exec_classic_border" style={{ color: resumeSkeleton.styles.theme_color }}>Links</h5>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, width: '100px', padding: '1px' }} className="mt-0" />
            {
              links && links.map((link, index) => {
                return (
                  <div className="row" key={index}>
                    <div className="col-3">
                      <h6 className="small fw-bold">{link.linkName}</h6>
                    </div>
                    <div className="col-9">
                      <p className="mb-0 small">{link.link}</p>
                    </div>
                  </div>
                )
              })
            }
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && links === '') || links === null || links.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeLinks">
            <h5 className="fw-bold text-capitalize text-break" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>Links</h5>
            <hr className="mt-0" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }} />
            <div className="row">
              <div className="col-3"></div>
              <div className="col-9">
                {
                  links && links.map((link, index) => {
                    return <LinksListCard key={index} linksData={link} />
                  })
                }
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && links === '') || links === null || links.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeLinks">
            <h5 className="fw-bold text-capitalize text-break">Links</h5>
            <div className="mb-3">
              {
                links && links.map((link, index) => {
                  return (
                    <div className="mb-2 d-flex" key={index}>
                      <p className="fw-bold mb-1 small me-1">{link.linkName}</p>
                      <p className="mb-0 small"> - {link.link}</p>
                    </div>
                  )
                })
              }
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && links === '') || links === null || links.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeLinks">
            <div className="mb-2">
              <h5 className="fw-bold text-uppercase text-break">Links</h5>
              <div className="row">
                <div className="col-12">
                  {
                    links && links.map((link, index) => {
                      return <LinksListCard key={index} linksData={link} />
                    })
                  }
                </div>
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }

    else if (templateType === "exec_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && links === '') || links === null || links.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeLinks">
            <div className="ps-2 mb-3">
              <h5 className="text-uppercase d-flex align-items-center fw-bold mb-3 text-break"><i className="bi bi-link icon_circle_dark" style={(resumeSkeleton.styles.theme_color === '#fff') ? { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#000' } : { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#fff' }}></i>Links</h5>
              <div className="row ps-1">
                <div className="col-12">
                  {
                    links && links.map((link, index) => {
                      return <LinksListCard key={index} linksData={link} />
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && links === '') || links === null || links.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeLinks">
            <h5 className="fw-bol text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>Links</h5>
            <div className="mb-3">
              {
                links && links.map((link, index) => {
                  return (
                    <div className="mb-2" key={index}>
                      <p className="fw-bold mb-1 small">{link.linkName}</p>
                      <p className="mb-0 small">{link.link}</p>
                    </div>
                  )
                })
              }
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && links === '') || links === null || links.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeLinks">
            <h5 className="fw-bold text-dark text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>Links</h5>
            <div className="mb-3">
              {
                links && links.map((link, index) => {
                  return (
                    <div className="mb-2" key={index}>
                      <p className="fw-bold mb-0 small">{link.linkName}</p>
                      <p className="mb-0 small">{link.link}</p>
                    </div>
                  )
                })
              }
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && links === '') || links === null || links.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeLinks">
            <h5 className="fw-bold text-dark text-uppercase text-break ps-2">Links</h5>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, padding: '1px' }} className="mt-0" />
            <div className="mb-3">
              {
                links && links.map((link, index) => {
                  return (
                    <div className="mb-2 ps-3" key={index}>
                      <p className="fw-bold mb-1 small">{link.linkName}</p>
                      <p className="mb-0 small">{link.link}</p>
                    </div>
                  )
                })
              }
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
  }

  return (
    <>{resumeLinksCard()}</>
  );
}

export default ResumeLinks;
