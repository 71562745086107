import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";
import { Markup } from 'interweave';
const ResumeExperience = ({ templateType, sections, suffle }) => {
  const { workExperience, resumeSkeleton, templateStatus } = useContext(AuthContext);
  const ExpericenceCard = ({ experiences, key }) => {
    return (
      <div className="row mb-2" key={key}>
        <div className="col-3 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(experiences.StartDate) ? new Date(experiences.StartDate).toString().split(' ')[1] + " " + new Date(experiences.StartDate).toString().split(' ')[3] : ''}  {(experiences.IsCurrent) ? '- Current' : (experiences.EndDate) ? ' - ' + new Date(experiences.EndDate).toString().split(' ')[1] + " " + new Date(experiences.EndDate).toString().split(' ')[3] : ''}</i></div>
        <div className="col-9">
          <p className="fw-bold mb-0">{experiences.JobTitle}</p>
          <i className="small">{experiences.Empoloyer}{(experiences.City) ? ', ' + experiences.City : ''}{(experiences.State) ? ', ' + experiences.State + '.' : ''}</i>
          <p className="small">{experiences.Client}</p>
          <Markup className="small" content={experiences.Summary} />
        </div>
      </div>
    )
  }
  const expericenceCardList = () => {
    if (templateType === "ats_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && workExperience === '') || workExperience === null || workExperience.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeExperience">
            <h5 className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" style={{ backgroundColor: '#0000001a' }}>Work Experience</h5>
            <div className="px-3 py-2">
              {workExperience && workExperience.map((experiences, index) => {
                return <ExpericenceCard key={index} experiences={experiences} />
              })}
              <input type="hidden" name="section_order" value={sections.section_order} />
              <input type="hidden" name="section_name" value={sections.section_name} />
              <input type="hidden" name="section_id" value={sections.section_id} />
              <input type="hidden" name="section_column" value={sections.section_column} />
              <input type="hidden" name="template_type" value={templateType} />
            </div>
          </li>
        </>
      );
    }
    else if (templateType === "exec_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && workExperience === '') || workExperience === null || workExperience.length <= 0) ? "d-none" : "resume_section list-group my-3"} draggable="true" id="ResumeExperience">
            <h5 className="text-capitalize fw-bold mb-3 text-break exec_classic_border" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>Work Experience</h5>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, width: '100px', padding: '1px' }} className="mt-0" />
            {workExperience && workExperience.map((experiences, index) => {
              return (
                <div className="row mb-2 ps-1" key={index}>
                  <div className="col-12">
                    <p className="fw-bold mb-0">{experiences.Empoloyer} | {experiences.JobTitle}</p>
                    <p className="mb-0 small">{experiences.Client}</p>
                    <i className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(experiences.StartDate) ? new Date(experiences.StartDate).toString().split(' ')[1] + " " + new Date(experiences.StartDate).toString().split(' ')[3] : ''}  {(experiences.IsCurrent) ? '- Current' : (experiences.EndDate) ? ' - ' + new Date(experiences.EndDate).toString().split(' ')[1] + " " + new Date(experiences.EndDate).toString().split(' ')[3] : ''}</i>
                    <Markup content={experiences.Summary} className="small" />
                  </div>
                </div>
              )
            })}

            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && workExperience === '') || workExperience === null || workExperience.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeExperience">
            <h5 className="fw-bold text-capitalize text-break" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>Work Experience</h5>
            <hr className="mt-0" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }} />
            {workExperience && workExperience.map((experiences, index) => {
              return (
                <div className="row mb-3" key={index}>
                  <div className="col-3 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(experiences.StartDate) ? new Date(experiences.StartDate).toString().split(' ')[1] + " " + new Date(experiences.StartDate).toString().split(' ')[3] : ''} {(experiences.IsCurrent) ? ' - Current' : (experiences.EndDate) ? ' - ' + new Date(experiences.EndDate).toString().split(' ')[1] + " " + new Date(experiences.EndDate).toString().split(' ')[3] : ''}</i></div>
                  <div className="col-9">
                    <p className="fw-bold mb-0">{experiences.JobTitle}</p>
                    <p className="small mb-0">{experiences.Empoloyer}{(experiences.City) ? ', ' + experiences.City : ''}{(experiences.State) ? ', ' + experiences.State : ''}</p>
                    <p className="small mb-0">{experiences.Client}</p>
                    <Markup className="small" content={experiences.Summary} />
                  </div>
                </div>
              )
            })}

            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && workExperience === '') || workExperience === null || workExperience.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeExperience">
            <h5 className="fw-bold text-capitalize text-break">Work Experience</h5>
            {workExperience && workExperience.map((experiences, index) => {
              return (
                <div className="row" key={index}>
                  <div className="col-12 mb-3">
                    <p className="mb-2 fw-bold">
                      <span className="">{experiences.Empoloyer}</span>
                      <span>{(experiences.JobTitle) ? ', ' + experiences.JobTitle : ''}{(experiences.StartDate) ? ', ' : ''}</span>
                      <i className="fw-normal" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(experiences.StartDate) ? new Date(experiences.StartDate).toString().split(' ')[1] + " " + new Date(experiences.StartDate).toString().split(' ')[3] : ''}  {(experiences.IsCurrent) ? '- Current' : (experiences.EndDate) ? ' - ' + new Date(experiences.EndDate).toString().split(' ')[1] + " " + new Date(experiences.EndDate).toString().split(' ')[3] : ''}</i>
                    </p>
                    <Markup content={experiences.Summary} />
                  </div>
                </div>
              )
            })}

            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && workExperience === '') || workExperience === null || workExperience.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeExperience">
            <div className="mb-2">
              <h5 className="fw-bold text-uppercase mb-3">Work Experience</h5>
              {workExperience && workExperience.map((experiences, index) => {
                return (
                  <div className="row mb-2" key={index}>
                    <div className="col-12">
                      <p className="fw-bold mb-0">{experiences.Empoloyer} - {experiences.JobTitle}</p>
                      <p class="mb-0 small">{experiences.Client}</p>
                      <i className="small mb-2 d-block" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(experiences.StartDate) ? new Date(experiences.StartDate).toString().split(' ')[1] + " " + new Date(experiences.StartDate).toString().split(' ')[3] : ''}  {(experiences.IsCurrent) ? '- Current' : (experiences.EndDate) ? ' - ' + new Date(experiences.EndDate).toString().split(' ')[1] + " " + new Date(experiences.EndDate).toString().split(' ')[3] : ''}</i>
                      <Markup content={experiences.Summary} className="small" />
                    </div>
                  </div>
                )
              })}
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && workExperience === '') || workExperience === null || workExperience.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeExperience">
            <div className="ps-2 mb-3">
              <h5 className="text-uppercase d-flex align-items-center fw-bold mb-3 text-break"><i className="bi bi-briefcase icon_circle_dark"
                style={(resumeSkeleton.styles.theme_color === '#fff') ? { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#000' } : { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#fff' }}></i>Work
                Experience</h5>
              {workExperience && workExperience.map((experiences, index) => {
                return (
                  <div className="row mb-2 ps-1" key={index}>
                    <div className="col-12">
                      <p className="fw-bold mb-0">{experiences.JobTitle}</p>
                      <p className="mb-0">{experiences.Client}</p>
                      <p className="mb-0">{experiences.Empoloyer}</p>
                      <i className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(experiences.StartDate) ? new Date(experiences.StartDate).toString().split(' ')[1] + " " + new Date(experiences.StartDate).toString().split(' ')[3] : ''}  {(experiences.IsCurrent) ? '- Current' : (experiences.EndDate) ? ' - ' + new Date(experiences.EndDate).toString().split(' ')[1] + " " + new Date(experiences.EndDate).toString().split(' ')[3] : ''}</i>
                      <Markup content={experiences.Summary} className="small" />
                    </div>
                  </div>
                )
              })}
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && workExperience === '') || workExperience === null || workExperience.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeExperience">
            <div className="mb-3">
              <h5 className="fw-bol text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>Work Experience</h5>
              {workExperience && workExperience.map((experiences, index) => {
                return (
                  <div className="row" key={index}>
                    <div className="col-12 mb-3">
                      <p className="text-uppercase fw-bold mb-1 small">{experiences.JobTitle}</p>
                      <p className="mb-0 small">{(experiences.Client) ? experiences.Empoloyer + ', ' : experiences.Empoloyer}{experiences.Client}</p>
                      <p className="mb-2 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(experiences.StartDate) ? new Date(experiences.StartDate).toString().split(' ')[1] + " " + new Date(experiences.StartDate).toString().split(' ')[3] : ''}  {(experiences.IsCurrent) ? '- Current' : (experiences.EndDate) ? ' - ' + new Date(experiences.EndDate).toString().split(' ')[1] + " " + new Date(experiences.EndDate).toString().split(' ')[3] : ''}</i></p>
                      <Markup content={experiences.Summary} className="small" />
                    </div>
                  </div>
                )
              })}
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && workExperience === '') || workExperience === null || workExperience.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeExperience">
            <div className="mb-3">
              <h5 className="fw-bold text-dark text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>Work Experience</h5>
              {workExperience && workExperience.map((experiences, index) => {
                return (
                  <div className="row mb-3" key={index}>
                    <div className="col-12 mb-0">
                      <p className="text-uppercase fw-bold mb-1 small text-dark">{experiences.JobTitle}</p>
                      <p className="mb-0 small">{(experiences.Client) ? experiences.Empoloyer + ', ' : experiences.Empoloyer}{experiences.Client}</p>
                      <p className="fw-bold mb-2 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(experiences.StartDate) ? new Date(experiences.StartDate).toString().split(' ')[1] + " " + new Date(experiences.StartDate).toString().split(' ')[3] : ''}  {(experiences.IsCurrent) ? '- Current' : (experiences.EndDate) ? ' - ' + new Date(experiences.EndDate).toString().split(' ')[1] + " " + new Date(experiences.EndDate).toString().split(' ')[3] : ''}</i></p>
                      <Markup content={experiences.Summary} className="small" />
                    </div>
                  </div>
                )
              })}
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && workExperience === '') || workExperience === null || workExperience.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeExperience">
            <div className="mb-3">
              <h5 className="fw-bold text-dark text-uppercase text-break ps-2">Work Experience</h5>
              <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, padding: '1px' }} className="mt-0" />
              <div className="ps-3">
                <ul className="" style={{ 'listStyle': 'none' }}>
                  {workExperience && workExperience.map((experiences, index) => {
                    return (
                      <li className={(workExperience.length > 1) ? "" : ''} key={index}>
                        <div className="row" >
                          <div className="col-12 mb-2">
                            <p className="text-uppercase fw-bold mb-0 small">{experiences.JobTitle}</p>
                            <p className="mb-0 small">{experiences.Empoloyer}</p>
                            <i className="mb-1 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(experiences.StartDate) ? new Date(experiences.StartDate).toString().split(' ')[1] + " " + new Date(experiences.StartDate).toString().split(' ')[3] : ''}  {(experiences.IsCurrent) ? '- Current' : (experiences.EndDate) ? ' - ' + new Date(experiences.EndDate).toString().split(' ')[1] + " " + new Date(experiences.EndDate).toString().split(' ')[3] : ''}</i>
                            <div className=''><Markup content={experiences.Summary} className="small" /></div>
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li >
        </>
      );
    }
  }

  return (
    <>
      {expericenceCardList()}
    </>
  )
}



export default ResumeExperience;
