import React from 'react'

const DetailCard = ({ Id, Icon, Title, Location, StartDate, EndDate, Industry, handleDelete, handleEdit, Summary }) => {
    return (
        <>
            <div className="row mt-3 pt-3 mb-3 mx-0 border">
                <div className="col-2 text-center">
                    <img src={Icon} alt='icon' />
                </div>
                <div className="col-10">
                    <p className="text-muted small mb-0">{Title}</p>
                    <p className="text-muted small mb-0">{Industry}{(Location) ? ` | ${Location}` : ''} </p>
                    <p className="text-muted small mb-0">{StartDate}{(EndDate) ? ` | ${EndDate}` : ''}</p>
                    <p className='small'>{Summary}</p>
                    <div className="row mb-3">
                        <div className="col"></div>
                        <div className="col-auto">
                            <span className="text-end text-decoration-underline text-danger" onClick={() => { handleDelete(Id) }}><i className="bi bi-trash"></i></span>
                        </div>
                        <div className="col-auto">
                            <span className="text-end text-decoration-underline text-primary" onClick={() => { handleEdit(Id) }}><i className="bi bi-pencil"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailCard;