import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const Success = () => {
    const location = useLocation();

    return (
        <>

            <div className='bg-login'>
                <div className="cred-card">
                    <div className="text-center pb-lg-5 pt-lg-5 pt-3 pb-3 shadow-sm-only">
                        <img src={process.env.PUBLIC_URL + '/image/logo_meritport.svg'} width="220" alt="" />
                    </div>
                    <div className="container d-flex justify-content-center px-lg-5 pb-3 pb-lg-0 mt-4 mt-lg-0 shadow-sm-only">
                        <div className="col-12 col-md-5 align-self-center text-center p-5 rounded-4 bg-white">
                            <img src={process.env.PUBLIC_URL + '/image/icons/check-circle.svg'} width="50px" className="mb-3" alt="" />
                            {(location.state === 'apply') ?
                                <p className="fs-5 text-dark text-center mb-0">Mail Sended successfully!</p>
                                :
                                <p className="fs-5 text-dark text-center mb-0">Resume has been created successfully!</p>
                            }
                            <div className="d-grid gap-3 mt-3">
                                <NavLink to={'/myresume'} className='btn btn-lg btn-primary'>Back to Home</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Success