import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";
const ResumeReference = ({ templateType, sections, suffle }) => {
  const { references, resumeSkeleton, templateStatus } = useContext(AuthContext)

  const referenceCards = () => {
    if (templateType === "ats_pro") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && references === '') || references === null || references.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeReference">

          <h5 className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" style={{ backgroundColor: '#0000001a' }}>Reference</h5>
          <div className="px-3 py-2">
            {
              references && references.map((reference, index) => {
                return (
                  <div key={index}>
                    <p className="mb-1 fw-bold">{reference.Name}</p>
                    <p className="mb-1 small">{reference.Designation}</p>
                    <p className="mb-1 small">{reference.Company}</p>
                    <p className="mb-1 small"><span>Phone: </span><span>{reference.Phone}</span></p>
                    <p className="mb-1 small"><span>Email: </span><span>{reference.Email}</span></p>
                  </div>
                )
              })
            }
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </div>
        </li>
      );
    } else if (templateType === "exec_classic") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && references === '') || references === null || references.length <= 0) ? "d-none" : "resume_section list-group mb-3 my-3"} draggable="true" id="ResumeReference">
          <h5 className="text-capitalize fw-bold mb-3 text-break exec_classic_border" style={{ color: resumeSkeleton.styles.theme_color }}>Reference</h5>
          <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, width: '100px', padding: '1px' }} className="mt-0" />
          <div className="row">
            <div className="col-12">
              {
                references && references.map((reference, index) => {
                  return (
                    <div className="mb-2" key={index}>
                      <p className="small mb-1" >{reference.Name}</p>
                      <p className="small mb-1">{reference.Designation} </p>
                      <p className="small mb-1">{reference.Company}</p>
                      <p className="small mb-1"><span>Phone: </span><span>{reference.Phone}</span></p>
                      <p className="small mb-1"><span>Email: </span><span>{reference.Email}</span></p>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && references === '') || references === null || references.length <= 0) ? "d-none" : "resume_section list-group mb-3 my-3"} draggable="true" id="ResumeReference">
          <h5 className="fw-bold text-capitalize text-break" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>Reference</h5>
          <hr className="mt-0" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }} />
          <div className="row">
            <div className="col-3"></div>
            <div className="col-9">
              {
                references && references.map((reference, index) => {
                  return (
                    <div className="mb-2" key={index}>
                      <p className="small mb-1 fw-bold" >{reference.Name}</p>
                      <p className="small mb-1">{reference.Designation} </p>
                      <p className="small mb-1">{reference.Company}</p>
                      <p className="small mb-1"><span>Phone: </span><span>{reference.Phone}</span></p>
                      <p className="small mb-1"><span>Email: </span><span>{reference.Email}</span></p>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && references === '') || references === null || references.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeReference">
          <h5 className="fw-bold text-capitalize text-break">Reference</h5>
          <div className="mb-3">
            {
              references && references.map((reference, index) => {
                return (
                  <div key={index}>
                    <p className="mb-1 fw-bold">{reference.Name}</p>
                    <p className="mb-1 small">{reference.Designation}</p>
                    <p className="mb-1 small">{reference.Company}</p>
                    <p className="mb-1 small"><span>Phone: </span><span>{reference.Phone}</span></p>
                    <p className="mb-1 small"><span>Email: </span><span>{reference.Email}</span></p>
                  </div>
                )
              })
            }
          </div>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && references === '') || references === null || references.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeReference">
            <div className="mb-2">
              <h5 className="fw-bold text-uppercase text-break">Reference</h5>
              {
                references && references.map((reference, index) => {
                  return (
                    <div key={index} className="mb-2">
                      <p className="mb-0 fw-bold">{reference.Name}</p>
                      <p className="mb-0 small">{reference.Designation}</p>
                      <p className="mb-0 small">{reference.Company}</p>
                      <p className="mb-0 small"><span>Phone: </span><span>{reference.Phone}</span></p>
                      <p className="mb-0 small"><span>Email: </span><span>{reference.Email}</span></p>
                    </div>
                  )
                })
              }
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && references === '') || references === null || references.length <= 0) ? "d-none" : "resume_section list-group mb-3 my-3"} draggable="true" id="ResumeReference">
          <div className="ps-2 mb-3">
            <h5 className="text-uppercase d-flex align-items-center fw-bold mb-3 text-break"><i className="bi bi-person icon_circle_dark" style={(resumeSkeleton.styles.theme_color === '#fff') ? { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#000' } : { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#fff' }}></i>Reference</h5>
            <div className="row ps-1">
              <div className="col-12">
                {
                  references && references.map((reference, index) => {
                    return (
                      <div key={index}>
                        <h6 className="fw-bold">{reference.Name}</h6>
                        <p className="mb-0 small">{reference.Designation}</p>
                        <p className="mb-0 small">{reference.Company}</p>
                        <p className="mb-0 small"><span>Phone: </span><span>{reference.Phone}</span></p>
                        <p className="mb-0 small"><span>Email: </span><span>{reference.Email}</span></p>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && references === '') || references === null || references.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeReference">
          <div className="mb-3 ">
            <h5 className="fw-bol text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>Reference</h5>
            {
              references && references.map((reference, index) => {
                return (
                  <div key={index}>
                    <p className="mb-1 fw-bold">{reference.Name}</p>
                    <p className="mb-1 small">{reference.Designation}</p>
                    <p className="mb-1 small">{reference.Company}</p>
                    <p className="mb-1 small"><span>Phone: </span><span>{reference.Phone}</span></p>
                    <p className="mb-1 small"><span>Email: </span><span>{reference.Email}</span></p>
                  </div>
                )
              })
            }
          </div>
          <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && references === '') || references === null || references.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeReference">
          <h5 className="fw-bold text-dark text-uppercase text-break ps-2">Reference</h5>
          <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, padding: '1px' }} className="mt-0" />
          <div className="row ps-3">
            <div className="col-12 mb-3">
              {
                references && references.map((reference, index) => {
                  return (
                    <div key={index}>
                      <p className="mb-1 fw-bold">{reference.Name}</p>
                      <p className="mb-1 small">{reference.Designation}</p>
                      <p className="mb-1 small">{reference.Company}</p>
                      <p className="mb-1 small"><span>Phone: </span><span>{reference.Phone}</span></p>
                      <p className="mb-1 small"><span>Email: </span><span>{reference.Email}</span></p>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && references === '') || references === null || references.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeReference">
          <div className="mb-3 ">
            <h5 className="fw-bold text-dark text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>Reference</h5>
            {
              references && references.map((reference, index) => {
                return (
                  <div key={index}>
                    <p className="mb-1 fw-bold">{reference.Name}</p>
                    <p className="mb-1 small">{reference.Designation}</p>
                    <p className="mb-1 small">{reference.Company}</p>
                    <p className="mb-1 small"><span>Phone: </span><span>{reference.Phone}</span></p>
                    <p className="mb-1 small"><span>Email: </span><span>{reference.Email}</span></p>
                  </div>
                )
              })
            }
          </div>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }

  }

  return (
    <>
      {referenceCards()}
    </>
  )
}



export default ResumeReference;

