import React from "react";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState, convertFromHTML, Modifier } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TitleCard from "./TitleCard";
import { nanoid } from "nanoid";
import { AuthContext } from "../Services/Authcontext";
import { Markup } from "interweave";

class AchievementsCard extends React.Component {
    static contextType = AuthContext

    constructor(props) {
        super(props)
        const html = '';
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.state = {
                editorState: editorState,
                // section: '',
                Summary: '',
                showForm: false,
                editId: '',
                isEdit: false,
                icon: '/image/icons/Skills.svg',
                header: 'Achievements',
                achieveList: [
                ]
            };
        }
        this.handleForm = this.handleForm.bind(this);
        this.handleAchieveDelete = this.handleAchieveDelete.bind(this);
        this.handleAchieveEdit = this.handleAchieveEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleForm() {
        this.setState({ showForm: !this.state.showForm })
    }

    handleAchieveEdit(id) {
        this.setState({ isEdit: !this.state.isEdit })
        this.setState({ editId: id })
        this.setState({ showForm: !this.state.showForm })
        const EditAchieve = this.state.achieveList.filter(achieve => achieve.id === id);
        EditAchieve.map((editData) => {
            this.setState({ id: editData.id });
            // this.setState({ section: editData.section });
            this.setState({ Summary: editData.Summary });
            const blocksFromHTML = convertFromHTML(editData.Summary);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            this.setState({ editorState: EditorState.createWithContent(state) });
            return '';
        });
    }

    handleAchieveDelete(id) {
        const deleteJob = this.state.achieveList.filter(achieve => achieve.id !== id);
        this.setState({ achieveList: deleteJob });
        this.handleState();
    }

    handleSubmit(e, formType) {
        e.preventDefault();
        const { dispatch } = this.context;
        dispatch({
            type: 'DATA_UPDATED',
            payload: true
        })
        if (formType === 'add') {
            const addAchieve = {
                id: nanoid(),
                icon: '/image/icons/Skills.svg',
                // section: this.state.section,
                Summary: this.state.Summary,
            }
            this.setState({ achieveList: [...this.state.achieveList, addAchieve] })

        } else {
            const EditAchieve = this.state.achieveList.map(achieve => {
                if (achieve.id === this.state.editId) {
                    return {
                        // ...achieve, section: this.state.section,
                        Summary: this.state.Summary,
                    };
                }
                return achieve;
            });
            this.setState({ achieveList: EditAchieve })
            this.setState({ isEdit: !this.state.isEdit })
            this.setState({ editId: '' })
        }
        this.setState({ showForm: !this.state.showForm })
        // this.setState({ section: '' })
        this.setState({ Summary: '' })
        this.setState({ editorState: EditorState.createEmpty() });
        this.handleState();
    }

    handleState() {
        const { dispatch } = this.context;
        setTimeout(() => {
            dispatch({
                type: 'ADD_ACHIEVEMENT',
                payload: this.state.achieveList
            })
            dispatch({
                type: 'ACHIEVEMENT_HEADER',
                payload: this.state.header
            })
        }, 1000)
    }

    hadleSetContent = (data) => {
        const html = (data) ? data : '';
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const editorState = this.state.editorState;

            let { contentBlocks, entityMap } = htmlToDraft(data);
            let contentState = Modifier.replaceWithFragment(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
            )
            const newEditorState = EditorState.push(editorState, contentState, 'insert-fragment');
            this.onEditorStateChange(newEditorState)
        }
    }

    onEditorStateChange = editorState => {
        this.setState({ editorState: editorState });
        this.setState({ Summary: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) })
    };
    componentDidMount() {
        this.setState({ header: this.context.achievementsTitle })
        if (this.context.achievements && this.context.achievements !== null) {
            this.setState({ achieveList: this.context.achievements })
        }

    }
    render() {
        const { editorState } = this.state;
        return (
            <div id="achievement_add" className={(this.props.Display) ? 'mt-4' : "d-none"}>
                <form onSubmit={(e) => { this.handleSubmit(e, (this.state.isEdit) ? 'edit' : 'add') }} method="post" className={(this.state.showForm) ? "mt-4" : 'd-none'}>
                    {(this.state.showForm) ? '' : this.state.header}
                    <div className="form-floating mb-3">
                        <input type="text" name="header_title" value={this.state.header}
                            onChange={(e) => {
                                this.setState({ header: e.target.value });
                                document.getElementById('achievement_title').innerHTML = e.target.value
                            }} id="achievement_header_title"
                            className="form-control" />
                        <label htmlFor="trainingproj_title">Title</label>
                    </div>
                    {/* <div className="form-floating mb-3">
                        <input type="text" name="achiev_name" id="achiev_name" value={this.state.section} onChange={(e) => { this.setState({ section: e.target.value }) }} className="form-control"
                            placeholder="Section Name" />
                        <label htmlFor="achiev_name">Section Name</label>
                    </div> */}

                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        spellCheck={true}
                        stripPastedStyles={true}
                        toolbar={{
                            options: ['inline', 'fontSize', 'list', 'textAlign'],
                            inline: { inDropdown: true },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                        }}
                        onEditorStateChange={this.onEditorStateChange} />

                    <div className="row mb-4">
                        <div className="col">
                            <button className="btn btn-outline-primary mt-3" type="button" onClick={this.handleForm}>Cancel</button>
                        </div>

                        <div className="col">
                            <button type="submit" className="btn btn-primary mt-3 float-end" id="achievement_add_done_btn">{(this.state.isEdit) ? 'Add' : 'Add'}</button>
                        </div>
                    </div>
                </form>
                <div className={(this.state.showForm) ? "d-none" : ''}>
                    <TitleCard Title={this.state.header} Section={'Section Name'} Description={'Achievements'} />
                    {
                        this.state.achieveList.map((achieve, index) => {
                            return (
                                <div className="row mt-3 pt-3 mb-3 mx-0 border" key={index}>
                                    <div className="col-2 text-center">
                                        <img src={achieve.icon} alt='icon' />
                                    </div>
                                    <div className="col-10">
                                        <p className="text-muted small mb-0">{achieve.section}</p>
                                        <Markup content={achieve.Summary} className="small" />
                                        <div className="row mb-3">
                                            <div className="col"></div>
                                            <div className="col-auto">
                                                <span className="text-end text-decoration-underline text-danger" onClick={() => { this.handleAchieveDelete(achieve.id) }}><i className="bi bi-trash"></i></span>
                                            </div>
                                            <div className="col-auto">
                                                <span className="text-end text-decoration-underline text-primary" onClick={() => { this.handleAchieveEdit(achieve.id) }}><i className="bi bi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div id="achievements" className={(this.state.showForm) ? "d-none" : ''}>
                    <div className="row mb-4 mt-3">
                        <div className="col">
                        </div>
                        {
                            (this.state.achieveList <= 0) ? (<>
                                <div className="col text-end">
                                    <button type="button" className="btn add_btn" id="achievement_add_btn" onClick={this.handleForm}><i
                                        className="bi bi-plus"></i> add</button>
                                </div>
                            </>) : ''
                        }

                    </div>
                </div>
            </div>
        )
    }
}

export default AchievementsCard;