import { Markup } from 'interweave';
import React, { useContext, useRef } from 'react'
import { AuthContext } from '../Services/Authcontext';
const ResumeHeader = ({ templateType, suffle }) => {
    const { resumeSkeleton, personalInformation, resumeDataSkeleton, resumeTitle, jobSummary } = useContext(AuthContext);
    const Image = (personalInformation && personalInformation.profile_picture !== '' && personalInformation.profile_picture !== null) ? personalInformation.profile_picture : '';
    const ref = useRef(null);
    const hexToRgbA = (hex, opacity) => {
        let c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = `0x${c.join('')}`;
            return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
        }
        throw new Error('Bad Hex');
    };

    switch (templateType) {
        case 'modern_pro':
            return (
                <>
                    <div className={(personalInformation && personalInformation.show_profile_picture) ? 'mt-5 mb-4' : 'mb-4'}>
                        <div className="row">
                            <div className="bg-ligh position-relative text-center pb-3" style={{ backgroundColor: hexToRgbA(resumeSkeleton.styles.theme_color, 0.1) }}>
                                <img
                                    src={(Image) ? Image : (process.env.NODE_ENV === 'development') ? "http://localhost:3000/image/default_user.png" : process.env.REACT_APP_PROD_RESUME_WEBSITE_URL + "image/default_user.png"}
                                    className={(personalInformation && personalInformation.show_profile_picture) ? "rounded-circle p-1 border border-3 position-absolute top-0 translate-middle bg-white" : 'd-none'} ref={ref} width="80px" height="80px" id="profileImage" alt='profile_image' />
                                <h2 className="text-break text-uppercase mt-5 fw-bolder" style={{ letterSpacing: '5px' }}>
                                    <span className="mb-0 me-1" id="firstName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.first_name : personalInformation.first_name}</span>
                                    <span className="mb-0" id="lastName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.last_name : personalInformation.last_name}</span>
                                </h2>
                                <p className="fw-bol text-uppercase h5" id="designation" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>{resumeTitle}</p>
                                <div className='d-flex justify-content-center gx-2'>
                                    <p className="mb-0 me-2">
                                        <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-telephone-fill fs-6"></i>
                                        <span id="phone" className="ps-2">
                                            {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.phone_number !== '') ? resumeDataSkeleton.personalInformation.phone_number : personalInformation.phone_number}
                                        </span>
                                    </p>
                                    <p className="mb-0 me-2">
                                        <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-envelope-fill fs-6"></i>
                                        <span id="email" className="ps-2">
                                            {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.email !== '') ? resumeDataSkeleton.personalInformation.email : personalInformation.email}
                                        </span>
                                    </p>
                                    <p className="mb-0 me-2">
                                        <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-house-door-fill fs-6"></i>
                                        <span className="ps-2" id="city">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.city !== '') ? ', ' + resumeDataSkeleton.personalInformation.city : personalInformation.city}</span>
                                    </p>
                                    <p className="d-none" id="dob"></p>
                                    <p className="mb-0 d-none" id="gender"></p>
                                    <p className="mb-1 d-none">
                                        <span id="address" className="ps-2">
                                            {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.address !== '') ? resumeDataSkeleton.personalInformation.address : ''}
                                        </span>
                                        <span id="address2" className="ps-2">
                                            {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.address_line_2 !== '') ? ', ' + resumeDataSkeleton.personalInformation.address_line_2 : ''}
                                        </span>
                                        <span className=" d-none" id="state">{personalInformation.state}{(personalInformation.state) ? ',' : ''}</span>
                                        <span id="country">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.country !== '') ? '.' + resumeDataSkeleton.personalInformation.country : ', ' + personalInformation.country}</span>
                                        <span id="postal" className="d-none">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.postalcode !== '') ? resumeDataSkeleton.personalInformation.postalcode : personalInformation.postalcode}</span>
                                        <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-house-door-fill fs-6 ms-2"></i>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        case 'modern_classic':
            return (
                <>
                    <div className='w-100 my-4 px-5'>
                        <div className='d-flex ps-5 justify-content-between'>
                            <div className="wrapper">
                                <div className="line"></div>
                                <div className="wordwrapper">
                                    <div className="word">
                                        <h1 className="text-break text-capitalize fw-bolder" style={{ letterSpacing: '5px' }}>
                                            <span className="mb-0 me-1 fs-4" id="firstName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.first_name : personalInformation.first_name}</span>
                                            <span className="mb-0 d-block fw-bold text-dark" id="lastName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.last_name : personalInformation.last_name}</span>
                                            <h5 style={{ fontFamily: 'Noto Serif Georgian, serif', color: resumeSkeleton.styles.theme_color }} id="designation">{resumeTitle}</h5>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <p className="fw-bol text-uppercase h5 d-none" id="designation" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>{resumeTitle}</p>
                            <img
                                src={(Image) ? Image : (process.env.NODE_ENV === 'development') ? "http://localhost:3000/image/default_user.png" : process.env.REACT_APP_PROD_RESUME_WEBSITE_URL + "image/default_user.png"}
                                className={(personalInformation && personalInformation.show_profile_picture) ? "rounded-circl p-3" : "d-none"} style={{ backgroundColor: hexToRgbA(resumeSkeleton.styles.theme_color, 0.2) }} ref={ref} width="300" height="300" id="profileImage" alt='profile_image' />
                        </div>
                    </div>
                </>
            );
        case 'modern_std':
            return (
                <>
                    <div className='row mt-3'>
                        <div className='col-8'>
                            <h2 className="text-break text-capitalize fw-bolder mb-0">
                                <span className="mb-0 me-1" id="firstName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.first_name : personalInformation.first_name}</span>
                                <span className="" id="lastName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.last_name : personalInformation.last_name}</span>
                            </h2>
                            <p className="mb-0 text-capitalize fw-bold" id="designation" style={{ color: resumeSkeleton.styles.theme_color }}>{resumeTitle}</p>
                        </div>
                        <div className='col-4'>
                            <div className="text-end">
                                <p className="mb-0 d-none">
                                    <span id="gender">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.gender !== '') ? resumeDataSkeleton.personalInformation.gender : personalInformation.gender}</span>
                                </p>
                                <p className="mb-1">
                                    <span id="phone" className="ps-2">
                                        {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.phone_number !== '') ? resumeDataSkeleton.personalInformation.phone_number : personalInformation.phone_number}
                                    </span>
                                    <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-telephone-fill fs-6 ms-2"></i>
                                </p>
                                <p className="mb-1">
                                    <span id="email" className="ps-2">
                                        {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.email !== '') ? resumeDataSkeleton.personalInformation.email : personalInformation.email}
                                    </span>
                                    <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-envelope-fill fs-6 ms-2"></i>
                                </p>
                                <p className="mb-1 d-none">
                                    <span id="dob" className="ps-2">{(resumeDataSkeleton && resumeDataSkeleton.personlInformation && resumeDataSkeleton.personalInformation.dob !== '') ? resumeDataSkeleton.personalInformation.dob : personalInformation.dob}</span>
                                    <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-tv-fill fs-6 ms-2"></i>
                                </p>
                                <p className="mb-1">
                                    <span id="address" className="ps-2">
                                        {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.address !== '') ? resumeDataSkeleton.personalInformation.address : ''}
                                    </span>
                                    <span id="address2" className="ps-2">
                                        {(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.address_line_2 !== '') ? ', ' + resumeDataSkeleton.personalInformation.address_line_2 : ''}
                                    </span>
                                    <span id="city">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.city !== '') ? ', ' + resumeDataSkeleton.personalInformation.city : personalInformation.city}</span>
                                    <span className="d-none" id="state"> {personalInformation.state}{(personalInformation.state) ? ',' : ''} </span>
                                    <span id="country">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.country !== '') ? '.' + resumeDataSkeleton.personalInformation.country : ', ' + personalInformation.country}</span>
                                    <span id="postal" className="d-none">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation && resumeDataSkeleton.personalInformation.postalcode !== '') ? resumeDataSkeleton.personalInformation.postalcode : personalInformation.postalcode}</span>
                                    <i style={{ color: resumeSkeleton.styles.theme_color }} className="bi bi-house-door-fill fs-6 ms-2"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="jobSummary" className='p-3'>
                        <Markup content={jobSummary.profile_summary} />
                    </div>
                </>
            );
        case 'exec_classic':
            return (
                <>
                    <div style={{ backgroundColor: hexToRgbA(resumeSkeleton.styles.theme_color, 1) }}>
                        <div className="row">
                            <div className={(personalInformation && personalInformation.show_profile_picture) ? "col-4 text-center" : 'd-none'}>
                                <div className='position-relative' style={{ top: '3rem' }}>
                                    <img
                                        src={(Image) ? Image : (process.env.NODE_ENV === 'development') ? "http://localhost:3000/image/default_user.png" : process.env.REACT_APP_PROD_RESUME_WEBSITE_URL + "image/default_user.png"}
                                        className={(personalInformation && personalInformation.show_profile_picture) ? "rounded-circle p-1" : "d-none"} width="160px" height="160px" id="profileImage" alt="profile_image" />
                                </div>
                            </div>
                            <div className={(personalInformation && personalInformation.show_profile_picture) ? "col-8 d-flex align-items-center" : 'col-12 d-flex align-items-center p-4'}>
                                <div>
                                    <h1 className="text-break fw-bold text-white mb-0">
                                        <span className="mb-0 me-1" id="firstName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.first_name : personalInformation.first_name}</span>
                                        <span className="mb-0" id="lastName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.last_name : personalInformation.last_name}</span>
                                    </h1>
                                    <p className="fw-bol text-white fs-5" id="designation">{resumeTitle}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        case 'exec_pro':
            return (
                <>
                    <div className="row mb-3 align-items-stretch mx-0 px-0">
                        <div className="col-9 ps-0 ">
                            <div className="d-flex p-2 bg-ab-header align-items-start rounded-3" style={{ minHeight: "200px" }}>
                                <div className=''>
                                    <img
                                        src={(Image) ? Image : (process.env.NODE_ENV === 'development') ? "http://localhost:3000/image/default_user.png" : process.env.REACT_APP_PROD_RESUME_WEBSITE_URL + "image/default_user.png"} width="100px" height="100px"
                                        className={(personalInformation && personalInformation.show_profile_picture) ? "rounded-circle p-1" : "d-none"} id="profileImage" alt="profile_image" />
                                </div>
                                <div className="ms-2">
                                    <h2 className="fw-bold mb-1 text-break">
                                        <span className="mb-0 me-1" id="firstName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.first_name : personalInformation.first_name}</span>
                                        <span className="mb-0" id="lastName">{(resumeDataSkeleton && resumeDataSkeleton.personalInformation) ? resumeDataSkeleton.personalInformation.last_name : personalInformation.last_name}</span>
                                    </h2>
                                    <p className="fw-bold mb-1 small" id="designation">{resumeTitle}</p>
                                    <div id="jobSummary">
                                        <Markup content={jobSummary.profile_summary} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 rounded-3" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { backgroundColor: resumeSkeleton.styles.theme_color, color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { backgroundColor: resumeSkeleton.styles.theme_color, color: '#5a5a5a' } : { backgroundColor: resumeSkeleton.styles.theme_color, color: 'white' } : { backgroundColor: 'unset' }}>
                            <div className="p-2" style={{ minHeight: "200px" }}>
                                <p className="text-break d-flex align-items-center">
                                    <i className="bi bi-envelope-fill p-1 d-flex align-items-center me-1 fs-6" style={(resumeSkeleton.styles.theme_color === '#fff') ? { color: '#000' } : null}></i><span
                                        className="" id="email">{personalInformation.email}</span></p>
                                <p className="text-break d-flex align-items-center">
                                    <i className="bi bi-telephone-fill p-1 d-flex align-items-center me-1 fs-6" style={(resumeSkeleton.styles.theme_color === '#fff') ? { color: '#000' } : null}></i><span
                                        className="" id="phone">{personalInformation.phone_number}</span></p>
                                <p className="text-break mb-2 d-flex align-items-center">
                                    <i className="bi bi-geo-alt-fill p-1 d-flex align-items-center me-1 fs-6" style={(resumeSkeleton.styles.theme_color === '#fff') ? { color: '#000' } : null}></i>
                                    <span className=" d-none" id="address"> {personalInformation.address}{(personalInformation.address) ? ',' : ''},</span>
                                    <span className=" d-none" id="address2"> {personalInformation.address_line_2}{(personalInformation.address_line_2) ? ',' : ''}</span>
                                    <span className="" id="city">{personalInformation.city}{(personalInformation.city) ? ', ' : ''}{personalInformation.country}{(personalInformation.country) ? '.' : ''}</span>
                                    <span className=" d-none" id="state">{personalInformation.state}{(personalInformation.state) ? ',' : ''}</span>
                                    <span className=" d-none" id="postal">{personalInformation.postalcode}{(personalInformation.postalcode) ? '.' : ''}</span>
                                </p>
                            </div>
                        </div>
                        <p className="d-none" id="dob"></p>
                        <p className="d-none" id="gender"></p>
                    </div>
                </>
            );
        default:
            return '';

    }
}

export default ResumeHeader