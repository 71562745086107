import React from "react";
import TitleCard from "./TitleCard";
import DetailCard from "./DetailCard";
import { nanoid } from "nanoid";
import { AuthContext } from "../Services/Authcontext";

class PersonalProjectsCard extends React.Component {
    static contextType = AuthContext

    constructor(props) {
        super(props)
        this.state = {
            showForm: false,
            editId: '',
            isEdit: false,
            icon: '/image/icons/education.png',
            title: '',
            section: '',
            startDate: '',
            endDate: '',
            header: 'Personal Projects',
            personalList: [

            ]
        };
        this.handleForm = this.handleForm.bind(this);
        this.handlePersonalDelete = this.handlePersonalDelete.bind(this);
        this.handlePersonalEdit = this.handlePersonalEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleForm() {
        this.setState({ showForm: !this.state.showForm })
    }

    handlePersonalEdit(id) {
        this.setState({ isEdit: !this.state.isEdit })
        this.setState({ editId: id })
        this.setState({ showForm: !this.state.showForm })
        const EditPersonal = this.state.personalList.filter(personal => personal.id === id);
        EditPersonal.map((editData) => {
            this.setState({ id: editData.id });
            this.setState({ title: editData.title });
            this.setState({ section: editData.section });
            this.setState({ startDate: editData.startDate });
            this.setState({ endDate: editData.endDate });
            return '';
        });
    }

    handlePersonalDelete(id) {
        const deletePersonal = this.state.personalList.filter(personal => personal.id !== id);
        this.setState({ personalList: deletePersonal });
        this.handleState();
    }

    handleSubmit(e, formType) {
        e.preventDefault();
        const { dispatch } = this.context;
        dispatch({
            type: 'DATA_UPDATED',
            payload: true
        })
        if (formType === 'add') {
            const addPersonal = {
                id: nanoid(),
                icon: '/image/icons/education.png',
                title: this.state.title,
                section: this.state.section,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
            }
            this.setState({ personalList: [...this.state.personalList, addPersonal] })

        } else {
            const EditPersonal = this.state.personalList.map(personal => {
                if (personal.id === this.state.editId) {
                    return {
                        ...personal, title: this.state.title,
                        section: this.state.section,
                        startDate: this.state.startDate,
                        endDate: this.state.endDate,
                    };
                }
                return personal;
            });
            this.setState({ personalList: EditPersonal })
            this.setState({ isEdit: !this.state.isEdit })
            this.setState({ editId: '' })
        }
        this.setState({ showForm: !this.state.showForm })
        this.setState({ title: '' })
        this.setState({ section: '' })
        this.setState({ startDate: '' })
        this.setState({ endDate: '' })
        this.handleState();
    }
    handleState() {
        const { dispatch } = this.context;
        setTimeout(() => {
            dispatch({
                type: 'ADD_PERSONALPROJECT',
                payload: this.state.personalList
            })
            dispatch({
                type: 'PERSONALPROJECT_HEADER',
                payload: this.state.header
            })
        }, 1000)
    }
    componentDidMount() {
        this.setState({ header: this.context.personalProjectTitle })
        if (this.context.personalProject && this.context.personalProject !== null) {
            this.setState({ personalList: this.context.personalProject })
        }
        document.getElementById("personalproj_start_date").setAttribute("max", this.props.handleDateValiadation((new Date())));
    }
    render() {
        return <>
            <div id="personal_add" className={(this.props.Display) ? 'mt-4' : "d-none"}>
                <form onSubmit={(e) => { this.handleSubmit(e, (this.state.isEdit) ? 'edit' : 'add') }} method="post" className={(this.state.showForm) ? "mt-4" : 'd-none'}>
                    {(this.state.showForm) ? '' : this.state.header}
                    <div className="form-floating mb-3">
                        <input type="text" name="header_title" value={this.state.header}
                            onChange={(e) => {
                                this.setState({ header: e.target.value });
                                document.getElementById('personalproject_title').innerHTML = e.target.value
                            }} id="personalproject_header_title"
                            className="form-control" />
                        <label htmlFor="trainingproj_title">Title</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="text" name="personalproj_title" id="personalproj_title" value={this.state.title} onChange={(e) => { this.setState({ title: e.target.value }) }}
                            className="form-control resume_personalproj_input"
                            placeholder="Title" />
                        <label htmlFor="personalproj_title">Section Name</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="text" name="personalproj_section" id="personalproj_section" value={this.state.section} onChange={(e) => { this.setState({ section: e.target.value }) }}
                            className="form-control resume_personalproj_input"
                            placeholder="Section Name" />
                        <label htmlFor="personalproj_section">Section Description</label>
                    </div>
                    <div className="row mb-4">
                        <div className="col mb-1">
                            <div className="form-floating">
                                <input type="date" name="personalproj_start_date" id="personalproj_start_date" value={this.state.startDate} onChange={(e) => { this.setState({ startDate: e.target.value }); document.getElementById('personalproj_end_date').setAttribute("min", this.props.handleDateValiadation(new Date(e.target.value))) }}
                                    className="form-control resume_personalproj_input_date"
                                    placeholder="Start Date" />
                                <label htmlFor="personalproj_start_date">Start Date</label>
                            </div>
                        </div>
                        <div className="col mb-1">
                            <div className="form-floating">
                                <input type="date" name="personalproj_end_date" id="personalproj_end_date" value={this.state.endDate} onChange={(e) => { this.setState({ endDate: e.target.value }) }}
                                    className="form-control resume_personalproj_input_date"
                                    placeholder="End Date" />
                                <label htmlFor="personalproj_end_date">End Date </label>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col ms-2">
                            <button className="btn btn-outline-primary mt-3" type="button" onClick={this.handleForm}>Cancel</button>
                        </div>

                        <div className="col">
                            <button className="btn btn-primary mt-3 float-end" id="personal_add_done_btn" type="submit">{(this.state.isEdit) ? 'Add' : 'Add'}</button>
                        </div>
                    </div>
                </form>
                <div className={(this.state.showForm) ? "d-none" : ''}>
                    <TitleCard Title={this.state.header} Section={'Section Name'} Description={'Personal Projects'} />
                    {
                        this.state.personalList.map((personal, index) => {
                            return <DetailCard key={index} handleDelete={this.handlePersonalDelete} handleEdit={this.handlePersonalEdit} Id={personal.id} Icon={personal.icon} Title={personal.title} Industry={personal.section} Location={''} StartDate={personal.startDate} EndDate={personal.endDate} Summary={personal.summary} />
                        })
                    }
                </div>
                <div id="personal_project" className={(this.state.showForm) ? "d-none" : ''}>
                    <div className="row mb-4 mt-3">
                        <div className="col">
                        </div>
                        <div className="col text-end">
                            <button type="button" className="btn add_btn" id="personal_add_btn" onClick={this.handleForm}><i
                                className="bi bi-plus"></i> add</button>

                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}

export default PersonalProjectsCard;