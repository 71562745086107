import React, { } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from './WithRouter';
import { Rating } from 'react-simple-star-rating';
import RightSideResume from './RightSideResume';
import { AuthContext } from '../Services/Authcontext';
import NotificationCard from './NotificationCard';
import LeftHideButton from './LeftHideButton';
import MobileHeader from './MobileHeader';
import video from '../image/animation/languages.webm'


class Language extends React.Component {
    static contextType = AuthContext

    constructor(props) {
        super(props)
        this.state = {
            languageList: [],
        }
        this.handleLangChange = this.handleLangChange.bind(this);
        this.handleLangRating = this.handleLangRating.bind(this);
        this.addLangFormFields = this.addLangFormFields.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleLangChange(i, e) {
        let languageList = this.state.languageList;
        languageList[i][e.target.name] = e.target.value;
        this.setState({ languageList });
    }

    handleLangRating(i, rate) {
        // eslint-disable-next-line
        this.state.languageList[i]["rating"] = parseInt(rate) / 20;
    }

    addLangFormFields() {
        this.setState(({
            languageList: [...this.state.languageList, { rating: "", language: "" }]
        }))
    }

    removeLangFormFields(i) {
        let languageList = this.state.languageList;
        languageList.splice(i, 1);
        this.setState({ languageList });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.context.languages) {
            this.setState({ languageList: this.context.languages })
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const { dispatch } = this.context;
        dispatch({
            type: 'DATA_UPDATED',
            payload: true
        })
        setTimeout(() => {
            dispatch({
                type: "ADD_LANGUAGE",
                // eslint-disable-next-line
                payload: this.state.languageList.filter((languageList) => { if (languageList.language !== '' && languageList.language !== 'undefined' && languageList.language !== undefined) { return languageList } })
            });
        }, 1000)
        for (var key in this.context.stepCompleteness) {
            if (this.context.stepCompleteness[key]['resume_step_id'] === Number(this.props.step)) {
                this.context.stepCompleteness[key]['step_status'] = true;
            }
        }
        this.props.navigate(`/rb-step?step=${Number(this.props.step) + 1}`)
    }

    render() {
        const step = Number(this.props.step);
        return (
            <>
                <MobileHeader setIsLoading={this.props.setIsLoading} Title={"Language"} ProgressBar={this.props.progressBar} StepList={this.props.stepList} Step={Number(this.props.step)} />
                <div className="row ms-lg-0">
                    <div className="col-md-12 col-lg-5 bg-white p-lg-4 position-relative" id="id_left">
                        <LeftHideButton rightHide={this.props.rightHide} rightShow={this.props.rightShow} />
                        <div className="" id="id_left_side">
                            <NotificationCard
                                short_notes={
                                    <>
                                        <p className='mb-0 small'>List out the languages you know and rate them according to proficiency.</p>
                                    </>
                                }
                                Notes={<>
                                    <p className='mb-0 small'>List out the languages you know and rate them according to proficiency.</p>
                                    <ul className='small'>
                                        <li>
                                            <b>3 Stars</b> if you can speak, read and write
                                        </li>
                                        <li>
                                            <b>2 Stars</b> if you can speak and read
                                        </li>
                                        <li>
                                            <b>1 Star</b> if can speak alone
                                        </li>
                                    </ul>
                                </>
                                } video={video} />
                            <form onSubmit={this.handleSubmit} className="mt-4">
                                <div id="job_view">
                                    <div className="row pt-3 mx-0">
                                        <div className="" id="new_add_skill_tech">
                                            <div className="mt-3 row add_skills">
                                                {this.state.languageList && this.state.languageList.map((element, index) => (
                                                    <div className="mt-3 row p-0 m-0 mx-lg-0 add_skills" key={index}>
                                                        <div className="col-5 col-lg-6 col-xl-5 px-0">
                                                            <Rating iconsCount={3} ratingValue={element.rating * 20} initialValue={element.rating * 20} onClick={(e) => { this.handleLangRating(index, e) }} size={25} />
                                                        </div>
                                                        <div className="col-5 col-lg-4 col-xl-5 px-0">
                                                            <input type="text" name="language" id={`language_${element.language}`} value={element.language} onChange={(e) => { this.handleLangChange(index, e) }} className="resume_skills_input form-control"
                                                                placeholder="add language" />
                                                        </div>
                                                        <button type="button" className="col-2 del_skills" onClick={() => this.removeLangFormFields(index)}><i className="bi bi-trash"></i></button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        <button className="mt-3 add_job_btn add_skills_tech btn" id="add_skills_tech" type='button' onClick={this.addLangFormFields}><i className="bi bi-plus"></i> add another language</button>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <NavLink to={`/rb-step?step=${step - 1}`}>
                                                <button className="btn btn-outline-primary mt-3">Back</button>
                                            </NavLink>
                                        </div>
                                        <div className="col">
                                            <button className="btn btn-primary mt-3 float-end" type='submit'>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <RightSideResume />
                </div>
            </>
        );
    }
}


export default withRouter(Language);