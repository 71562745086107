import React from 'react'

const ChooseTemplates = ({ template, hadleSelectedTemplate, previewImageFunction }) => {
    return (
        <div className="col-lg-3 mb-4">
            <div className="card border-0 overflow-hidden">
                <div className="card-body p-0" style={{height:'425px'}}>
                    <img src={(template.photo_thumbnail) ? template.photo_thumbnail : process.env.PUBLIC_URL + "/image/resume-default.jpg"} width="100%" height="100%" alt="resume_templates" />
                    <div className="circle-overlay"></div>
                    <div className="demo-info has-text-centered">
                        <div className="wrapper px-4">
                            <div className="demo-title text-center">
                                <p className="text-white small text-uppercase mb-4">{template.name}</p>
                                <p className="text-white small text-capitalize mb-2">{template.summary}</p>
                                <p className="text-white small text-capitalize fw-bold mb-5 px-4">{template.description}</p>
                                <div className="">
                                    <button className="btn btn-sm btn-outline-light text-uppercase me-1" data-bs-toggle="modal" data-bs-target="#previewModal" onClick={() => { previewImageFunction(template.photo_thumbnail) }}>preview</button>
                                    <button className="btn btn-sm btn-light text-uppercase" onClick={(e) => { hadleSelectedTemplate(template.id) }}>select</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseTemplates