import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";
const ResumeCompetitions = ({ templateType, sections, suffle, format }) => {
  const { competitions, resumeSkeleton, templateStatus, competitionsTitle } = useContext(AuthContext);
  const CompetitionsListCard = ({ competitionData }) => {
    return (
      <div className="mb-3">
        <p className="mb-0 fw-bold small">{competitionData.title}</p>
        <p className="mb-0 small">{competitionData.section}</p>
        <p className="mb-0 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(competitionData.startDate) ? format(competitionData.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}  {(competitionData.endDate) ? ' - ' + format(competitionData.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
        <div className="small">{competitionData.summary}</div>
      </div>
    )
  }
  const resumeCompetitionsCard = () => {
    if (templateType === "ats_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competitions === '') || competitions === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetitions">
            <h5 id="competitions_title" className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" style={{ backgroundColor: '#0000001a' }}>{competitionsTitle}</h5>
            <div className="px-3 py-2">
              {
                competitions && competitions.map((competition, index) => {
                  return (
                    <div className="row mb-3" key={index}>
                      <div className="col-3">
                        <p className="mb-0 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(competition.startDate) ? format(competition.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}  {(competition.endDate) ? ' - ' + format(competition.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                      </div>
                      <div className="col-9">
                        <p className="mb-0 fw-bold small">{competition.title}</p>
                        <p className="mb-0 small">{competition.section}</p>
                        <div className="small">{competition.summary}</div>
                      </div>
                    </div>
                  )
                })
              }
            </div>

            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competitions === '') || competitions === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetitions">
            <h5 id="competitions_title" className="text-capitalize fw-bold mb-3 text-break exec_classic_border" style={{ color: resumeSkeleton.styles.theme_color }}>{competitionsTitle}</h5>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, width: '100px', padding: '1px' }} className="mt-0" />
            {
              competitions && competitions.map((competition, index) => {
                return (

                  <div className="row" key={index}>
                    {/* <div className="col-3">

                    </div> */}
                    <div className="col-12">
                      <h6 className="fw-bold">{competition.title}</h6>
                      <p className="mb-0 small">{competition.section}</p>
                      <div className="small">{competition.summary}</div>
                      <p className="mb-0 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(competition.startDate) ? format(competition.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}  {(competition.endDate) ? ' - ' + format(competition.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                    </div>
                  </div>

                )
              })
            }
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competitions === '') || competitions === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetitions">
            <h5 id="competitions_title" className="fw-bold text-capitalize text-break" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{competitionsTitle}</h5>
            <hr className="mt-0" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }} />
            <div className="row">
              <div className="col-3"></div>
              <div className="col-9">
                {
                  competitions && competitions.map((competition, index) => {
                    return <CompetitionsListCard key={index} competitionData={competition} />
                  })
                }
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competitions === '') || competitions === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetitions">
            <div className="mb-1">
              <h5 id="competitions_title" className="fw-bold text-capitalize text-break">{competitionsTitle}</h5>
              <div className="row">
                <div className="col-12">
                  {
                    competitions && competitions.map((competition, index) => {
                      return (
                        <div className="mb-3" key={index}>
                          <p className="mb-0 fw-bold small">{competition.title}</p>
                          <p className="mb-0 small">{competition.section}</p>
                          <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(competition.startDate) ? format(competition.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}  {(competition.endDate) ? ' - ' + format(competition.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                          <div className="small">{competition.summary}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competitions === '') || competitions === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetitions">
            <div className="mb-2">
              <h5 id="competitions_title" className="fw-bold text-uppercase text-break">{competitionsTitle}</h5>
              <div className="row">
                <div className="col-12">
                  {
                    competitions && competitions.map((competition, index) => {
                      return (
                        <div className="mb-3" key={index}>
                          <p className="mb-0 fw-bold small">{competition.title}</p>
                          <p className="mb-0 small">{competition.section}</p>
                          <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(competition.startDate) ? format(competition.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}  {(competition.endDate) ? ' - ' + format(competition.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                          <div className="small">{competition.summary}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competitions === '') || competitions === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetitions">
            <div className="ps-2 mb-3">
              <h5 id="competitions_title" className="text-uppercase d-flex align-items-center fw-bold mb-3 text-break"><i className="bi bi-bicycle icon_circle_dark" style={(resumeSkeleton.styles.theme_color === '#fff') ? { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#000' } : { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#fff' }}></i>{competitionsTitle}</h5>
              <div className="row ps-1">
                <div className="col-12">
                  {
                    competitions && competitions.map((competition, index) => {
                      return <CompetitionsListCard key={index} competitionData={competition} />
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competitions === '') || competitions === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetitions">
            <div className="mb-1">
              <h5 id="competitions_title" className="fw-bol text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>{competitionsTitle}</h5>
              <div className="row">
                <div className="col-12">
                  {
                    competitions && competitions.map((competition, index) => {
                      return <CompetitionsListCard key={index} competitionData={competition} />
                    })
                  }
                </div>
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competitions === '') || competitions === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetitions">
            <div className="mb-1">
              <h5 id="competitions_title" className="fw-bold text-dark text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>{competitionsTitle}</h5>
              <div className="row">
                <div className="col-12">
                  {
                    competitions && competitions.map((competition, index) => {
                      return (
                        <div className="mb-3" key={index}>
                          <p className="mb-0 fw-bold small">{competition.title}</p>
                          <p className="mb-0 small">{competition.section}</p>
                          <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(competition.startDate) ? format(competition.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}  {(competition.endDate) ? ' - ' + format(competition.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                          <div className="small">{competition.summary}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && competitions === '') || competitions === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCompetitions">
            <div className="mb-1">
              <h5 id="competitions_title" className="fw-bold text-dark text-uppercase text-break ps-2">{competitionsTitle}</h5>
              <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, padding: '1px' }} className="mt-0" />
              <div className="row ps-3">
                <div className="col-12">
                  {
                    competitions && competitions.map((competition, index) => {
                      return (
                        <div className="mb-3" key={index}>
                          <p className="mb-0 fw-bold small">{competition.title}</p>
                          <p className="mb-0 small">{competition.section}</p>
                          <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(competition.startDate) ? format(competition.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}  {(competition.endDate) ? ' - ' + format(competition.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                          <div className="small">{competition.summary}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
  }
  return (
    <>{resumeCompetitionsCard()}</>
  );
}

export default ResumeCompetitions;