import React from 'react';
import { NavLink } from "react-router-dom";
import Navbar from '../../Components/Navbar';


const ResumebuilderCreateOption = () => {
    return (
        <div>
            <Navbar status={true} />
            <div className="main">
                <section className="bg-chose">
                    <div className="container h-100 d-flex justify-content-center">
                        <div className="col-12 vh-100 col-md-8 align-self-center">
                            <p className="fs-5 text-dark text-center mb-5 mt-3 fw-bld">How do you want to start</p>
                            <div className="row">
                                <NavLink className="col-md-6 mb-3 text-decoration-none" to="/rb-template-selection">
                                    <div className="card rounded-4 border-0 shadow bg-transparent-card py-lg-5 py-3">
                                        <div className="card-body text-lg-center d-flex d-lg-block align-items-center">
                                            <img src={process.env.PUBLIC_URL + "/image/create_icon.svg"} width="60" height="60" className="mb-lg-4 me-4 me-lg-0" alt="" />
                                            <div>
                                                <p className="text-uppercase mb-lg-2 mb-0 text-dark fw-bold">CREATE A NEW RESUME</p>
                                                <p className="small text-muted px-lg-5">We will guide you to create a resume step by step</p>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                                <div className="col-md-6">
                                    <div className="card rounded-4 border-0 shadow bg-transparent-card py-lg-5 py-3" >
                                        <div className="card-body text-lg-center d-flex d-lg-block align-items-center">
                                            <img src={process.env.PUBLIC_URL + "/image/cloud_icon.svg"} width="60" height="60" className="mb-lg-4 me-4 me-lg-0" alt="" />
                                            <div>
                                                <p className="text-uppercase mb-lg-2 mb-0 text-dark fw-bold">I ALREADY HAVE A RESUME</p>
                                                <p className="small text-muted px-lg-5">We will guide you to create a resume step by step</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>


    );
};

export default ResumebuilderCreateOption;