import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../Services/Authcontext';
import axiosInstance from '../Services/axios';
import { RESUME_SAVE_API_LINK } from '../Services/commonurls';

const MobileHeader = ({ Title, ProgressBar, StepList, Step, setIsLoading }) => {
    const { dispatch, resumeID, basicInfo, jobSummary, personalInformation, education, workExperience, certificate, skills, competencies, references, languages, resumeSkeleton, section, stepCompleteness, links, resumeTitle, volunteer, honours, achievements, cocurricular, training, competitions, personalProject, hobbies } = useContext(AuthContext);
    const navigate = useNavigate();
    const handleSave = () => {
        document.getElementById("pdf").style.zoom = 'unset';
        setIsLoading(true)
        if (personalInformation.first_name === '' || personalInformation.first_name === null) {
            if (document.getElementById('ResumePersonaldetails')) {
                document.getElementById('ResumePersonaldetails').classList.add('d-none')
            }
        }
        if (jobSummary.profile_summary === '' || jobSummary.profile_summary === null) {
            if (document.getElementById('ResumeJobsummary')) {
                document.getElementById('ResumeJobsummary').classList.add('d-none')
            }
        }
        if (education === '' || education === null) {
            if (document.getElementById('ResumeEducation')) {
                document.getElementById('ResumeEducation').classList.add('d-none')
            }
        }
        if (certificate === '' || certificate === null || certificate.length === 0) {
            if (document.getElementById('ResumeCertifications')) {
                document.getElementById('ResumeCertifications').classList.add('d-none')
            }
        }
        if (cocurricular === '' || cocurricular === null) {
            if (document.getElementById('ResumeCoactivities')) {
                document.getElementById('ResumeCoactivities').classList.add('d-none')
            }
        }
        if (competencies === '' || competencies === null || (competencies.OtherList.length === 0 && competencies.behavioralList.length === 0 && competencies.techList.length === 0)) {
            if (document.getElementById('ResumeCompetencies')) {
                document.getElementById('ResumeCompetencies').classList.add('d-none')
            }
        }
        if (competitions === '' || competitions === null) {
            if (document.getElementById('ResumeCompetitions')) {
                document.getElementById('ResumeCompetitions').classList.add('d-none')
            }
        }
        if (hobbies === '' || hobbies === null) {
            if (document.getElementById('ResumeHobbies')) {
                document.getElementById('ResumeHobbies').classList.add('d-none')
            }
        }
        if (honours === '' || honours === null) {
            if (document.getElementById('ResumeAwards')) {
                document.getElementById('ResumeAwards').classList.add('d-none')
            }
        }
        if (languages === '' || languages === null || languages.length === 0) {
            if (document.getElementById('ResumeLanguage')) {
                document.getElementById('ResumeLanguage').classList.add('d-none')
            }
        }
        if (personalProject === '' || personalProject === null) {
            if (document.getElementById('ResumePersonalprojects')) {
                document.getElementById('ResumePersonalprojects').classList.add('d-none')
            }
        }
        if (references === '' || references === null || references.length === 0) {
            if (document.getElementById('ResumeReference')) {
                document.getElementById('ResumeReference').classList.add('d-none')
            }
        }
        if (training === '' || training === null) {
            if (document.getElementById('ResumeTraining')) {
                document.getElementById('ResumeTraining').classList.add('d-none')
            }
        }
        if (volunteer === '' || volunteer === null) {
            if (document.getElementById('ResumeVolunteer')) {
                document.getElementById('ResumeVolunteer').classList.add('d-none')
            }
        }
        if (skills === '' || skills === null || (skills.FunctionSkillList.length === 0 && skills.OtherSkillList.length === 0 && skills.TechSkillList.length === 0)) {
            if (document.getElementById('ResumeSkills')) {
                document.getElementById('ResumeSkills').classList.add('d-none')
            }
        }
        if (achievements === '' || achievements === null) {
            if (document.getElementById('ResumeAchievements')) {
                document.getElementById('ResumeAchievements').classList.add('d-none')
            }
        }
        if (workExperience === '' || workExperience === null) {
            if (document.getElementById('ResumeExperience')) {
                document.getElementById('ResumeExperience').classList.add('d-none')
            }
        }
        if (links === '' || links === null || links.length === 0) {
            if (document.getElementById('ResumeLinks')) {
                document.getElementById('ResumeLinks').classList.add('d-none')
            }
        }
        dispatch({
            type: 'ADD_RESUME_DATA',
            payload: document.querySelector('#mainContainer').innerHTML
        })
        if (Number(Step) === 10) {
            for (var key in stepCompleteness) {
                if (stepCompleteness[key]['resume_step_id'] === Number(Step)) {
                    stepCompleteness[key]['step_status'] = true;
                }
            }
        }
        localStorage.setItem("PDF", document.querySelector('#mainContainer').innerHTML)
        
        const data = {
            "resume_id": resumeID,
            "resume_document_html": document.querySelector('#mainContainer').innerHTML,
            "basic_info": {
                "job_family": basicInfo.job_family,
                "job_group": basicInfo.job_group,
                "job_type": basicInfo.job_type,
                "preferred_data_format": basicInfo.preferred_data_format,
                "resume_title": resumeTitle,
            },
            "personal_info": personalInformation,

            "profile_summary": {
                "profile_summary": jobSummary.profile_summary
            },
            "work_experience": {
                "work_experience": workExperience
            },
            "education": {
                "education": education
            },
            "certifications": {
                "certification": certificate
            },
            "skills": {
                "skill": skills
            },
            "competencies": {
                "competency": competencies
            },
            "reference": {
                "reference": references
            },
            "language": {
                "language": languages
            },
            "others": {
                "award": honours,
                "volunteering": volunteer,
                "achievement": achievements,
                "hobbies": hobbies,
                "personal_project": personalProject,
                "co_curricular": cocurricular,
                "competitions": competitions,
                "training": training,
                "links": links
            },
            "step_completeness": stepCompleteness,
            "resume_skeleton": {
                "styles": resumeSkeleton.styles,
                "sections": section
            },
        }

        axiosInstance.post(RESUME_SAVE_API_LINK, data).then((res) => {
            if (res.status === 200) {
                setIsLoading(false)
                navigate('/success')
            }
        });
    }

    return (
        <>
            <div className='d-lg-none w-100'>
                <div className="row align-items-center my-4">
                    <div className="col-3">
                        <div className="position-relative d-flex justify-content-center align-items-center flexDirection">
                            <div className="mobile_progress">
                                <div id="middle-circle-mob">{ProgressBar}%</div>
                                <div id="progress-spinner-mob" style={{ background: `conic-gradient(#61C33E ${ProgressBar}%,rgb(242, 242, 242) ${ProgressBar}%)` }}>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 px-1">
                        <h6 className="text-center mb-1">{Title}</h6>
                        <div className="navlink-button mb-0">
                            {StepList && StepList.map((steps, index) => {
                                return <button key={index} type="button" className={(Step === steps.id) ? "mob-btn active" : "mob-btn"}></button>
                            })}
                            
                        </div>
                    </div>
                    <div className="col-2 text-center icons_size cursor-pointer" onClick={handleSave}>
                        <i className="bi bi-file-text-fill"></i>
                    </div>
                    <div style={{ display: "contents" }} className='col-1 cursor-pointer' data-bs-toggle="modal" data-bs-target="#closeAlertModal">
                        <div className="icons_size">
                            <i className="bi bi-x-lg"></i>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default MobileHeader