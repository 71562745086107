import React, { useEffect } from 'react'
import Navbar from './Navbar'
import $ from "jquery";
import { Markup } from 'interweave'


const Preview = () => {
    var max_pages = 10;
    var page_count = 0;
    let resumeType = 2;

    function pageBreak() {
        orderWorkExperience();
        if (resumeType === 2) {
            $('.rightColumn').append(detachChild('#RIGHT'));
            $('.leftColumn').each(function () {
                snipMe.call(this, 'LEFT', 'leftColumn');
            });
            $('.rightColumn').each(function () {
                snipMe.call(this, 'RIGHT', 'rightColumn');
            });

            let leftColumnSize = $('.leftColumn').length;
            let rightColumnSize = $('.rightColumn').length;

            if (leftColumnSize >= rightColumnSize) {
                reArrange1(rightColumnSize, '.rightColumn', '.leftColumn', true);
            } else {
                reArrange1(rightColumnSize, '.leftColumn', '.rightColumn', false);
            }
        } else {
            $('.leftColumn').each(function () {
                snipMe.call(this, 'LEFT', 'leftColumn');
            });

        }
    }

    function reArrange1(itx, source, destination, isAppend) {
        for (let i = 0; i < itx; i++) {
            let child = $($(source)[i]).children();
            child.detach();
            if (isAppend === true) {
                $($(destination)[i]).append($(child));
            } else {
                $($(destination)[i]).prepend($(child));
            }
        }
        $(source).detach();
    }

    function detachChild(id) {
        var childs = $('.A4').children().filter(id);
        return $(childs).detach();
    }


    function orderWorkExperience() {
        let workExpDivComponents = $('.A4').children().children('#ResumeExperience');
        let childs = workExpDivComponents.children().filter('.row');
        let removedWorkExp = [];
        childs.each(function (idx, ele) {
            if (idx > 0) {
                let newli = $('<li class="resume_section list-group ui-sortable-handle" draggable="true" ></li>');
                newli.append($(ele).clone());
                removedWorkExp.push(newli);
                $(ele).detach();
                $(workExpDivComponents).after(newli);
            }
        });
    }

    function snipMe(id, customClass) {
        page_count++;
        if (page_count > max_pages) {
            return;
        }
        var leftLong = $(this)[0].scrollHeight - Math.ceil($(this).innerHeight());
        let parent = $(this).children().filter('#' + id);
        let className = $(parent).prop('class');
        let styleText = $(parent).prop('style').cssText;
        var leftChildren = $(this).children().filter('#' + id).children().toArray();
        var leftRemoved = [];
        while (leftLong > 0 && leftChildren.length > 0) {
            var child = leftChildren.pop();
            $(child).detach();
            leftRemoved.unshift(child);
            leftLong = $(this)[0].scrollHeight - Math.ceil($(this).innerHeight());
        }

        let parentClassName = "row A4 " + customClass;

        if (leftRemoved.length > 0) {
            var a4 = $('<div class="' + parentClassName + '" style="font-family: Arial, Helvetica, sans-serif;"></div>');
            var ulLeftEl = $('<ul class="' + className + '" id="' + id + '" style="' + styleText + ';"></ul>');
            a4.append(ulLeftEl);
            ulLeftEl.append(leftRemoved);
            $(this).after(a4);
            snipMe.call(a4[0], id, customClass);
        }
    }

    useEffect(() => {
        pageBreak();

    }, [])

    return (
        <>
            <Navbar status={true} userDetails='' />
            <div className="main">
                <div className="container mt-6 p-0">
                    <div className="row mx-0">
                        <div className="col-lg-12 bgab py-4">
                            <div className="d-flex justify-content-center" id='preview'>
                                <div className="col-12">
                                    <Markup content={localStorage.getItem('PDF')} />
                                    <div className="row A4 rightColumn">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Preview