import React from 'react';
import { withRouter } from './WithRouter';
import { AuthContext } from '../Services/Authcontext';
import TitleCard from './TitleCard';


class Links extends React.Component {
    static contextType = AuthContext
    constructor(props) {
        super(props)
        this.state = {
            isValid: false,
            isEdit: false,
            showForm: false,
            header: 'Hobbies',
            hobbiesList: [],
        }
        this.handleForm = this.handleForm.bind(this);
        this.handleTechChange = this.handleTechChange.bind(this);
        this.addTechFormFields = this.addTechFormFields.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleTechChange(i, e) {
        let hobbiesList = this.state.hobbiesList;
        hobbiesList[i][e.target.name] = e.target.value;
        this.setState({ hobbiesList });
    }

    addTechFormFields(name) {
        this.setState(({
            hobbiesList: [...this.state.hobbiesList, { title: "" }]
        }))
    }

    removeTechFormFields(i) {
        let hobbiesList = this.state.hobbiesList;
        hobbiesList.splice(i, 1);
        this.setState({ hobbiesList });
    }

    handleForm() {
        this.setState({ showForm: !this.state.showForm })
    }

    handleSubmit(e, formType) {
        e.preventDefault();
        const { dispatch } = this.context;
        if (formType === 'add') {
        } else {
            this.setState({ isEdit: !this.state.isEdit })
        }
        this.setState({ showForm: !this.state.showForm })
        setTimeout(() => {
            dispatch({
                type: 'ADD_HOBBIE',
                // eslint-disable-next-line
                payload: this.state.hobbiesList.filter((hobbiesList) => { if (hobbiesList.title !== '' && hobbiesList.title !== undefined) { return hobbiesList } })
            })
            dispatch({
                type: 'HOBBIE_HEADER',
                payload: this.state.header
            })
        }, 1000)
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ header: this.context.hobbiesTitle })
        if (this.context.hobbies && this.context.hobbies !== '' && this.context.hobbies !== null) {
            this.setState({ hobbiesList: this.context.hobbies })
        }
    }

    render() {
        return (
            <>

                <div id="hobbies_add" className={(this.props.Display) ? 'mt-4' : "d-none"}>
                    <form onSubmit={(e) => { this.handleSubmit(e, (this.state.isEdit) ? 'edit' : 'add') }} method="post" className={(this.state.showForm) ? "mt-4" : 'd-none'}>
                        {(this.state.showForm) ? '' : this.state.header}
                        <div className="row mx-lg-0 p-0 m-0 pt-3">
                            <div className="p-0 m-0" id="new_add_links">
                                <div className="form-floating mb-3">
                                    <input type="text" name="header_title" spellCheck={true} value={this.state.header}
                                        onChange={(e) => {
                                            this.setState({ header: e.target.value });
                                            document.getElementById('hobbie_title').innerHTML = e.target.value
                                        }} id="hobbie_header_title"
                                        className="form-control" />
                                    <label htmlFor="header_title">Title</label>
                                </div>
                                {this.state.hobbiesList.map((element, index) => (
                                    <div className="mt-3 row p-0 m-0 mx-lg-0 add_links" key={index}>
                                        <div className="col-10 ps-0 pe-1">
                                            <input type="text" name="title" spellCheck={true} id={`title_${element.title}`} value={element.title} onChange={(e) => { this.handleTechChange(index, e) }} className="form-control"
                                                placeholder="Section Name" />
                                        </div>
                                        <button type="button" className="col-2 del_link btn" onClick={() => this.removeTechFormFields(index)}><i className="bi bi-trash"></i></button>

                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="d-grid mb-3 add_links">
                            <button className=" btn mt-3 add_job_btn add_links" type='button' id="add_links"
                                onClick={() => this.addTechFormFields()}><i className="bi bi-plus"></i> add hobbies</button>
                        </div>
                        <div className="row mb-4">
                            <div className="col ms-2">
                                <button className="btn btn-outline-primary mt-3" type="button" onClick={this.handleForm}>Cancel</button>
                            </div>

                            <div className="col">
                                <button className="btn btn-primary mt-3 float-end" id="links_done_btn" type="submit">{(this.state.isEdit) ? 'Add' : 'Add'}</button>
                            </div>
                        </div>
                    </form>
                    <div className={(this.state.showForm) ? "d-none" : ''}>
                        <TitleCard Title={this.state.header} Section={'Section Name'} Description={'Hobbies'} />
                        {
                            this.state.hobbiesList && this.state.hobbiesList.map((link, index) => {
                                return <LinkListCard key={index} handleDelete={this.handlePersonalDelete} handleEdit={this.handlePersonalEdit} link={link} />
                            })
                        }
                    </div>
                    <div id="links" className={(this.state.showForm) ? "d-none" : ''}>
                        <div className="row mb-4 mt-3">
                            <div className="col">
                            </div>
                            <div className="col text-end">
                                <button type="button" className="btn add_btn" id="links_add_btn" onClick={this.handleForm}><i
                                    className="bi bi-plus"></i> add</button>

                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    };
}

const LinkListCard = ({ link }) => {
    return (
        <>
            <div className='card my-3'>
                <div className='card-body'>
                    <p className="mb-0">{link.title}</p>
                </div>
            </div>
        </>
    )
}

export default withRouter(Links);