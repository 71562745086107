import React, { } from 'react';
import { NavLink } from 'react-router-dom';
import RightSideResume from './RightSideResume';
import { withRouter } from './WithRouter';
import { Rating } from 'react-simple-star-rating';
import { AuthContext } from '../Services/Authcontext';
import NotificationCard from './NotificationCard';
import LeftHideButton from './LeftHideButton';
import axiosInstance from '../Services/axios';
import MobileHeader from './MobileHeader';
import video from '../image/animation/competencies.webm'
import { RESUME_DATA_CONTENT_API_LINK } from '../Services/commonurls';


class Competencies extends React.Component {
    static contextType = AuthContext

    constructor(props) {
        super(props)
        this.state = {
            behavioralList: [],
            techList: [],
            otherList: [],
            competenciesList: [],
            isValid: false,
            behavioralSuggestions: null,
            techSuggestions: null,
            otherSuggestions: null,
            competencyEnable: null,
        }
        this.handleBehavChange = this.handleBehavChange.bind(this);
        this.handleBehavRating = this.handleBehavRating.bind(this);
        this.addBehavFormFields = this.addBehavFormFields.bind(this);
        this.handleTechChange = this.handleTechChange.bind(this);
        this.handleTechRating = this.handleTechRating.bind(this);
        this.addTechFormFields = this.addTechFormFields.bind(this);
        this.handleOtherChange = this.handleOtherChange.bind(this);
        this.handleOtherRating = this.handleOtherRating.bind(this);
        this.addOtherFormFields = this.addOtherFormFields.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleBehavChange(i, e) {
        let behavioralList = this.state.behavioralList;
        behavioralList[i][e.target.name] = e.target.value;
        this.setState({ behavioralList });
    }

    handleBehavRating(i, rate) {
        // eslint-disable-next-line
        this.state.behavioralList[i]["rating"] = parseInt(rate) / 20;

    }

    addBehavFormFields(name) {
        this.setState(({
            behavioralList: [...this.state.behavioralList, { rating: 0, skill: name }]
        }))
    }

    removeBehavFormFields(i) {
        let behavioralList = this.state.behavioralList;
        behavioralList.splice(i, 1);
        this.setState({ behavioralList });
    }

    handleTechChange(i, e) {
        let techList = this.state.techList;
        techList[i][e.target.name] = e.target.value;
        this.setState({ techList });
    }

    handleTechRating(i, rate) {
        // eslint-disable-next-line
        this.state.techList[i]["rating"] = parseInt(rate) / 20;
    }


    addTechFormFields(name) {
        this.setState(({
            techList: [...this.state.techList, { rating: 0, skill: name }]
        }))
    }

    removeTechFormFields(i) {
        let techList = this.state.techList;
        techList.splice(i, 1);
        this.setState({ techList });
    }
    handleOtherChange(i, e) {
        let otherList = this.state.otherList;
        otherList[i][e.target.name] = e.target.value;
        this.setState({ otherList });
    }

    handleOtherRating(i, rate) {
        // eslint-disable-next-line
        this.state.otherList[i]["rating"] = parseInt(rate) / 20;
    }


    addOtherFormFields(name) {
        this.setState(({
            otherList: [...this.state.otherList, { rating: 0, skill: name }]
        }))
    }

    removeOtherFormFields(i) {
        let otherList = this.state.otherList;
        otherList.splice(i, 1);
        this.setState({ otherList });
    }
    // eslint-disable-next-line
    handleFetchData = async () => {
        await axiosInstance.get(`${RESUME_DATA_CONTENT_API_LINK}?data_name=competencies&job_family=${this.context.basicInfo.job_family}`)
            .then((res) => {
                // eslint-disable-next-line
                res.data.data.results.map((suggestion, index) => {
                    if (suggestion.competency_type === "BEHAVIORAL COMPETENCIES") {
                        this.setState({ behavioralSuggestions: suggestion.competency_choices })
                    } else if (suggestion.competency_type === "TECHNICAL COMPETENCIES") {
                        this.setState({ techSuggestions: suggestion.competency_choices })
                    } else if (suggestion.competency_type === "OTHER COMPETENCIES") {
                        this.setState({ otherSuggestions: suggestion.competency_choices })
                    }
                })
            });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { dispatch } = this.context;
        dispatch({
            type: 'DATA_UPDATED',
            payload: true
        })
        // eslint-disable-next-line
        this.setState({
            // eslint-disable-next-line
            competenciesList: {
                // eslint-disable-next-line
                behavioralList: this.state.behavioralList.filter((skillsList) => { if (skillsList.skill !== '' && skillsList.skill !== 'undefined' && skillsList.skill !== undefined) { return skillsList } }),
                // eslint-disable-next-line
                techList: this.state.techList.filter((skillsList) => { if (skillsList.skill !== '' && skillsList.skill !== 'undefined' && skillsList.skill !== undefined) { return skillsList } }),
                // eslint-disable-next-line
                OtherList: this.state.otherList.filter((skillsList) => { if (skillsList.skill !== '' && skillsList.skill !== 'undefined' && skillsList.skill !== undefined) { return skillsList } }),
            }
        })
        setTimeout(() => {
            dispatch({
                type: "ADD_COMPETENCIES",
                payload: this.state.competenciesList
            });
        }, 1000)
        for (var key in this.context.stepCompleteness) {
            if (this.context.stepCompleteness[key]['resume_step_id'] === Number(this.props.step)) {
                this.context.stepCompleteness[key]['step_status'] = true;
            }
        }
        this.props.navigate(`/rb-step?step=${Number(this.props.step) + 1}`)

    }


    componentDidMount() {
        window.scrollTo(0, 0);
        this.handleFetchData();
        document.querySelector('.slider').classList.toggle('close');
        this.setState({ competencyEnable: this.context.competencyEnable })
        if (this.context.competencies) {
            this.setState({ behavioralList: this.context.competencies.behavioralList })
            this.setState({ techList: this.context.competencies.techList })
            this.setState({ otherList: this.context.competencies.OtherList })
        }
    }

    render() {
        const step = Number(this.props.step)
        return (
            <>
                <MobileHeader setIsLoading={this.props.setIsLoading} Title={"Competencies"} ProgressBar={this.props.progressBar} StepList={this.props.stepList} Step={Number(this.props.step)} />

                <div className="row ms-lg-0">
                    <div className="col-md-12 col-lg-5 bg-white position-relative p-lg-4" id="id_left">
                        <LeftHideButton rightHide={this.props.rightHide} rightShow={this.props.rightShow} />
                        <div className="" id="id_left_side">
                            <NotificationCard
                                short_notes={
                                    <>
                                        <p className='mb-1 small'>A competency is a broader concept that combines skills with knowledge and behaviour.</p>
                                    </>
                                }
                                Notes={
                                    <>
                                        <p className='mb-1 small'>A competency is a broader concept that combines skills with knowledge and behaviour.</p>
                                        <p className='mb-1 small'>This section is crucial for employers to pick suitable candidates so utilises this space to point out
                                            your core competencies. Try to focus on your strength and keep the number of competencies to max
                                            of Six</p>
                                    </>
                                } video={video} />
                            <form onSubmit={this.handleSubmit} method="post" className="mt-4">
                                {(this.state.isValid) ? <p className='text-danger'>Please fill the field.</p> : ''}
                                <div id="job_view">
                                    <p className='mb-0 text-muted small text-uppercase'>Behavioral Competencies</p>
                                    <div className="row mx-lg-0 m-0 pt-3">
                                        <div className="px-0">
                                            {
                                                this.state.behavioralSuggestions && this.state.behavioralSuggestions.map((suggestion, index) => {
                                                    return <Suggestion key={index} name={suggestion.name} addFormFields={this.addBehavFormFields} />
                                                })
                                            }
                                        </div>
                                        <div className="new_add_skill_tech px-0" id="new_add_skill_tech">
                                            <div className="mt-3 row mx-lg-0 add_skills">
                                                {this.state.behavioralList.map((element, index) => (
                                                    <div className="mt-3 row p-0 m-0 mx-lg-0 add_skills" key={index}>
                                                        <div className="col-5 col-lg-6 col-xl-5 px-0">
                                                            <Rating ratingValue={element.rating * 20} initialValue={element.rating * 20} onClick={(e) => { this.handleBehavRating(index, e) }} size={25} />
                                                        </div>
                                                        <div className="col-5 col-lg-4 col-xl-5 px-0">
                                                            <input type="text" name="skill" id={`skill_${element.skill}`} value={element.skill} onChange={(e) => { this.handleBehavChange(index, e) }} className="resume_skills_input form-control"
                                                                placeholder="add competencies" />
                                                        </div>
                                                        <button type="button" className="col-2 del_skills" onClick={() => this.removeBehavFormFields(index)}><i className="bi bi-trash"></i></button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        <button type='button' className="my-3 add_job_btn add_competencies_behav btn" id="add_competencies_behav"
                                            onClick={() => this.addBehavFormFields()}><i className="bi bi-plus"></i> add Competencies</button>
                                    </div>
                                    <p className='mb-0 text-muted small text-uppercase'>Technical Competencies</p>
                                    <div className="row m-0 mx-lg-0 pt-3">
                                        <div className="px-0">
                                            {
                                                this.state.techSuggestions && this.state.techSuggestions.map((suggestion, index) => {
                                                    return <Suggestion key={index} name={suggestion.name} addFormFields={this.addTechFormFields} />
                                                })
                                            }
                                        </div>
                                        <div className="new_add_skill_func p-0" id="new_add_skill_func">
                                            <div className="mt-3 row mx-lg-0">
                                                {this.state.techList.map((element, index) => (
                                                    <div className="mt-3 row p-0 m-0 mx-lg-0 add_skills" key={index}>
                                                        <div className="col-5 col-lg-6 col-xl-5 px-0">
                                                            <Rating ratingValue={element.rating * 20} initialValue={element.rating * 20} onClick={(e) => { this.handleTechRating(index, e) }} size={25} />
                                                        </div>
                                                        <div className="col-5 col-lg-4 col-xl-5 px-0">
                                                            <input type="text" name="skill" id={`skill_${element.skill}`} value={element.skill} onChange={(e) => { this.handleTechChange(index, e) }} className="resume_skills_input form-control"
                                                                placeholder="add competencies" />
                                                        </div>
                                                        <button type="button" className="col-2 del_skills" onClick={() => this.removeTechFormFields(index)}><i className="bi bi-trash"></i></button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        <button type='button' className="my-3 add_job_btn add_competencies_tech btn" id="add_competencies_tech"
                                            onClick={() => this.addTechFormFields()}><i className="bi bi-plus"></i> add Competencies</button>
                                    </div>
                                    <p className='mb-0 text-muted small text-uppercase'>Others Competencies</p>
                                    <div className="row mx-lg-0 m-0 pt-3">
                                        <div className="px-0">
                                            {
                                                this.state.otherSuggestions && this.state.otherSuggestions.map((suggestion, index) => {
                                                    return <Suggestion key={index} name={suggestion.name} addFormFields={this.addOtherFormFields} />
                                                })
                                            }
                                        </div>
                                        <div className="new_add_skill_other  p-0" id="new_add_skill_other">
                                            <div className="mt-3 row mx-lg-0">
                                                {this.state.otherList.map((element, index) => (
                                                    <div className="mt-3 row p-0 m-0 mx-lg-0 add_skills" key={index}>
                                                        <div className="col-5 col-lg-6 col-xl-5 px-0">
                                                            <Rating ratingValue={element.rating * 20} initialValue={element.rating * 20} onClick={(e) => { this.handleOtherRating(index, e) }} size={25} />
                                                        </div>
                                                        <div className="col-5 col-lg-4 col-xl-5 px-0">
                                                            <input type="text" name="skill" id={`skill_${element.skill}`} value={element.skill} onChange={(e) => { this.handleOtherChange(index, e) }} className="resume_skills_input form-control"
                                                                placeholder="add competencies" />
                                                        </div>
                                                        <button type="button" className="col-2 del_skills" onClick={() => this.removeOtherFormFields(index)}><i className="bi bi-trash"></i></button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0">
                                        <button type='button' className="my-3 add_job_btn add_skills_other btn" id="add_skills_other"
                                            onClick={() => this.addOtherFormFields()}><i className="bi bi-plus"></i> add Competencies</button>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="enableSkill" aria-checked={this.state.competencyEnable}
                                            onChange={(e) => {
                                                this.setState({ competencyEnable: e.target.checked })
                                                const { dispatch } = this.context;
                                                dispatch({
                                                    type: "COMPETENCIES_ENABLED",
                                                    payload: e.target.checked
                                                })
                                            }
                                            } checked={this.state.competencyEnable} />
                                        <label className="form-check-label" htmlFor="enableSkill">Enable Level</label>
                                    </div>
                                    <div className="row mx-lg-0 mb-4">
                                        <div className="col">
                                            <NavLink to={`/rb-step?step=${step - 1}`} onClick={() => { this.props.setProgressBar(60) }}>
                                                <button className="btn btn-outline-primary mt-3">Back</button>
                                            </NavLink>
                                        </div>

                                        <div className="col">
                                            <button className="btn btn-primary mt-3 float-end" type='submit'>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='d-none'>
                        <div className="d-flex align-items-center mt-2 mb-3 slider close hi-popper-bg">

                            <div className="topright"><span id="hi_pop_close" onClick={sliderclose}><i
                                className="bi bi-x-lg"></i></span>
                            </div>
                            <div className="col-4 mt-5">
                                <img src={process.env.PUBLIC_URL + "/image/hi.png"} width="100" alt='hi' />
                            </div>

                            <div className="col-8 mt-5">
                                <div className="card border-0 m-3" data-popper-placement="right">
                                    <div className="bubble position-relative"></div>
                                    <div className="card-body bg-transparent">
                                        <p>Highlight 6-8 Competencies that are most relevant to your desired job.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <RightSideResume />
                </div>
            </>
        );
    }
}

const Suggestion = ({ name, addFormFields }) => {
    return (
        <>
            <span className="badge alert-secondary px-2 m-1 d-inline-block cursor-pointer" onClick={() => { addFormFields(name) }}>{name}</span>
        </>
    )
}


const sliderclose = () => {
    document.querySelector('.slider').classList.toggle('close');
}

export default withRouter(Competencies);