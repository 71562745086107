import React from 'react';

const Loading = () => {

    return (
        <>
            <section className="centered_loading">
                <div className="text-center">
                    <img src="/image/logo_icon.svg" alt='logo' />
                </div>
                <div className="row py-3 pt-5">
                    <h6 className="text-center">PLEASE WAIT</h6>
                    <p className="text-center text-muted small mb-0">We are setting up things for you</p>
                </div>
                <div className="row py-3">
                    <div className='col-12 text-center'>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}



export default Loading;