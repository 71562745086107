import React from 'react'
import { Link } from 'react-router-dom'

const Error500 = () => {
    return (
        <>
            <div className="container h-100 d-flex justify-content-center">
                <div className="align-self-center text-center p-5">
                    <i className="bi bi-info-circle rounded-circle text-primary display-6"></i>
                    <p className='display-6 my-5'>Oops! Something went <br/> wrong.</p>
                    <Link to={'/myresume'} className="btn btn-primary">Home</Link>
                </div>
            </div>
        </>
    )
}

export default Error500