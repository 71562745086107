import React, { useContext, useEffect, useRef } from 'react'
import ResumeCertifications from '../Resume/ResumeCertifications';
import ResumeEducation from '../Resume/ResumeEducation';
import ResumeExperience from '../Resume/ResumeExperience';
import ResumeJobsummary from '../Resume/ResumeJobsummary';
import ResumeLanguage from '../Resume/ResumeLanguage';
import ResumePersonalDetails from '../Resume/ResumePersonalDetails';
import ResumeProfilePhoto from '../Resume/ResumeProfilePhoto';
import ResumeSkills from '../Resume/ResumeSkills';
import ResumeTitle from '../Resume/ResumeTitle';
import ResumeCompetencies from '../Resume/ResumeCompetencies'
import ResumeCompetitions from '../Resume/ResumeCompetitions'
import ResumeReference from '../Resume/ResumeReference'
import ResumeAchievements from '../Resume/ResumeAchievements'
import ResumeAwards from '../Resume/ResumeAwards'
import ResumePersonalprojects from '../Resume/ResumePersonalprojects'

import { AuthContext } from '../Services/Authcontext';
import ResumeCoactivities from '../Resume/ResumeCoactivities';
import ResumeVolunteer from '../Resume/ResumeVolunteer';
import ResumeHobbies from '../Resume/ResumeHobbies';
import ResumeTraining from '../Resume/ResumeTraining';
import ResumeLinks from '../Resume/ResumeLinks';
import ResumeHeader from '../Resume/ResumeHeader';

const ResumeTemplates = ({ suffle, flip }) => {

    const { resumeTemplate, dispatch, resumeSkeleton } = useContext(AuthContext);
    const borderColor = useRef(null);
    const fontFamily = (resumeSkeleton) ? resumeSkeleton.styles.section_title_font_family : ''
    const borderColorImg = (resumeSkeleton) ? `'${resumeSkeleton.styles.theme_color}'` : ''

    // if (resumeSkeleton && resumeSkeleton.sections.right_sec && resumeSkeleton.sections.right_sec.length > 0 && resumeSkeleton.sections.right_sec[0].template_type === "exec_pro") {
    //     const listData = document.querySelectorAll('#RIGHT li');
    //     listData.forEach((item) => {
    //         document.getElementById(item.id).style.padding = '7px';
    //     })
    // }

    const format = (input, type) => {
        // eslint-disable-next-line
        var pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
        if (!input || !input.match(pattern)) {
            return null;
        }
        if (type === "dd/mm/yyyy") {
            if (document.getElementById("dd")) {
                document.getElementById("dd").classList.add('text-success')
                document.getElementById("mm").classList.remove('text-success')
                document.getElementById("yyyy").classList.remove('text-success')
            }
            return input.replace(pattern, '$3/$2/$1');
        } else if (type === "mm/dd/yyyy") {
            if (document.getElementById("mm")) {
                document.getElementById("dd").classList.remove('text-success')
                document.getElementById("mm").classList.add('text-success')
                document.getElementById("yyyy").classList.remove('text-success')
            }
            return input.replace(pattern, '$2/$3/$1');
        } else if (type === "yyyy/mm/dd") {
            if (document.getElementById("yyyy")) {
                document.getElementById("dd").classList.remove('text-success')
                document.getElementById("mm").classList.remove('text-success')
                document.getElementById("yyyy").classList.add('text-success')
            }
            return input.replace(pattern, '$1/$2/$3');
        }
        else {
            return input;
        }
    };

    useEffect(() => {

        if (resumeTemplate) {
            dispatch({
                type: 'ADD_RESUMEID',
                payload: resumeTemplate.resume_id
            });
        }
    }, [])


    const skeleton = (position) => {
        if (position) {
            if (resumeSkeleton) {
                // eslint-disable-next-line
                return position.map((section, index) => {
                    switch (section.section_name) {
                        case 'basic_info':
                            return <ResumeTitle sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'header':
                            return <ResumeProfilePhoto sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'contact':
                            return <ResumePersonalDetails sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'professional_summary':
                            return <ResumeJobsummary sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'profile_summary':
                            return <ResumeJobsummary sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'work_experience':
                            return <ResumeExperience sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'education':
                            return <ResumeEducation sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'competencies':
                            return <ResumeCompetencies sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'language':
                            return <ResumeLanguage sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'skills':
                            return <ResumeSkills sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'reference':
                            return <ResumeReference sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'certifications':
                            return <ResumeCertifications sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'volunteering':
                            return <ResumeVolunteer sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'achievement':
                            return <ResumeAchievements sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'award':
                            return <ResumeAwards sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'hobbies':
                            return <ResumeHobbies sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'competitions':
                            return <ResumeCompetitions sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'co_curricular':
                            return <ResumeCoactivities sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'personal_project':
                            return <ResumePersonalprojects sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'training':
                            return <ResumeTraining sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        case 'links':
                            return <ResumeLinks sections={section} templateType={section.template_type} suffle={suffle} flip={flip} key={index} format={format} />
                        default:
                            break;
                    }
                })
            }
        }
    }

    const layoutStyle = (layoutType, templateName) => {
        switch (templateName) {
            case 'ats_pro':
                return (
                    <>
                        <ul className={(resumeSkeleton.styles.layout_flip_enabled) ? "connectedSortable left_list mb-0 pt-2 px-0" : "connectedSortable left_list mb-0 pt-2 px-0 order-2"} style={(resumeSkeleton.styles.theme_color === '#fff') ? { backgroundColor: resumeSkeleton.styles.theme_color, color: '#5a5a5a', width: `${resumeSkeleton.sections.col_1}%` } : { backgroundColor: resumeSkeleton.styles.theme_color, color: 'white', width: `${resumeSkeleton.sections.col_1}%` }} id={(resumeSkeleton.styles.layout_flip_enabled) ? "RIGHT": "LEFT"} >
                            {skeleton(resumeSkeleton.sections.left_sec)}
                        </ul>
                        <ul style={{ width: `${resumeSkeleton.sections.col_2}%` }} className={(resumeSkeleton.styles.layout_flip_enabled) ? "connectedSortable right_list mb-0 pt- pe-0" : "connectedSortable right_list mb-0 pt- ps-0"} id={(resumeSkeleton.styles.layout_flip_enabled) ? "LEFT": "RIGHT"} >
                            {skeleton(resumeSkeleton.sections.right_sec)}
                        </ul>
                    </>
                );
            case 'exec_std':
                return (
                    <>
                        <ul className={(resumeSkeleton.styles.layout_flip_enabled) ? "connectedSortable left_list mb-0 pt-4" : "connectedSortable left_list mb-0 pt-4 order-2"} style={{ width: `${resumeSkeleton.sections.col_1}%` }} id={(resumeSkeleton.styles.layout_flip_enabled) ? "RIGHT": "LEFT"} >
                            {skeleton(resumeSkeleton.sections.left_sec)}
                        </ul>
                        <ul style={{ width: `${resumeSkeleton.sections.col_2}%` }} className={(resumeSkeleton.styles.layout_flip_enabled) ? "connectedSortable right_list pt-4" : "connectedSortable right_list pt-4"} id={(resumeSkeleton.styles.layout_flip_enabled) ? "LEFT": "RIGHT"} >
                            {skeleton(resumeSkeleton.sections.right_sec)}
                        </ul>
                    </>
                );
            case 'modern_pro':
                return (
                    <>
                        <ResumeHeader templateType={templateName} />
                        <ul style={{ width: `${resumeSkeleton.sections.col_1}%` }} className={(resumeSkeleton.styles.layout_flip_enabled) ? "connectedSortable left_list mb-0" : "connectedSortable left_list mb-0 border-start border-2 order-2"} id={(resumeSkeleton.styles.layout_flip_enabled) ? "RIGHT": "LEFT"} >
                            {skeleton(resumeSkeleton.sections.left_sec)}
                        </ul>

                        <ul className={(resumeSkeleton.styles.layout_flip_enabled) ? "connectedSortable right_list mb-0 border-start border-2" : "connectedSortable right_list mb-0 "} id={(resumeSkeleton.styles.layout_flip_enabled) ? "LEFT": "RIGHT"} style={{ borderColor: borderColorImg, width: `${resumeSkeleton.sections.col_2}%` }} ref={borderColor}>
                            {skeleton(resumeSkeleton.sections.right_sec)}
                        </ul>
                    </>
                );
            case 'modern_classic':
                return (<>
                    <ResumeHeader templateType={templateName} />
                    <ul className={(resumeSkeleton.styles.layout_flip_enabled) ? "connectedSortable left_list mb-0" : "connectedSortable left_list mb-0 order-2"} style={{ width: `${resumeSkeleton.sections.col_1}%`, fontFamily: 'Noto Serif Georgian, serif' }} id={(resumeSkeleton.styles.layout_flip_enabled) ? "RIGHT": "LEFT"} >
                        {skeleton(resumeSkeleton.sections.left_sec)}
                    </ul>
                    <ul className={(resumeSkeleton.styles.layout_flip_enabled) ? "connectedSortable right_list mb-0 pt- pe-0" : "connectedSortable right_list mb-0 pt- "} style={{ width: `${resumeSkeleton.sections.col_2}%`, fontFamily: 'Noto Serif Georgian, serif' }} id={(resumeSkeleton.styles.layout_flip_enabled) ? "LEFT": "RIGHT"} >
                        {skeleton(resumeSkeleton.sections.right_sec)}
                    </ul>
                </>);
            case 'modern_std':
                return (<>
                    <ResumeHeader templateType={templateName} />
                    <ul className={(resumeSkeleton.styles.layout_flip_enabled) ? "connectedSortable left_list mb-0" : "connectedSortable left_list mb-0 order-2"} style={{ width: `${resumeSkeleton.sections.col_1}%` }} id={(resumeSkeleton.styles.layout_flip_enabled) ? "RIGHT": "LEFT"} >
                        {skeleton(resumeSkeleton.sections.left_sec)}
                    </ul>
                    <ul className="connectedSortable right_list mb-0" style={{ width: `${resumeSkeleton.sections.col_2}%` }} id={(resumeSkeleton.styles.layout_flip_enabled) ? "LEFT": "RIGHT"} >
                        {skeleton(resumeSkeleton.sections.right_sec)}
                    </ul>
                </>);
            case 'exec_classic':
                return (<>
                    <ResumeHeader templateType={templateName} />
                    <ul style={{ width: `${resumeSkeleton.sections.col_1}%` }} className={(resumeSkeleton.styles.layout_flip_enabled) ? "connectedSortable left_list mb-0 pt-4 ps-4 pe-0 mt-5" : "connectedSortable left_list mb-0 ps-4 pe-0 mt-5 order-2"} id={(resumeSkeleton.styles.layout_flip_enabled) ? "RIGHT": "LEFT"} >
                        {skeleton(resumeSkeleton.sections.left_sec)}
                    </ul>
                    <ul style={{ width: `${resumeSkeleton.sections.col_2}%` }} className={(resumeSkeleton.styles.layout_flip_enabled) ? "connectedSortable right_list mb-0 pe-4 ps-0" : "connectedSortable right_list mb-0 pe-4 mt-5"} id={(resumeSkeleton.styles.layout_flip_enabled) ? "LEFT": "RIGHT"} >
                        {skeleton(resumeSkeleton.sections.right_sec)}
                    </ul>
                </>);
            case 'exec_pro':
                return (
                    <>
                        <ResumeHeader templateType={templateName} />
                        <ul style={{ width: `${resumeSkeleton.sections.col_1}%` }} className={(resumeSkeleton.styles.layout_flip_enabled) ? "connectedSortable left_list mb-0 pe-3" : "connectedSortable left_list mb-0 ps-3 order-2"} id={(resumeSkeleton.styles.layout_flip_enabled) ? "RIGHT": "LEFT"} >
                            {skeleton(resumeSkeleton.sections.left_sec)}
                        </ul>
                        <ul style={{ width: `${resumeSkeleton.sections.col_2}%` }} className="connectedSortable right_list mb-0" id={(resumeSkeleton.styles.layout_flip_enabled) ? "LEFT": "RIGHT"} >
                            {skeleton(resumeSkeleton.sections.right_sec)}
                        </ul>
                    </>
                );
            default:
                return (
                    <>
                        <ResumeHeader templateType={templateName} />
                        <ul style={{ width: `${resumeSkeleton.sections.col_1}%` }} className={(resumeSkeleton.styles.layout_flip_enabled) ? "connectedSortable left_list mb-0 py-4" : "connectedSortable left_list mb-0 py-4 order-2"} id={(resumeSkeleton.styles.layout_flip_enabled) ? "RIGHT": "LEFT"} >
                            {skeleton(resumeSkeleton.sections.left_sec)}
                        </ul>
                        <ul style={{ width: `${resumeSkeleton.sections.col_2}%` }} className="connectedSortable right_list mb-0 py-4" id={(resumeSkeleton.styles.layout_flip_enabled) ? "LEFT": "RIGHT"} >
                            {skeleton(resumeSkeleton.sections.right_sec)}
                        </ul>
                    </>
                );


        }

    }

    const grid = () => {
        if (resumeSkeleton) {
            return layoutStyle(resumeSkeleton.styles.layout_style, resumeSkeleton.sections.left_sec[0].template_type)
        }
    }


    return (
        <>
            <div id="mainContainer" className='position-relative'>
                <div className="row A4 p-0 m-0 leftColumn pdf_zoom_level" id="pdf" style={{ fontFamily: fontFamily }}>
                    {grid()}
                </div>
            </div>
        </>
    )
}

export default ResumeTemplates