import {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import moment from 'moment';
import { AuthContext } from './Authcontext';
import { SESSION_TIME } from './commonurls';


const SessionTimeout = () => {
  // eslint-disable-next-line
  const [events, setEvents] = useState(['click', 'load', 'scroll']);
  const [second, setSecond] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const isAuth = useContext(AuthContext);

  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  // start inactive check
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem('lastTimeStamp').toString();
      warningInactive(storedTimeStamp);
    }, 60000);
  };

  // warning timer
  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = SESSION_TIME;
      const popTime = 1;

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();
      const leftSecond = 60 - diff.seconds();

      if (minPast === popTime) {
        setSecond(leftSecond);
        setOpen(true);
      }

      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        setOpen(false);
        sessionStorage.removeItem('lastTimeStamp');
        window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_LOGOUT_URL : process.env.REACT_APP_PROD_LOGOUT_URL;
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('accessToken')
      }
    }, 1000);
  };

  // reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (isAuth) {
      timeStamp = moment();
      sessionStorage.setItem('lastTimeStamp', timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem('lastTimeStamp');
    }
    timeChecker();
    setOpen(false);
  }, [isAuth]);

  // handle close popup
  // eslint-disable-next-line
  const handleClose = () => {
    setOpen(false);

    resetTimer();
  };

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
      //   resetTimer();
    };
  }, [resetTimer, events, timeChecker]);

  // console.log(second);

  if (!isOpen) {
    return null;
  }

  // change fragment to modal and handleclose func to close
  return [isOpen,second];
};

export default SessionTimeout;