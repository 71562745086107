import React from "react";
import TitleCard from "./TitleCard";
// import DetailCard from "./DetailCard";
import { nanoid } from "nanoid";
import { AuthContext } from "../Services/Authcontext";

class ReferenceCard extends React.Component {
    static contextType = AuthContext
    constructor(props) {
        super(props)
        this.state = {
            showForm: false,
            isEdit: false,
            editId: '',
            Name: '',
            Phone: '',
            Email: '',
            Designation: '',
            Company: '',
            referenceList: []
        };
        this.handleForm = this.handleForm.bind(this);
        this.handleReferenceDelete = this.handleReferenceDelete.bind(this);
        this.handleReferenceEdit = this.handleReferenceEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleForm() {
        this.setState({ showForm: !this.state.showForm })
    }

    handleReferenceEdit(id) {
        this.setState({ isEdit: !this.state.isEdit })
        this.setState({ editId: id })
        this.setState({ showForm: !this.state.showForm })
        const EditReference = this.state.referenceList.filter(reference => reference.id === id);
        EditReference.map((editData) => {
            this.setState({ id: editData.id });
            this.setState({ Name: editData.Name });
            this.setState({ Phone: editData.Phone });
            this.setState({ Email: editData.Email });
            this.setState({ Designation: editData.Designation });
            this.setState({ Company: editData.Company });
            return '';
        });
    }

    handleReferenceDelete(id) {
        const deleteReference = this.state.referenceList.filter(reference => reference.id !== id);
        this.setState({ referenceList: deleteReference });
        this.handleState();
    }

    handleSubmit(e, formType) {
        e.preventDefault();
        const { dispatch } = this.context;
        dispatch({
            type: 'DATA_UPDATED',
            payload: true
        })
        if (formType === 'add') {
            const addReference = {
                id: nanoid(),
                Name: this.state.Name,
                Phone: this.state.Phone,
                Email: this.state.Email,
                Designation: this.state.Designation,
                Company: this.state.Company,
            }
            this.setState({ referenceList: [...this.state.referenceList, addReference] })

        } else {
            const EditReference = this.state.referenceList.map(school => {
                if (school.id === this.state.editId) {
                    return {
                        ...school, Name: this.state.Name,
                        Phone: this.state.Phone,
                        Email: this.state.Email,
                        Designation: this.state.Designation,
                        Company: this.state.Company,
                    };
                }
                return school;
            });
            this.setState({ referenceList: EditReference })
            this.setState({ isEdit: !this.state.isEdit })

        }
        this.setState({ showForm: !this.state.showForm })
        this.setState({ editId: '' })
        this.setState({ id: '' });
        this.setState({ Name: '' });
        this.setState({ Phone: '' });
        this.setState({ Email: '' });
        this.setState({ Designation: '' });
        this.setState({ Company: '' });
        this.handleState();
    };
    handleState() {
        const { dispatch } = this.context;
        setTimeout(() => {
            dispatch({
                type: 'ADD_REFERENCES',
                payload: this.state.referenceList
            })
        }, 1000)
    }
    componentDidMount() {
        if (this.context.references && this.context.references !== null) {
            this.setState({ referenceList: this.context.references })
        }
    }
    render() {
        return (
            <div id="reference_add" className={(this.props.Display) ? 'mt-4' : "d-none"}>
                <form onSubmit={(e) => { this.handleSubmit(e, (this.state.isEdit) ? 'edit' : 'add') }} method="post" className={(this.state.showForm) ? "mt-4" : "d-none"} >
                    <p className="fw-bold">Reference</p>
                    <div className="form-floating mb-3">
                        <input type="text" name="refer_name" id="refer_name" className="form-control" value={this.state.Name} onChange={(e) => { this.setState({ Name: e.target.value }) }} placeholder="Name" />
                        <label htmlFor="name">Name</label>
                    </div>

                    <div className="form-floating mb-3">
                        <input type="text" name="email" id="refer_email" className="form-control" value={this.state.Email} onChange={(e) => { this.setState({ Email: e.target.value }) }}
                            placeholder="Email" />
                        <label htmlFor="email">Email</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="text" name="phone" id="refer_phone" className="form-control" value={this.state.Phone} onChange={(e) => { this.setState({ Phone: e.target.value }) }}
                            placeholder="Phone" />
                        <label htmlFor="phone">Phone</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="text" name="designation" id="refer_designation" value={this.state.Designation} onChange={(e) => { this.setState({ Designation: e.target.value }) }} className="form-control"
                            placeholder="Designation" />
                        <label htmlFor="designation">Designation</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="text" name="company" id="refer_company" value={this.state.Company} onChange={(e) => { this.setState({ Company: e.target.value }) }} className="form-control"
                            placeholder="Company" />
                        <label htmlFor="company">Company</label>
                    </div>
                    <div className="row mb-4 mx-0">
                        <div className="col px-0">
                            <button className="btn btn-sm fs-6 btn-outline-primary mt-3" type="button" onClick={this.handleForm}>Back</button>
                        </div>
                        <div className="col px-0">
                            <button type='submit' className="btn btn-sm fs-6 btn-primary px-3 mt-3 float-end" id="add_new_job">Save</button>
                        </div>
                    </div>
                </form>
                <div className={(this.state.showForm) ? "d-none" : ''}>
                    <TitleCard Title={'Reference'} Section={'Section Name'} Description={'Personal Projects'} />
                    {
                        this.state.referenceList && this.state.referenceList.map((reference, index) => {
                            return <ReferenceListCard key={index} handleReferenceDelete={this.handleReferenceDelete} handleReferenceEdit={this.handleReferenceEdit} Id={reference.id} Name={reference.Name} Email={reference.Email} Phone={reference.Phone} Designation={reference.Designation} Company={reference.Company} />
                        })
                    }
                </div>
                <div id="reference" className={(this.state.showForm) ? "d-none" : ''}>
                    <div className="row mb-4 mt-3">
                        <div className="col">
                        </div>
                        <div className="col text-end">
                            <button type="button" className="btn add_btn" id="reference_add_btn" onClick={this.handleForm}><i
                                className="bi bi-plus"></i> add</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const ReferenceListCard = ({ Id, Name, Phone, Email, Designation, Company, handleReferenceDelete, handleReferenceEdit }) => {
    return (
        <div className="row pt-3 mx-0 ms-0 border mt-2">
            <div className="col-2 text-center">
                <img src={process.env.PUBLIC_URL + "/image/icons/reference.svg"} alt='icon' />
            </div>
            <div className="col-10 ps-lg-0">
                <p className="fw-bold small mb-0">{Name}</p>
                <p className="text-muted small mb-0">{Designation} | {Company}</p>
                <p className="text-muted small mb-0">{Phone} | {Email}</p>
                <div className="row mb-3">
                    <div className="col">
                    </div>
                    <div className="col-auto">
                        <span className="text-end text-decoration-underline text-danger" onClick={() => { handleReferenceDelete(Id) }}> <i className="bi bi-trash"></i> </span>
                    </div>
                    <div className="col-auto">
                        <span className="text-end text-decoration-underline text-primary" onClick={() => { handleReferenceEdit(Id) }}> <i className="bi bi-pencil"></i> </span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ReferenceCard;