import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";

const ResumePersonalprojects = ({ templateType, sections, suffle, format }) => {
  const { personalProject, resumeSkeleton, templateStatus, personalProjectTitle } = useContext(AuthContext);
  const ResumePersonalListCard = ({ personalProjectData }) => {
    return (
      <div className="mb-2">
        <p className="mb-0 fw-bold small">{personalProjectData.title}</p>
        <p className="mb-0 small">{personalProjectData.section}</p>
        <p className="mb-0 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(personalProjectData.startDate) ? format(personalProjectData.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}  {(personalProjectData.endDate) ? ' - ' + format(personalProjectData.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
        <div className="small">{personalProjectData.summary}</div>
      </div>
    )
  }
  const resumePersonalCard = () => {
    if (templateType === "ats_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalProject === '') || personalProject === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumePersonalprojects">
            <h5 id="personalproject_title" className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" style={{ backgroundColor: '#0000001a' }}>{personalProjectTitle}</h5>
            <div className="px-3 py-2">
              {
                personalProject && personalProject.map((personal, index) => {
                  return (
                    <div className="row mb-2" key={index}>
                      <div className="col-3">
                        <p className="mb-0 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(personal.startDate) ? format(personal.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}  {(personal.endDate) ? ' - ' + format(personal.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                      </div>
                      <div className="col-9">
                        <p className="mb-0 fw-bold small">{personal.title}</p>
                        <p className="mb-0 small">{personal.section}</p>
                        <div className="small">{personal.summary}</div>
                      </div>
                    </div>
                  )
                })
              }
            </div>

            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>

        </>
      );
    }
    else if (templateType === "exec_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalProject === '') || personalProject === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumePersonalprojects">
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalProject === '') || personalProject === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumePersonalprojects">
            <h5 id="personalproject_title" className="fw-bold text-capitalize text-break" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{personalProjectTitle}</h5>
            <hr className="mt-0" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }} />
            <div className="row">
              <div className="col-3"></div>
              <div className="col-9">
                {
                  personalProject && personalProject.map((personal, index) => {
                    return <ResumePersonalListCard key={index} personalProjectData={personal} />
                  })
                }
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalProject === '') || personalProject === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumePersonalprojects">
            <div className="mb-2">
              <h5 id="personalproject_title" className="fw-bold text-uppercase text-break">{personalProjectTitle}</h5>
              <div className="row mb-2">
                <div className="col-12">
                  {
                    personalProject && personalProject.map((personal, index) => {
                      return (
                        <div className="mb-2" key={index}>
                          <p className="mb-0 fw-bold small">{personal.title}</p>
                          <p className="mb-0 small">{personal.section}</p>
                          <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(personal.startDate) ? format(personal.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}  {(personal.endDate) ? ' - ' + format(personal.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                          <div className="small">{personal.summary}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalProject === '') || personalProject === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumePersonalprojects">
            <div className="mb-1">
              <h5 id="personalproject_title" className="fw-bold text-capitalize text-break">{personalProjectTitle}</h5>
              <div className="row mb-2">
                <div className="col-12">
                  {
                    personalProject && personalProject.map((personal, index) => {
                      return (
                        <div className="mb-2" key={index}>
                          <p className="mb-0 fw-bold small">{personal.title}</p>
                          <p className="mb-0 small">{personal.section}</p>
                          <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(personal.startDate) ? format(personal.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}  {(personal.endDate) ? ' - ' + format(personal.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                          <div className="small">{personal.summary}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalProject === '') || personalProject === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumePersonalprojects">
            <div className="ps-2 mb-3">
              <h5 id="personalproject_title" className="text-uppercase d-flex align-items-center fw-bold mb-3 text-break"><i className="bi bi-person-workspace icon_circle_dark" style={(resumeSkeleton.styles.theme_color === '#fff') ? { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#000' } : { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#fff' }}></i>{personalProjectTitle}</h5>
              <div className="row">
                <div className="col-12">
                  {
                    personalProject && personalProject.map((personal, index) => {
                      return <ResumePersonalListCard key={index} personalProjectData={personal} />
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalProject === '') || personalProject === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumePersonalprojects">
            <div className="mb-1">
              <h5 id="personalproject_title" className="fw-bol text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>{personalProjectTitle}</h5>
              <div className="row mb-2">
                <div className="col-12">
                  {
                    personalProject && personalProject.map((personal, index) => {
                      return <ResumePersonalListCard key={index} personalProjectData={personal} />
                    })
                  }
                </div>
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalProject === '') || personalProject === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumePersonalprojects">
            <div className="mb-1">
              <h5 id="personalproject_title" className="fw-bold text-dark text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>{personalProjectTitle}</h5>
              <div className="row mb-2">
                <div className="col-12">
                  {
                    personalProject && personalProject.map((personal, index) => {
                      return (
                        <div className="mb-2" key={index}>
                          <p className="mb-0 fw-bold small">{personal.title}</p>
                          <p className="mb-0 small">{personal.section}</p>
                          <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(personal.startDate) ? format(personal.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}  {(personal.endDate) ? ' - ' + format(personal.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                          <div className="small">{personal.summary}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && personalProject === '') || personalProject === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumePersonalprojects">
            <div className="mb-1">
              <h5 id="personalproject_title" className="fw-bold text-dark text-uppercase text-break ps-2">{personalProjectTitle}</h5>
              <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, padding: '1px' }} className="mt-0" />
              <div className="row mb-2 ps-3">
                <div className="col-12">
                  {
                    personalProject && personalProject.map((personal, index) => {
                      return (
                        <div className="mb-2" key={index}>
                          <p className="mb-0 fw-bold small">{personal.title}</p>
                          <p className="mb-0 small">{personal.section}</p>
                          <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(personal.startDate) ? format(personal.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}  {(personal.endDate) ? ' - ' + format(personal.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                          <div className="small">{personal.summary}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
  }

  return (
    <>{resumePersonalCard()}</>
  );
}


export default ResumePersonalprojects;