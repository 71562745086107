import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";
const ResumeSkills = ({ templateType, sections, suffle }) => {
  const { resumeSkeleton, skills, templateStatus, skillEnable } = useContext(AuthContext);
  const SkillListCard = ({ skill }) => {
    const skillRate = skill.rating * 20 + '%'
    return (
      <div id="" className="my-2">
        <p className="small fw-bold mb-1">{skill.skill}</p>
        <div className={(skillEnable) ? "progress" : "d-none"} style={{ height: '8px', backgroundColor: '#0000001a' }}>
          <div className="progress-bar" role="progressbar" style={{ width: skillRate, backgroundColor: '#e9ecef' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    )
  }

  const SkillCard = () => {
    if (templateType === "ats_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && skills === '') || skills === null || ((skills.FunctionSkillList && skills.OtherSkillList && skills.TechSkillList) ? (skills.FunctionSkillList.length <= 0 && skills.OtherSkillList.length <= 0 && skills.TechSkillList.length <= 0) : null)) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeSkills">
            <h5 className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" style={{ backgroundColor: '#0000001a' }}>Skills</h5>
            <div className="px-3 py-2">
              {
                skills && skills.TechSkillList.map((skill, index) => {
                  return <SkillListCard skill={skill} key={index} />
                })
              }
              {
                skills && skills.FunctionSkillList.map((skill, index) => {
                  return <SkillListCard skill={skill} key={index} />
                })
              }
              {
                skills && skills.OtherSkillList.map((skill, index) => {
                  return <SkillListCard skill={skill} key={index} />
                })
              }
              <input type="hidden" name="section_order" value={sections.section_order} />
              <input type="hidden" name="section_name" value={sections.section_name} />
              <input type="hidden" name="section_id" value={sections.section_id} />
              <input type="hidden" name="section_column" value={sections.section_column} />
              <input type="hidden" name="template_type" value={templateType} />
            </div>
          </li>
        </>
      );
    }
    else if (templateType === "exec_classic") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && skills === '') || skills === null || ((skills.FunctionSkillList && skills.OtherSkillList && skills.TechSkillList) ? (skills.FunctionSkillList.length <= 0 && skills.OtherSkillList.length <= 0 && skills.TechSkillList.length <= 0) : null)) ? "d-none" : "resume_section list-group my-3"} draggable="true" id="ResumeSkills">
          <h5 className="text-capitalize fw-bold mb-3 text-break exec_classic_border" style={{ color: resumeSkeleton.styles.theme_color }}>Skills</h5>
          <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, width: '100px', padding: '1px' }} className="mt-0" />
          <div className="row">
            <div className="col-12">
              <ul className="" style={{ listStyleType: 'disc' }}>
                {
                  skills && skills.TechSkillList.map((skill, index) => {
                    return <li key={index}>{skill.skill}</li>
                  })
                }
              </ul>
              <ul className="" style={{ listStyleType: 'disc' }}>
                {
                  skills && skills.FunctionSkillList.map((skill, index) => {
                    return <li key={index}>{skill.skill}</li>
                  })
                }
              </ul>
              <ul className="" style={{ listStyleType: 'disc' }}>
                {
                  skills && skills.OtherSkillList.map((skill, index) => {
                    return <li key={index}>{skill.skill}</li>
                  })
                }
              </ul>
              <input type="hidden" name="section_order" value={sections.section_order} />
              <input type="hidden" name="section_name" value={sections.section_name} />
              <input type="hidden" name="section_id" value={sections.section_id} />
              <input type="hidden" name="section_column" value={sections.section_column} />
              <input type="hidden" name="template_type" value={templateType} />
            </div>
          </div>
        </li>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && skills === '') || skills === null || ((skills.FunctionSkillList && skills.OtherSkillList && skills.TechSkillList) ? (skills.FunctionSkillList.length <= 0 && skills.OtherSkillList.length <= 0 && skills.TechSkillList.length <= 0) : null)) ? "d-none" : "resume_section list-group my-3"} draggable="true" id="ResumeSkills">
          <h5 className="fw-bold text-capitalize text-break" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>Skills</h5>
          <hr className="mt-0" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }} />
          <div className="row">
            <div className="col-3"></div>
            <div className="col-9">
              {
                skills && skills.TechSkillList.map((skill, index) => {
                  return <p className="mb-0" key={index}>{skill.skill}</p>
                })
              }

              {
                skills && skills.FunctionSkillList.map((skill, index) => {
                  return <p className="mb-0" key={index}>{skill.skill}</p>
                })
              }
              {
                skills && skills.OtherSkillList.map((skill, index) => {
                  return <p className="mb-0" key={index}>{skill.skill}</p>
                })
              }
              <input type="hidden" name="section_order" value={sections.section_order} />
              <input type="hidden" name="section_name" value={sections.section_name} />
              <input type="hidden" name="section_id" value={sections.section_id} />
              <input type="hidden" name="section_column" value={sections.section_column} />
              <input type="hidden" name="template_type" value={templateType} />
            </div>
          </div>
        </li>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <li className={
          ((templateStatus === 'PUBLISHED' && skills === '')
            || skills === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeSkills">

          <h5 className="fw-bold text-capitalize text-break">Skills</h5>
          <div className="row">
            <div className="col-12 mb-3">
              {
                skills && skills.TechSkillList.map((skill, index) => {
                  return <span className="badge me-2 mb-1 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{skill.skill}</span>
                })
              }
              {
                skills && skills.FunctionSkillList.map((skill, index) => {
                  return <span className="badge me-2 mb-1 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{skill.skill}</span>
                })
              }
              {
                skills && skills.OtherSkillList.map((skill, index) => {
                  return <span className="badge me-2 mb-1 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{skill.skill}</span>
                })
              }
              <input type="hidden" name="section_order" value={sections.section_order} />
              <input type="hidden" name="section_name" value={sections.section_name} />
              <input type="hidden" name="section_id" value={sections.section_id} />
              <input type="hidden" name="section_column" value={sections.section_column} />
              <input type="hidden" name="template_type" value={templateType} />
            </div>
          </div>
        </li>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && skills === '') || skills === null || ((skills.FunctionSkillList && skills.OtherSkillList && skills.TechSkillList) ? (skills.FunctionSkillList.length <= 0 && skills.OtherSkillList.length <= 0 && skills.TechSkillList.length <= 0) : null)) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeSkills">
          <div className="mb-2">
            <h5 className="text-uppercase fw-bold mb-3 text-break">Skills</h5>
            <div className="row mb-2">
              <div className="col-12">
                <ul className="px-3">
                  {
                    skills && skills.TechSkillList.map((skill, index) => {
                      return <li key={index}>{skill.skill}</li>
                    })
                  }
                </ul>
                <ul className="px-3">
                  {
                    skills && skills.FunctionSkillList.map((skill, index) => {
                      return <li key={index}>{skill.skill}</li>
                    })
                  }
                </ul>
                <ul className="px-3">
                  {
                    skills && skills.OtherSkillList.map((skill, index) => {
                      return <li key={index}>{skill.skill}</li>
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
          <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && skills === '') || skills === null || ((skills.FunctionSkillList && skills.OtherSkillList && skills.TechSkillList) ? (skills.FunctionSkillList.length <= 0 && skills.OtherSkillList.length <= 0 && skills.TechSkillList.length <= 0) : null)) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeSkills">
          <div className="ps-2 mb-3">
            <h5 className="text-uppercase d-flex align-items-center fw-bold mb-3 text-break"><i className="bi bi-headset icon_circle_dark" style={(resumeSkeleton.styles.theme_color === '#fff') ? { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#000' } : { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#fff' }}></i>Skills</h5>
            <div className="row ps-1">
              <div className="col-12">
                {
                  skills && skills.TechSkillList.map((skill, index) => {
                    return <span className="badge bg-secondary me-1 mb-1 text-break text-wrap" key={index}>{skill.skill}</span>
                  })
                }
                {
                  skills && skills.FunctionSkillList.map((skill, index) => {
                    return <span className="badge bg-secondary me-1 mb-1 text-break text-wrap" key={index}>{skill.skill}</span>
                  })
                }
                {
                  skills && skills.OtherSkillList.map((skill, index) => {
                    return <span className="badge bg-secondary me-1 mb-1 text-break text-wrap" key={index}>{skill.skill}</span>
                  })
                }
              </div>
              <input type="hidden" name="section_order" value={sections.section_order} />
              <input type="hidden" name="section_name" value={sections.section_name} />
              <input type="hidden" name="section_id" value={sections.section_id} />
              <input type="hidden" name="section_column" value={sections.section_column} />
              <input type="hidden" name="template_type" value={templateType} />
            </div>
          </div>
        </li>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && skills === '') || skills === null || ((skills.FunctionSkillList && skills.OtherSkillList && skills.TechSkillList) ? (skills.FunctionSkillList.length <= 0 && skills.OtherSkillList.length <= 0 && skills.TechSkillList.length <= 0) : null)) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeSkills">
          <h5 className="fw-bol text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>Skills</h5>
          <div className="row">
            <div className="col-12 mb-3">
              <ul style={{ listStyleType: 'disc' }}>
                {
                  skills && skills.TechSkillList.map((skill, index) => {
                    return <li key={index}>{skill.skill}</li>
                  })
                }
              </ul>
              <ul style={{ listStyleType: 'disc' }}>
                {
                  skills && skills.FunctionSkillList.map((skill, index) => {
                    return <li key={index}>{skill.skill}</li>
                  })
                }
              </ul>
              <ul style={{ listStyleType: 'disc' }}>
                {
                  skills && skills.OtherSkillList.map((skill, index) => {
                    return <li key={index}>{skill.skill}</li>
                  })
                }
              </ul>
              <input type="hidden" name="section_order" value={sections.section_order} />
              <input type="hidden" name="section_name" value={sections.section_name} />
              <input type="hidden" name="section_id" value={sections.section_id} />
              <input type="hidden" name="section_column" value={sections.section_column} />
              <input type="hidden" name="template_type" value={templateType} />
            </div>
          </div>
          <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
        </li>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && skills === '') || skills === null || ((skills.FunctionSkillList && skills.OtherSkillList && skills.TechSkillList) ? (skills.FunctionSkillList.length <= 0 && skills.OtherSkillList.length <= 0 && skills.TechSkillList.length <= 0) : null)) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeSkills">
          <h5 className="fw-bold text-dark text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>Skills</h5>
          <div className="row">
            <div className="col-12 mb-3">
              <ul style={{ listStyleType: 'disc' }}>
                {
                  skills && skills.TechSkillList.map((skill, index) => {
                    return <li key={index}>{skill.skill}</li>
                  })
                }
              </ul>
              <ul style={{ listStyleType: 'disc' }}>
                {
                  skills && skills.FunctionSkillList.map((skill, index) => {
                    return <li key={index}>{skill.skill}</li>
                  })
                }
              </ul>
              <ul style={{ listStyleType: 'disc' }}>
                {
                  skills && skills.OtherSkillList.map((skill, index) => {
                    return <li key={index}>{skill.skill}</li>
                  })
                }
              </ul>
              <input type="hidden" name="section_order" value={sections.section_order} />
              <input type="hidden" name="section_name" value={sections.section_name} />
              <input type="hidden" name="section_id" value={sections.section_id} />
              <input type="hidden" name="section_column" value={sections.section_column} />
              <input type="hidden" name="template_type" value={templateType} />
            </div>
          </div>
        </li>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <li className={((templateStatus === 'PUBLISHED' && skills === '') || skills === null || ((skills.FunctionSkillList && skills.OtherSkillList && skills.TechSkillList) ? (skills.FunctionSkillList.length <= 0 && skills.OtherSkillList.length <= 0 && skills.TechSkillList.length <= 0) : null)) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeSkills">
          <h5 className="fw-bold text-dark text-uppercase text-break ps-2">Skills</h5>
          <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, padding: '1px' }} className="mt-0" />
          <div className="row ps-3">
            <div className="col-12 mb-3">
              {
                skills && skills.TechSkillList.map((skill, index) => {
                  return <span className="badge me-1 mb-1 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{skill.skill}</span>
                })
              }
              {
                skills && skills.FunctionSkillList.map((skill, index) => {
                  return <span className="badge me-1 mb-1 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{skill.skill}</span>
                })
              }
              {
                skills && skills.OtherSkillList.map((skill, index) => {
                  return <span className="badge me-1 mb-1 text-break text-wrap" style={{ backgroundColor: resumeSkeleton.styles.theme_color }} key={index}>{skill.skill}</span>
                })
              }
              <input type="hidden" name="section_order" value={sections.section_order} />
              <input type="hidden" name="section_name" value={sections.section_name} />
              <input type="hidden" name="section_id" value={sections.section_id} />
              <input type="hidden" name="section_column" value={sections.section_column} />
              <input type="hidden" name="template_type" value={templateType} />
            </div>
          </div>
        </li>
      );
    }
  }
  return (
    <>
      {SkillCard()}
    </>
  )
}
export default ResumeSkills;


