import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";
const ResumeCertifications = ({ templateType, sections, suffle, format, flip }) => {
  const { resumeSkeleton, certificate, templateStatus } = useContext(AuthContext);
  const CertificateListCard = ({ certificate }) => {
    return (
      <div className="row mb-2">
        <div className="col-3 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(certificate.CertificateDate) ? new Date(certificate.CertificateDate).toString().split(' ')[1] + " " + new Date(certificate.CertificateDate).toString().split(' ')[3] : ''}</i></div>
        <div className="col-9">
          <p className="small mb-0 fw-bold">
            {certificate.NameOfCertificate}
          </p>
          <i className="small mb-0">
            {certificate.CertificateBy}
          </i>
          <p className="small mb-0">
            {certificate.CertificateDescription}
          </p>
        </div>
      </div>
    )
  }

  const CertificateCard = () => {
    if (templateType === "ats_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && certificate === '') || certificate === null || certificate.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCertifications">
            <h5 className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" style={{ backgroundColor: '#0000001a' }}>Certifications</h5>
            <div className="resume_address2 px-3 py-2">
              {
                certificate && certificate.map((certificate, index) => {
                  return <CertificateListCard certificate={certificate} key={index} />
                })
              }
              <input type="hidden" name="section_order" value={sections.section_order} />
              <input type="hidden" name="section_name" value={sections.section_name} />
              <input type="hidden" name="section_id" value={sections.section_id} />
              <input type="hidden" name="section_column" value={sections.section_column} />
              <input type="hidden" name="template_type" value={templateType} />
            </div>
          </li>
        </>
      );
    }

    else if (templateType === "exec_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && certificate === '') || certificate === null || certificate.length <= 0) ? "d-none" : "resume_section list-group my-3"} draggable="true" id="ResumeCertifications">
            <h5 className="text-capitalize fw-bold mb-3 text-break" style={{ color: resumeSkeleton.styles.theme_color }}>Certifications</h5>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, width: '100px', padding: '1px' }} className="mt-0" />
            {
              certificate && certificate.map((certificate, index) => {
                return (
                  <div className="row mb-2" key={index}>
                    {/* <div className="col-3 small"></div> */}
                    <div className="col-12">
                      <p className="mb-0 fw-bold small">{certificate.NameOfCertificate}</p>
                      <p className="mb-0 small">{certificate.CertificateBy} {(certificate.CertificateDate && certificate.CertificateBy) ? '|' :''} <i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(certificate.CertificateDate) ? new Date(certificate.CertificateDate).toString().split(' ')[1] + " " + new Date(certificate.CertificateDate).toString().split(' ')[3] : ''}</i></p>
                    </div>
                  </div>
                )
              })
            }
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && certificate === '') || certificate === null || certificate.length <= 0) ? "d-none" : "resume_section list-group my-3"} draggable="true" id="ResumeCertifications">
            <h5 className="fw-bold text-capitalize text-break" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>certifications</h5>
            <hr className="mt-0" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }} />
            {
              certificate && certificate.map((certificate, index) => {
                return (
                  <div className="row mb-2" key={index}>
                    <div className="col-3 small"></div>
                    <div className="col-9">
                      <p className="mb-0 fw-bold small">{certificate.NameOfCertificate}</p>
                      <p className="mb-0 small">{certificate.CertificateBy}</p>
                      <p className="mb-0 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(certificate.CertificateDate) ? new Date(certificate.CertificateDate).toString().split(' ')[1] + " " + new Date(certificate.CertificateDate).toString().split(' ')[3] : ''}</i></p>
                    </div>
                  </div>
                )
              })
            }
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && certificate === '') || certificate === null || certificate.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCertifications">
            <div className="mb-3">
              <h5 className="fw-bold text-capitalize text-break">certifications</h5>
              {
                certificate && certificate.map((certificate, index) => {
                  return (
                    <div className="mb-3" key={index}>
                      <p className="fw-bold mb-1 small">{certificate.NameOfCertificate}</p>
                      <p className="mb-1 small">{certificate.CertificateBy}</p>
                      <p className="mb-1 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(certificate.CertificateDate) ? new Date(certificate.CertificateDate).toString().split(' ')[1] + " " + new Date(certificate.CertificateDate).toString().split(' ')[3] : ''}</i></p>
                    </div>
                  )
                })
              }
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && certificate === '') || certificate === null || certificate.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCertifications">
            <div className="mb-2">
              <h5 className="text-uppercase fw-bold mb-3 text-break">certifications</h5>
              {
                certificate && certificate.map((certificate, index) => {
                  return (
                    <div className="mb-2 ps-1" key={index}>
                      <p className="mb-0 fw-bold small">{certificate.NameOfCertificate}</p>
                      <p className="mb-0 small">{certificate.CertificateBy} {(certificate.CertificateDate && certificate.CertificateBy) ? '|' :''} <span style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset'}}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(certificate.CertificateDate) ? new Date(certificate.CertificateDate).toString().split(' ')[1] + " " + new Date(certificate.CertificateDate).toString().split(' ')[3] : ''}</i></span></p>
                      <p className="small">{certificate.CertificateDescription}</p>
                    </div>
                  )
                })
              }
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && certificate === '') || certificate === null || certificate.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCertifications">
            <div className="ps-2 mb-3">
              <h5 className="text-uppercase d-flex align-items-center fw-bold mb-3 text-break">
                <i className="bi bi-file-earmark-text icon_circle_dark" style={(resumeSkeleton.styles.theme_color === '#fff') ? { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#000' } : { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#fff' }}></i>certificates</h5>
              {
                certificate && certificate.map((certificate, index) => {
                  return (
                    <div className="mb-2 ps-1" key={index}>
                      <p className="mb-0 fw-bold small">{certificate.NameOfCertificate}</p>
                      <i className="mb-0 small">{certificate.CertificateBy}</i> {(certificate.CertificateDate && certificate.CertificateBy) ? '|' :''} <i className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(certificate.CertificateDate) ? new Date(certificate.CertificateDate).toString().split(' ')[1] + " " + new Date(certificate.CertificateDate).toString().split(' ')[3] : ''}</i>
                      <p className="small">{certificate.CertificateDescription}</p>
                    </div>
                  )
                })
              }
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && certificate === '') || certificate === null || certificate.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCertifications">
            <div className="mb-1">
              <h5 className="fw-bol text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>certifications</h5>
              {
                certificate && certificate.map((certificate, index) => {
                  return (
                    <div className="mb-2" key={index}>
                      <p className="fw-bold mb-1 small">{certificate.NameOfCertificate}</p>
                      <p className="mb-1 small">{certificate.CertificateBy}</p>
                      <p className="mb-1 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(certificate.CertificateDate) ? new Date(certificate.CertificateDate).toString().split(' ')[1] + " " + new Date(certificate.CertificateDate).toString().split(' ')[3] : ''}</i></p>
                    </div>
                  )
                })
              }
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && certificate === '') || certificate === null || certificate.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCertifications">
            <div className="mb-1">
              <h5 className="fw-bold text-dark text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>certifications</h5>
              {
                certificate && certificate.map((certificate, index) => {
                  return (
                    <div className="mb-3" key={index}>
                      <p className="fw-bold mb-1 small">{certificate.NameOfCertificate}</p>
                      <p className="mb-1 small">{certificate.CertificateBy}</p>
                      <p className="mb-1 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(certificate.CertificateDate) ? new Date(certificate.CertificateDate).toString().split(' ')[1] + " " + new Date(certificate.CertificateDate).toString().split(' ')[3] : ''}</i></p>
                    </div>
                  )
                })
              }
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && certificate === '') || certificate === null || certificate.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeCertifications">
            <div className="mb-1">
              <h5 className="fw-bold text-dark text-uppercase text-break ps-2">certificates</h5>
              <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, padding: '1px' }} className="mt-0" />
              <div className="ps-3">
                {
                  certificate && certificate.map((certificate, index) => {
                    return (
                      <div className="mb-2" key={index}>
                        <p className="mb-0 small fw-bold">{certificate.NameOfCertificate}</p>
                        <p className="mb-0 small">{certificate.CertificateBy}</p>
                        <p className="mb-0 small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}> <i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}> {(certificate.CertificateDate) ? new Date(certificate.CertificateDate).toString().split(' ')[1] + " " + new Date(certificate.CertificateDate).toString().split(' ')[3] : ''}</i></p>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
  }
  return (<>
    {CertificateCard()}
  </>)
}

export default ResumeCertifications;