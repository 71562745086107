import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../Services/Authcontext';



const CreateResumeSidebar = ({ progressBar, step, setStep,stepList, setProgressBar }) => {
    const { stepCompleteness } = useContext(AuthContext);
    const ClickHandler = (step) => {
        setStep(step);
    }



    useEffect(() => {
        setStep(step);
        var cnt = 0
        for (var key in stepCompleteness) {
            if (stepCompleteness[key]['step_status'] === true)
                cnt += 1;
        }
        setProgressBar(cnt * 10)
    }, [step])


    return (
        <>
            <nav className="offcanvas offcanvas-start show navbar-light bg-light d-none d-lg-block visible" id="offcanvas" data-bs-keyboard="false"
                data-bs-backdrop="false" data-bs-scroll="true">
                <div className="offcanvas-body p-0">
                    <div className="card-body p-0">
                        <div className="position-relative d-flex justify-content-center align-items-center  p-4 mb-1 flexDirection">
                            <div className="mobile_progress">
                                <div id="middle-circle">{progressBar}%</div>
                                <div id="progress-spinner" style={{ background: `conic-gradient(#61C33E ${progressBar}%,rgb(239 238 243)  ${progressBar}%)` }}></div>
                            </div>
                            <p className="text-secondary mt-2 small">Profile Completeness</p>
                        </div>
                        {
                            stepList && stepList.map((steps, index) => {
                                return <LinkCard key={index} ClickHandler={ClickHandler} step={Number(step)} steps={steps} stepCompleteness={stepCompleteness} />
                            })
                        }
                    </div>
                </div>
            </nav >
        </>
    );
};

export default CreateResumeSidebar;

const LinkCard = ({ step, steps, ClickHandler, index, stepCompleteness }) => {
    return (
        <>
            {(stepCompleteness[steps.name]['step_status']) ?
                (<>
                    <NavLink to={`/rb-step?step=${steps.id}`} onClick={() => { ClickHandler(steps.id) }} className="text-dark alert-success text-decoration-none">
                        {(step === steps.id) ? (<>
                            <div className={(step === steps.id) ? "border-bottom ps-4 text-dark alert-primary side-active " : "border-bottom ps-4 "}>
                                <p className="d-inline-block my-2 py-1">{steps.id}. {steps.display_name}</p>
                                <span className={(step === steps.id) ? "float-end m-2 text-success" : "float-end m-2 text-success"}> <i className='bi bi-check-circle fs-5'></i> </span>
                            </div>
                        </>) :
                            (<>

                                <div className={(step === steps.id) ? "border-bottom ps-4 side-active" : "border-bottom ps-4"}>
                                    <p className="d-inline-block my-2 py-1">{steps.id}. {steps.display_name}</p>
                                    <span className={(step === steps.id) ? "float-end m-2 text-success" : "float-end m-2 text-success"}> <i className='bi bi-check-circle fs-5'></i> </span>
                                </div>
                            </>)
                        }

                    </NavLink>
                </>)
                :
                (<>
                    <div className={(step === steps.id) ? "border-bottom ps-4 text-primary alert-primary side-active" : "border-bottom ps-4 text-secondary"}>
                        <p className="d-inline-block my-2 py-1">{steps.id}. {steps.display_name}</p>
                        <span className={(step === steps.id) ? "float-end m-2" : "float-end m-2"}> <i className='bi bi-plus-circle-dotted'></i> </span>
                    </div>
                </>)
            }

        </>
    )
}