import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import CreateResumeNavbar from '../../Components/createResumeNavbar';
import CreateResumeSidebar from '../../Components/createResumeSidebar';
import JobSummary from '../../Components/JobSummary';
import WorkExperience from '../../Components/WorkExperience';
import PersonalInformation from '../../Components/PersonalInformation';
import Education from '../../Components/Education';
import Certification from '../../Components/Certification';
import Language from '../../Components/Language';
import Competencies from '../../Components/Competencies';
import Skills from '../../Components/Skills';
import Others from '../../Components/Others';
import axiosInstance from '../../Services/axios';
import Loading from '../../Components/Loading';
import Links from '../../Components/Links';
import { RESUME_STEPS_API_LINK } from '../../Services/commonurls';

const ResumebuilderStep = () => {

    const [searchParams] = useSearchParams();
    const [step, setStep] = useState(1);
    const [progressBar, setProgressBar] = useState(0);
    const [stepList, setStepList] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const rightHide = () => {
        document.getElementById('id_right').classList.add('d-none')
        document.getElementById('id_left').classList.add('col-lg-12');
        document.getElementById('id_left').classList.add('d-flex');
        document.getElementById('id_left').classList.add('justify-content-center');
        document.getElementById('id_left').classList.remove('col-md-5');
        document.getElementById('id_left_side').classList.add('col-md-7');
        document.getElementById('id_right').classList.remove('d-lg-block');
        document.getElementById('id_right_expand_hide').classList.add('d-none');
        document.getElementById('id_right_expand_hide').classList.remove('d-lg-block');
        document.getElementById('id_right_expand_show').classList.remove('d-none');
    }

    const rightShow = () => {
        document.getElementById('id_right').classList.remove('d-none');
        document.getElementById('id_left').classList.remove('col-lg-12');
        document.getElementById('id_left').classList.remove('d-flex');
        document.getElementById('id_left').classList.remove('justify-content-center');
        document.getElementById('id_left').classList.add('col-lg-5');
        document.getElementById('id_left_side').classList.remove('col-md-7');
        document.getElementById('id_right_expand_hide').classList.remove('d-none');
        document.getElementById('id_right_expand_show').classList.add('d-none');
    }

    const leftHide = () => {
        document.getElementById('id_left').classList.add('d-none');
        document.getElementById('id_right').classList.add('col-md-12');
        document.getElementById('id_right').classList.remove('col-md-7');
        document.getElementById('id_left_expand_hide').classList.add('d-none');
        document.getElementById('id_left_expand_show').classList.remove('d-none');
    }

    const leftShow = () => {
        document.getElementById('id_left').classList.remove('d-none');
        document.getElementById('id_right').classList.remove('col-md-12');
        document.getElementById('id_right').classList.add('col-md-7');
        document.getElementById('id_left_expand_hide').classList.remove('d-none');
        document.getElementById('id_left_expand_show').classList.add('d-none');
    }


    const handleSteps = async () => {
        await axiosInstance.get(RESUME_STEPS_API_LINK).then((res) => {
            setStepList(res.data.data.results)
        });
    }

    const format = (input, type) => {
        // eslint-disable-next-line
        var pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
        if (!input || !input.match(pattern)) {
            return null;
        }
        if (type === "dd/mm/yyyy") {
            return input.replace(pattern, '$3/$2/$1');
        } else if (type === "mm/dd/yyyy") {
            return input.replace(pattern, '$2/$3/$1');
        } else {
            return input;
        }
    };

    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        setStep(parseInt(currentParams.step) || 1);
        if(!stepList){
            handleSteps();
        }
    }, [searchParams]);
    return (
        <>
            {(isLoading) ? <Loading /> :
                (<>
                    <CreateResumeNavbar setIsLoading={setIsLoading} rightHide={rightHide} rightShow={rightShow} leftHide={leftHide} leftShow={leftShow} progressBar={progressBar} setProgressBar={setProgressBar} step={step} setStep={setStep} stepList={stepList} format={format} />
                    <CreateResumeSidebar rightHide={rightHide} rightShow={rightShow} leftHide={leftHide} leftShow={leftShow} progressBar={progressBar} setProgressBar={setProgressBar} step={step} setStep={setStep} stepList={stepList} format={format} />
                    <main className="container-fluid container-alter mt-7">
                        <div className="row">
                            <div className="col">
                                {step === 1 ? <PersonalInformation setIsLoading={setIsLoading} rightHide={rightHide} rightShow={rightShow} leftHide={leftHide} leftShow={leftShow} progressBar={progressBar} setProgressBar={setProgressBar} step={step} setStep={setStep} stepList={stepList} format={format} /> :
                                    step === 2 ? <JobSummary setIsLoading={setIsLoading} rightHide={rightHide} rightShow={rightShow} leftHide={leftHide} leftShow={leftShow} progressBar={progressBar} setProgressBar={setProgressBar} step={step} setStep={setStep} stepList={stepList} format={format} /> :
                                        step === 3 ? <WorkExperience setIsLoading={setIsLoading} rightHide={rightHide} rightShow={rightShow} leftHide={leftHide} leftShow={leftShow} progressBar={progressBar} setProgressBar={setProgressBar} step={step} setStep={setStep} stepList={stepList} format={format} /> :
                                            step === 4 ? <Education setIsLoading={setIsLoading} rightHide={rightHide} rightShow={rightShow} leftHide={leftHide} leftShow={leftShow} progressBar={progressBar} setProgressBar={setProgressBar} step={step} setStep={setStep} stepList={stepList} format={format} /> :
                                                step === 5 ? <Certification setIsLoading={setIsLoading} rightHide={rightHide} rightShow={rightShow} leftHide={leftHide} leftShow={leftShow} progressBar={progressBar} setProgressBar={setProgressBar} step={step} setStep={setStep} stepList={stepList} format={format} /> :
                                                    step === 6 ? <Skills setIsLoading={setIsLoading} rightHide={rightHide} rightShow={rightShow} leftHide={leftHide} leftShow={leftShow} progressBar={progressBar} setProgressBar={setProgressBar} step={step} setStep={setStep} stepList={stepList} format={format} /> :
                                                        step === 7 ? <Competencies setIsLoading={setIsLoading} rightHide={rightHide} rightShow={rightShow} leftHide={leftHide} leftShow={leftShow} progressBar={progressBar} setProgressBar={setProgressBar} step={step} setStep={setStep} stepList={stepList} format={format} /> :
                                                            step === 8 ? <Links setIsLoading={setIsLoading} rightHide={rightHide} rightShow={rightShow} leftHide={leftHide} leftShow={leftShow} progressBar={progressBar} setProgressBar={setProgressBar} step={step} setStep={setStep} stepList={stepList} format={format} /> :
                                                                step === 9 ? <Language setIsLoading={setIsLoading} rightHide={rightHide} rightShow={rightShow} leftHide={leftHide} leftShow={leftShow} progressBar={progressBar} setProgressBar={setProgressBar} step={step} setStep={setStep} stepList={stepList} format={format} /> :
                                                                    step === 10 ? <Others setIsLoading={setIsLoading} rightHide={rightHide} rightShow={rightShow} leftHide={leftHide} leftShow={leftShow} progressBar={progressBar} setProgressBar={setProgressBar} step={step} setStep={setStep} stepList={stepList} format={format} /> :
                                                                        <PersonalInformation setIsLoading={setIsLoading} rightHide={rightHide} rightShow={rightShow} leftHide={leftHide} leftShow={leftShow} progressBar={progressBar} setProgressBar={setProgressBar} step={step} setStep={setStep} stepList={stepList} format={format} />}
                            </div>
                        </div>
                    </main>
                </>)
            }
        </>
    );
};

export default ResumebuilderStep;