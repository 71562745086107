import React, { } from 'react';
import { NavLink } from 'react-router-dom';
import RightSideResume from './RightSideResume';
import { withRouter } from './WithRouter';
import { Rating } from 'react-simple-star-rating';
import { AuthContext } from '../Services/Authcontext';
import NotificationCard from './NotificationCard';
import LeftHideButton from './LeftHideButton';
import axiosInstance from '../Services/axios';
import MobileHeader from './MobileHeader';
import video from '../image/animation/skills.webm'
import { RESUME_DATA_CONTENT_API_LINK } from '../Services/commonurls';

class Skills extends React.Component {
    static contextType = AuthContext

    constructor(props) {
        super(props)
        this.state = {
            resume_tech_skills: '',
            resume_func_skills: '',
            resume_other_skills: '',
            isValid: false,
            techSkillList: [],
            functionSkillList: [],
            otherSkillList: [],
            skillsList: [],
            techSkillSuggestions: null,
            functionSkillSuggestions: null,
            otherSkillSuggestions: null,
            skillEnable: null,
        }
        this.handleTechChange = this.handleTechChange.bind(this);
        this.handleTechRating = this.handleTechRating.bind(this);
        this.addTechFormFields = this.addTechFormFields.bind(this);
        this.handleFuncChange = this.handleFuncChange.bind(this);
        this.handleFuncRating = this.handleFuncRating.bind(this);
        this.addFuncFormFields = this.addFuncFormFields.bind(this);
        this.handleOtherChange = this.handleOtherChange.bind(this);
        this.handleOtherRating = this.handleOtherRating.bind(this);
        this.addOtherFormFields = this.addOtherFormFields.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleTechChange(i, e) {
        let techSkillList = this.state.techSkillList;
        techSkillList[i][e.target.name] = e.target.value;
        this.setState({ techSkillList });
    }

    handleTechRating(i, rate) {
        // eslint-disable-next-line
        this.state.techSkillList[i]["rating"] = parseInt(rate) / 20;
    }


    addTechFormFields(name) {
        this.setState(({
            techSkillList: [...this.state.techSkillList, { rating: 0, skill: name }]
        }))

    }


    removeTechFormFields(i) {
        let techSkillList = this.state.techSkillList;
        techSkillList.splice(i, 1);
        this.setState({ techSkillList });
    }

    handleFuncChange(i, e) {
        let functionSkillList = this.state.functionSkillList;
        functionSkillList[i][e.target.name] = e.target.value;
        this.setState({ functionSkillList });
    }

    handleFuncRating(i, rate) {
        // eslint-disable-next-line
        this.state.functionSkillList[i]["rating"] = parseInt(rate) / 20;
    }


    addFuncFormFields(name) {
        this.setState(({
            functionSkillList: [...this.state.functionSkillList, { rating: 0, skill: name }]
        }))

    }

    removeFuncFormFields(i) {
        let functionSkillList = this.state.functionSkillList;
        functionSkillList.splice(i, 1);
        this.setState({ functionSkillList });
    }

    handleOtherChange(i, e) {
        let otherSkillList = this.state.otherSkillList;
        otherSkillList[i][e.target.name] = e.target.value;
        this.setState({ otherSkillList });
    }

    handleOtherRating(i, rate) {
        // eslint-disable-next-line
        this.state.otherSkillList[i]["rating"] = parseInt(rate) / 20;
    }


    addOtherFormFields(name) {
        this.setState(({
            otherSkillList: [...this.state.otherSkillList, { rating: 0, skill: name }]
        }))
    }

    removeOtherFormFields(i) {
        let otherSkillList = this.state.otherSkillList;
        otherSkillList.splice(i, 1);
        this.setState({ otherSkillList });
    }

    handleFetchData = async () => {
        await axiosInstance.get(`${RESUME_DATA_CONTENT_API_LINK}?data_name=skills&job_family=${this.context.basicInfo.job_family}`)
            .then((res) => {
                // eslint-disable-next-line
                res.data.data.results.map((suggestion, index) => {
                    if (suggestion.skill_type === "TECHNICAL SKILLS") {
                        this.setState({ techSkillSuggestions: suggestion.skill_choices })
                    } else if (suggestion.skill_type === "FUNCTIONAL SKILLS") {
                        this.setState({ functionSkillSuggestions: suggestion.skill_choices })
                    } else if (suggestion.skill_type === "OTHER SKILLS") {
                        this.setState({ otherSkillSuggestions: suggestion.skill_choices })
                    }
                })
            });
    }


    handleSubmit(e) {
        e.preventDefault();
        const { dispatch } = this.context;
        this.setState({
            skillsList: {
                // eslint-disable-next-line
                TechSkillList: this.state.techSkillList.filter((skillsList) => { if (skillsList.skill !== '' && skillsList.skill !== 'undefined' && skillsList.skill !== undefined) { return skillsList } }),
                // eslint-disable-next-line
                FunctionSkillList: this.state.functionSkillList.filter((skillsList) => { if (skillsList.skill !== '' && skillsList.skill !== 'undefined' && skillsList.skill !== undefined) { return skillsList } }),
                // eslint-disable-next-line
                OtherSkillList: this.state.otherSkillList.filter((skillsList) => { if (skillsList.skill !== '' && skillsList.skill !== 'undefined' && skillsList.skill !== undefined) { return skillsList } }),
            }
        })
        setTimeout(() => {
            dispatch({
                type: "ADD_SKILLS",
                payload: this.state.skillsList
            });

        }, 1000)
        for (var key in this.context.stepCompleteness) {
            if (this.context.stepCompleteness[key]['resume_step_id'] === Number(this.props.step)) {
                this.context.stepCompleteness[key]['step_status'] = true;
            }
        }
        this.props.navigate(`/rb-step?step=${Number(this.props.step) + 1}`)

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.handleFetchData();
        this.setState({ skillEnable: this.context.skillEnable })
        if (this.context.skills) {
            this.setState({ techSkillList: this.context.skills.TechSkillList })
            this.setState({ functionSkillList: this.context.skills.FunctionSkillList })
            this.setState({ otherSkillList: this.context.skills.OtherSkillList })
        }
    }

    render() {
        const step = Number(this.props.step)

        return (
            <>
                <MobileHeader setIsLoading={this.props.setIsLoading} Title={"Skills"} ProgressBar={this.props.progressBar} StepList={this.props.stepList} Step={Number(this.props.step)} />

                <div className="row ms-lg-0">
                    <div className="col-md-12 col-lg-5 bg-white p-lg-4 position-relative" id="id_left">
                        <LeftHideButton rightHide={this.props.rightHide} rightShow={this.props.rightShow} />
                        <div className="" id="id_left_side">
                            <NotificationCard
                                short_notes={
                                    <>
                                        <p className='mb-1 small'>Hiring Managers scan the resume for specific skills relevant to the role so tailor your skills to the job
                                            description you are targeting. Include relevant skills in separate skill sections. Keep the total number
                                            of skills to a maximum of 10.</p>
                                    </>
                                }
                                Notes={
                                    <>
                                        <p className='mb-1 small'>Hiring Managers scan the resume for specific skills relevant to the role so tailor your skills to the job
                                            description you are targeting. Include relevant skills in separate skill sections. Keep the total number
                                            of skills to a maximum of 10.</p>
                                        <p className='mb-1 small'>Ensure you are really strong on the skills you are adding, hiring managers tend to dive deeper on
                                            those skills you mentioned in the resume during interviews.</p>
                                    </>
                                } video={video} />

                            <form onSubmit={this.handleSubmit}>
                                <div id="job_view" className='mt-3'>
                                    {(this.state.isValid) ? <p className='text-danger'>Please fill the field.</p> : ''}
                                    <p className='mb-0 text-muted small'>TECHNICAL SKILLS</p>
                                    <div className="row mx-lg-0 p-0 m-0 pt-3">
                                        <div className="px-0">
                                            {
                                                this.state.techSkillSuggestions && this.state.techSkillSuggestions.map((suggestion, index) => {
                                                    return <Suggestion key={index} name={suggestion.name} addFormFields={this.addTechFormFields} />
                                                })
                                            }
                                        </div>
                                        <div className="new_add_skill_tech skills_rating p-0 m-0" id="new_add_skill_tech">
                                            {this.state.techSkillList.map((element, index) => (
                                                <div className="mt-3 row p-0 m-0 mx-lg-0 add_skills" key={index}>
                                                    <div className="col-5 col-lg-6 col-xl-5 px-0">
                                                        <Rating ratingValue={element.rating * 20} initialValue={element.rating * 20} onClick={(e) => { this.handleTechRating(index, e) }} size={25} />
                                                    </div>
                                                    <div className="col-5 col-lg-4 col-xl-5 px-0">
                                                        <input type="text" name="skill" id={`skill_${element.skill}`} value={element.skill} onChange={(e) => { this.handleTechChange(index, e) }} className="resume_skills_input form-control"
                                                            placeholder="add skills" />
                                                    </div>
                                                    <button type="button" className="col-2 del_skills" onClick={() => this.removeTechFormFields(index)}><i className="bi bi-trash"></i></button>

                                                </div>
                                            ))}
                                        </div>
                                        <div className="add_skill_tech  p-0 m-0" id="add_skill_tech"></div>
                                    </div>
                                    <div className="d-grid mb-3 add_skills">
                                        <button className=" btn mt-3 add_job_btn add_skills_tech" type='button' id="add_skills_tech"
                                            onClick={() => this.addTechFormFields()}><i className="bi bi-plus"></i> add skill</button>
                                    </div>
                                    <p className='mb-0 text-muted small'>FUNCTIONAL SKILLS</p>
                                    <div className="row mx-lg-0 p-0 m-0 pt-3">
                                        <div className="px-0">
                                            {
                                                this.state.functionSkillSuggestions && this.state.functionSkillSuggestions.map((suggestion, index) => {
                                                    return <Suggestion key={index} name={suggestion.name} addFormFields={this.addFuncFormFields} />
                                                })
                                            }
                                        </div>
                                        <div className="new_add_skill_func p-0 m-0" id="new_add_skill_func">
                                            <div className="mt-3 row p-0 m-0 mx-lg-0">
                                                {this.state.functionSkillList.map((element, index) => (
                                                    <div className="mt-3 row p-0 m-0 mx-lg-0 add_skills" key={index}>
                                                        <div className="col-5 col-lg-6 col-xl-5 px-0">
                                                            <Rating ratingValue={element.rating * 20} initialValue={element.rating * 20} onClick={(e) => { this.handleFuncRating(index, e) }} size={25} />
                                                        </div>
                                                        <div className="col-5 col-lg-4 col-xl-5 px-0">
                                                            <input type="text" name="skill" id={`skill_${element.skill}`} value={element.skill} onChange={(e) => { this.handleFuncChange(index, e) }} className="resume_skills_input form-control"
                                                                placeholder="add skills" />
                                                        </div>
                                                        <button type="button" className="col-2 del_skills" onClick={() => this.removeFuncFormFields(index)}><i className="bi bi-trash"></i></button>

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="add_skill_func p-0 m-0" id="add_skill_func"></div>
                                    </div>
                                    <div className="d-grid mb-3 add_skills">
                                        <button className="btn mt-3 add_job_btn add_skills_func" type='button' id="add_skills_func"
                                            onClick={() => this.addFuncFormFields()}><i className="bi bi-plus"></i> add skill</button>
                                    </div>
                                    <p className='mb-0 text-muted small'>OTHER SKILLS</p>
                                    <div className="row mx-lg-0 p-0 m-0 pt-3">
                                        <div className="px-0">
                                            {
                                                this.state.otherSkillSuggestions && this.state.otherSkillSuggestions.map((suggestion, index) => {
                                                    return <Suggestion key={index} name={suggestion.name} addFormFields={this.addOtherFormFields} />
                                                })
                                            }
                                        </div>
                                        <div className="new_add_skill_other mx-0 px-0" id="new_add_skill_other">
                                            <div className="mt-3 row mx-0 px-0 mx-lg-0">
                                                {this.state.otherSkillList.map((element, index) => (
                                                    <div className="mt-3 row p-0 m-0 mx-lg-0 add_skills" key={index}>
                                                        <div className="col-5 col-lg-6 col-xl-5 px-0">
                                                            <Rating ratingValue={element.rating * 20} initialValue={element.rating * 20} onClick={(e) => { this.handleOtherRating(index, e) }} size={25} />
                                                        </div>
                                                        <div className="col-5 col-lg-4 col-xl-5 px-0">
                                                            <input type="text" name="skill" id={`skill_${element.skill}`} value={element.skill} onChange={(e) => { this.handleOtherChange(index, e) }} className="resume_skills_input form-control"
                                                                placeholder="add skills" />
                                                        </div>
                                                        <button type="button" className="col-2 del_skills" onClick={() => this.removeOtherFormFields(index)}><i className="bi bi-trash"></i></button>

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="add_skill_other p-0 m-0" id="add_skill_other"></div>
                                    </div>
                                    <div className="d-grid">
                                        <button className="mt-3 add_job_btn add_skills_other btn" type='button' id="add_skills_other"
                                            onClick={() => this.addOtherFormFields()}><i className="bi bi-plus"></i> add skill</button>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="enableSkill" aria-checked={this.state.skillEnable}
                                            onChange={(e) => {
                                                this.setState({ skillEnable: e.target.checked })
                                                const { dispatch } = this.context;
                                                dispatch({
                                                    type: "SKILL_ENABLED",
                                                    payload: e.target.checked
                                                })
                                            }} checked={this.state.skillEnable} />
                                        <label className="form-check-label" htmlFor="enableSkill">Enable Level</label>
                                    </div>
                                    <div className="row mx-lg-0 mb-4">
                                        <div className="col">
                                            <NavLink to={`/rb-step?step=${step - 1}`}>
                                                <button type='button' className="btn btn-outline-primary mt-3" onClick={() => { this.props.setProgressBar(50) }}>Back</button>
                                            </NavLink>
                                        </div>

                                        <div className="col">
                                            <button className="btn btn-primary mt-3 float-end" type='submit'>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='d-lg-none'>
                        <div className="d-flex align-items-center mt-2 mb-3 slider close hi-popper-bg">
                            <div className="topright"><span id="hi_pop_close" onClick={sliderclose}><i
                                className="bi bi-x-lg"></i></span>
                            </div>
                            <div className="col-4 mt-5">
                                <img src={process.env.PUBLIC_URL + "/image/hi.png"} width="100" alt='hi' />
                            </div>
                            <div className="col-8 mt-5">
                                <div className="card border-0 m-3" data-popper-placement="right">
                                    <div className="bubble position-relative"></div>
                                    <div className="card-body bg-transparent">
                                        <p>Highlight 6-8 skills that are most relevant to your desired job..</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <RightSideResume />
                </div>
            </>
        );
    }
}


const Suggestion = ({ name, addFormFields }) => {
    return (
        <>
            <span className="badge alert-secondary px-2 m-1 d-inline-block cursor-pointer" onClick={() => { addFormFields(name) }}>{name}</span>
        </>
    )
}

//for mobile hi slider

const sliderclose = () => {
    document.querySelector('.slider').classList.toggle('close');
}

export default withRouter(Skills);