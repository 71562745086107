import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";

const ResumeAwards = ({ templateType, sections, suffle, format, flip }) => {
  const { honours, resumeSkeleton, templateStatus, honoursTitle } = useContext(AuthContext);

  const AwardListCard = ({ awardData, key }) => {
    return (
      <div className="mb-2" key={key}>
        <p className="mb-0 small fw-bold">{awardData.title}</p>
        <p className="mb-0 small">{awardData.section}</p>
        <p className="mb-0 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(awardData.startDate) ? format(awardData.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(awardData.endDate) ? '-' + format(awardData.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
        <div className="small">{awardData.summary}</div>
      </div>
    )
  }

  const resumeAwardsCard = () => {
    if (templateType === "ats_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && honours === '') || honours === null || honours.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeAwards">
            <h5 id="award_res_title" className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" style={{ backgroundColor: '#0000001a' }}>{honoursTitle}</h5>
            <div className="px-3 py-2">
              {
                honours && honours.map((award, index) => {
                  return (
                    <div className="row" key={index}>
                      <div className="col-3">
                        <p className="mb-0 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(award.startDate) ? format(award.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(award.endDate) ? '-' + format(award.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                      </div>
                      <div className="col-9">
                        <p className="mb-0 small fw-bold">{award.title}</p>
                        <p className="mb-0 small">{award.section}</p>
                        <div className="small">{award.summary}</div>
                      </div>
                    </div>
                  )
                })
              }
            </div>

            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>

        </>
      );
    }
    else if (templateType === "exec_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && honours === '') || honours === null || honours.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeAwards">
            <h5 id="award_res_title" className="text-capitalize fw-bold mb-3 text-break exec_classic_border" style={{ color: resumeSkeleton.styles.theme_color }}>{honoursTitle}</h5>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, width: '100px', padding: '1px' }} className="mt-0" />
            {
              honours && honours.map((award, index) => {
                return (

                  <div className="row" key={index}>
                    {/* <div className="col-3">
                      <p className="mb-0 small">{(award.startDate) ? format(award.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(award.endDate) ? '-' + format(award.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</p>
                    </div> */}
                    <div className="col-12">
                      <p className="mb-0 small fw-bold">{award.title}</p>
                      <p className="mb-0 small">{award.section}</p>
                      <div className="small">{award.summary}</div>
                      <p className="mb-0 small"><i style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(award.startDate) ? format(award.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(award.endDate) ? '-' + format(award.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i></p>
                    </div>
                  </div>

                )
              })
            }
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && honours === '') || honours === null || honours.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeAwards">
            <h5 id="award_res_title" className="fw-bold text-capitalize text-break" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{honoursTitle}</h5>
            <hr className="mt-0" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }} />
            <div className="row">
              <div className="col-3"></div>
              <div className="col-9">
                {
                  honours && honours.map((award, index) => {
                    return <AwardListCard key={index} awardData={award} />
                  })
                }
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && honours === '') || honours === null || honours.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeAwards">
            <div className="ps-2 mb-3">
              <h5 id="award_res_title" className="text-uppercase d-flex align-items-center fw-bold mb-3 text-break"><i className="bi bi-award icon_circle_dark" style={(resumeSkeleton.styles.theme_color === '#fff') ? { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#000' } : { background: resumeSkeleton.styles.theme_color, borderColor: resumeSkeleton.styles.theme_color, color: '#fff' }}></i>{honoursTitle}</h5>
              {
                honours && honours.map((awards, index) => {
                  return (
                    <div className="mb-2 ps-1" key={index}>
                      <p className="mb-0 small"><span className="fw-bold">{awards.title}</span></p>
                      <p className="mb-0 text-muted small">{awards.section}</p>
                      <i className="small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(awards.startDate) ? format(awards.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(awards.endDate) ? '-' + format(awards.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i>
                    </div>
                  )
                })
              }
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && honours === '') || honours === null || honours.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeAwards">
            <div className="mb-2">
              <h5 id="award_res_title" className="fw-bold text-uppercase text-break">{honoursTitle}</h5>
              <div className="row">
                <div className="col-12">
                  {
                    honours && honours.map((awards, index) => {
                      return (
                        <div className="mb-2 ps-1" key={index}>
                          <p className="mb-0 small"><span className="fw-bold">{awards.title}</span></p>
                          <p className="mb-0 text-muted small">{awards.section}</p>
                          <i className="small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(awards.startDate) ? format(awards.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(awards.endDate) ? '-' + format(awards.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && honours === '') || honours === null || honours.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeAwards">
            <div className="mb-1">
              <h5 id="award_res_title" className="fw-bold text-capitalize text-break">{honoursTitle}</h5>
              <div className="row">
                <div className="col-12">
                  {
                    honours && honours.map((awards, index) => {
                      return (
                        <div className="mb-2" key={index}>
                          <p className="mb-0 small"><span className="fw-bold">{awards.title}</span></p>
                          <p className="mb-0 text-muted small">{awards.section}</p>
                          <i className="small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(awards.startDate) ? format(awards.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(awards.endDate) ? '-' + format(awards.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && honours === '') || honours === null || honours.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeAwards">
            <div className="mb-1">
              <h5 id="award_res_title" className="fw-bol text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>{honoursTitle}</h5>
              <div className="row">
                <div className="col-12">
                  {
                    honours && honours.map((awards, index) => {
                      return (
                        <div className="mb-2" key={index}>
                          <p className="mb-0 small"><span className="fw-bold">{awards.title}</span></p>
                          <p className="mb-0 text-muted small">{awards.section}</p>
                          <i className="small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(awards.startDate) ? format(awards.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(awards.endDate) ? '-' + format(awards.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && honours === '') || honours === null || honours.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeAwards">
            <div className="mb-1">
              <h5 id="award_res_title" className="fw-bold text-dark text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>{honoursTitle}</h5>
              <div className="row">
                <div className="col-12">
                  {
                    honours && honours.map((awards, index) => {
                      return (
                        <div className="mb-2" key={index}>
                          <p className="mb-0 small"><span className="fw-bold">{awards.title}</span></p>
                          <p className="mb-0 text-muted small">{awards.section}</p>
                          <i className="small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>{(awards.startDate) ? format(awards.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(awards.endDate) ? '-' + format(awards.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && honours === '') || honours === null || honours.length <= 0) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeAwards">
            <div className="mb-1">
              <h5 id="award_res_title" className="fw-bold text-dark text-uppercase text-break ps-2">{honoursTitle}</h5>
              <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, padding: '1px' }} className="mt-0" />
              <div className="row ps-3">
                <div className="col-12">
                  {
                    honours && honours.map((awards, index) => {
                      return (
                        <div className="mb-2" key={index}>
                          <p className="mb-0 small"><span className="fw-bold">{awards.title}</span></p>
                          <p className="mb-0 text-muted small">{awards.section}</p>
                          <i className="small" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}> {(awards.startDate) ? format(awards.startDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''} {(awards.endDate) ? '-' + format(awards.endDate, ((resumeSkeleton.styles.theme_date_format) ? resumeSkeleton.styles.theme_date_format : "dd/mm/yyyy")) : ''}</i>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
  }



  return (
    <>{resumeAwardsCard()}</>
  );
}

export default ResumeAwards;
