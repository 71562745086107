import { Markup } from "interweave";
import React, { useContext } from "react";
import { AuthContext } from "../Services/Authcontext";
const ResumeJobsummary = ({ templateType, sections,suffle }) => {
  const { resumeSkeleton, jobSummary, templateStatus } = useContext(AuthContext);

  const jobSummaryCard = () => {
    if (templateType === "ats_pro") {
      return (

        <li className={((templateStatus === 'PUBLISHED' && jobSummary.profile_summary === '') || jobSummary.profile_summary === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeJobsummary">
          <h5 className="text-capitalize fw-bold bg-primary-dark-heading py-2 px-3 mb-0 text-break" style={{ backgroundColor: '#0000001a' }}>Professional Summary</h5>
          <div className="px-3 py-2" id="jobSummary">
            <Markup content={jobSummary.profile_summary} />
          </div>
          <input type="hidden" name="section_order" value={sections.section_order} />
          <input type="hidden" name="section_name" value={sections.section_name} />
          <input type="hidden" name="section_id" value={sections.section_id} />
          <input type="hidden" name="section_column" value={sections.section_column} />
          <input type="hidden" name="template_type" value={templateType} />
        </li>

      );
    }
    else if (templateType === "ats_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && jobSummary.profile_summary === '') || jobSummary.profile_summary === null) ? "d-none" : "resume_section list-group my-3"} draggable="true" id="ResumeJobsummary">
            <h5 className="fw-bold text-capitalize text-break" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }}>Professional Summary</h5>
            <hr className="mt-0" style={(!suffle) ? (resumeSkeleton.styles.theme_color === '#fff') ? { color: '#5a5a5a' } : (resumeSkeleton.styles.theme_color === "None") ? { color: '#5a5a5a' } : { color: resumeSkeleton.styles.theme_color } : { backgroundColor: 'unset' }} />
            <div id="jobSummary">
              <Markup content={jobSummary.profile_summary} />
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && jobSummary.profile_summary === '') || jobSummary.profile_summary === null) ? "d-none" : "resume_section list-group my-3"} draggable="true" id="ResumeJobsummary">
            <h5 className="text-capitalize fw-bold mb-3 text-break exec_classic_border" style={{ color: resumeSkeleton.styles.theme_color }}>About Me</h5>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color, width: '100px', padding: '1px' }} className="mt-0" />
            <div id="jobSummary">
              <Markup content={jobSummary.profile_summary} />
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "ats_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && jobSummary.profile_summary === '') || jobSummary.profile_summary === null) ? "d-none" : "resume_section list-group my-3"} draggable="true" id="ResumeJobsummary">
            <div id="jobSummary" className="mb-3 d-none">
              <Markup content={jobSummary.profile_summary} />
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && jobSummary.profile_summary === '') || jobSummary.profile_summary === null) ? "d-none" : "resume_section list-group mt-3"} draggable="true" id="ResumeJobsummary">
            <div className="mb-2">
              <h5 className="text-uppercase fw-bold mb-3 text-break">professional summary</h5>
              <div id="jobSummary">
                <Markup content={jobSummary.profile_summary} />
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "exec_pro") {
      return (
        <>

        </>
      );
    }
    else if (templateType === "modern_pro") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && jobSummary.profile_summary === '') || jobSummary.profile_summary === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeJobsummary">
            <div className="mb-3">
              <h5 className="text-capitalize fw-bol mb-3 text-break fs-2" style={{ fontFamily: 'Dancing Script,cursive' }}>profile</h5>
              <div id="jobSummary" className="">
                <Markup content={jobSummary.profile_summary} />
              </div>
            </div>
            <hr style={{ backgroundColor: resumeSkeleton.styles.theme_color }} className="mt-0" />
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_classic") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && jobSummary.profile_summary === '') || jobSummary.profile_summary === null) ? "d-none" : "resume_section list-group mb-3"} draggable="true" id="ResumeJobsummary">
            <div className="">
              <h5 className="fw-bold text-dark text-uppercase text-break" style={{ fontFamily: 'Noto Serif Georgian, serif' }}>profile overview</h5>
              <div id="jobSummary" className="">
                <Markup content={jobSummary.profile_summary} />
              </div>
            </div>
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
    else if (templateType === "modern_std") {
      return (
        <>
          <li className={((templateStatus === 'PUBLISHED' && jobSummary.profile_summary === '') || jobSummary.profile_summary === null) ? "d-none" : "resume_section list-group mb-"} draggable="true" id="ResumeJobsummary">
            <input type="hidden" name="section_order" value={sections.section_order} />
            <input type="hidden" name="section_name" value={sections.section_name} />
            <input type="hidden" name="section_id" value={sections.section_id} />
            <input type="hidden" name="section_column" value={sections.section_column} />
            <input type="hidden" name="template_type" value={templateType} />
          </li>
        </>
      );
    }
  }
  return (
    <>{jobSummaryCard()}</>
  )

}
export default ResumeJobsummary;
