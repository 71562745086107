import React, { } from 'react';
import { NavLink, useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    function clickHandler() {
        navigate("/twoFactor");
    }

    return (
        <div className='bg-login'>
            <div className="cred-card">
                <div className="text-center pb-lg-5 pt-lg-5 pt-3 pb-3 shadow-sm-only">
                    <img src={process.env.PUBLIC_URL + '/image/logo_meritport.svg'} width="220" alt="" />
                </div>
                <div className="container d-flex justify-content-center px-lg-5 mt-5 mt-lg-0 shadow-sm-only">
                    <div className="col-12 col-md-5 align-self-center text-start user_card p-lg-5 rounded-4 bg-white ">
                        <p className="fs-5 fw-bold text-dark text-center">Login</p>
                        <form method="post" action="" noValidate>
                            <div className="form-floating mb-3">
                                <input type="email" className="form-control" id="email" placeholder="Email" />
                                <label htmlFor="email">Email</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="password" className="form-control" id="password" placeholder="Password" />
                                <label htmlFor="password">Password</label>
                            </div>
                            <p className="text-end mb-4">
                                <NavLink className="text-decoration-none" to="/ForgetPassword" >Forgot Password?</NavLink>
                            </p>
                            <div className="d-grid gap-2">
                                <button className="btn btn-lg btn-primary fs-6" onClick={clickHandler}>Login</button>
                                <input type="hidden" name="next" defaultValue="" />
                            </div>
                        </form>
                        <div className="card mt-4">
                            <div className="d-flex align-items-center p-2 justify-content-between">
                                <div>
                                    <span className="p-2"><img src={process.env.PUBLIC_URL + "/image/google_icon.png"} alt="" /></span>
                                    <span className="text-muted">Sign up with Google</span>
                                </div>
                                <img src={process.env.PUBLIC_URL + "/image/icons/next.svg"} width="20px" alt="" />
                            </div>
                        </div>
                        <p className="text-muted text-center mt-4"><span className='text-muted'> Dont have an account?</span> <NavLink className="text-decoration-none" to="/signup">Signup Now!</NavLink></p>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Login;