import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import '../../css/ResumebuilderTemplateSelection.css';
import ChooseTemplates from '../../Components/ChooseTemplates';
import { AuthContext } from '../../Services/Authcontext';
import axiosInstance from '../../Services/axios';
import ChooseTemplateCategory from '../../Components/ChooseTemplateCategory';
import { RESUME_CREATE_API_LINK, RESUME_TEMPLATE_SELECTION_API_LINK, SWITCH_TEMPLATE_API_LINK } from '../../Services/commonurls';

const ResumebuilderTemplateSelection = () => {
    const { dispatch, basicInfo, resumeID } = useContext(AuthContext);
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [templates, setTemplates] = useState([]);
    const [filtertemplates, setFilterTemplates] = useState([]);
    const [empty, setEmpty] = useState(true)

    const fetchTemplates = async () => {
        await axiosInstance.get(`${RESUME_TEMPLATE_SELECTION_API_LINK}?job_family=${basicInfo.job_family}`)
            .then((response) => {
                setTemplates(response.data.data.resume_template_list);
                setFilterTemplates(response.data.data.resume_template_list);
            })
    }

    const handleChangeTemplate = (id) => {
        let data = {
            resume_id: resumeID,
            template_id: id
        };
        axiosInstance.post(SWITCH_TEMPLATE_API_LINK, data).then((res) => {
            templateSkeletonData(res);
        });
    }

    const templateSkeletonData = (res) => {
        localStorage.setItem("ADD_RESUME_SKELETON_COPY", JSON.stringify(res.data.data.resume_skeleton));
        dispatch({
            type: 'ADD_PROFILE_PICTURE',
            payload: { 'picture': res.data.data.resume_data_skeleton.personal_info.profile_picture }
        })
        dispatch({
            type: 'ADD_RESUME_TEMPLATE',
            payload: res.data.data
        });
        dispatch({
            type: 'CITYLIST',
            payload: res.data.data.city_list
        })
        dispatch({
            type: 'COUNTRYLIST',
            payload: res.data.data.country_list
        })
        dispatch({
            type: 'ADD_RESUME_DATA_SKELETON',
            payload: res.data.data.resume_data_skeleton
        });
        dispatch({
            type: 'ADD_RESUME_SKELETON',
            payload: res.data.data.resume_skeleton
        });
        dispatch({
            type: 'ADD_RESUME_TITLE',
            payload: res.data.data.resume_data_skeleton.basic_info.resume_title
        });
        dispatch({
            type: "ADD_BASIC_INFO",
            payload: res.data.data.resume_data_skeleton.basic_info
        });
        dispatch({
            type: 'ADD_JOB_SUMMARY',
            payload: res.data.data.resume_data_skeleton.profile_summary
        });
        dispatch({
            type: 'ADD_WORK_EXPERIENCE',
            payload: res.data.data.resume_data_skeleton.work_experience.work_experience
        });
        dispatch({
            type: 'ADD_EDUCATION',
            payload: res.data.data.resume_data_skeleton.education.education
        });
        dispatch({
            type: 'ADD_CERTIFICATE',
            payload: res.data.data.resume_data_skeleton.certifications.certification
        });
        dispatch({
            type: 'ADD_SKILLS',
            payload: res.data.data.resume_data_skeleton.skills.skill
        });
        dispatch({
            type: 'ADD_COMPETENCIES',
            payload: res.data.data.resume_data_skeleton.competencies.competency
        });
        dispatch({
            type: 'ADD_REFERENCES',
            payload: res.data.data.resume_data_skeleton.reference.reference
        });
        dispatch({
            type: 'ADD_LANGUAGE',
            payload: res.data.data.resume_data_skeleton.language.language
        });
        dispatch({
            type: 'ADD_OTHERS',
            payload: res.data.data.resume_data_skeleton.others
        });
        dispatch({
            type: 'ADD_STEP_COMPLETENESS',
            payload: res.data.data.resume_data_skeleton.step_completeness
        })
        dispatch({
            type: 'ADD_RESUME_SKELETON_SECTION',
            payload: res.data.data.resume_skeleton.sections
        })
        dispatch({
            type: 'ADD_VOLUNTEER',
            payload: res.data.data.resume_data_skeleton.others.volunteering
        })
        dispatch({
            type: 'ADD_HONOURS',
            payload: res.data.data.resume_data_skeleton.others.award
        })
        dispatch({
            type: 'ADD_ACHIEVEMENT',
            payload: res.data.data.resume_data_skeleton.others.achievement
        })
        dispatch({
            type: 'ADD_COCURRICULAR',
            payload: res.data.data.resume_data_skeleton.others.co_curricular
        })
        dispatch({
            type: 'ADD_TRAINING',
            payload: res.data.data.resume_data_skeleton.others.training
        })
        dispatch({
            type: 'ADD_COMPETITION',
            payload: res.data.data.resume_data_skeleton.others.competitions
        })
        dispatch({
            type: 'ADD_PERSONALPROJECT',
            payload: res.data.data.resume_data_skeleton.others.personal_project
        })
        dispatch({
            type: 'ADD_HOBBIE',
            payload: res.data.data.resume_data_skeleton.others.hobbies
        })
        dispatch({
            type: 'ADD_LINKS',
            payload: res.data.data.resume_data_skeleton.others.links
        })
        dispatch({
            type: 'FLIPED_UPDATED',
            payload: res.data.data.resume_skeleton.styles.layout_flip_enabled
        })
        dispatch({
            type: 'SKILL_ENABLED',
            payload: res.data.data.resume_data_skeleton.skills.skillEnable
        })
        dispatch({
            type: 'COMPETENCIES_ENABLED',
            payload: res.data.data.resume_data_skeleton.competencies.competencyEnable
        })
        dispatch({
            type: 'TRAINING_HEADER',
            payload: res.data.data.resume_data_skeleton.others.training_title
        })
        dispatch({
            type: 'COMPETITION_HEADER',
            payload: res.data.data.resume_data_skeleton.others.competitions_title
        })
        dispatch({
            type: 'HOBBIE_HEADER',
            payload: res.data.data.resume_data_skeleton.others.hobbies_title
        })
        dispatch({
            type: 'PERSONALPROJECT_HEADER',
            payload: res.data.data.resume_data_skeleton.others.personal_project_title
        })
        dispatch({
            type: 'VOLUNTEER_HEADER',
            payload: res.data.data.resume_data_skeleton.others.volunteering_title
        })
        dispatch({
            type: 'TRAINING_HEADER',
            payload: res.data.data.resume_data_skeleton.others.training_title
        })
        dispatch({
            type: 'HONOURS_HEADER',
            payload: res.data.data.resume_data_skeleton.others.award_title
        })
        dispatch({
            type: 'ACHIEVEMENT_HEADER',
            payload: res.data.data.resume_data_skeleton.others.achievement_title
        })
        dispatch({
            type: 'COCURRICULAR_HEADER',
            payload: res.data.data.resume_data_skeleton.others.co_curricular_title
        })
        navigate('/rb-step');
    }

    const hadleSelectedTemplate = (id) => {
        const data = JSON.stringify({
            template_id: id,
            job_family: basicInfo.job_family,
            job_group: basicInfo.job_group,
            job_type: basicInfo.job_type,
        });

        axiosInstance.post(RESUME_CREATE_API_LINK, data).then((res) => {
            templateSkeletonData(res);
        });

    }


    const previewImageFunction = (image) => {
        document.getElementById('idPreviewImage').setAttribute("src", image);
    }


    useEffect(() => {
        if (basicInfo) {
            fetchTemplates();
        }
    }, [])

    return (
        <>

            <section className={(empty) ? "bg-chose vh-100" : "vh-lg-100 bg-e5"}>
                <div className="container h-100 p-4">
                    <div className="d-flex justify-content-center pt-4">
                        <div className="col-12 col-md-5 align-self-center text-center">
                            {(empty) ? <h5 className="mb-5 fw-bold">Tell us about your profession</h5> : <h5 className="mb-5 fw-bold">Choose your resume template</h5>}
                        </div>
                        <Link className='position-absolute fw-bold template_close text-decoration-none' to="/myresume">X</Link>
                    </div>

                    <div className="">
                        <div className="row flex-nowrap flex-sm-wrap overflow-auto py-3">
                            {(empty) ? (<>
                                <ChooseTemplateCategory setEmpty={setEmpty} setTemplates={setTemplates} setFilterTemplates={setFilterTemplates} />
                            </>) :
                                (<>
                                    {(filtertemplates.length > 0) ?
                                        (<>
                                            {filtertemplates && filtertemplates.map((template, index) => {
                                                return <ChooseTemplates key={index} template={template} hadleSelectedTemplate={(resumeID && resumeID !== '') ? handleChangeTemplate : hadleSelectedTemplate} previewImageFunction={previewImageFunction} />
                                            })}
                                        </>) :
                                        <p className='text-center fw-bold h-100 d-flex align-items-center justify-content-center'>No Data Found.</p>
                                    }
                                </>)
                            }
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" id="previewModal" tabIndex="-1" aria-labelledby="previewModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content shadow p-0">
                        <div className="modal-header border-0 p-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            <img src="" id='idPreviewImage' width={'100%'} height={'100%'} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResumebuilderTemplateSelection;