import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../Services/Authcontext';
import axiosInstance from '../Services/axios';
import Navbar from './Navbar';
import Sidebar from './sidebar';
import Loading from './Loading';
import { isEmptyObject } from 'jquery';
import { RESUME_CLONE_API_LINK, RESUME_DELETE_API_LINK, RESUME_DOWNLOAD_API_LINK, RESUME_LIST_API_LINK, MAX_RESUMES_LIMIT, RESUME_VIEW_API_LINK, USER_INFO_API_LINK } from '../Services/commonurls';


const MyResume = () => {
    const { dispatch, CURRENT_RESUMES_COUNT } = useContext(AuthContext);
    const [userDetails, setuserDetails] = useState('');
    const [myresumeList, setmyresumeList] = useState('');
    const [deleteresumeId, setdeleteresumeId] = useState('');
    const [load, setload] = useState(true);

    const navigate = useNavigate();
    const fetchUserDetails = async () => {
        await axiosInstance.get(USER_INFO_API_LINK).then((res) => {
            setuserDetails(res.data.data);
            dispatch({
                type: 'ADD_USER_DETAILS',
                payload: res.data.data,
            });
            // eslint-disable-next-line
            String.prototype.toCapitalize = function () {
                return this.toLowerCase().replace(/^.|\s\S/g, function (a) { return a.toUpperCase(); });
            }
            if (res.data.data) {
                let data = {
                    is_terms_verified: true,
                    address_line_1: res.data.data.user_social_profile.address,
                    address_line_2: res.data.data.user_social_profile.address_2,
                    city: res.data.data.user_social_profile.living_city,
                    state: res.data.data.user_social_profile.living_state,
                    country: (res.data.data.user_social_profile.living_country) ? res.data.data.user_social_profile.living_country.toCapitalize() : '',
                    dob: res.data.data.user_social_profile.dob,
                    email: res.data.data.email,
                    // first_name: (res.data.data.user_kyc_profile.first_name !== null && res.data.data.user_kyc_profile.first_name !== '') ? res.data.data.user_kyc_profile.first_name : res.data.data.first_name,
                    first_name: res.data.data.first_name,
                    gender: res.data.data.user_social_profile.gender,
                    // last_name: (res.data.data.user_kyc_profile.last_name !== null && res.data.data.user_kyc_profile.last_name !== '') ? res.data.data.user_kyc_profile.last_name : res.data.data.last_name,
                    last_name: res.data.data.last_name,
                    phone_number: res.data.data.user_kyc_profile.phone_number,
                    postalcode: res.data.data.user_social_profile.postalcode,
                    profile_picture: res.data.data.user_kyc_profile.avatar,
                    show_profile_picture: res.data.data.user_kyc_profile.show_profile_picture,
                    // profile: ImageName,
                }
                dispatch({
                    type: "ADD_PERSONAL_INFORMATION",
                    payload: data
                })
            }
        });
    }
    const apply = (resumeId) => {
        dispatch({
            type: 'ADD_RESUMEID',
            payload: resumeId,
        });
        navigate('/apply');
    }
    const MyresumeCard = ({ resumeTemplateImage, resumeTitle, resumeType, createdOn, resumeId, editResumeTemplate, deleteResumeTemplateId, cloneResumes, preview, pdf }) => {
        return (
            <div className="col-lg-4 mb-3 d-lg-block  bg-white-sm-only border-sm-only px-0 px-lg-2 rounded-4-sm-only">
                <div className="card p-0 border-0">
                    <img src={resumeTemplateImage} className="mb-3 overlay-img" width="100%" alt="" />
                    <div className="overlay">
                        <div className="ps d-lg-flex align-items-center">
                            <i className="mb-2 fs-6 bi-trash rounded-pill pd-overlay-icon border me-2 z-index" title='Delete' onClick={() => { deleteResumeTemplateId(resumeId) }} role="button" data-bs-toggle="modal" data-bs-target="#deleteModal"></i>
                            <i className="mb-2 fs-6 bi-eye rounded-pill pd-overlay-icon border me-2 z-index text-white" onClick={() => { downloadResume(resumeId) }} role="button" title='Preview'></i>
                            <i className="mb-2 fs-6 bi-clipboard rounded-pill pd-overlay-icon border me-2 z-index" title='Clone' onClick={() => { cloneResumes(resumeId) }} role="button"></i>
                            <i className="mb-2 fs-6 bi-pencil rounded-pill pd-overlay-icon border me-2 z-index" title='Edit' role="button" onClick={() => { editResumeTemplate(resumeId) }}></i>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center my-3 px-2 px-lg-0">
                    <div>
                        <h6 className="mb-0 fw-bold small text-wrap">{resumeId}_{resumeType}_{resumeTitle}</h6>
                        <p className="text-muted small mb-0">Created On {createdOn}</p>
                    </div>
                    <div className='text-end d-flex flex-column'>
                        <button className='btn btn-outline-primary btn-sm mb-2' disabled={(preview === 'DRAFT') ? true : false} onClick={() => { apply(resumeId) }}>Apply</button>
                        <button onClick={() => { downloadResume(resumeId) }} className="btn btn-sm alert-primary text-nowrap"><i className="bi bi-download me-2"></i> Download </button>
                    </div>
                </div>

            </div>
        )
    }

    const downloadResume = (resumeId) => {
        let data = { "resume_id": resumeId }
        setload(true);
        axiosInstance.post(RESUME_DOWNLOAD_API_LINK, data).then((res) => {
            // let url = { "resume_url": res.data.data.resume_url }
            var isIphone = /(iPhone)/i.test(navigator.userAgent);
            // eslint-disable-next-line
            var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

            if (isIphone && isSafari) {

                let link = document.createElement('a');
                link.setAttribute('href', res.data.data.resume_url);
                link.click();
                link.remove();
            }
            else {
                let link = document.createElement('a');
                link.setAttribute('target', '_blank');
                link.setAttribute('rel', 'noopener noreferrer');
                link.setAttribute('href', res.data.data.resume_url);
                link.click();
                link.remove();
            }
            setload(false);
        });
    }

    const editResumeTemplate = async (resumeId) => {
        await axiosInstance.get(`${RESUME_VIEW_API_LINK}${resumeId}/`)
            .then((res) => {
                localStorage.setItem("ADD_RESUME_SKELETON_COPY", JSON.stringify(res.data.data.resume_skeleton));
                dispatch({
                    type: 'ADD_PROFILE_PICTURE',
                    payload: res.data.data.resume_data_skeleton.personal_info.profile_picture
                })
                dispatch({
                    type: 'CITYLIST',
                    payload: res.data.data.city_list
                })
                dispatch({
                    type: 'COUNTRYLIST',
                    payload: res.data.data.country_list
                })
                dispatch({
                    type: 'ADD_RESUME_TEMPLATE',
                    payload: res.data.data
                });
                dispatch({
                    type: 'ADD_RESUME_DATA_SKELETON',
                    payload: res.data.data.resume_data_skeleton
                });
                dispatch({
                    type: 'ADD_RESUME_SKELETON',
                    payload: res.data.data.resume_skeleton
                });
                dispatch({
                    type: 'ADD_RESUME_TITLE',
                    payload: res.data.data.resume_data_skeleton.basic_info.resume_title
                });
                dispatch({
                    type: "ADD_BASIC_INFO",
                    payload: res.data.data.resume_data_skeleton.basic_info
                });
                dispatch({
                    type: 'ADD_JOB_SUMMARY',
                    payload: res.data.data.resume_data_skeleton.profile_summary
                });
                dispatch({
                    type: 'ADD_WORK_EXPERIENCE',
                    payload: res.data.data.resume_data_skeleton.work_experience.work_experience
                });
                dispatch({
                    type: 'ADD_EDUCATION',
                    payload: res.data.data.resume_data_skeleton.education.education
                });
                dispatch({
                    type: 'ADD_CERTIFICATE',
                    payload: res.data.data.resume_data_skeleton.certifications.certification
                });
                dispatch({
                    type: 'ADD_SKILLS',
                    payload: res.data.data.resume_data_skeleton.skills.skill
                });
                dispatch({
                    type: 'ADD_COMPETENCIES',
                    payload: res.data.data.resume_data_skeleton.competencies.competency
                });
                dispatch({
                    type: 'ADD_REFERENCES',
                    payload: res.data.data.resume_data_skeleton.reference.reference
                });
                dispatch({
                    type: 'ADD_LANGUAGE',
                    payload: res.data.data.resume_data_skeleton.language.language
                });
                dispatch({
                    type: 'ADD_OTHERS',
                    payload: res.data.data.resume_data_skeleton.others
                });
                dispatch({
                    type: 'ADD_STEP_COMPLETENESS',
                    payload: res.data.data.resume_data_skeleton.step_completeness
                })
                dispatch({
                    type: 'ADD_RESUME_SKELETON_SECTION',
                    payload: res.data.data.resume_skeleton.sections
                })
                dispatch({
                    type: 'ADD_VOLUNTEER',
                    payload: res.data.data.resume_data_skeleton.others.volunteering
                })
                dispatch({
                    type: 'ADD_HONOURS',
                    payload: res.data.data.resume_data_skeleton.others.award
                })
                dispatch({
                    type: 'ADD_ACHIEVEMENT',
                    payload: res.data.data.resume_data_skeleton.others.achievement
                })
                dispatch({
                    type: 'ADD_COCURRICULAR',
                    payload: res.data.data.resume_data_skeleton.others.co_curricular
                })
                dispatch({
                    type: 'ADD_TRAINING',
                    payload: res.data.data.resume_data_skeleton.others.training
                })
                dispatch({
                    type: 'ADD_COMPETITION',
                    payload: res.data.data.resume_data_skeleton.others.competitions
                })
                dispatch({
                    type: 'ADD_PERSONALPROJECT',
                    payload: res.data.data.resume_data_skeleton.others.personal_project
                })
                dispatch({
                    type: 'ADD_HOBBIE',
                    payload: res.data.data.resume_data_skeleton.others.hobbies
                })
                dispatch({
                    type: 'ADD_LINKS',
                    payload: res.data.data.resume_data_skeleton.others.links
                })
                dispatch({
                    type: 'ADD_TEMPLATE_STATUS',
                    payload: res.data.data.resume_info.status
                })
                dispatch({
                    type: 'FLIPED_UPDATED',
                    payload: res.data.data.resume_skeleton.styles.layout_flip_enabled
                })
                dispatch({
                    type: 'SKILL_ENABLED',
                    payload: res.data.data.resume_data_skeleton.skills.skillEnable
                })
                dispatch({
                    type: 'COMPETENCIES_ENABLED',
                    payload: res.data.data.resume_data_skeleton.competencies.competencyEnable
                })
                dispatch({
                    type: 'TRAINING_HEADER',
                    payload: res.data.data.resume_data_skeleton.others.training_title
                })
                dispatch({
                    type: 'COMPETITION_HEADER',
                    payload: res.data.data.resume_data_skeleton.others.competitions_title
                })
                dispatch({
                    type: 'HOBBIE_HEADER',
                    payload: res.data.data.resume_data_skeleton.others.hobbies_title
                })
                dispatch({
                    type: 'PERSONALPROJECT_HEADER',
                    payload: res.data.data.resume_data_skeleton.others.personal_project_title
                })
                dispatch({
                    type: 'VOLUNTEER_HEADER',
                    payload: res.data.data.resume_data_skeleton.others.volunteering_title
                })
                dispatch({
                    type: 'TRAINING_HEADER',
                    payload: res.data.data.resume_data_skeleton.others.training_title
                })
                dispatch({
                    type: 'HONOURS_HEADER',
                    payload: res.data.data.resume_data_skeleton.others.award_title
                })
                dispatch({
                    type: 'ACHIEVEMENT_HEADER',
                    payload: res.data.data.resume_data_skeleton.others.achievement_title
                })
                dispatch({
                    type: 'COCURRICULAR_HEADER',
                    payload: res.data.data.resume_data_skeleton.others.co_curricular_title
                })
                navigate('/rb-step')
            })

    }

    const fetchMyresumes = async () => {
        await axiosInstance.get(RESUME_LIST_API_LINK).then((res) => {
            if (res.data.data) {
                if (isEmptyObject(res.data.data.results)) {
                    navigate('/rb-creation-option')
                }
                setmyresumeList(res.data.data.results);
                setload(false);
                dispatch({
                    type: 'ADD_RESUMELIST',
                    payload: res.data.data.results,
                });
                dispatch({
                    type: 'RESUMECOUNT',
                    payload: res.data.data.results.length,
                });
            }
        });
    }

    const cloneResumes = async (resumeId) => {
        let data = { 'resume_id': resumeId };
        if (CURRENT_RESUMES_COUNT < MAX_RESUMES_LIMIT) {
            dispatch({
                type: 'RESUMECOUNT',
                payload: CURRENT_RESUMES_COUNT + 1,
            });
            await axiosInstance.post(RESUME_CLONE_API_LINK, data).then((res) => {
                if (res.data.status_code === 200) {
                    myresumeList.filter(
                        myresumeLists => {
                            if (myresumeLists.uid === resumeId) {
                                myresumeLists.resume_title = "Cloned_" + myresumeLists.resume_title;
                                setmyresumeList([...myresumeList, myresumeLists])
                            }
                            return ''
                        }

                    )
                }
            });
        } else {
            document.getElementById('saveBtn').click();
        }
    }

    const deleteResumeTemplateId = (resumeId) => {
        setdeleteresumeId(resumeId);
    }

    const deleteResumeTemplate = () => {
        let data = { 'resume_id': deleteresumeId };
        axiosInstance.post(RESUME_DELETE_API_LINK, data).then((res) => {
            if (res.data.status_code === 200) {
                const newResume = myresumeList.filter(myresumeList => myresumeList.uid !== deleteresumeId)
                setmyresumeList(newResume);
            }
        });
        dispatch({
            type: 'RESUMECOUNT',
            payload: CURRENT_RESUMES_COUNT - 1,
        });
    }

    useEffect(() => {
        dispatch({
            type: 'ADD_BASIC_INFO',
            payload: '',
        });

        fetchUserDetails();
        fetchMyresumes();
    }, [])


    return (
        (load) ? <Loading /> :

            <>
                <Navbar status={true} userDetails={userDetails} />
                <div className="main">
                    <div className="container-fluid mt-6 p-0">
                        <div className="row mx-0">
                            <Sidebar />
                            <div className={(myresumeList.length > 0) ? "col-lg-9 bg-ab" : "col-lg-9 bg-ab"}>
                                <p className="my-4 px-3 px-lg-0 py-2"><span className="fw-bold">Hey {userDetails && (userDetails.first_name && userDetails.last_name) ? userDetails.first_name + ' ' + userDetails.last_name : userDetails.username}!</span><br /> Great to see you again!</p>
                                <div className="row px-4 px-lg-0 pb-4  ">
                                    {(myresumeList.length > 0) ? myresumeList.map((myresume, index) => {
                                        return <MyresumeCard key={index}
                                            resumeTemplateImage={(myresume.resume_document_image && myresume.resume_document_image !== '' && myresume.resume_document_image !== null) ? myresume.resume_document_image : myresume.template_info.photo_thumbnail}
                                            cloneResumes={cloneResumes} editResumeTemplate={editResumeTemplate}
                                            deleteResumeTemplateId={deleteResumeTemplateId}
                                            resumeId={myresume.uid}
                                            resumeTitle={myresume.resume_title}
                                            resumeType={myresume.template_info.name}
                                            preview={myresume.status}
                                            createdOn={myresume.created_on}
                                            pdf={myresume.resume_document_pdf}
                                        />
                                    }) :
                                        (<>
                                            <div className='text-center d-flex align-items-center justify-content-center' style={{ height: '70vh' }}>
                                                <p className=''>No Resume Found.</p>
                                            </div>
                                        </>)
                                    }
                                </div>
                            </div>
                            <button type="button" className="btn-primary-vlight px-5 fw-bold d-none" data-bs-toggle="modal" data-bs-target="#maxAlertModal" id='saveBtn'>SAVE</button>
                            <div className="p-0 h-100 d-lg-none d-block">
                                <div className="">
                                    <Link to="/rb-creation-option">
                                        <img src={process.env.PUBLIC_URL + "/image/sidebar_img.png"} width="100%" height="100%" alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content border-0 rounded-3 shadow">
                            <div className="modal-body text-center">
                                <p className=''>Do you wish to <span className='fw-bold'>delete</span> this resume <span className='fw-bold'>permanently?</span></p>
                                <div className="">
                                    <button className='btn btn-light me-2 px-4' data-bs-dismiss="modal">Cancel</button>
                                    <button className='btn btn-danger px-4' onClick={() => { deleteResumeTemplate() }} data-bs-dismiss="modal">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="maxAlertModal" tabIndex="-1" aria-labelledby="maxAlertModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content border-0 rounded-3 shadow">
                            <div className="modal-body text-center py-5">
                                <i className="bi bi-info-circle fs-4 mb-4 text-primary"></i>
                                {/* <img src={process.env.PUBLIC_URL + '/image/icons/check-circle.svg'} width="50px" className="mb-3" alt="" /> */}
                                <p className='text-dark'>You have reached the maximum limit ({MAX_RESUMES_LIMIT} resumes). <br /> Please contact our support to remove this limitation.</p>
                                <div className="">
                                    <button className='btn btn-sm btn-primary' data-bs-dismiss="modal">OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>


    );
};

export default MyResume;
