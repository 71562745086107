import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../Services/Authcontext';
import axiosInstance from '../Services/axios';
import { RESUME_DOWNLOAD_API_LINK, RESUME_PDF_DELETE_API_LINK, RESUME_SAVE_API_LINK, USER_INFO_API_LINK } from '../Services/commonurls';
import Loading from './Loading';
import { withRouter } from './WithRouter';


class CreateResumeNavbar extends React.Component {
    static contextType = AuthContext


    constructor(prop) {
        super(prop)
        this.state = {
            isActive: false, userDetails: '', loading: false,
        };
        this.handleToggle = this.handleToggle.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }
    handleToggle() {
        this.setState({ isActive: !this.state.isActive });
    };
    handleRedirect = (page) => {
        switch (page) {
            case 'logout':
                window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_LOGOUT_URL : process.env.REACT_APP_PROD_LOGOUT_URL;
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('accessToken')
                break;
            case 'my-profile':
                window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_WEBSITE_URL + `account/${page}` : process.env.REACT_APP_PROD_WEBSITE_URL + `account/${page}`;
                break;
            case 'overview':
                window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_WEBSITE_URL + `account/${page}` : process.env.REACT_APP_PROD_WEBSITE_URL + `account/${page}`;
                break;
            default:
                window.location.href = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_WEBSITE_URL + `${page}` : process.env.REACT_APP_PROD_WEBSITE_URL + `account/${page}`;
                break;
        }
    }
    fetchUserDetails = async () => {
        await axiosInstance.get(USER_INFO_API_LINK).then((res) => {
            this.setState({ userDetails: res.data.data });
        });
    }
    componentDidMount() {
        this.fetchUserDetails();
    }

    handleSave() {
        document.getElementById("pdf").style.zoom = 'unset';
        this.props.setIsLoading(true)
        const { dispatch, resumeID, basicInfo, jobSummary, personalInformation, education, workExperience, certificate, skills, competencies, references, languages, resumeSkeleton, section, stepCompleteness, links, resumeTitle, volunteer, honours, achievements, cocurricular, training, competitions, personalProject, hobbies, skillEnable,
            honoursTitle,
            volunteerTitle,
            achievementsTitle,
            hobbiesTitle,
            personalProjectTitle,
            cocurricularTitle,
            competitionsTitle,
            trainingTitle,
            competencyEnable
        } = this.context;
        if (personalInformation.first_name === '' || personalInformation.first_name === null) {
            if (document.getElementById('ResumePersonaldetails')) {
                document.getElementById('ResumePersonaldetails').classList.add('d-none')
            }
        }
        if (jobSummary.profile_summary === '' || jobSummary.profile_summary === null) {
            if (document.getElementById('ResumeJobsummary')) {
                document.getElementById('ResumeJobsummary').classList.add('d-none')
            }
        }
        if (education === '' || education === null) {
            if (document.getElementById('ResumeEducation')) {
                document.getElementById('ResumeEducation').classList.add('d-none')
            }
        }
        if (certificate === '' || certificate === null || certificate.length === 0) {
            if (document.getElementById('ResumeCertifications')) {
                document.getElementById('ResumeCertifications').classList.add('d-none')
            }
        }
        if (cocurricular === '' || cocurricular === null) {
            if (document.getElementById('ResumeCoactivities')) {
                document.getElementById('ResumeCoactivities').classList.add('d-none')
            }
        }
        if (competencies === '' || competencies === null || (competencies.OtherList.length === 0 && competencies.behavioralList.length === 0 && competencies.techList.length === 0)) {
            if (document.getElementById('ResumeCompetencies')) {
                document.getElementById('ResumeCompetencies').classList.add('d-none')
            }
        }
        if (competitions === '' || competitions === null) {
            if (document.getElementById('ResumeCompetitions')) {
                document.getElementById('ResumeCompetitions').classList.add('d-none')
            }
        }
        if (hobbies === '' || hobbies === null) {
            if (document.getElementById('ResumeHobbies')) {
                document.getElementById('ResumeHobbies').classList.add('d-none')
            }
        }
        if (honours === '' || honours === null) {
            if (document.getElementById('ResumeAwards')) {
                document.getElementById('ResumeAwards').classList.add('d-none')
            }
        }
        if (languages === '' || languages === null || languages.length === 0) {
            if (document.getElementById('ResumeLanguage')) {
                document.getElementById('ResumeLanguage').classList.add('d-none')
            }
        }
        if (personalProject === '' || personalProject === null) {
            if (document.getElementById('ResumePersonalprojects')) {
                document.getElementById('ResumePersonalprojects').classList.add('d-none')
            }
        }
        if (references === '' || references === null || references.length === 0) {
            if (document.getElementById('ResumeReference')) {
                document.getElementById('ResumeReference').classList.add('d-none')
            }
        }
        if (training === '' || training === null) {
            if (document.getElementById('ResumeTraining')) {
                document.getElementById('ResumeTraining').classList.add('d-none')
            }
        }
        if (volunteer === '' || volunteer === null) {
            if (document.getElementById('ResumeVolunteer')) {
                document.getElementById('ResumeVolunteer').classList.add('d-none')
            }
        }
        if (skills === '' || skills === null || (skills.FunctionSkillList.length === 0 && skills.OtherSkillList.length === 0 && skills.TechSkillList.length === 0)) {
            if (document.getElementById('ResumeSkills')) {
                document.getElementById('ResumeSkills').classList.add('d-none')
            }
        }
        if (achievements === '' || achievements === null) {
            if (document.getElementById('ResumeAchievements')) {
                document.getElementById('ResumeAchievements').classList.add('d-none')
            }
        }
        if (workExperience === '' || workExperience === null) {
            if (document.getElementById('ResumeExperience')) {
                document.getElementById('ResumeExperience').classList.add('d-none')
            }
        }
        if (links === '' || links === null || links.length === 0) {
            if (document.getElementById('ResumeLinks')) {
                document.getElementById('ResumeLinks').classList.add('d-none')
            }
        }
        dispatch({
            type: 'DATA_UPDATED',
            payload: false
        })
        dispatch({
            type: 'ADD_RESUME_DATA',
            payload: document.querySelector('#mainContainer').innerHTML
        })
        if (Number(this.props.step) === 10) {
            for (var key in this.context.stepCompleteness) {
                if (this.context.stepCompleteness[key]['resume_step_id'] === Number(this.props.step)) {
                    this.context.stepCompleteness[key]['step_status'] = true;
                }
            }
        }
        localStorage.setItem("PDF", document.querySelector('#mainContainer').innerHTML)

        const data = {
            "resume_id": resumeID,
            "resume_document_html": document.querySelector('#mainContainer').innerHTML,
            "basic_info": {
                "job_family": basicInfo.job_family,
                "job_group": basicInfo.job_group,
                "job_type": basicInfo.job_type,
                "preferred_data_format": basicInfo.preferred_data_format,
                "resume_title": resumeTitle,
            },
            "personal_info": personalInformation,
            "profile_summary": {
                "profile_summary": jobSummary.profile_summary
            },
            "work_experience": {
                "work_experience": workExperience
            },
            "education": {
                "education": education
            },
            "certifications": {
                "certification": certificate
            },
            "skills": {
                "skill": skills,
                "skillEnable": skillEnable,

            },
            "competencies": {
                "competency": competencies,
                "competencyEnable": competencyEnable,
            },
            "reference": {
                "reference": references
            },
            "language": {
                "language": languages
            },
            "others": {
                "award": honours,
                "volunteering": volunteer,
                "achievement": achievements,
                "hobbies": hobbies,
                "personal_project": personalProject,
                "co_curricular": cocurricular,
                "competitions": competitions,
                "training": training,
                "links": links,
                "training_title": trainingTitle,
                "competitions_title": competitionsTitle,
                "hobbies_title": hobbiesTitle,
                "personal_project_title": personalProjectTitle,
                "volunteering_title": volunteerTitle,
                "award_title": honoursTitle,
                "achievement_title": achievementsTitle,
                "co_curricular_title": cocurricularTitle,
            },
            "step_completeness": stepCompleteness,
            // "resume_skeleton": {
            //     "styles": resumeSkeleton.styles,
            //     "sections": section
            // },
            "resume_skeleton": JSON.parse(localStorage.getItem("ADD_RESUME_SKELETON_COPY"))
        }

        axiosInstance.post(RESUME_SAVE_API_LINK, data).then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: 'ADD_RESUME_SKELETON',
                    payload: {
                        "styles": resumeSkeleton.styles,
                        "sections": section
                    }
                });
                dispatch({
                    type: 'ADD_RESUME_SKELETON_SECTION',
                    payload: section
                });
                dispatch({
                    type: 'ADD_RESUME_SKELETON_SECTION_LEFT',
                    payload: section.left_sec
                });
                dispatch({
                    type: 'ADD_RESUME_SKELETON_SECTION_RIGHT',
                    payload: section.right_sec
                });
                this.props.setIsLoading(false)
                if (this.context.isUpdated === false) {
                    document.getElementById('saveBtn').click();
                }
            }
        });
    }
    redirectMyresume = () => {
        this.props.navigate('/myresume')
        window.location.reload();
    }
    redirectTemplateSelectio = () => {
        this.handleSave();
        this.props.navigate('/rb-template-selection')
    }
    downloadResume(resumeId) {
        let data = { "resume_id": resumeId }
        this.props.setIsLoading(true)
        axiosInstance.post(RESUME_DOWNLOAD_API_LINK, data).then((res) => {
            let url = { "resume_url": res.data.data.resume_url }
            var isIphone = /(iPhone)/i.test(navigator.userAgent);
            // eslint-disable-next-line
            var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
            if (isIphone && isSafari) {
                // var popup_window = window.open(res.data.data.resume_url, '_blank');
                // try {
                //     popup_window.focus();
                // } catch (e) {
                //     alert("Pop-up Blocker is enabled! Please add this site to your exception list.");
                // }

                let link = document.createElement('a');
                link.setAttribute('href', res.data.data.resume_url);
                link.click();
                link.remove();
                
            }
            else {
                let link = document.createElement('a');
                link.setAttribute('target', '_blank');
                link.setAttribute('rel', 'noopener noreferrer');
                link.setAttribute('href', res.data.data.resume_url);
                link.click();
                link.remove();
            }
            this.props.setIsLoading(false)
            setTimeout(() => {
                axiosInstance.post(RESUME_PDF_DELETE_API_LINK, url).then((res) => {
                })
            }, 5000);
        });
    }
    render() {
        const isActive = this.state.isActive;

        return (
            <>
                {(this.state.loading) ? <Loading /> :
                    <div className="shadow-sm fixed-top">
                        <nav className="navbar navbar-expand-lg navbar-light bg-white p-0">
                            <div className="container-fluid py-2 py-md-0 pe-lg-0">
                                <button type="button" className='border-0 bg-white d-lg-none' onClick={this.handleToggle}>
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="navbar-brand d-none d-lg-block">
                                    <img src={process.env.PUBLIC_URL + "/image/logo_meritport.svg"} width="170" alt="" />
                                </div>
                                <div className="navbar-brand mx-auto py-2 d-lg-none">
                                    <img src={process.env.PUBLIC_URL + "/image/logo_meritport.svg"} width="170" alt="" />
                                </div>
                                <div className={isActive ? "bg-primary-light-gradient d-lg-none position-absolute top-0 start-0 vh-100 w-100" : "bg-primary-light-gradient d-lg-none position-absolute top-0 start-0 vh-100"} id="navbarNav_1">
                                    <div className={isActive ? " p-3 app" : 'd-none'}>
                                        <div className="row align-items-center mt-2 w-100">
                                            <div className="col-3">
                                                <div className="position-relative d-flex justify-content-center align-items-center flexDirection">
                                                    <img src={(this.state.userDetails && this.state.userDetails.user_kyc_profile && this.state.userDetails.user_kyc_profile.avatar !== '' && this.state.userDetails.user_kyc_profile.avatar !== null) ? this.state.userDetails.user_kyc_profile.avatar : process.env.PUBLIC_URL + "/image/default_user.png"} className="rounded-circle p-2 border" width="100%" alt='profile' />
                                                </div>
                                            </div>
                                            <div className="col-7">
                                                <h4 className="mt-4 mb-1">{this.state.userDetails && (this.state.userDetails.first_name && this.state.userDetails.last_name) ? this.state.userDetails.first_name + ' ' + this.state.userDetails.last_name : this.state.userDetails.username}</h4>
                                                <p>
                                                    {(this.state.userDetails && this.state.userDetails.email !== '') ? this.state.userDetails.email : ''}
                                                </p>
                                            </div>
                                            <div className="col-2 text-center icons_size" onClick={this.handleToggle}>
                                                <i className="bi bi-x-lg"></i>
                                            </div>

                                        </div>
                                        <div className='mt-3'>
                                            <NavLink to='/myresume' className='text-white text-decoration-none'>My Resumes</NavLink>
                                            <hr />
                                            <NavLink to='/apply' className='text-white text-decoration-none'>Apply</NavLink>
                                            <hr />
                                            <NavLink to='#' onClick={() => { this.handleRedirect('overview') }} className='text-white text-decoration-none'>Account Settings</NavLink>
                                            <hr />
                                            <div className='mt-5'>

                                                <NavLink to='#' onClick={() => { this.handleRedirect('help') }} className='text-white text-decoration-none'>Help Center</NavLink>
                                                <hr />
                                                <NavLink to='#' onClick={() => { this.handleRedirect('contact') }} className='text-white text-decoration-none'>Contact Us</NavLink>
                                                <hr />
                                            </div>
                                            <div className='mt-5'>
                                                <NavLink to='#' onClick={() => { this.handleRedirect('logout') }} className='text-white text-decoration-none'>Logout</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-none d-lg-block">
                                    <div className="d-flex">
                                        <button type="button" title='Download' className="btn-success-vlight px-5 fw-bold text-decoration-none d-flex align-items-center" onClick={() => { this.downloadResume(this.context.resumeID) }}>DOWNLOAD</button>
                                        <button type="button" className="btn-primary-vlight px-5 fw-bold" id='saveBtnMain' title='Save' onClick={this.handleSave}>SAVE</button>
                                        {

                                            (this.context.resumeDataSkeleton && this.context.resumeDataSkeleton.resume_document_pdf !== '' && this.context.isUpdated === false) ? (<>
                                                <Link to={'/myresume'} id='closeBtn' title='Close' className='border-0 px-4 bg-white text-dark d-flex align-items-center justify-content-center'><i className="bi bi-x-lg"></i></Link>
                                            </>) : (<>
                                                <button type="button" id='closeBtn' title='Close' className='border-0 px-4 bg-white' data-bs-toggle="modal" data-bs-target="#closeAlertModal"><i className="bi bi-x-lg"></i></button>
                                            </>)
                                        }

                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                }
                <button type="button" className="btn-primary-vlight px-5 fw-bold d-none" data-bs-toggle="modal" data-bs-target="#successModal" id='saveBtn'>SAVE</button>

                <div className="modal fade" id="successModal" tabIndex="-1" aria-labelledby="successModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content border-0 rounded-3 shadow">
                            <div className="modal-body text-center py-5">
                                <img src={process.env.PUBLIC_URL + '/image/icons/check-circle.svg'} width="50px" className="mb-3" alt="" />
                                <p className='text-dark'>Resume has been saved successfully!</p>
                                <div className="">
                                    <button className='btn btn-sm btn-primary' data-bs-dismiss="modal">OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="closeAlertModal" tabIndex="-1" aria-labelledby="closeAlertModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content border-0 rounded-3 shadow">
                            <div className="modal-body text-center p-4">
                                <i className="bi bi-info-circle fs-3 text-primary mb-2"></i>
                                <p className='text-dark fw-bold mb-1'>Do you want without saving?</p>
                                <p className='small'>Changes that you made may not be saved.</p>
                                <div className="">
                                    <button className='btn btn-sm btn-light me-2 px-4' onClick={() => { this.redirectMyresume() }}>Yes</button>
                                    <button className='btn btn-sm btn-danger px-4' data-bs-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="templateChangeAlertModal" tabIndex="-1" aria-labelledby="templateChangeAlertModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content border-0 rounded-3 shadow">
                            <div className="modal-body text-center p-4">
                                <i className="bi bi-info-circle fs-3 text-primary mb-2"></i>
                                <p className='text-dark fw-bold mb-1'>Your make changes</p>
                                <p className='small'>Changes that you made may not be saved.</p>
                                <div className="">
                                    <button className='btn btn-sm btn-light me-2 px-4' onClick={() => { this.redirectTemplateSelectio() }} data-bs-dismiss="modal">Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default withRouter(CreateResumeNavbar);