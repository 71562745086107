import React from "react";
import TitleCard from "./TitleCard";
import DetailCard from "./DetailCard";
import { nanoid } from "nanoid";
import { AuthContext } from "../Services/Authcontext";

class CompetitionsCard extends React.Component {
  static contextType = AuthContext

  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
      editId: '',
      isEdit: false,
      icon: '/image/icons/education.png',
      title: '',
      section: '',
      startDate: '',
      endDate: '',
      header: 'Competitions',
      competitionsList: [

      ]
    };
    this.handleForm = this.handleForm.bind(this);
    this.handleCompetitionsDelete = this.handleCompetitionsDelete.bind(this);
    this.handleCompetitionsEdit = this.handleCompetitionsEdit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleForm() {
    this.setState({ showForm: !this.state.showForm })
  }

  handleCompetitionsEdit(id) {
    this.setState({ isEdit: !this.state.isEdit })
    this.setState({ editId: id })
    this.setState({ showForm: !this.state.showForm })
    const EditCompetitions = this.state.competitionsList.filter(competitions => competitions.id === id);
    EditCompetitions.map((editData) => {
      this.setState({ id: editData.id });
      this.setState({ title: editData.title });
      this.setState({ section: editData.section });
      this.setState({ startDate: editData.startDate });
      this.setState({ endDate: editData.endDate });
      return '';
    });
  }

  handleCompetitionsDelete(id) {
    const deleteCompetitions = this.state.competitionsList.filter(competitions => competitions.id !== id);
    this.setState({ competitionsList: deleteCompetitions })
    this.handleState();
  }

  handleSubmit(e, formType) {
    e.preventDefault();
    const { dispatch } = this.context;
    dispatch({
      type: 'DATA_UPDATED',
      payload: true
    })
    if (formType === 'add') {
      const addCompetitions = {
        id: nanoid(),
        icon: '/image/icons/education.png',
        title: this.state.title,
        section: this.state.section,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      }
      this.setState({ competitionsList: [...this.state.competitionsList, addCompetitions] })

    } else {
      const EditCompetitions = this.state.competitionsList.map(competitions => {
        if (competitions.id === this.state.editId) {
          return {
            ...competitions, title: this.state.title,
            section: this.state.section,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          };
        }
        return competitions;
      });
      this.setState({ competitionsList: EditCompetitions })
      this.setState({ isEdit: !this.state.isEdit })
      this.setState({ editId: '' })
    }
    this.setState({ showForm: !this.state.showForm })
    this.setState({ title: '' })
    this.setState({ section: '' })
    this.setState({ startDate: '' })
    this.setState({ endDate: '' })
    this.handleState();
  }
  handleState() {
    const { dispatch } = this.context;
    setTimeout(() => {
      dispatch({
        type: 'ADD_COMPETITION',
        payload: this.state.competitionsList
      })
      dispatch({
        type: 'COMPETITION_HEADER',
        payload: this.state.header
      })
    }, 1000)
  }
  componentDidMount() {
    this.setState({ header: this.context.competitionsTitle })
    if (this.context.competitions && this.context.competitions !== null) {
      this.setState({ competitionsList: this.context.competitions })
    }
    document.getElementById("competitionsproj_start_date").setAttribute("max", this.props.handleDateValiadation((new Date())));
  }
  render() {
    return <>
      <div id="competitions_add" className={(this.props.Display) ? 'mt-4' : "d-none"}>
        <form onSubmit={(e) => { this.handleSubmit(e, (this.state.isEdit) ? 'edit' : 'add') }} method="post" className={(this.state.showForm) ? "mt-4" : 'd-none'}>
          {(this.state.showForm) ? '' : this.state.header}
          <div className="form-floating mb-3">
            <input type="text" name="header_title" value={this.state.header}
              onChange={(e) => {
                this.setState({ header: e.target.value });
                document.getElementById('competitions_title').innerHTML = e.target.value
              }} id="competitions_header_title"
              className="form-control" />
            <label htmlFor="trainingproj_title">Title</label>
          </div>
          <div className="form-floating mb-3">
            <input type="text" name="competitionsproj_title" id="competitionsproj_title" value={this.state.title} onChange={(e) => { this.setState({ title: e.target.value }) }}
              className="form-control resume_competitionsproj_input"
              placeholder="Title" />
            <label htmlFor="competitionsproj_title">Section Name</label>
          </div>
          <div className="form-floating mb-3">
            <input type="text" name="competitionsproj_section" id="competitionsproj_section" value={this.state.section} onChange={(e) => { this.setState({ section: e.target.value }) }}
              className="form-control resume_competitionsproj_input"
              placeholder="Section Name" />
            <label htmlFor="competitionsproj_section">Section Description</label>
          </div>
          <div className="row mb-4">
            <div className="col mb-1">
              <div className="form-floating">
                <input type="date" name="competitionsproj_start_date" id="competitionsproj_start_date" value={this.state.startDate} onChange={(e) => { this.setState({ startDate: e.target.value }); document.getElementById('competitionsproj_end_date').setAttribute("min", this.props.handleDateValiadation(new Date(e.target.value))) }}
                  className="form-control resume_competitionsproj_input_date"
                  placeholder="Start Date" />
                <label htmlFor="competitionsproj_start_date">Start Date</label>
              </div>
            </div>
            <div className="col mb-1">
              <div className="form-floating">
                <input type="date" name="competitionsproj_end_date" id="competitionsproj_end_date" value={this.state.endDate} onChange={(e) => { this.setState({ endDate: e.target.value }) }}
                  className="form-control resume_competitionsproj_input_date"
                  placeholder="End Date" />
                <label htmlFor="competitionsproj_end_date">End Date </label>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col ms-2">
              <button className="btn btn-outline-primary mt-3" type="button" onClick={this.handleForm}>Cancel</button>
            </div>

            <div className="col">
              <button className="btn btn-primary mt-3 float-end" id="competitions_add_done_btn" type="submit">{(this.state.isEdit) ? 'Add' : 'Add'}</button>
            </div>
          </div>
        </form>
        <div className={(this.state.showForm) ? "d-none" : ''}>
          <TitleCard Title={this.state.header} Section={'Section Name'} Description={'Competitions'} />
          {
            this.state.competitionsList.map((competitions, index) => {
              return <DetailCard key={index} handleDelete={this.handleCompetitionsDelete} handleEdit={this.handleCompetitionsEdit} Id={competitions.id} Icon={competitions.icon} Title={competitions.title} Industry={competitions.section} Location={''} StartDate={competitions.startDate} EndDate={competitions.endDate} Summary={''} />
            })
          }
        </div>
        <div id="competitions_project" className={(this.state.showForm) ? "d-none" : ''}>
          <div className="row mb-4 mt-3">
            <div className="col">
            </div>
            <div className="col text-end">
              <button type="button" className="btn add_btn" id="competitions_add_btn" onClick={this.handleForm}><i
                className="bi bi-plus"></i> add</button>

            </div>
          </div>
        </div>
      </div>
    </>;
  }
}

export default CompetitionsCard;