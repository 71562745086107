import React from "react";
import TitleCard from "./TitleCard";
import DetailCard from "./DetailCard";
import { nanoid } from "nanoid";
import { AuthContext } from "../Services/Authcontext";

class HonoursandAwardsCard extends React.Component {
    static contextType = AuthContext

    constructor(props) {
        super(props)
        this.state = {
            showForm: false,
            editId: '',
            isEdit: false,
            icon: '/image/icons/awards.svg',
            title: '',
            section: '',
            startDate: '',
            endDate: '',
            summary: '',
            header: 'Honours and Awards',
            honourList: [
                // {
                //     id: "default",
                //     icon: '/image/icons/awards.svg',
                //     title: 'Project Manager (3 Projects)',
                //     section: 'ABC IT Solutions',
                //     startDate: '2014',
                //     endDate: '2019',
                //     // Summary: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate similique optio tenetur nemo? Excepturi.',

                // },
            ]
        };
        this.handleForm = this.handleForm.bind(this);
        this.handleHonourDelete = this.handleHonourDelete.bind(this);
        this.handleHonourEdit = this.handleHonourEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleForm() {
        this.setState({ showForm: !this.state.showForm })
    }

    handleHonourEdit(id) {
        this.setState({ isEdit: !this.state.isEdit })
        this.setState({ editId: id })
        this.setState({ showForm: !this.state.showForm })
        const EditHonour = this.state.honourList.filter(honour => honour.id === id);
        EditHonour.map((editData) => {
            this.setState({ id: editData.id });
            this.setState({ title: editData.title });
            this.setState({ section: editData.section });
            this.setState({ startDate: editData.startDate });
            this.setState({ endDate: editData.endDate });
            return '';
        });
    }

    handleHonourDelete(id) {
        const deleteHonour = this.state.honourList.filter(honour => honour.id !== id);
        this.setState({ honourList: deleteHonour });
        this.handleState();
    }

    handleSubmit(e, formType) {
        e.preventDefault();
        const { dispatch } = this.context;
        dispatch({
            type: 'DATA_UPDATED',
            payload: true
        })
        if (formType === 'add') {
            const addHonour = {
                id: nanoid(),
                icon: '/image/icons/awards.svg',
                title: this.state.title,
                section: this.state.section,
                startDate: this.state.startDate,
                endDate: this.state.endDate,

            }
            this.setState({ honourList: [...this.state.honourList, addHonour] })

        } else {
            const EditHonour = this.state.honourList.map(honour => {
                if (honour.id === this.state.editId) {
                    return {
                        ...honour, title: this.state.title,
                        section: this.state.section,
                        startDate: this.state.startDate,
                        endDate: this.state.endDate,
                    };
                }
                return honour;
            });
            this.setState({ honourList: EditHonour })
            this.setState({ isEdit: !this.state.isEdit })
            this.setState({ editId: '' })
        }
        this.setState({ showForm: !this.state.showForm })
        this.setState({ title: '' })
        this.setState({ section: '' })
        this.setState({ startDate: '' })
        this.setState({ endDate: '' })
        this.handleState();
    }

    handleState() {
        const { dispatch } = this.context;
        setTimeout(() => {
            dispatch({
                type: 'ADD_HONOURS',
                payload: this.state.honourList
            })
            dispatch({
                type: 'HONOURS_HEADER',
                payload: this.state.header
            })
        }, 1000)
    }

    componentDidMount() {
        this.setState({ header: this.context.honoursTitle })
        if (this.context.honours && this.context.honours !== null) {
            this.setState({ honourList: this.context.honours })
        }
        document.getElementById("award_date").setAttribute("max", this.props.handleDateValiadation((new Date())));
    }
    render() {
        return (
            <>
                <div id="honours_add" className={(this.props.Display) ? 'mt-4' : "d-none"}>
                    <form onSubmit={(e) => { this.handleSubmit(e, (this.state.isEdit) ? 'edit' : 'add') }} method="post" className={(this.state.showForm) ? "mt-4" : 'd-none'}>
                        {(this.state.showForm) ? '' : this.state.header}
                        <div className="form-floating mb-3">
                            <input type="text" name="header_title" value={this.state.header}
                                onChange={(e) => {
                                    this.setState({ header: e.target.value });
                                    document.getElementById('award_res_title').innerHTML = e.target.value
                                }} id="award_header_title"
                                className="form-control" />
                            <label htmlFor="trainingproj_title">Title</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" name="award_section" id="award_title" className="form-control resume_awards_input" value={this.state.title} onChange={(e) => { this.setState({ title: e.target.value }) }} placeholder="Title" />
                            <label htmlFor="award_title">Section Name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" name="award_name" id="award_name" className="form-control resume_awards_input" value={this.state.section} onChange={(e) => { this.setState({ section: e.target.value }) }} placeholder="Section" />
                            <label htmlFor="section_name">Section Description</label>
                        </div>
                        <div className="row mb-4">
                            <div className="col">
                                <div className="form-floating">
                                    <input type="date" name="award_date" value={this.state.startDate} onChange={(e) => { this.setState({ startDate: e.target.value }); document.getElementById('award_end_date').setAttribute("min", this.props.handleDateValiadation(new Date(e.target.value))) }} id="award_date" className="form-control resume_award_input_date"
                                        placeholder="Start Date" />
                                    <label htmlFor="start_date">Start Date</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating">
                                    <input type="date" name="award_end_date" id="award_end_date" value={this.state.endDate} onChange={(e) => { this.setState({ endDate: e.target.value }) }} className="form-control"
                                        placeholder="End Date" />
                                    <label htmlFor="end_date">End Date </label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4 mx-0">
                            <div className="col">
                                <button className="btn btn-outline-primary mt-3" type="button" onClick={this.handleForm}>Cancel</button>
                            </div>
                            <div className="col">
                                <button className="btn btn-primary px-4 mt-3 float-end" type="submit">{(this.state.isEdit) ? 'Add' : 'Add'}</button>
                            </div>
                        </div>
                    </form>
                    <div className={(this.state.showForm) ? "d-none" : ''}>
                        <TitleCard Title={this.state.header} Section={'Section Name'} Description={'Awards'} />
                        {
                            this.state.honourList.map((honours, index) => {
                                return <DetailCard key={index} handleDelete={this.handleHonourDelete} handleEdit={this.handleHonourEdit} Id={honours.id} Icon={honours.icon} Title={honours.title} Industry={honours.section} Location={''} StartDate={honours.startDate} EndDate={honours.endDate} Summary={''} />
                            })
                        }
                    </div>
                    <div id="honours_and_awards" className={(this.state.showForm) ? "d-none" : ''}>
                        <div className="row mb-4 mt-3">
                            <div className="col">
                            </div>
                            <div className="col text-end">
                                <button type="button" className="btn add_btn" id="honours_add_btn" onClick={this.handleForm}><i
                                    className="bi bi-plus"></i> add</button>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}

export default HonoursandAwardsCard;