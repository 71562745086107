import { Markup } from 'interweave'
import React from 'react'

const ExperienceCard = ({ Id, JobTitle, Empoloyer, Client, City, State, StartDate, EndDate, Summary, IsCurrent, handleJobDelete, handleJobEdit }) => {
  return (
    <>
      <div className="row pt-3 mx-0 ms-0 border mt-2">
        <div className="col-2 text-center">
          <img src={process.env.PUBLIC_URL + "/image/icons/job.png"} alt='icon' />
        </div>
        <div className="col-10 ps-lg-0">
          <p className="fw-bold small mb-0">{JobTitle}</p>
          <p className="text-muted small mb-0">{Empoloyer}{(City && City !== '' && City !== null)?', '+City:''}{(State && State !== '' && State !== null)?', '+State+'.':''}</p>
          <p className="text-muted small mb-0">{Client}</p>
          <p className="text-muted small mb-0">{(StartDate) ? new Date(StartDate).toString().split(' ')[1] + " " + new Date(StartDate).toString().split(' ')[3]:""} {(EndDate) ? `- ${new Date(EndDate).toString().split(' ')[1] + " " + new Date(EndDate).toString().split(' ')[3]}` : ''} </p>
          <Markup content={Summary} className="small" />
          <div className="row mb-3">
            <div className="col">
              {(IsCurrent) ? (<span className="small alert-success rounded-pill py-1 px-2">Currently working</span>) : ''}
            </div>
            <div className="col-auto">
              <span className="text-end text-decoration-underline text-danger" onClick={() => { handleJobDelete(Id) }}><i className="bi bi-trash"></i> </span>
            </div>
            <div className="col-auto">
              <span className="text-end text-decoration-underline text-primary" onClick={() => { handleJobEdit(Id) }}><i className="bi bi-pencil"></i> </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExperienceCard
